import React, { useContext, useState } from "react";
/// React router dom
import { Link } from "react-router-dom";
import { ThemeContext } from "../../../context/ThemeContext";
import BookagriName from "../../components/ui/svg/BookagriName";
import Bookagri_Icon from "../../components/ui/svg/Bookagri_Icon";

export function NavMenuToggle() {
  setTimeout(() => {
    let mainwrapper = document.querySelector("#main-wrapper");
    if (mainwrapper.classList.contains("menu-toggle")) {
      mainwrapper.classList.remove("menu-toggle");
    } else {
      mainwrapper.classList.add("menu-toggle");
    }
  }, 0);
}

const NavHader = () => {
  const [toggle, setToggle] = useState(false);
  const { navigationHader, openMenuToggle, background } =
    useContext(ThemeContext);

  return (
    <div className='nav-header'>
      <Link to='/dashboard' className={`logo-container d-flex justify-content-center align-content-center flex-wrap mt-4 ${toggle && "px-4"}`}>
        <div className={`bookagri-name-nav my-auto ${toggle ? "hide" : ""}`}>
          <BookagriName />
        </div>
        <div className='bookagri-icon-nav ms-2'>
          <Bookagri_Icon />
        </div>
      </Link>

      <div
        className='nav-control'
        onClick={() => {
          setToggle(!toggle);
          NavMenuToggle();
        }}
      >
        <div className={`hamburger ${toggle ? "is-active" : ""}`}>
          <span className='line'></span>
          <span className='line'></span>
          <span className='line'></span>
        </div>
      </div>
    </div>
  );
};

export default NavHader;
