import "react-phone-number-input/style.css";
import { getPublicTerms } from "../../../queries/queryFunctions/publicQueryFns/getQueryFns";
import { useQuery } from "@tanstack/react-query";
import { useEffect } from "react";
import { usePublicContext } from "../../../providers/ReactPublicContextProvider";


export default function Privacy() {
    const { setIsLoading } = usePublicContext();


    const {
        data: privacyData,
        isPending: isLoadingPrivacyData,
        isError: isErrorPrivacyData,
        refetch,
      } = useQuery({
        queryKey: ["privacy-policy"],
        queryFn: getPublicTerms,
      });

      useEffect(() => {
        setIsLoading(isLoadingPrivacyData);
      }, [isLoadingPrivacyData]);


  return (
    <div className='authincation justify-content-center py-5'>
        {privacyData && (
        <div dangerouslySetInnerHTML={{ __html: privacyData.privacyPolicyEn }} />
        )}
    </div>

  );
}
