import { useMutation } from "@tanstack/react-query";
import { useFormik } from "formik";
import { usePublicContext } from "../../../../providers/ReactPublicContextProvider";
import { useSearchParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { patchAddSiteStep3QueryFn } from "../../../../queries/queryFunctions/privetQueryFns/patchQueryFns";
import { toast } from "react-toastify";
import {
  postUploadImageQueryFn,
  postUploadImagesQueryFn,
  postUploadVideoQueryFn,
} from "../../../../queries/queryFunctions/privetQueryFns/postQueryFns";
import {
  ACCEPT_IMAGES_TYPES_OBJECT,
  ACCEPT_VIDEO_TYPES_OBJECT,
  MAX_SIZE_IMAGE,
  MAX_SIZE_VIDEO,
  S3_FILES_NAME,
} from "../../../../utils/constants";
import { addSiteStep3Schema } from "../../../../utils/forms-schemas";
import Label from "../../ui/custom-inputs/Label";
import FileDropzone from "../../ui/FileDropzone";
import ErrorFormik from "../../ui/ErrorFormik";
import Hr from "../../ui/Hr";
import ReorderableImageList from "../../ui/ReorderableImageList";
import VideoPreview from "../../ui/VideoPreview";

const StepThree = ({ handleNext, handlePrev, ExperienceId, siteInfo }) => {
  const { setIsLoading } = usePublicContext();
  const [searchParams, setSearchParams] = useSearchParams();

  const [experienceMainPhoto, setExperienceMainPhoto] = useState("");
  const [experienceMainPhotos, setExperienceMainPhotos] = useState([]);
  const [experienceVideos, setExperienceVideos] = useState([]);

  const {
    values,
    errors,
    touched,
    handleBlur,
    handleChange,
    handleSubmit,
    setFieldValue,
    setFieldTouched,
    setValues,
    isValid,
  } = useFormik({
    initialValues: {
      experienceMainPhoto: "",
      experienceMainPhotos: [],
      experienceVideos: [],
    },
    enableReinitialize: true,
    validationSchema: addSiteStep3Schema,
    onSubmit: onSubmit,
  });

  const {
    mutate,
    isPending: isLoadingMutate,
    isError: isErrorMutate,
  } = useMutation({
    mutationFn: patchAddSiteStep3QueryFn,
    onSuccess(data) {
      setSearchParams({ id: data?._id, step: 4 });
      handleNext();
    },
    onError(error) {
      console.error("error Site Step 3: ", error);
      toast.error("Something went wrong, please try later!");
    },
  });

  const {
    mutate: mutateUploadImage,
    isPending: isLoadingUploadImage,
    isError: IsErrorUploadImage,
    data: dataUploadImage,
    error: errorUploadImage,
  } = useMutation({
    mutationFn: postUploadImageQueryFn,
    onSuccess(data) {
      setIsLoading(false);
      setFieldValue("experienceMainPhoto", data);
      setExperienceMainPhoto("");
      toast.success("Upload Stay Main Photo successfully");
    },
    onError(error) {
      setIsLoading(false);
      toast.error(error?.response?.data?.message);
    },
  });

  const {
    mutate: mutateUploadImages,
    isPending: isLoadingUploadImages,
    isError: IsErrorUploadImages,
    data: dataUploadImages,
    error: errorUploadImages,
  } = useMutation({
    mutationFn: postUploadImagesQueryFn,
    onError(error) {
      setIsLoading(false);
      toast.error(error?.response?.data?.message);
    },
  });

  function onSuccessExperienceMainPhotos(data, variables, context) {
    setIsLoading(false);
    setFieldValue("experienceMainPhotos", [
      ...values.experienceMainPhotos,
      ...data,
    ]);
    setExperienceMainPhotos([]);
    toast.success("Upload Stay Main Photos successfully");
  }

  const {
    mutate: mutateUploadVideos,
    isPending: isLoadingUploadVideos,
    isError: IsErrorUploadVideos,
    data: dataUploadVideos,
    error: errorUploadVideos,
  } = useMutation({
    mutationFn: postUploadVideoQueryFn,
    onSuccess(data) {
      setIsLoading(false);
      setFieldValue("experienceVideos", [...values.experienceVideos, data]);
      setExperienceVideos([]);
      toast.success("Upload Stay Video successfully");
    },
    onError(error) {
      setIsLoading(false);
      toast.error(error?.response?.data?.message);
    },
  });

  async function onImageUpload() {
    if (experienceMainPhoto?.length && experienceMainPhoto[0] instanceof File) {
      setIsLoading(true);
      mutateUploadImage({
        file: experienceMainPhoto[0],
        type: S3_FILES_NAME.PLACES_MEDIA,
      });
    }
  }

  async function onExperienceMainImagesUpload(type) {
    if (
      experienceMainPhotos?.length &&
      experienceMainPhotos[0] instanceof File
    ) {
      setIsLoading(true);
      mutateUploadImages(
        { file: experienceMainPhotos, type: S3_FILES_NAME.PLACES_MEDIA },
        { onSuccess: onSuccessExperienceMainPhotos },
      );
    }
  }

  async function onVideosUpload() {
    if (experienceVideos?.length && experienceVideos[0] instanceof File) {
      setIsLoading(true);
      mutateUploadVideos({
        file: experienceVideos[0],
        type: S3_FILES_NAME.PLACES_MEDIA,
      });
    }
  }

  useEffect(() => {
    setValues({
      experienceMainPhoto: siteInfo?.site?.mainImage || "",
      experienceMainPhotos: siteInfo?.site?.images?.length
        ? siteInfo?.site?.images
        : [],
      experienceVideos: siteInfo?.site?.images?.length
        ? siteInfo?.site?.videos
        : [],
    });
  }, [siteInfo]);

  useEffect(() => {
    onImageUpload();
  }, [experienceMainPhoto]);

  useEffect(() => {
    onExperienceMainImagesUpload();
  }, [experienceMainPhotos]);

  useEffect(() => {
    onVideosUpload();
  }, [experienceVideos]);

  useEffect(() => {
    setIsLoading(isLoadingMutate);
  }, [isLoadingMutate]);

  function onSubmit() {
    mutate({
      id: ExperienceId,
      mainImage: values?.experienceMainPhoto,
      images: values?.experienceMainPhotos,
      videos: values?.experienceVideos,
    });
  }

  const handleErrors = async () => {
    const err = Object.values(errors);
    if (err.length > 0) {
      toast.error(err[0]);
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      {/* Stay Main Photo */}
      <section>
        <Label
          forId='experienceMainPhoto'
          text='Stay Main Photo'
          isRequired={true}
        />
        <div className='w-100 d-flex flex-column gap-3'>
          {values?.experienceMainPhoto && (
            <img
              style={{ width: "250px", height: "250px", objectFit: "cover" }}
              className='img-fluid img-thumbnail rounded'
              src={values?.experienceMainPhoto}
              alt='experienceMainPhoto'
            />
          )}
          <FileDropzone
            name={"experienceMainPhoto"}
            setState={setExperienceMainPhoto}
            value={false}
            filesAccept={ACCEPT_IMAGES_TYPES_OBJECT}
            maxSize={MAX_SIZE_IMAGE}
          />
          <ErrorFormik
            isError={errors?.experienceMainPhoto}
            isTouched={touched?.experienceMainPhoto}
            error={errors?.experienceMainPhoto}
          />
        </div>
      </section>
      <Hr style={"my-5"} />
      {/* Stay Main Photos */}
      <section>
        <Label
          forId='experienceMainPhotos'
          text='Stay Photos'
          isRequired={true}
        />
        <div className='w-100 d-flex flex-column gap-3'>
          {values.experienceMainPhotos.length > 0 && (
            <ReorderableImageList
              items={values?.experienceMainPhotos?.map((item) => ({
                id: item,
                content: (
                  <img
                    style={{
                      width: "220px",
                      height: "220px",
                      objectFit: "cover",
                    }}
                    className='rounded'
                    src={item}
                    alt={item}
                  />
                ),
              }))}
              setItems={(val) => {
                setFieldValue(
                  "experienceMainPhotos",
                  val.map((val) => val.id),
                );
              }}
            />
          )}

          <FileDropzone
            multiple={true}
            name={"experienceMainPhotos"}
            setState={setExperienceMainPhotos}
            value={false}
            filesAccept={ACCEPT_IMAGES_TYPES_OBJECT}
            maxSize={MAX_SIZE_IMAGE}
          />
          <ErrorFormik
            isError={errors?.experienceMainPhotos}
            isTouched={touched?.experienceMainPhotos}
            error={errors?.experienceMainPhotos}
          />
        </div>
      </section>
      <Hr style={"my-5"} />
      {/* Stay Videos */}
      <section>
        <Label forId='experienceVideos' text='Stay Videos' isRequired={false} />

        <div className='w-100 d-flex flex-column gap-3'>
          {values.experienceVideos.length > 0 && (
            <ReorderableImageList
              items={values?.experienceVideos?.map((item, index) => ({
                id: item,
                content: (
                  <VideoPreview video={item} width='300px' height='250px' />
                ),
              }))}
              setItems={(val) => {
                setFieldValue(
                  "experienceVideos",
                  val.map((val) => val.id),
                );
              }}
            />
          )}

          <FileDropzone
            multiple={false}
            name={"experienceVideos"}
            setState={setExperienceVideos}
            value={false}
            filesAccept={ACCEPT_VIDEO_TYPES_OBJECT}
            maxSize={MAX_SIZE_VIDEO}
          />
          <ErrorFormik
            isError={errors?.experienceVideos}
            isTouched={touched?.experienceVideos}
            error={errors?.experienceVideos}
          />
        </div>
      </section>
      <Hr style={"my-5"} />
      <Hr />
      {/* Buttons */}
      <div className='text-end toolbar toolbar-bottom ps-2'>
        <button
          type='button'
          className='btn btn-secondary sw-btn-prev me-1'
          onClick={() => handlePrev()}
        >
          Prev
        </button>
        <button
          type='submit'
          onClick={handleErrors}
          className='btn btn-primary sw-btn-next ms-1'
        >
          Next
        </button>
      </div>
    </form>
  );
};
// gallery images /  main image
export default StepThree;
