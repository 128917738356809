import { Link } from "react-router-dom";
/// Scroll
import PerfectScrollbar from "react-perfect-scrollbar";

/// Image
import profile from "../../../images/user.jpg";
import avatar from "../../../images/avatar/1.jpg";
import { Dropdown } from "react-bootstrap";
import LogoutPage from "./Logout";
import { USER_DETAILS } from "../../../utils/constants";
import { useContext, useEffect, useState } from "react";
import { usePublicContext } from "../../../providers/ReactPublicContextProvider";
import ReactThemeToggleButton from "../../components/ui/ThemeToggleButton";
import { ThemeContext } from "../../../context/ThemeContext";

const Header = ({ onNote }) => {
  const { changeBackground } = useContext(ThemeContext);

  const [isDark, setDark] = useState(false);

  useEffect(() => {
    if (isDark) {
      changeBackground({ value: "dark", label: "Dark" });
    } else {
      changeBackground({ value: "light", label: "Light" });
    }

  }, [isDark]);

  var path = window.location.pathname.split("/");
  var name = path[1].split("-");

  const storedUserData = localStorage.getItem(USER_DETAILS);
  const userData = storedUserData ? JSON.parse(storedUserData) : null;
  const { userDataUpdated } = usePublicContext();


  useEffect(() => {
  }, [userDataUpdated])
  return (
    <div className='header border-bottom'>
      <div className='header-content'>
        <nav className='navbar navbar-expand'>
          <div className='collapse navbar-collapse justify-content-between'>
            <div className='header-left'>
              <div
                className='dashboard_bar'
                style={{ textTransform: "capitalize" }}
              >
                {name.join(" ").length === 0
                  ? "Dashboard"
                  : name.join(" ") === "dashboard dark"
                    ? "Dashboard"
                    : name.join(" ")}
              </div>
            </div>
            <ul className='navbar-nav header-right'>
              <div className='nav-item'>
                <ReactThemeToggleButton
                  isDark={isDark}
                  onChange={() => setDark((prev) => !prev)}
                />
              </div>
              <Dropdown as='li' className='nav-item dropdown header-profile'>
                <Dropdown.Toggle
                  variant=''
                  as='a'
                  className='nav-link i-false c-pointer'
                >
                  <img src={userData?.profileImageUrl  || "https://placehold.co/50x50" } width={20} alt='' />
                </Dropdown.Toggle>
                <Dropdown.Menu
                  align='right'
                  className='mt-3 dropdown-menu dropdown-menu-end'
                >
                  <Link to={`/profile?id=${userData?._id}`}
                    className='dropdown-item ai-icon'>
                    <svg
                      id='icon-user1'
                      xmlns='http://www.w3.org/2000/svg'
                      className='text-primary me-1'
                      width={18}
                      height={18}
                      viewBox='0 0 24 24'
                      fill='none'
                      stroke='currentColor'
                      strokeWidth={2}
                      strokeLinecap='round'
                      strokeLinejoin='round'
                    >
                      <path d='M20 21v-2a4 4 0 0 0-4-4H8a4 4 0 0 0-4 4v2' />
                      <circle cx={12} cy={7} r={4} />
                    </svg>
                    <span className='ms-2'>Profile </span>
                  </Link>
                  <Link to={`/change-password?id=${userData?._id}`}
                    className='dropdown-item ai-icon'>
                    <svg
                      id='icon-user1'
                      xmlns='http://www.w3.org/2000/svg'
                      className='text-primary me-1'
                      width={18}
                      height={18}
                      viewBox='0 0 24 24'
                      fill='none'
                      stroke='currentColor'
                      strokeWidth={2}
                      strokeLinecap='round'
                      strokeLinejoin='round'
                    >
                      <rect x="3" y="11" width="18" height="11" rx="2" ry="2" />
                      <path d="M7 11V7a5 5 0 0 1 10 0v4" />
                    </svg>
                    <span className='ms-2'>Change Password </span>
                  </Link>
                  <LogoutPage />

                </Dropdown.Menu>
              </Dropdown>
            </ul>
          </div>
        </nav>
      </div>
    </div>
  );
};

export default Header;
