import axios from "axios";
import { USER_DETAILS } from "../utils/constants";

const api = axios.create({
  baseURL: process.env.REACT_APP_PUBLIC_API_URL,
  headers: {
    "Content-Type": "application/json",
  },
});

api.interceptors.request.use(
  async (config) => {
    const storedUserData = localStorage.getItem(USER_DETAILS);
    const userData = storedUserData ? JSON.parse(storedUserData) : null;

    if (userData && userData?.token) {
      config.headers["Authorization"] = `Bearer ${userData.token}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  },
);

export const get = async (url, params = {}, headers = {}) => {
  try {
    const response = await api.get(url, { params }, headers);
    return response?.data;
  } catch (error) {
    throw error;
  }
};

export const post = async (url, data, headers = {}) => {
  try {
    const response = await api.post(url, data, headers);
    return response?.data;
  } catch (error) {
    throw error;
  }
};

export const put = async (url, data, headers = {}) => {
  try {
    const response = await api.put(url, data, headers);
    return response?.data;
  } catch (error) {
    throw error;
  }
};

export const patch = async (url, data, headers = {}) => {
  try {
    const response = await api.patch(url, data, headers);
    return response?.data;
  } catch (error) {
    throw error;
  }
};

export const del = async (url, headers = {}) => {
  try {
    const response = await api.delete(url, headers);
    return response?.data;
  } catch (error) {
    throw error;
  }
};

export default api;
