import { Link } from "react-router-dom";
import loginbg from "../../../images/pic1.png";
import { useFormik } from "formik";
import { useMutation } from "@tanstack/react-query";
import { toast } from "react-toastify";
import { SignInSchema } from "../../../utils/forms-schemas";
import {
  postSignInQueryFn,
  postSignInWithSocialQueryFn,
} from "../../../queries/queryFunctions/publicQueryFns/postQueryFns";
import { usePublicContext } from "../../../providers/ReactPublicContextProvider";
import { useEffect, useState } from "react";
import ErrorFormik from "../../components/ui/ErrorFormik";
import useUser from "../../../utils/hooks/useUser";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';

import GoogleLogin from "../../components/ui/auth/GoogleLogin";
import FacebookLogIn from "../../components/ui/auth/FacebookLogIn";
import AppleLogin from "../../components/ui/auth/AppleLogin";
import BookagriName from "../../components/ui/svg/BookagriName";
import Bookagri_Icon from "../../components/ui/svg/Bookagri_Icon";

function Login(props) {
  const { setIsLoading, isLoading } = usePublicContext();
  const { redirectTo, isAuthenticUser, setUserData } = useUser();
  const [showPassword, setShowPassword] = useState(false);
  const [accountLocked, setAccountLocked] = useState(false);


  const {
    values,
    errors,
    touched,
    handleBlur,
    handleChange,
    handleSubmit,
    setFieldValue,
    setFieldTouched,
    isValid,
  } = useFormik({
    initialValues: {
        email: "",
        password: "",
    },
    validationSchema: SignInSchema,
    onSubmit: onSubmit,
  });

  const {
    mutate,
    isPending: isLoadingMutate,
    isError: isErrorMutate,
  } = useMutation({
    mutationFn: postSignInQueryFn,
    onSuccess(data) {
      const userData = data?.user;
      const token = data?.token;
      setUserData({ ...userData, token });
      redirectTo("/dashboard");
      setIsLoading(false);
    },
    onError(error) {
      if (error?.response?.status === 401) {
        toast.error("Please Verify your email first");
        redirectTo("/verification-code");
      } else {
        toast.error(error.response.data.message);
      }
    },
  });

  const {
    mutate: socialMutate,
    isPending: isLoadingSocialMutate,
    isError: isErrorSocialMutate,
  } = useMutation({
    mutationFn: postSignInWithSocialQueryFn,
    onSuccess(data, variables) {
      if (!data.user.isProfileCompleted) {
        sessionStorage.setItem("socialData", JSON.stringify(variables));
        redirectTo("/complete-information");
        return;
      }
      const userData = data?.user;
      const token = data?.token;
      setUserData({ ...userData, token });
      redirectTo("/dashboard");
      setIsLoading(false);
    },
    onError(error) {
      console.error("error social Mutate: ", error);
      toast.error("Something went wrong, please try again later!");
    },
  });

  useEffect(() => {
    if (isAuthenticUser) setIsLoading(true);

    if (isAuthenticUser) {
      redirectTo("/dashboard");
    }
    setIsLoading(false);
  }, []);

  useEffect(() => {
    setIsLoading(isLoadingMutate || isLoadingSocialMutate);
  }, [isLoadingMutate, isLoadingSocialMutate]);

  useEffect(() => {
    setFieldTouched("", "");
  }, []);

  async function onSubmit() {
    mutate({
      ...values,
    });
  }

  const onClickSocial = (data) => {
    socialMutate(data);
  };

  return (
    <div className='authincation d-flex flex-column flex-lg-row flex-column-fluid'>
      <div className='login-aside text-center  d-flex flex-column flex-row-auto'>
        <div className='d-flex flex-column-auto flex-column pt-lg-40 pt-15'>
          <div className='d-flex justify-content-center mb-3'>
            <div className={``}>
              <BookagriName />
            </div>
            <div className='ms-2'>
              <Bookagri_Icon />
            </div>
          </div>
        </div>
        <div
          className='aside-image'
          style={{ backgroundImage: "url(" + loginbg + ")" }}
        ></div>
      </div>
      <div className='container flex-row-fluid d-flex flex-column justify-content-center position-relative overflow-hidden p-7 mx-auto'>
        <div className='d-flex justify-content-center h-100 align-items-center'>
          <div className='authincation-content style-2'>
            <div className='row no-gutters'>
              <div className='col-xl-12 tab-content'>
                <div id='sign-in' className='auth-form   form-validation'>
                  {props.errorMessage && (
                    <div className='bg-red-300 text-red-900 border border-red-900 p-1 my-2'>
                      {props.errorMessage}
                    </div>
                  )}
                  {props.successMessage && (
                    <div className='bg-green-300 text-green-900 border border-green-900 p-1 my-2'>
                      {props.successMessage}
                    </div>
                  )}
                  <form onSubmit={handleSubmit} className='form-validate'>
                    <h3 className='text-center mb-4 text-black'>
                      Sign in your account
                    </h3>
                    <div className='form-group mb-3'>
                      <label className='mb-1' htmlFor='val-email'>
                        <strong>Email</strong>
                      </label>
                      <div>
                        <input
                          className='form-control'
                          type='email'
                          name='email'
                          value={values?.email}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          placeholder='Type Your Email Address'
                        />
                      </div>
                      <ErrorFormik
                        isError={errors?.email}
                        isTouched={touched?.email}
                        error={errors?.email}
                      />
                    </div>
                    <div className="form-group mb-3 position-relative">
                    <label className="mb-1">
                      <strong>Password</strong>
                    </label>
                    <div className="input-group">
                      <input
                        className="form-control"
                        name="password"
                        type={showPassword ? "text" : "password"}
                        value={values?.password}
                        placeholder="Type Your Password"
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                      <div
                        className="input-group-append"
                        onClick={() => setShowPassword(!showPassword)}
                        style={{
                          cursor: "pointer",
                          position: "absolute",
                          right: "10px",
                          top: "50%",
                          transform: "translateY(-50%)",
                          zIndex: "1",
                        }}
                      >
                        <FontAwesomeIcon
                          icon={showPassword ? faEyeSlash : faEye}
                          color="#999"
                        />
                      </div>
                    </div>
                      <ErrorFormik
                        isError={errors?.password}
                        isTouched={touched?.password}
                        error={errors?.password}
                      />
                    </div>
                    <div className='form-row d-flex justify-content-between mt-4 mb-2'>
                      <div className='form-group mb-3'>
                        {/* <div className='custom-control custom-checkbox ml-1'>
                          <input
                            type='checkbox'
                            className='form-check-input'
                            id='basic_checkbox_1'
                          />
                          <label
                            className='form-check-label'
                            htmlFor='basic_checkbox_1'
                          >
                            Remember my preference
                          </label>
                        </div> */}
                      </div>
                    </div>
                    <div className='text-center form-group mb-3'>
                      <button
                        disabled={!isValid}
                        type='submit'
                        className='btn btn-primary btn-block'
                      >
                        Sign In
                      </button>
                    </div>
                  </form>

                  <div className='new-account mt-3'>
                    <p>
                      forget your password?{" "}
                      <Link className='text-primary' to='/forgot-password'>
                        Forget password
                      </Link>
                      <hr />
                      <div className='w-full d-flex flex-column gap-4 mt-4'>
                        <GoogleLogin
                          handleClick={onClickSocial}
                          text='Sign Up with Google'
                        />
                        <FacebookLogIn
                          handleClick={onClickSocial}
                          text='Sign Up with Facebook'
                        />
                        <AppleLogin
                          handleClick={onClickSocial}
                          text='Sign Up with Apple'
                        /> 
                      </div>
                    </p>
                  </div>
                  <div className='text-right'>
                    <p>
                      Don't have an account?{" "}
                      <Link className='text-primary' to='/sign-up'>
                        Sign up
                      </Link>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Login;
