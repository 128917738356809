import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import PhoneInput, { parsePhoneNumber } from "react-phone-number-input";
import "react-phone-number-input/style.css";
import { usePublicContext } from "../../../providers/ReactPublicContextProvider";
import useUser from "../../../utils/hooks/useUser";
import { useFormik } from "formik";
import { useMutation } from "@tanstack/react-query";
import { SignUpSchema } from "../../../utils/forms-schemas";
import { toast } from "react-toastify";
import {
  postSignInWithSocialQueryFn,
  postSignUpQueryFn,
} from "../../../queries/queryFunctions/publicQueryFns/postQueryFns";
import ErrorFormik from "../../components/ui/ErrorFormik";
import FacebookLogIn from "../../components/ui/auth/FacebookLogIn";
import BookagriName from "../../components/ui/svg/BookagriName";
import Bookagri_Icon from "../../components/ui/svg/Bookagri_Icon";
import AppleLogin from "../../components/ui/auth/AppleLogin";
import GoogleLogin from "../../components/ui/auth/GoogleLogin";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";

export default function Registration() {
  const { setIsLoading, isLoading } = usePublicContext();
  const { redirectTo, isAuthenticUser, setUserData } = useUser();
  const [showPassword, setShowPassword] = useState(false);

  const {
    values,
    errors,
    touched,
    handleBlur,
    handleChange,
    handleSubmit,
    setFieldValue,
    setFieldTouched,
    isValid,
  } = useFormik({
    initialValues: {
      email: "",
      password: "",
      firstName: "",
      lastName: "",
      phoneNumber: "",
    },
    validationSchema: SignUpSchema,
    onSubmit: onSubmit,
  });

  const {
    mutate,
    isPending: isLoadingMutate,
    isError: isErrorMutate,
  } = useMutation({
    mutationFn: postSignUpQueryFn,
    onSuccess(data) {
      sessionStorage.setItem("email", values.email);
      toast.success("Please Verify your email");
      redirectTo("/verification-code");
      setIsLoading(false);
    },
    onError(error) {
      if (error?.response?.status === 401) {
        toast.error("Please Verify your email first");
        // sessionStorage.setItem("email", values.email);
        redirectTo("/verification-code");
      } else {
        toast.error(error.response.data.message);
      }
    },
  });

  const {
    mutate: socialMutate,
    isPending: isLoadingSocialMutate,
    isError: isErrorSocialMutate,
  } = useMutation({
    mutationFn: postSignInWithSocialQueryFn,
    onSuccess(data, variables) {
      if (!data.user.isProfileCompleted) {
        sessionStorage.setItem("socialData", JSON.stringify(variables));
        redirectTo("/complete-information");
        return;
      }
      const userData = data?.user;
      const token = data?.token;
      setUserData({ ...userData, token });
      redirectTo("/dashboard");
      setIsLoading(false);
    },
    onError(error) {
      console.error("error social Mutate: ", error);
      toast.error("Something went wrong, please try again later!");
    },
  });

  useEffect(() => {
    if (isAuthenticUser) setIsLoading(true);

    if (isAuthenticUser) {
      redirectTo("/dashboard");
    }
    setIsLoading(false);
  }, [isLoading]);

  useEffect(() => {
    setFieldTouched("", "");
  }, []);

  useEffect(() => {
    setIsLoading(isLoadingMutate || isLoadingSocialMutate);
  }, [isLoadingMutate, isLoadingSocialMutate]);

  async function onSubmit() {
    try {
      const { countryCallingCode, nationalNumber } = parsePhoneNumber(
        values.phoneNumber,
      );

      mutate({
        countryCode: "+" + countryCallingCode,
        phoneNumber: nationalNumber.toString(),
        email: values.email,
        password: values.password,
        firstName: values.firstName,
        lastName: values.lastName,
      });
    } catch (error) {
      toast.error("Please enter valid phone number");
    }
  }

  const onClickSocial = (data) => {
    socialMutate(data);
  };
  return (
    <div className='authincation py-5'>
      <div className='container '>
        <div className='row justify-content-center align-items-center'>
          <div className='d-flex justify-content-center mb-3'>
            <div className={``}>
              <BookagriName />
            </div>
            <div className='ms-2'>
              <Bookagri_Icon />
            </div>
          </div>
          <div className='col-md-6'>
            <div className='authincation-content'>
              <div className='row no-gutters'>
                <div className='col-xl-12'>
                  <div className='auth-form'>
                    <h4 className='text-center mb-4 '>Sign up your account</h4>
                    <form onSubmit={handleSubmit}>
                      <div className='form-group mb-3'>
                        <label className='mb-1 '>
                          <strong>First Name</strong>
                        </label>
                        <input
                          className='form-control'
                          name='firstName'
                          defaultValue={values?.firstName}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          placeholder='first Name'
                        />
                      </div>
                      <ErrorFormik
                        isError={errors?.firstName}
                        isTouched={touched?.firstName}
                        error={errors?.firstName}
                      />
                      <div className='form-group mb-3'>
                        <label className='mb-1 '>
                          <strong>Last Name</strong>
                        </label>
                        <input
                          className='form-control'
                          name='lastName'
                          defaultValue={values?.lastName}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          placeholder='Last Name'
                        />
                      </div>
                      <ErrorFormik
                        isError={errors?.lastName}
                        isTouched={touched?.lastName}
                        error={errors?.lastName}
                      />
                      <div
                        className='form-group mb-3'
                        style={{ position: "relative" }}
                      >
                        {" "}
                        <label className='mb-1'>
                          <strong>Phone Number</strong>
                        </label>
                        <PhoneInput
                          className='form-control'
                          international
                          defaultCountry='JO'
                          placeholder='Enter phone number'
                          name='phoneNumber'
                          value={values?.phoneNumber}
                          onChange={(val) => setFieldValue("phoneNumber", val)}
                          onBlur={handleBlur}
                          style={{
                            display: "inline-flex",
                            alignItems: "center",
                            width: "100%",
                            border: "1px solid #ced4da",
                            borderRadius: "0.8rem",
                            padding: "0.375rem 0.75rem",
                            fontSize: "1rem",
                          }}
                        />
                      </div>
                      <ErrorFormik
                        isError={errors?.phoneNumber}
                        isTouched={touched?.phoneNumber}
                        error={errors?.phoneNumber}
                      />
                      <div className='form-group mb-3'>
                        <label className='mb-1 '>
                          <strong>Email</strong>
                        </label>
                        <input
                          className='form-control'
                          name='email'
                          value={values?.email}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          placeholder='email'
                        />
                      </div>
                      <ErrorFormik
                        isError={errors?.email}
                        isTouched={touched?.email}
                        error={errors?.email}
                      />
                      <div className='form-group mb-3 position-relative'>
                        <label className='mb-1'>
                          <strong>Password</strong>
                        </label>
                        <div className='input-group'>
                          <input
                            style={{ borderRadius: "1rem" }}
                            className='form-control'
                            name='password'
                            type={showPassword ? "text" : "password"}
                            value={values?.password}
                            placeholder='Type Your Password'
                            onChange={handleChange}
                            onBlur={handleBlur}
                          />
                          <div
                            className='input-group-append'
                            onClick={() => setShowPassword(!showPassword)}
                            style={{
                              cursor: "pointer",
                              position: "absolute",
                              right: "10px",
                              top: "50%",
                              transform: "translateY(-50%)",
                              zIndex: "1",
                            }}
                          >
                            <FontAwesomeIcon
                              icon={showPassword ? faEyeSlash : faEye}
                              color='#999'
                            />
                          </div>
                        </div>
                        <ErrorFormik
                          isError={errors?.password}
                          isTouched={touched?.password}
                          error={errors?.password}
                        />
                      </div>
                      <div className='text-center mt-4'>
                        <button
                          type='submit'
                          className='btn btn-primary btn-block'
                          disabled={!isValid}
                        >
                          Sign me up
                        </button>
                      </div>
                    </form>
                    <hr />
                    <div className='w-full d-flex flex-column gap-4 mt-4'>
                      <GoogleLogin
                        handleClick={onClickSocial}
                        text='Sign Up with Google'
                      />
                      <FacebookLogIn
                        handleClick={onClickSocial}
                        text='Sign Up with Facebook'
                      />
                      <AppleLogin
                        handleClick={onClickSocial}
                        text='Sign Up with Apple'
                      />
                    </div>
                    <div className='new-account mt-3'>
                      <p className=''>
                        Already have an account?{" "}
                        <Link className='text-primary' to='/'>
                          Sign in
                        </Link>
                      </p>
                    </div>
                    <div className='new-account mt-3'>
                      <p className=''>
                        Agree to our{" "}
                        <Link className='text-primary' to='/public-privacy'>
                          Privacy Policy
                        </Link>{" "}
                        and{" "}
                        <Link className='text-primary' to='/public-terms'>
                          Usage Agreement
                        </Link>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
