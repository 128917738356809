import Select from "react-select";
import { colourStyles } from "./style";
import { useContext, useState, useRef, useEffect } from "react";
import { ThemeContext } from "../../../../../context/ThemeContext";

export default function MultiSelect({
  isClearable = true,
  isSearchable = true,
  isDisabled = false,
  isLoading = true,
  isRtl = false,
  options,
  handleChange,
  handleBlur,
  style,
  id,
  name,
  defaultValue = "",
  placeholder = "Select...",
}) {
  const { background } = useContext(ThemeContext);
  const [menuIsOpen, setMenuIsOpen] = useState(false);
  const selectRef = useRef();

  // Convert defaultValue to array if it's not already
  const defaultValues = Array.isArray(defaultValue)
    ? defaultValue
    : [defaultValue];

  // Find the default values in the options array
  const defaultOptions = defaultValues.map((value) =>
    options?.find((option) => option.value === value || option.label === value)
  );

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (selectRef.current && !selectRef.current.contains(event.target)) {
        setMenuIsOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [selectRef]);

  return (
    <div className={`${style}`} ref={selectRef}>
      <Select
        value={defaultOptions}
        id={id}
        isMulti={true}
        placeholder={placeholder}
        styles={colourStyles(background.value === "dark")}
        onChange={(selectedOptions) => {
          handleChange(selectedOptions);
          setMenuIsOpen(true);
        }}
        onBlur={handleBlur}
        className="basic-single"
        classNamePrefix="select"
        isDisabled={isDisabled}
        isLoading={isLoading}
        isClearable={isClearable}
        isRtl={isRtl}
        isSearchable={isSearchable}
        name={name}
        options={options}
        menuIsOpen={menuIsOpen}
        onMenuOpen={() => setMenuIsOpen(true)}
        onMenuClose={() => setMenuIsOpen(false)}
      />
    </div>
  );
}
