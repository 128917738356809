import { useEffect } from "react";

export default function CustomInput({
  style,
  height = "42px",
  onChange,
  onBlur,
  placeholder,
  isRequired,
  name,
  id,
  value,
  type,
  defaultValue = "",
  isDisable,
  pattern,
}) {

  return (
    <div style={{ position: "relative" }}>
      <input
        pattern={pattern}
        disabled={isDisable}
        id={id}
        name={name}
        value={value}
        style={{ borderColor: "#d3d2da", height: height, color: "#000", backgroundColor: isDisable && "#ACACAC" }}
        className={` ${style} form-control`}
        type={type}
        onChange={onChange}
        onBlur={onBlur}
        placeholder={placeholder}
        required={isRequired}
        defaultValue={defaultValue}
        onWheel={(e) => e.target.blur()}
      />
    </div>
  );
}
