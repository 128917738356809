export const colourStyles = (isDark) => ({
  control: (styles) => ({
    ...styles,
    minHeight: "42px",
    borderRadius: "16px",
    backgroundColor: isDark ? "#2E2E40" : "white", 
    borderColor: "#d3d2da",
    boxShadow: "#d3d2da",
    ":focus": {
      ...styles[":focus"],
      borderColor: "#d3d2da",
      boredShadow: "#d3d2da",
      boxShadow: "#d3d2da",
    },
    ":active": {
      ...styles[":active"],
      borderColor: "#d3d2da",
      boredShadow: "#d3d2da",
      boxShadow: "#d3d2da",
    },
    ":hover": {
      ...styles[":hover"],
      borderColor: "#d3d2da",
      boredShadow: "#d3d2da",
      boxShadow: "#d3d2da",
    },
  }),
  menu: (styles) => ({
    ...styles,
    zIndex: 999,
  }),
  option: (styles) => ({
    ...styles,
    color: "#241F5C",
    backgroundColor: "#FFFFFF",
    ":active": {
      ...styles[":active"],
      backgroundColor: "#0000000a",
    },
    ":hover": {
      ...styles[":active"],
      backgroundColor: "#0000000a",
    },
    cursor: "pointer",
  }),
  input: (styles) => ({
    ...styles,
  }),
  placeholder: (styles) => ({
    ...styles,
    color: isDark ? "#6E6E6E" : "#241F5Caa",
  }),
  singleValue: (styles) => ({
    ...styles,
    color: "#000",
  }),
  valueContainer: (styles) => ({
    ...styles,
    color: "#241F5C",
    padding: "0px 16px",
  }),
});
