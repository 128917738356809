export default function Bookagri_Icon() {
  return (
    <svg
      style={{ width: "2.81rem", height: "3.5rem" }}
      viewBox='0 0 45 56'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <g id='Group'>
        <g id='Group_2'>
          <path
            id='Vector'
            d='M22.3074 0.000868153C10.1754 -0.105865 0.249301 9.6424 0.142568 21.7744C0.035835 34.8313 14.1957 46.5008 21.8093 56C29.6008 46.6787 43.9742 35.2227 44.0809 22.1657C44.1876 10.0338 34.4394 0.107601 22.3074 0.000868153ZM26.1854 38.567C17.4689 40.8084 8.61003 35.5785 6.36865 26.8975C4.12726 18.181 9.35716 9.3222 18.0381 7.08081C26.7546 4.83942 35.6134 10.0693 37.8548 18.7503C40.0962 27.4668 34.8663 36.3256 26.1854 38.567Z'
            fill='url(#paint0_linear_59_1626)'
          />
        </g>
        <path
          id='Vector_2'
          d='M20.8487 27.7515C15.69 24.5495 9.57063 25.4389 7.79175 25.7591C7.82733 26.0082 7.89848 26.2216 7.96964 26.4707C8.89465 30.0284 11.0293 32.9458 13.8399 34.867C17.3621 29.5303 20.8487 27.7515 20.8487 27.7515Z'
          fill='url(#paint1_linear_59_1626)'
        />
        <path
          id='Vector_3'
          d='M31.9845 24.3359C31.9845 24.3359 24.6555 15.3703 12.9861 24.3359C14.2313 24.407 16.6862 24.407 19.2833 25.759C19.9949 25.1186 24.4421 20.1733 31.9845 24.3359Z'
          fill='url(#paint2_linear_59_1626)'
        />
        <path
          id='Vector_4'
          d='M25.7585 36.9661C31.273 35.543 35.2577 31.1313 36.3606 25.9014C33.8346 25.0831 24.0863 23.0196 15.1564 35.6853C18.2872 37.3574 22.0584 37.9267 25.7585 36.9661Z'
          fill='url(#paint3_linear_59_1626)'
        />
      </g>
      <defs>
        <linearGradient
          id='paint0_linear_59_1626'
          x1='24.9608'
          y1='7.99021'
          x2='16.1855'
          y2='50.4788'
          gradientUnits='userSpaceOnUse'
        >
          <stop stop-color='#FFCC01' />
          <stop offset='0.0751487' stop-color='#FFB305' />
          <stop offset='0.2122' stop-color='#FF8C08' />
          <stop offset='0.3543' stop-color='#FF6C09' />
          <stop offset='0.5004' stop-color='#FF530A' />
          <stop offset='0.6522' stop-color='#FF420A' />
          <stop offset='0.8134' stop-color='#FE370A' />
          <stop offset='1' stop-color='#FE330A' />
        </linearGradient>
        <linearGradient
          id='paint1_linear_59_1626'
          x1='14.3317'
          y1='35.2503'
          x2='14.3317'
          y2='25.392'
          gradientUnits='userSpaceOnUse'
        >
          <stop offset='0.0490798' stop-color='#459724' />
          <stop offset='0.6687' stop-color='#87C11C' />
        </linearGradient>
        <linearGradient
          id='paint2_linear_59_1626'
          x1='31.9926'
          y1='23.0819'
          x2='13.0107'
          y2='23.0819'
          gradientUnits='userSpaceOnUse'
        >
          <stop stop-color='#FFCC01' />
          <stop offset='0.1306' stop-color='#FFC603' />
          <stop offset='0.3014' stop-color='#FFB605' />
          <stop offset='0.4944' stop-color='#FF9B07' />
          <stop offset='0.7037' stop-color='#FF7509' />
          <stop offset='0.9239' stop-color='#FF460A' />
          <stop offset='1' stop-color='#FE330A' />
        </linearGradient>
        <linearGradient
          id='paint3_linear_59_1626'
          x1='25.7656'
          y1='37.5187'
          x2='25.7656'
          y2='24.8999'
          gradientUnits='userSpaceOnUse'
        >
          <stop offset='0.0490798' stop-color='#459724' />
          <stop offset='0.6687' stop-color='#87C11C' />
        </linearGradient>
      </defs>
    </svg>
  );
}
