import { useEffect, useState } from "react";
import ErrorFormik from "../../ui/ErrorFormik";
import { useFormik } from "formik";
import { useMutation, useQuery } from "@tanstack/react-query";
import { toast } from "react-toastify";
import { addStayStep1Schema } from "../../../../utils/forms-schemas";
import GeneralSelect from "../../ui/custom-inputs/select-inputs/GeneralSelect";
import Label from "../../ui/custom-inputs/Label";
import CustomEditor from "../../ui/custom-inputs/CustomEditor";
import MultiSelect from "../../ui/custom-inputs/select-inputs/MultiSelect";
import Switch from "../../ui/custom-inputs/Switch";
import Hr from "../../ui/Hr";
import CustomInput from "../../ui/custom-inputs/CustomInput";
import DynamicInputFields from "../../ui/custom-inputs/DynamicInputFields";
import { usePublicContext } from "../../../../providers/ReactPublicContextProvider";
import {
  getAccessibilityFeaturesList,
  getAmenitiesList,
  getBookingOptionsList,
  getCitiesForCountries,
  getCountriesList,
  getExperienceTypeList,
} from "../../../../queries/queryFunctions/publicQueryFns/getQueryFns";
import GoogleMaps from "../../ui/GoogleMaps";
import { postAddSiteStep1QueryFn } from "../../../../queries/queryFunctions/privetQueryFns/postQueryFns";
import { useSearchParams } from "react-router-dom";
import { patchAddSiteStep1QueryFn } from "../../../../queries/queryFunctions/privetQueryFns/patchQueryFns";
import CustomTextarea from "../../ui/custom-inputs/CustomTextarea";

const StepOne = ({ handleNext, ExperienceId, siteInfo }) => {
  const { setIsLoading } = usePublicContext();
  const [searchParams, setSearchParams] = useSearchParams();

  const [accessibilityList, setAccessibilityList] = useState([]);
  const [countriesList, setCountriesList] = useState([]);
  const [citiesList, setCitiesList] = useState([]);
  const [availableAmenitiesList, setAvailableAmenitiesList] = useState([]);
  const [stayTypes, setstayTypes] = useState([]);
  const [bookOptionsList, setBookOptionsList] = useState([]);

  const {
    values,
    errors,
    touched,
    handleBlur,
    handleChange,
    handleSubmit,
    setFieldValue,
    setFieldTouched,
    isValid,
    resetForm,
    setValues,
  } = useFormik({
    initialValues: {
      checkIn: "",
      checkOut: "",
      stayTitle: "",
      overview: "",
      stayDetails: {
        description: "",
        numberOfBedrooms: 0,
        numberOfBeds: 0,
        numberOfBathrooms: 0
      },
      nearbyAndSurroundings: "",
      stayTypes: [],
      accessProvider: false,
      bookagriExperience: false,
      country: {
        label: "",
        value: "",
      },
      city: {
        label: "",
        value: "",
      },
      location: {
        type: "Point",
        coordinates: [],
        area: "",
      },
      extras: [{ extraNameEn: "", extraNameAR: "", extraPrice: 0 }],
      airportTransportationIncluded: false,
      airportTransOptionalOrMandatory: false,
      airportTransDescription: "",
      airportTransPrice: 0,
      houseRules: "",
      accessibilityFeatures: [],
      availableAmenities: [],
      cancellationPolicy: "",
    },
    enableReinitialize: true,
    validationSchema: addStayStep1Schema,
    onSubmit: onSubmit,
  });

  const {
    data: bookingOptionsData,
    isPending: isLoadingBookingOptions,
    isError: isErrorBookingOptions,
  } = useQuery({
    queryKey: ["book-options-list", { isStayType: true }],
    queryFn: getBookingOptionsList,
  });

  const {
    data: accessibilityData,
    isPending: isLoadingAccessibilityData,
    isError: isErrorAccessibilityData,
  } = useQuery({
    queryKey: ["accessibility-features-list"],
    queryFn: getAccessibilityFeaturesList,
  });

  const {
    data: amenitiesData,
    isPending: isLoadingAmenitiesData,
    isError: isErrorAmenitiesData,
  } = useQuery({
    queryKey: ["amenities-list"],
    queryFn: getAmenitiesList,
  });

  const {
    data: experienceTypeData,
    isPending: isLoadingExperienceTypeData,
    isError: isErrorExperienceTypeData,
  } = useQuery({
    queryKey: ["experienceType-list", { isStayType: true }],
    queryFn: getExperienceTypeList,
  });

  const {
    data: countryData,
    isPending: isLoadingCountryData,
    isError: isErrorCountryData,
    fetchStatus: fetchStatusCountryData,
  } = useQuery({
    queryKey: ["country-list"],
    queryFn: getCountriesList,
  });

  const {
    data: citiesData,
    isPending: isLoadingCitiesData,
    isError: isErrorCitiesData,
    fetchStatus: fetchStatusCitiesData,
  } = useQuery({
    queryKey: ["cities-list", { countryId: values.country?.value }],
    queryFn: getCitiesForCountries,
    enabled: !!values.country?.value,
  });

  const {
    mutate,
    isPending: isLoadingMutate,
    isError: isErrorMutate,
  } = useMutation({
    mutationFn:
      ExperienceId && siteInfo
        ? patchAddSiteStep1QueryFn
        : postAddSiteStep1QueryFn,
    async onSuccess(data) {
      setSearchParams({ id: data?._id, step: 2 });
    },
    onError(error) {
      console.error("error Site Step 1: ", error);
      toast.error("Something went wrong, please try later!");
    },
  });

  useEffect(() => {
    setValues({
      checkIn: siteInfo?.site?.checkinTime || "",
      checkOut: siteInfo?.site?.checkoutTime || "",
      placeType: siteInfo?.site?.type || "",
      stayTitle: siteInfo?.site?.name || "",
      nearbyAndSurroundings: siteInfo?.site?.stayNearby || "",
      overview: siteInfo?.site?.longDescription || "",
      // stayDetails: siteInfo?.site?.stayDetails || "",
      stayDetails: {
        description: siteInfo?.site?.stayDetails?.description || "",
        numberOfBeds: siteInfo?.site?.stayDetails?.numberOfBeds || 0,
        numberOfBedrooms: siteInfo?.site?.stayDetails?.numberOfBedrooms || 0,
        numberOfBathrooms: siteInfo?.site?.stayDetails?.numberOfBathrooms || 0
      },
      stayTypes:
        siteInfo?.site?.experienceTypes?.map((item) => ({
          label: item.nameEn,
          value: item?._id,
        })) || [],
      accessProvider: siteInfo?.site?.accessProvider || false,
      bookagriExperience: siteInfo?.site?.bookagriBadge || false,
      country: {
        label: siteInfo?.site?.country?.name || "",
        value: siteInfo?.site?.country?._id || "",
      },
      city: {
        label: siteInfo?.site?.city || "",
        value: siteInfo?.site?.city || "",
      },
      location: {
        type: "Point",
        coordinates: siteInfo?.site?.location?.coordinates || [],
        area: siteInfo?.site?.googleLocationLink || "",
      },
      extras:
        siteInfo?.site?.extras.length > 0
          ? siteInfo?.site?.extras?.map((item) => ({
              extraNameEn: item.nameEn,
              extraNameAr: item.nameAr,
              extraPrice: Number(item.price),
            }))
          : [{ extraNameEn: "",extraNameAr: "", extraPrice: 0 }],
      airportTransportationIncluded: siteInfo?.site?.airportIsIncluded || false,
      airportTransOptionalOrMandatory:
        siteInfo?.site?.airportIsMandatory || false,
      airportTransDescription: siteInfo?.site?.airport?.description || "",
      airportTransPrice: siteInfo?.site?.airport?.price || 0,
      airportTransLearnMore: siteInfo?.site?.airport?.learnMore || "",
      houseRules: siteInfo?.site?.stayHouseRules,
      accessibilityFeatures:
        siteInfo?.site?.accessibilityFeatures?.map((item) => ({
          label: item.nameEn,
          value: item?._id,
        })) || [],
      bookOptions:
        siteInfo?.site?.bookOptions?.map((item) => ({
          label: item.nameEn,
          value: item?._id,
        })) || [],
      availableAmenities:
        siteInfo?.site?.amenities?.map((item) => ({
          label: item.nameEn,
          value: item?._id,
        })) || [],
      cancellationPolicy: siteInfo?.site?.cancellationPolicy || "",
    });
  }, [siteInfo]);

  useEffect(() => {
    setIsLoading(isLoadingMutate);
  }, [isLoadingMutate]);

  useEffect(() => {
    let res = (countryData || [])
      .filter((item) => item.name) // Filter out countries
      .map((item) => ({
        label: item.name || "",
        value: item?._id,
      }));
    setCountriesList(res);
  }, [countryData]);

  useEffect(() => {
    let res = (citiesData || [])
      .filter((item) => item.name) // Filter out countries
      .map((item) => ({
        label: item.name || "",
        value: item.name,
      }));
    setCitiesList(res);
  }, [values?.country, citiesData]);

  useEffect(() => {
    let res = (accessibilityData || [])
      .filter((item) => item.nameEn && item?._id)
      .map((item) => ({
        label: item.nameEn,
        value: item?._id,
      }));
    setAccessibilityList(res);
  }, [accessibilityData]);

  useEffect(() => {
    let res = (amenitiesData || [])
      .filter((item) => item.nameEn && item?._id)
      .map((item) => ({
        label: item.nameEn || "",
        value: item?._id,
      }));
    setAvailableAmenitiesList(res);
  }, [amenitiesData]);

  useEffect(() => {
    let res = (experienceTypeData || [])
      .filter((item) => item.nameEn && item?._id)
      .map((item) => ({
        label: item.nameEn || "",
        value: item?._id,
      }));
    setstayTypes(res);
  }, [experienceTypeData]);

  useEffect(() => {
    let res = (bookingOptionsData || [])
      .filter((item) => item.nameEn) // Filter out countries
      .map((item) => ({
        label: item.nameEn || "",
        value: item?._id,
      }));
    setBookOptionsList(res);
  }, [bookingOptionsData]);

  const handleExtrasChange = (values) => {
    setFieldValue("extras", values);
  };

  const handleLocationChange = (values) => {
    setFieldValue("location", values);
  };

  function onSubmit() {
    mutate({
      ...(ExperienceId && siteInfo && { id: ExperienceId }),
      type: "Stay",
      name: values?.stayTitle,
      longDescription: values?.overview,
      experienceTypes: values?.stayTypes?.map((item) => item?.value),
      accessProvider: values?.accessProvider,
      bookagriBadge: values?.bookagriExperience,
      city: values?.city?.value,
      country: values?.country?.value,
      googleLocationLink: values?.location?.area,
      location: {
        type: values?.location?.type,
        coordinates: values?.location?.coordinates,
      },
      extras: values?.extras?.map((item) => ({
        nameEn: item.extraNameEn,//check this 
        nameAr: item.extraNameAr,
        price: Number(item.extraPrice),
      })),
      airportIsIncluded: values?.airportTransportationIncluded,
      airportIsMandatory: values?.airportTransOptionalOrMandatory,
      ...(values.airportTransportationIncluded && {
        airport: {
          description: values?.airportTransDescription,
          price: values?.airportTransPrice,
          learnMore: values?.airportTransLearnMore,
        },
      }),
      accessibilityFeatures: values?.accessibilityFeatures?.map(
        (item) => item?.value,
      ),
      amenities: values?.availableAmenities?.map((item) => item?.value),
      bookOptions: values?.bookOptions?.map((item) => item?.value),
      cancellationPolicy: values?.cancellationPolicy,
      // stayDetails: values?.stayDetails,
      stayDetails: {
        description: values?.stayDetails?.description,
        numberOfBeds: values?.stayDetails?.numberOfBeds,
        numberOfBedrooms: values?.stayDetails?.numberOfBedrooms,
        numberOfBathrooms: values?.stayDetails?.numberOfBathrooms,
      },
      stayHouseRules: values?.houseRules,
      stayNearby: values?.nearbyAndSurroundings,
      checkinTime: values?.checkIn,
      checkoutTime: values?.checkOut,
    });
  }

  const handleErrors = async () => {
    const err = Object.values(errors);
    if (err.length > 0) {
      toast.error(err[0]);
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <h4 className=''>General Info</h4>
      <Hr />
      {/* Available Amenities & Stay Title(Name) */}
      <section className='row'>
        {/* Stay Title(Name) */}
        <div className='col-lg-6'>
          <div className='form-group mb-3'>
            <Label
              forId='stayTitle'
              text='Stay Title(Name)'
              isRequired={true}
            />
            <CustomInput
              withFocus={true}
              placeholder='Stay Title'
              id='stayTitle'
              name='stayTitle'
              type='text'
              value={values?.stayTitle}
              onChange={handleChange}
              onBlur={handleBlur}
            />
            <ErrorFormik
              isError={errors?.stayTitle}
              isTouched={touched?.stayTitle}
              error={errors?.stayTitle}
            />
          </div>
        </div>
        {/* Available Amenities */}
        <div className='col-lg-6'>
          <div className='form-group mb-3'>
            <Label
              forId='availableAmenities'
              text='Available Amenities'
              isRequired={false}
            />
            <MultiSelect
              defaultValue={values?.availableAmenities?.map(
                (item) => item.label,
              )}
              placeholder='Search'
              isClearable={false}
              isSearchable={true}
              isDisabled={false}
              isLoading={isLoadingAmenitiesData}
              isRtl={false}
              options={availableAmenitiesList}
              handleChange={(val) => {
                setFieldValue("availableAmenities", val);
              }}
              handleBlur={() => {
                handleBlur("availableAmenities");
              }}
              id={"availableAmenities"}
              name={"availableAmenities"}
            />
            <ErrorFormik
              isError={errors?.availableAmenities}
              isTouched={touched?.availableAmenities}
              error={errors?.availableAmenities}
            />
          </div>
        </div>
      </section>
      {/* Stay Types & Accessibility Features */}
      <section className='row'>
        {/* Stay Types */}
        <div className='col-lg-6'>
          <div className='form-group mb-3'>
            <Label forId='stayTypes' text='Stay Types' isRequired={true} />
            <MultiSelect
              defaultValue={values?.stayTypes?.map((item) => item.label)}
              placeholder='Search'
              isClearable={false}
              isSearchable={true}
              isDisabled={false}
              isLoading={isLoadingExperienceTypeData}
              isRtl={false}
              options={stayTypes}
              handleChange={(val) => {
                setFieldValue("stayTypes", val);
              }}
              handleBlur={() => {
                handleBlur("stayTypes");
              }}
              id={"stayTypes"}
              name={"stayTypes"}
            />
            <ErrorFormik
              isError={errors?.stayTypes}
              isTouched={touched?.stayTypes}
              error={errors?.stayTypes}
            />
          </div>
        </div>
        {/* Accessibility Features */}
        <div className='col-lg-6'>
          <div className='form-group mb-3'>
            <Label
              forId='accessibilityFeatures'
              text='Accessibility Features'
              isRequired={false}
            />
            <MultiSelect
              defaultValue={values?.accessibilityFeatures?.map(
                (item) => item.label,
              )}
              placeholder='Search'
              isClearable={false}
              isSearchable={true}
              isDisabled={false}
              isLoading={isLoadingAccessibilityData}
              isRtl={false}
              options={accessibilityList}
              handleChange={(val) => {
                setFieldValue("accessibilityFeatures", val);
              }}
              handleBlur={() => {
                handleBlur("accessibilityFeatures");
              }}
              id={"accessibilityFeatures"}
              name={"accessibilityFeatures"}
            />
            <ErrorFormik
              isError={errors?.accessibilityFeatures}
              isTouched={touched?.accessibilityFeatures}
              error={errors?.accessibilityFeatures}
            />
          </div>
        </div>
      </section>
      {/* Check-in and & Check-out */}
      <section className='row'>
        {/* Check-in */}
        <div className='col-lg-6'>
          <div className='form-group mb-3'>
            <Label forId='checkIn' text='Check-in Time' isRequired={true} />
            <CustomInput
              withFocus={true}
              placeholder='Stay Title'
              id='checkIn'
              name='checkIn'
              type='time'
              value={values?.checkIn}
              onChange={handleChange}
              onBlur={handleBlur}
            />
            <ErrorFormik
              isError={errors?.checkIn}
              isTouched={touched?.checkIn}
              error={errors?.checkIn}
            />
          </div>
        </div>
        {/* Check-out Time */}
        <div className='col-lg-6'>
          <div className='form-group mb-3'>
            <Label forId='checkOut' text='Check-out Time' isRequired={true} />
            <CustomInput
              withFocus={true}
              placeholder='Stay Title'
              id='checkOut'
              name='checkOut'
              type='time'
              value={values?.checkOut}
              onChange={handleChange}
              onBlur={handleBlur}
            />
            <ErrorFormik
              isError={errors?.checkOut}
              isTouched={touched?.checkOut}
              error={errors?.checkOut}
            />
          </div>
        </div>
      </section>
      {/* Booking Options & --- */}
      <section className='row'>
        {/* Booking Options */}
        <div className='col-lg-6'>
          <div className='form-group mb-3'>
            <Label forId='bookOptions' text='Book Options' isRequired={false} />
            <MultiSelect
              defaultValue={values?.bookOptions?.map((item) => item.label)}
              placeholder='Search'
              isClearable={false}
              isSearchable={true}
              isDisabled={false}
              isLoading={isLoadingBookingOptions}
              isRtl={false}
              options={bookOptionsList}
              handleChange={(val) => {
                setFieldValue("bookOptions", val);
              }}
              handleBlur={() => {
                handleBlur("bookOptions");
              }}
              id={"bookOptions"}
              name={"bookOptions"}
            />
            <ErrorFormik
              isError={errors?.bookOptions}
              isTouched={touched?.bookOptions}
              error={errors?.bookOptions}
            />
          </div>
        </div>
      </section>
      <section className='row'>
        {/* Access Provider */}
        <div className='col-lg-6 mb-2'>
          <Label
            forId='accessProvider'
            text='Free admission for people assisting guests with disabilities'
            isRequired={false}
          />
          <Switch
            id='accessProvider'
            label='Access Provider'
            checked={values.accessProvider}
            onChange={(e) =>
              setFieldValue("accessProvider", e.target.checked)
            }
          />
        </div>
      {/* </section> */}
      {/* Bookagri Stay */}
      {/* <section className='row'> */}
        {/* Bookagri Stay */}
        <div className='col-lg-6 mb-2'>
          <Label
            forId='bookagriExperience'
            text='Is Listing Certified By Bookagri'
            isRequired={false}
          />
          <Switch
            id='bookagriExperience'
            label='Bookagri Stay'
            checked={values.bookagriExperience}
            onChange={(e) =>
              setFieldValue("bookagriExperience", e.target.checked)
            }
          />
        </div>
      </section>
      {/* Overview */}
      <section className='col-lg-12 mb-3'>
        <Label forId='overview' text='Overview' isRequired={true} />
        <CustomTextarea
          id={"overview"}
          name={"overview"}
          value={values?.overview}
          onChange={handleChange}
          onBlur={handleBlur}
        />
        <ErrorFormik
          isError={errors?.overview}
          isTouched={touched?.overview}
          error={errors?.overview}
        />
      </section>
      {/* Stay Details */}
      <section className='col-lg-12 my-3'>
        <Label forId='stayDetails' text='Stay Details' isRequired={false} />
         <div className='row'>
              <Label
                forId='description'
                text='Description'
                isRequired={false}
              />
              <CustomEditor
                id={"description"}
                name={"stayDetails.description"}
                value={values?.stayDetails.description}
                // onChange={handleChange}
                onChange={(newValue) => {
                  setFieldValue("stayDetails.description", newValue);
                }}
                onBlur={handleBlur}
              />
              <ErrorFormik
                isError={errors?.stayDetails?.description}
                isTouched={touched?.stayDetails?.description}
                error={errors?.stayDetails?.description}
              />
       
            </div> 
           </section>
           <section className='row'>
           <div className='col-lg-6'>
            <div className='form-group mb-3'>
              <Label forId='numberOfBeds' text='Number Of Beds' isRequired={true} />
              <CustomInput
                withFocus={true}
                placeholder='numberOfBeds'
                id='numberOfBeds'
                name='numberOfBeds'
                type='number'
                value={values?.stayDetails?.numberOfBeds}
                // onChange={handleChange}
                onChange={(event) => {
                  setFieldValue("stayDetails.numberOfBeds", parseInt(event.target.value)); 
                }}
                onBlur={handleBlur}
              />
              <ErrorFormik
                isError={errors?.stayDetails?.numberOfBeds}
                isTouched={touched?.stayDetails?.numberOfBeds}
                error={errors?.stayDetails?.numberOfBeds}
              />
            </div>
          </div>
      

    
          <div className='col-lg-6'>
            <div className='form-group mb-3'>
              <Label forId='numberOfBedrooms' text='Number Of Bedrooms' isRequired={true} />
              <CustomInput
                withFocus={true}
                placeholder='numberOfBedrooms'
                id='numberOfBedrooms'
                name='numberOfBedrooms'
                type='number'
                value={values?.stayDetails?.numberOfBedrooms}
                onChange={(event) => {
                  setFieldValue("stayDetails.numberOfBedrooms", parseInt(event.target.value)); 
                }}
                onBlur={handleBlur}
              />
              <ErrorFormik
                isError={errors?.stayDetails?.numberOfBedrooms}
                isTouched={touched?.stayDetails?.numberOfBedrooms}
                error={errors?.stayDetails?.numberOfBedrooms}
              />
            </div>
          </div>
          <div className='col-lg-6'>
            <div className='form-group mb-3'>
              <Label forId='numberOfBathrooms' text='Number Of Bathrooms' isRequired={true} />
              <CustomInput
                withFocus={true}
                placeholder='numberOfBathrooms'
                id='numberOfBathrooms'
                name='numberOfBathrooms'
                type='number'
                value={values?.stayDetails?.numberOfBathrooms}
                // onChange={handleChange}
                onChange={(event) => {
                  setFieldValue("stayDetails.numberOfBathrooms", parseInt(event.target.value)); 
                }}
                onBlur={handleBlur}
              />
              <ErrorFormik
                isError={errors?.stayDetails?.numberOfBathrooms}
                isTouched={touched?.stayDetails?.numberOfBathrooms}
                error={errors?.stayDetails?.numberOfBathrooms}
              />
            </div>
          </div>

      
      {/* <section className='col-lg-12 my-3'>
        <Label forId='stayDetails' text='Stay Details' isRequired={false} />
        <CustomEditor
          id={"stayDetails"}
          value={values?.stayDetails}
          onChange={(value) => {
            setFieldValue("stayDetails.description", value);
          }}
          onBlur={() => {
            setFieldTouched("stayDetails", true);
            handleBlur("stayDetails");
          }}
        />
        <ErrorFormik
          isError={errors?.stayDetails}
          isTouched={touched?.stayDetails}
          error={errors?.stayDetails}
        /> */}
      </section>
      {/* Nearby and Surroundings */}
      <section className='col-lg-12 my-3'>
        <Label
          forId='nearbyAndSurroundings'
          text='Nearby and Surroundings'
          isRequired={true}
        />
        <CustomEditor
          id={"nearbyAndSurroundings"}
          value={values?.nearbyAndSurroundings}
          onChange={(value) => {
            setFieldValue("nearbyAndSurroundings", value);
          }}
          onBlur={() => {
            setFieldTouched("nearbyAndSurroundings", true);
            handleBlur("nearbyAndSurroundings");
          }}
        />
        <ErrorFormik
          isError={errors?.nearbyAndSurroundings}
          isTouched={touched?.nearbyAndSurroundings}
          error={errors?.nearbyAndSurroundings}
        />
      </section>
      {/* Airport Transportation availability */}
      <section>
        <h4 className=''>Airport Transportation availability</h4>
        <Hr />
        <div className='row'>
          {/* Airport Transportation Included */}
          <div className='col-lg-6'>
            <div className='mb-2'>
              <Label
                forId='airportTransportationIncluded'
                text='Is Airport Transportation Included ?'
                isRequired={false}
              />
              <Switch
                id='airportTransportationIncluded'
                checked={values.airportTransportationIncluded}
                onChange={(e) =>
                  setFieldValue(
                    "airportTransportationIncluded",
                    e.target.checked,
                  )
                }
              />
            </div>
          </div>
          {/* Airport Transportation Optional or Mandatory */}
          {values.airportTransportationIncluded && (
            <div className='col-lg-6'>
              <div className='mb-2'>
                <Label
                  forId='airportTransOptionalOrMandatory'
                  text='Is Airport Transportation Mandatory ?'
                  isRequired={false}
                />
                <Switch
                  id='airportTransOptionalOrMandatory'
                  checked={values.airportTransOptionalOrMandatory}
                  onChange={(e) =>
                    setFieldValue(
                      "airportTransOptionalOrMandatory",
                      e.target.checked,
                    )
                  }
                />
              </div>
            </div>
          )}
        </div>
        {values.airportTransportationIncluded && (
          <>
            <div className='row'>
              {/* Airport Transportation description */}
              <div className='col-lg-6'>
                <div className='form-group mb-3'>
                  <Label
                    forId='airportTransDescription'
                    text='Airport Transportation Description'
                    isRequired={true}
                  />
                  <CustomInput
                    withFocus={true}
                    placeholder='Airport Description'
                    id='airportTransDescription'
                    name='airportTransDescription'
                    type='text'
                    value={values?.airportTransDescription}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                  <ErrorFormik
                    isError={errors?.airportTransDescription}
                    isTouched={touched?.airportTransDescription}
                    error={errors?.airportTransDescription}
                  />
                </div>
              </div>
              {/* Airport Transportation Price */}
              <div className='col-lg-6'>
                <div className='form-group mb-3'>
                  <Label
                    forId='airportTransPrice'
                    text='Airport Transportation Price (JOD)'
                    isRequired={true}
                  />
                  <CustomInput
                    withFocus={true}
                    placeholder='Airport Price'
                    id='airportTransPrice'
                    name='airportTransPrice'
                    type='number'
                    value={values?.airportTransPrice}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                  <ErrorFormik
                    isError={errors?.airportTransPrice}
                    isTouched={touched?.airportTransPrice}
                    error={errors?.airportTransPrice}
                  />
                </div>
              </div>
              {/* Airport Trans Learn More */}
              <div className='col-lg-12 mb-3'>
                <Label
                  forId='airportTransLearnMore'
                  text='Airport Trans Learn More'
                  isRequired={false}
                />
                <CustomEditor
                  id={"airportTransLearnMore"}
                  value={values?.airportTransLearnMore}
                  onChange={(value) => {
                    setFieldValue("airportTransLearnMore", value);
                  }}
                  onBlur={() => {
                    setFieldTouched("airportTransLearnMore", true);
                    handleBlur("airportTransLearnMore");
                  }}
                />
                <ErrorFormik
                  isError={errors?.airportTransLearnMore}
                  isTouched={touched?.airportTransLearnMore}
                  error={errors?.airportTransLearnMore}
                />
              </div>
            </div>
          </>
        )}
      </section>
      {/* location */}
      <section className='mb-3'>
        <h4 className=''>location</h4>
        <Hr />
        <section className='row'>
          {/* Country */}
          <div className='col-lg-6'>
            <div className='form-group mb-3'>
              <Label forId='country' text='Country' isRequired={true} />
              <GeneralSelect
                isLoading={isLoadingCountryData}
                defaultValue={values?.country?.label}
                placeholder='Search'
                isClearable={false}
                isSearchable={true}
                isDisabled={false}
                isRtl={false}
                options={countriesList}
                handleChange={(val) => setFieldValue("country", val)}
                handleBlur={() => {
                  handleBlur("country");
                }}
                id={"country"}
                name={"country"}
              />
              <ErrorFormik
                isError={errors?.country?.value}
                isTouched={touched?.country?.value}
                error={errors?.country?.value}
              />
            </div>
          </div>
          {/* Area */}
          <div className='col-lg-6'>
            <div className='form-group mb-3'>
              <Label forId='area' text='Area' isRequired={true} />
              <GeneralSelect
                defaultValue={values?.city?.label}
                placeholder='Search'
                isClearable={false}
                isSearchable={true}
                isDisabled={false}
                isLoading={isLoadingCitiesData}
                isRtl={false}
                options={citiesList}
                handleChange={(val) => setFieldValue("city", val)}
                handleBlur={() => {
                  handleBlur("city");
                }}
                id={"city"}
                name={"city"}
              />

              <ErrorFormik
                isError={errors?.city?.value}
                isTouched={touched?.city?.value}
                error={errors?.city?.value}
              />
            </div>
          </div>
        </section>
        <GoogleMaps
          onClick={handleLocationChange}
          defaultValue={{
            lat: values?.location?.coordinates[0],
            lng: values?.location?.coordinates[1],
          }}
        />
        <ErrorFormik
          isError={errors?.location?.coordinates}
          isTouched={touched?.location?.coordinates}
          error={errors?.location?.coordinates}
        />
      </section>
      {/* Extras */}
      <section>
        <h4 className=''>Extras</h4>
        <Hr />
        <div className='mb-3'>
          <DynamicInputFields
            inputFields={[
              {
                id: "extraNameEn",
                name: "extraNameEn",
                label: "Extra Name En",
                placeholder: "Extra Name En",
                type: "text",
                isRequired: false,
              },
              {
                id: "extraNameAr",
                name: "extraNameAr",
                label: "Extra Name Ar",
                placeholder: "Extra Name Ar",
                type: "text",
                isRequired: false,
              },
              {
                id: "extraPrice",
                name: "extraPrice",
                label: "Extra Price",
                placeholder: "Extra Price",
                type: "number",
                isRequired: false,
              },
            ]}
            textButton='Add Extra Slot'
            fieldName={"extras"}
            onChange={handleExtrasChange}
            val={values?.extras}
            errors={errors}
            touched={touched}
          />
          
        </div>
      </section>
      {/* Instructions */}
      <section>
        <h4 className=''>Instructions</h4>
        <Hr />
        {/* House Rules */}
        <section className='col-lg-12 mb-3'>
          <Label forId='houseRules' text='House Rules' isRequired={true} />
          <CustomEditor
            id={"houseRules"}
            value={values?.houseRules}
            onChange={(value) => {
              setFieldValue("houseRules", value);
            }}
            onBlur={() => {
              setFieldTouched("houseRules", true);
              handleBlur("houseRules");
            }}
          />
          <ErrorFormik
            isError={errors?.houseRules}
            isTouched={touched?.houseRules}
            error={errors?.houseRules}
          />
        </section>
        {/* Cancellation Policy */}
        <div className='col-lg-12 mb-3'>
          <Label
            forId='cancellationPolicy'
            text='Cancellation Policy'
            isRequired={true}
          />
          <CustomEditor
            id={"cancellationPolicy"}
            value={values?.cancellationPolicy}
            onChange={(value) => {
              setFieldValue("cancellationPolicy", value);
            }}
            onBlur={() => {
              setFieldTouched("cancellationPolicy", true);
              handleBlur("cancellationPolicy");
            }}
          />
          <ErrorFormik
            isError={errors?.cancellationPolicy}
            isTouched={touched?.cancellationPolicy}
            error={errors?.cancellationPolicy}
          />
        </div>
      </section>
      <Hr />
      {/* Button */}
      <div className='text-end toolbar toolbar-bottom p-2'>
        <button
          // disabled={!isValid}
          onClick={handleErrors}
          type='submit'
          className='btn btn-primary sw-btn-next'
        >
          Next
        </button>
      </div>
    </form>
  );
};

export default StepOne;
