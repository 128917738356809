import React, { useContext, useReducer, useState } from "react";
/// Scroll
import PerfectScrollbar from "react-perfect-scrollbar";
import { Collapse } from "react-bootstrap";
/// Link
import { Link } from "react-router-dom";
import { MenuList } from "./Menu";
import { useScrollPosition } from "@n8tb1t/use-scroll-position";
import { ThemeContext } from "../../../context/ThemeContext";
import useUser from "../../../utils/hooks/useUser";

const reducer = (previousState, updatedState) => ({
  ...previousState,
  ...updatedState,
});

const initialState = {
  active: "",
  activeSubmenu: "",
};

const SideBar = () => {
  const {
    iconHover,
    sidebarposition,
    headerposition,
    sidebarLayout,
    ChangeIconSidebar,
  } = useContext(ThemeContext);

  const [state, setState] = useReducer(reducer, initialState);
  const { userData } = useUser();
  const isAdmin = userData && userData.role.id == 1;

  //For scroll
  const [hideOnScroll, setHideOnScroll] = useState(true);
  useScrollPosition(
    ({ prevPos, currPos }) => {
      const isShow = currPos.y > prevPos.y;
      if (isShow !== hideOnScroll) setHideOnScroll(isShow);
    },
    [hideOnScroll],
  );
  const handleMenuActive = (status) => {
    setState({ active: status });
    if (state.active === status) {
      setState({ active: "" });
    }
  };
  const handleSubmenuActive = (status) => {
    setState({ activeSubmenu: status });
    if (state.activeSubmenu === status) {
      setState({ activeSubmenu: "" });
    }
  };

  /// Path
  let path = window.location.pathname;
  //path = path.split("/");
  //path = path[path.length - 1];


  return (
    <div
      onMouseEnter={() => ChangeIconSidebar(true)}
      onMouseLeave={() => ChangeIconSidebar(false)}
      className={`dlabnav ${iconHover} ${sidebarposition.value === "fixed" &&
          sidebarLayout.value === "horizontal" &&
          headerposition.value === "static"
          ? hideOnScroll > 120
            ? "fixed"
            : ""
          : ""
        }`}
    >
      <PerfectScrollbar className='dlabnav-scroll'>
        <ul className='metismenu' id='menu'>
          {MenuList.map((data, index) => {
            let menuClass = data.classsChange;
            if (menuClass === "menu-title") {
              return (
                <li className={menuClass} key={index}>
                  {data.title}
                </li>
              );
            }
            else {
              if (data.isAdminAccess && !isAdmin) {
                return null;
              }
              return (
                <li
                  className={` ${state.active === data.title ? "mm-active" : ""}`}
                  key={index}
                >
                  {data.content && data.content.length > 0 ? (
                    <>
                      <a
                        role='button'
                        className='has-arrow'
                        onClick={() => {
                          handleMenuActive(data.title);
                        }}
                      >
                        {data.iconStyle}
                        <span className='nav-text'>{data.title}</span>
                      </a>
                      <Collapse in={state.active === data.title ? true : false}>
                        <ul
                          className={`${menuClass === "mm-collapse" ? "mm-show" : ""}`}
                        >
                          {data.content &&
                            data.content.map((data1, index) => {
                              return (
                                <li
                                  key={index}
                                  className={`${state.activeSubmenu === data1.title ? "mm-active" : ""}`}
                                >
                                  {data1.content && data1.content.length > 0 ? (
                                    <>
                                      <Link
                                        to={data1.to}
                                        className={`text-nowrap ${data1.hasMenu ? "has-arrow" : ""}`}
                                        onClick={() => {
                                          handleSubmenuActive(data1.title);
                                        }}
                                      >
                                        {data1.title}
                                      </Link>
                                      <Collapse
                                        in={
                                          state.activeSubmenu === data1.title
                                            ? true
                                            : false
                                        }
                                      >
                                        <ul
                                          className={`${menuClass === "mm-collapse" ? "mm-show" : ""}`}
                                        >
                                          {data1.content &&
                                            data1.content.map((data2, index) => {
                                              return (
                                                <>
                                                  <li className={`${data1.isNested ? "ps-3" : ""}`} key={index}>
                                                    <Link
                                                      className={`${path === data2.to ? "mm-active" : ""}`}
                                                      to={data2.to}
                                                    >
                                                      {data2.title}
                                                    </Link>
                                                  </li>
                                                </>
                                              );
                                            })}
                                        </ul>
                                      </Collapse>
                                    </>
                                  ) : (
                                    <Link to={data1.to}>{data1.title}</Link>
                                  )}
                                </li>
                              );
                            })}
                        </ul>
                      </Collapse>
                    </>
                  ) : (
                    <Link to={data.to}>
                      {data.iconStyle}
                      <span className='nav-text'>{data.title}</span>
                    </Link>
                  )}
                </li>
              );
            }
          })}
        </ul>
        {/* <div className='dropdown header-profile2 '>
          <div className='header-info2 text-center'>
            <img src={profile} alt='' />
            <div className='sidebar-info'>
              <div>
                <h5 className='font-w500 mb-0'>William Johanson</h5>
                <span className='fs-12'>williamjohn@mail.com</span>
              </div>
            </div>
            <div>
              <Link to={"#"} className='btn btn-md text-secondary'>
                Contact Us
              </Link>
            </div>
          </div>
        </div>
        <div className='copyright'>
          <p className='text-center'>
            <strong>Travl Hotel Admin Dashboard</strong> © {d.getFullYear()}{" "}
            All Rights Reserved
          </p>
          <p className='fs-12 text-center'>
            Made with{" "}
            <span className='heart' onClick={() => heartBlast()}></span> by
            DexignLab
          </p>
        </div> */}
      </PerfectScrollbar>
    </div>
  );
};

export default SideBar;
