import Index from "./jsx";
import ThemeContext from "./context/ThemeContext";
import ReactPublicContextProvider from "./providers/ReactPublicContextProvider";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import ReactLoadingProvider from "./providers/ReactLoadingProvider";
import CustomAuthProvider from "./providers/CustomAuthProvider";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import { reactQueryClientOptions } from "./configs/reactQueryClientOptions";
import ReactToastifyProvider from "./providers/ReactToastifyProvider";

const queryClient = new QueryClient(reactQueryClientOptions);

export default function App() {
  return (
    <CustomAuthProvider>
      <QueryClientProvider client={queryClient}>
        {process.env.REACT_APP_NODE_ENV === "development" && <ReactQueryDevtools initialIsOpen={false} />}
        <ReactPublicContextProvider>
          <ReactToastifyProvider>
            <ReactLoadingProvider>
              <ThemeContext>
                <Index />
              </ThemeContext>
            </ReactLoadingProvider>
          </ReactToastifyProvider>
        </ReactPublicContextProvider>
      </QueryClientProvider>
    </CustomAuthProvider>
  );
}
