import React, { useState, useEffect } from "react";
import { useMutation } from "@tanstack/react-query";
import { toast } from "react-toastify";
import { Link } from "react-router-dom";
import StatusBadge from "../../ui/status-badge";
import DropdownBlog from "../../ui/DropdownBlog";
import { usePublicContext } from "../../../../providers/ReactPublicContextProvider";
import {
  delDeleteSiteQueryFn,
  patchExperienceStatusToArchiveQueryFn,
  patchExperienceStatusToUnArchiveQueryFn,
  patchPublishSiteQueryFn,
  patchUnPublishSiteQueryFn,
} from "../../../../queries/queryFunctions/privetQueryFns/patchQueryFns";
import Pagination from "../../ui/Pagination";

export default function Table({
  refetch,
  data,
  itemsPerPage,
  setSkip,
  setLimit,
  activePage,
  setActivePage,
  isSearchable
}) {
  const { setIsLoading } = usePublicContext();
  const [pageSize, setPageSize] = useState(itemsPerPage);

  const { mutate: archiveExperience, isPending: isLoadingArchive } =
    useMutation({
      mutationFn: patchExperienceStatusToArchiveQueryFn,
      onSuccess: (data) => {
        toast.success("Experience archived successfully!");
        refetch();
      },
      onError: (error) => {
        toast.error(error?.response?.data?.message);
      },
    });

  const { mutate: publishExperience, isPending: isLoadingPublish } =
    useMutation({
      mutationFn: patchPublishSiteQueryFn,
      onSuccess: (data) => {
        toast.success("Experience publish successfully!");
        refetch();
      },
      onError: (error) => {
        toast.error(error?.response?.data?.message);
      },
    });

  const { mutate: unPublishExperience, isPending: isLoadingUnPublish } =
    useMutation({
      mutationFn: patchUnPublishSiteQueryFn,
      onSuccess: (data) => {
        toast.success("Experience unPublish successfully!");
        refetch();
      },
      onError: (error) => {
        toast.error(error?.response?.data?.message);
      },
    });

  const { mutate: unArchiveExperience, isPending: isLoadingUnArchive } =
    useMutation({
      mutationFn: patchExperienceStatusToUnArchiveQueryFn,
      onSuccess: (data) => {
        toast.success("Experience unArchive successfully!");
        refetch();
      },
      onError: (error) => {
        toast.error(error?.response?.data?.message);
      },
    });

  const { mutate: deleteExperience, isPending: isLoadingDelete } = useMutation({
    mutationFn: delDeleteSiteQueryFn,
    onSuccess: (data) => {
      toast.success("Experience Delete successfully!");
      refetch();
    },
    onError: (error) => {
      toast.error(error?.response?.data?.message);
    },
  });

  const handlePublish = (id) => {
    publishExperience({ id: id });
    refetch();
  };

  const handleUnPublish = (id) => {
    unPublishExperience({ id: id });
    refetch();
  };

  const handleArchive = (id) => {
    archiveExperience(id);
    refetch();
  };

  const handleUnArchive = (id) => {
    unArchiveExperience(id);
    refetch();
  };

  const handleDelete = (id) => {
    deleteExperience(id);
    refetch();
  };

  const handleClick = (pageIndex) => {
    setActivePage(pageIndex);
    const newSkip = pageIndex * itemsPerPage;
    const newLimit = itemsPerPage;
    setSkip(newSkip);
    setLimit(newLimit);
  };

  const handleChangePageSize = (event) => {
    const newSize = parseInt(event.target.value, 10);
    setPageSize(newSize);
    setLimit(newSize);
  };

  useEffect(() => {
    setIsLoading(
      isLoadingPublish ||
        isLoadingArchive ||
        isLoadingUnPublish ||
        isLoadingUnArchive ||
        isLoadingDelete,
    );
  }, [
    isLoadingPublish,
    isLoadingArchive,
    isLoadingUnPublish,
    isLoadingUnArchive,
    isLoadingDelete,
  ]);

  const startIdx = activePage * itemsPerPage;
  const endIdx = Math.min(startIdx + itemsPerPage, data?.[0]?.data?.length);

  return (
    <div className='table-container'>
      <div className='table-responsive'>
        <table className='table card-table display mb-4 shadow-hover default-table .dataTablesCard dataTable no-footer'>
          <thead>
            <tr>
              <th className='sorting_asc'>Experience Name</th>
              <th className='sorting'>Type</th>
              <th className='sorting'>Country</th>
              <th className='sorting'>Area</th>
              <th className='sorting'>Status</th>
              <th className='sorting'>Price (JOD)</th>
              <th className='sorting bg-none'></th>
            </tr>
          </thead>
          <tbody>
            {data?.[0]?.data?.map((experience, index) => (
              <tr key={index} className={index % 2 === 0 ? "even" : "odd"}>
                <td>
                  <div className='concierge-bx d-flex align-items-center'>
                    <img
                      className='me-3 rounded'
                      src={experience?.images?.[0] || "https://placehold.co/40x40"}
                      alt=''
                    />
                    <div
                      style={{
                        width: "200px",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                      }}
                    >
                      <h5 className='fs-16 mb-0 text-nowrap'>
                        <Link
                          to={experience?.type === "Stay" ? `/edit-stay?id=${experience?._id}` : `/edit-experience?id=${experience?._id}`}
                          className='text-black'
                          style={{
                            display: "block",
                            width: "100%",
                            height: "100%",
                            textDecoration: "none",
                          }}
                        >
                          <span
                            className='experience-name'
                            style={{
                              display: "inline-block",
                              width: "100%",
                              height: "100%",
                              overflow: "hidden",
                              textOverflow: "ellipsis",
                              whiteSpace: "nowrap",
                            }}
                            title={experience?.name || ""}
                          >
                            {experience?.name || ""}
                          </span>
                        </Link>
                      </h5>
                    </div>
                  </div>
                </td>
                <td>
                  <div>
                    <h5 className='text-nowrap'>{experience?.type || ""}</h5>
                  </div>
                </td>
                <td>
                  <div>
                    <h5 className='text-nowrap'>
                      {experience?.country?.[0]?.name || ""}
                    </h5>
                  </div>
                </td>
                <td>
                  <div>
                    <h5 className='text-nowrap'>{experience?.city || ""}</h5>
                  </div>
                </td>
                <td>
                  <StatusBadge status={experience?.status || ""} />
                </td>
                <td>
                  <div>
                    <h5 className='text-nowrap'>
                      {experience?.pricingInformation?.[0]?.price || ""}
                    </h5>
                  </div>
                </td>
                <td>
                  <DropdownBlog
                    experienceId={experience?._id}
                    showDeleteOption={experience?.status === 3}
                    showArchiveOption={true}
                    showDraftOption={false}
                    showPublishOption={experience?.status !== 3}
                    handleDelete={() => handleDelete(experience?._id)}
                    handleArchive={() =>
                      experience?.status === 3
                        ? handleUnArchive(experience?._id)
                        : handleArchive(experience?._id)
                    }
                    handlePublish={() =>
                      experience?.status === 2
                        ? handleUnPublish(experience?._id)
                        : handlePublish(experience?._id)
                    }
                    editLink={
                      experience?.type === "Stay"
                        ? `/edit-stay?id=${experience?._id || ""}`
                        : `/edit-experience?id=${experience?._id || ""}`
                    }
                    publishText={
                      experience?.status === 2 ? "Unpublish" : "Publish"
                    }
                    archiveText={
                      experience?.status === 3 ? "Unarchive" : "Archive"
                    }
                  />
                </td>
              </tr>
            ))}
          </tbody>
        </table>
       
       { !isSearchable && (
          <Pagination
          startIdx={startIdx}
          endIdx={endIdx}
          pageSize={pageSize}
          activePage={activePage}
          handleClick={handleClick}
          handleChangePageSize={handleChangePageSize}
          data={data}
          itemsPerPage={itemsPerPage}
          bookings={data}
          totalCount={data?.[0]?.totalCount}
           />

        )}
      </div>
    </div>
  );
}
