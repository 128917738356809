import { del } from "../../../configs/api";


export async function deleteSpokenLanguageQueryFn(id) {
    return await del(`/language/${id}`);
  }

  export async function deleteAccessibilityFeaturesQueryFn(id) {
    return await del(`/accessibility-features/${id}`);
  }

  export async function deleteAmenityQueryFn(id) {
    return await del(`/amenities/${id}`);
  }
  
  export async function deleteCategotyQueryFn(id) {
    return await del(`/amenity-category/${id}`);
  }

  export async function deleteExperienceTypeQueryFn(id) {
    return await del(`/experience-type/${id}`);
  }
  export async function deleteBookingOptionsQueryFn(id) {
    return await del(`/book-options/${id}`);
  }

  export async function deleteQuestionQueryFn(id) {
    return await del(`/faq/${id}`);
  }