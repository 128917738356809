import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import X_Svg from "./svg/X_Svg";

const grid = 8;

const getItemStyle = (isDragging, draggableStyle) => ({
  // some basic styles to make the items look a bit nicer
  userSelect: "none",
  margin: `0 ${grid}px 0 0`,
  borderRadius: "0.75rem",
  // change background colour if dragging
  background: isDragging ? "lightblue" : "grey",

  // styles we need to apply on draggables
  ...draggableStyle,
});

const getListStyle = (isDraggingOver) => ({
  background: isDraggingOver ? "#78b81d" : "lightgrey",
  display: "flex",
  padding: grid,
  overflow: "auto",
});

const ReorderableImageList = ({ items, setItems }) => {
  const onDragEnd = (result) => {
    if (!result.destination) {
      return;
    }
    const updatedImages = reorder(
      items,
      result.source.index,
      result.destination.index,
    );
    setItems(updatedImages);
  };

  const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);
    return result;
  };

  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <Droppable droppableId='droppable' direction='horizontal'>
        {(provided, snapshot) => (
          <div
            {...provided.droppableProps}
            ref={provided.innerRef}
            style={getListStyle(snapshot.isDraggingOver)}
          >
            {items?.map((item, index) => (
              <Draggable
                disableInteractiveElementBlocking
                key={item.id}
                draggableId={item.id}
                index={index}
              >
                {(provided, snapshot) => (
                  <div
                    ref={provided.innerRef}
                    {...provided.draggableProps}
                    {...provided.dragHandleProps}
                    style={getItemStyle(
                      snapshot.isDragging,
                      provided.draggableProps.style,
                    )}
                  >
                    <div className='position-relative'>
                      <div
                        style={{ zIndex: 999 }}
                        role='button'
                        className='position-absolute end-0 me-2 mt-2'
                        onClick={() =>
                          setItems(items.filter((val) => val.id !== item.id))
                        }
                      >
                        <X_Svg />
                      </div>
                      <p className='position-absolute text-black font-w600 ms-2'>
                        {index + 1}
                      </p>
                      {item.content}
                    </div>
                  </div>
                )}
              </Draggable>
            ))}
            {provided.placeholder}
          </div>
        )}
      </Droppable>
    </DragDropContext>
  );
};

export default ReorderableImageList;
