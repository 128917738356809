export default function BookagriName() {
  return (
    <svg
      style={{ width: "10rem", height: "2.56rem" }}
      viewBox='0 0 160 41'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <g id='Group'>
        <g id='Group_2'>
          <path
            id='Vector'
            d='M134.846 38.7997C134.655 38.9905 134.559 39.229 134.559 39.5151C134.559 39.8013 134.655 40.0398 134.846 40.2306C135.036 40.4214 135.275 40.5168 135.561 40.5168C135.847 40.5168 136.086 40.4214 136.276 40.2306C136.467 40.0398 136.563 39.8013 136.563 39.5151C136.563 39.229 136.467 38.9905 136.276 38.7997C135.895 38.3704 135.227 38.3704 134.846 38.7997Z'
            fill='#459724'
          />
          <path
            id='Vector_2'
            d='M142.859 38.6089L142.144 38.1319C142 38.0365 141.857 38.0842 141.762 38.1796L141.714 38.275C141.285 38.7997 140.808 39.0382 140.14 39.0382C139.806 39.0382 139.472 38.9428 139.186 38.7997C138.9 38.6566 138.709 38.4181 138.518 38.1319C138.375 37.8457 138.28 37.5595 138.28 37.2256C138.28 36.7486 138.423 36.3193 138.805 35.9855C139.138 35.6516 139.615 35.4608 140.14 35.4608C140.808 35.4608 141.333 35.6993 141.714 36.2239L141.762 36.3193C141.857 36.4624 142.048 36.4624 142.144 36.4147L142.859 35.9378C143.002 35.8424 143.05 35.6516 142.954 35.5085L142.907 35.4131C142.716 35.0792 142.477 34.8407 142.239 34.6022C141.953 34.4114 141.667 34.2206 141.285 34.1252C140.903 33.9821 140.522 33.9344 140.14 33.9344C139.52 33.9344 138.948 34.0775 138.423 34.3637C137.898 34.6499 137.469 35.0792 137.183 35.5562C136.897 36.0808 136.754 36.6055 136.754 37.2256C136.754 38.1319 137.087 38.8951 137.708 39.5152C138.328 40.1353 139.138 40.4692 140.092 40.4692C140.713 40.4692 141.237 40.3261 141.762 40.0876C142.239 39.8491 142.668 39.4675 142.954 39.0382L143.002 38.9428C143.05 38.8951 143.05 38.7997 143.05 38.7043C142.954 38.752 142.907 38.6566 142.859 38.6089Z'
            fill='#459724'
          />
          <path
            id='Vector_3'
            d='M146.341 34.0299C145.387 34.0299 144.624 34.3638 144.004 35.0793C143.431 35.6993 143.145 36.4625 143.145 37.3211C143.145 38.1797 143.431 38.9429 144.052 39.563C144.624 40.2308 145.435 40.5647 146.341 40.5647C147.247 40.5647 148.058 40.2308 148.631 39.563C149.203 38.9429 149.537 38.1797 149.537 37.3211C149.537 36.4625 149.251 35.6993 148.678 35.0793C148.058 34.3638 147.295 34.0299 146.341 34.0299ZM146.341 35.5562C146.818 35.5562 147.152 35.6993 147.486 36.0809C147.82 36.4148 147.963 36.8441 147.963 37.3211C147.963 37.655 147.867 37.9412 147.724 38.2274C147.581 38.5136 147.39 38.7044 147.152 38.8475C146.675 39.1337 146.007 39.1337 145.483 38.8475C145.244 38.7044 145.053 38.5136 144.91 38.2274C144.767 37.9412 144.672 37.655 144.672 37.3688C144.672 36.8918 144.815 36.4625 145.149 36.1286C145.483 35.747 145.912 35.5562 146.341 35.5562Z'
            fill='#459724'
          />
          <path
            id='Vector_4'
            d='M157.693 34.4115C157.36 34.173 156.93 34.0776 156.501 34.0776C156.024 34.0776 155.595 34.2207 155.165 34.4592C154.975 34.6023 154.784 34.7454 154.593 34.9362C154.545 34.8885 154.498 34.7931 154.45 34.7454C154.259 34.5069 154.021 34.3638 153.734 34.2207C153.448 34.0776 153.162 34.0299 152.828 34.0299C152.351 34.0299 151.922 34.173 151.54 34.4115C151.493 34.4115 151.493 34.4592 151.445 34.4592C151.445 34.2684 151.302 34.173 151.159 34.173H150.252C150.109 34.173 149.966 34.3161 149.966 34.4592V40.0877C149.966 40.2785 150.109 40.3739 150.252 40.3739H151.159C151.302 40.3739 151.445 40.2308 151.445 40.0877V37.7027C151.445 36.9395 151.54 36.5579 151.588 36.3671C151.683 36.1286 151.826 35.9378 152.065 35.7947C152.256 35.6516 152.494 35.5562 152.733 35.5562C152.924 35.5562 153.067 35.6039 153.21 35.6993C153.353 35.7947 153.401 35.8901 153.448 36.0332C153.496 36.1286 153.544 36.4148 153.544 37.2257V40.0877C153.544 40.2785 153.687 40.3739 153.83 40.3739H154.736C154.879 40.3739 155.022 40.2308 155.022 40.0877V37.8458C155.022 36.9395 155.118 36.5579 155.165 36.3671C155.261 36.1286 155.404 35.9378 155.595 35.7471C155.785 35.604 156.024 35.5086 156.262 35.5086C156.453 35.5086 156.596 35.5562 156.739 35.6516C156.883 35.747 156.93 35.8424 156.978 35.9855C157.026 36.0809 157.073 36.3194 157.073 37.0349V40.0877C157.073 40.2785 157.216 40.3739 157.36 40.3739H158.314C158.457 40.3739 158.6 40.2308 158.6 40.0877V37.0349C158.6 36.3194 158.504 35.747 158.361 35.3655C158.314 34.9362 158.075 34.6023 157.693 34.4115Z'
            fill='#459724'
          />
        </g>
        <g id='Group_3'>
          <path
            id='Vector_5'
            d='M15.693 15.9994C15.216 15.5701 14.6437 15.1885 13.9282 14.8546C14.4529 14.3776 14.8821 13.8529 15.2637 13.2328C15.9315 12.0881 16.3131 10.7525 16.3131 9.36921C16.3131 7.89053 15.9315 6.45956 15.1206 5.21938C14.3098 3.9792 13.2604 3.02522 11.8771 2.45282C10.5415 1.88043 8.77665 1.59424 6.43939 1.59424H0.429293C0.190797 1.59424 0 1.78503 0 2.02353V30.5953C0 30.8338 0.190797 31.0246 0.429293 31.0246H8.53815C11.4955 31.0246 13.9282 30.166 15.7407 28.4489C17.5533 26.7317 18.5073 24.5852 18.5073 22.1049C18.5073 20.8647 18.2688 19.7199 17.7918 18.6705C17.3148 17.6689 16.5993 16.7626 15.693 15.9994ZM10.7323 11.3249C10.3507 11.8973 9.77833 12.3266 9.11054 12.6128C8.34736 12.8989 7.05948 13.0897 5.29461 13.0897H4.81762V6.50726H6.91638C8.58585 6.50726 9.73063 6.79345 10.3984 7.31814C11.0185 7.84283 11.3047 8.51062 11.3047 9.4169C11.3524 10.0847 11.1139 10.7525 10.7323 11.3249ZM4.81762 17.9551H5.29461C7.48877 17.9551 9.06284 18.0505 9.92143 18.2412C11.0662 18.5274 11.9725 19.0521 12.6403 19.7676C13.2604 20.4831 13.5466 21.294 13.5466 22.248C13.5466 23.0112 13.3081 23.679 12.8788 24.3467C12.4495 25.0145 11.8294 25.4915 11.1139 25.7777C10.303 26.1116 8.96745 26.3024 7.15488 26.3024H4.86532V17.9551H4.81762Z'
            fill='url(#paint0_linear_59_1632)'
          />
          <path
            id='Vector_6'
            d='M32.7026 8.2149C29.2683 8.2149 26.4063 9.45509 24.1644 11.9831C22.1134 14.225 21.1117 16.9439 21.1117 19.9966C21.1117 23.0971 22.2088 25.8159 24.3552 28.1532C26.5017 30.4904 29.3637 31.6829 32.7503 31.6829C36.1369 31.6829 38.9512 30.4904 41.1454 28.1532C43.2918 25.8636 44.3889 23.0971 44.3889 19.9966C44.3889 16.9439 43.3395 14.225 41.3361 11.9831C38.9989 9.45509 36.0892 8.2149 32.7026 8.2149ZM32.6549 12.9371C34.5152 12.9371 36.0892 13.6049 37.4248 14.9882C38.8081 16.3715 39.4759 18.0409 39.4759 20.0443C39.4759 21.3322 39.142 22.5247 38.5696 23.6217C37.9495 24.7188 37.1386 25.5297 36.0892 26.1498C35.0399 26.7222 33.8951 27.0561 32.6549 27.0561C31.4147 27.0561 30.2699 26.7699 29.2206 26.1498C28.1712 25.5774 27.3603 24.7188 26.7402 23.6217C26.1201 22.5247 25.8339 21.3322 25.8339 20.0443C25.8339 18.0409 26.5017 16.4192 27.8373 14.9882C29.2206 13.6049 30.7946 12.9371 32.6549 12.9371Z'
            fill='url(#paint1_linear_59_1632)'
          />
          <path
            id='Vector_7'
            d='M56.9051 8.22443C53.4708 8.22443 50.6088 9.46461 48.367 11.9927C46.3159 14.2345 45.3142 16.9534 45.3142 20.0061C45.3142 23.1066 46.4113 25.8254 48.5578 28.1627C50.7042 30.4999 53.5662 31.6924 56.9528 31.6924C60.3395 31.6924 63.1537 30.4999 65.3479 28.1627C67.4943 25.8731 68.5914 23.1066 68.5914 20.0061C68.5914 16.9534 67.542 14.2345 65.5387 11.9927C63.2014 9.46461 60.3395 8.22443 56.9051 8.22443ZM56.9051 12.9466C58.7654 12.9466 60.3394 13.6144 61.7227 14.9977C63.106 16.381 63.7738 18.0505 63.7738 20.0538C63.7738 21.3417 63.4399 22.5342 62.8675 23.6313C62.2474 24.7283 61.4365 25.5392 60.3871 26.1593C59.3378 26.7317 58.193 27.0656 56.9528 27.0656C55.7126 27.0656 54.5678 26.7794 53.5185 26.1593C52.4691 25.5869 51.6582 24.7283 51.0381 23.6313C50.418 22.5342 50.1318 21.3417 50.1318 20.0538C50.1318 18.0505 50.7996 16.4287 52.1352 14.9977C53.4231 13.6144 54.9971 12.9466 56.9051 12.9466Z'
            fill='url(#paint2_linear_59_1632)'
          />
          <path
            id='Vector_8'
            d='M78.5605 19.4815L90.0083 9.56006C90.1514 9.41696 90.1991 9.22617 90.1514 9.08307C90.1037 8.89227 89.9129 8.79688 89.7221 8.79688H83.9982C83.9028 8.79688 83.7597 8.84458 83.712 8.89228L75.9371 15.6656V1.4512C75.9371 1.21271 75.7463 1.02191 75.5078 1.02191H71.6441C71.4056 1.02191 71.2148 1.21271 71.2148 1.4512V30.7385C71.2148 30.977 71.4056 31.1678 71.6441 31.1678H75.5078C75.7463 31.1678 75.9371 30.977 75.9371 30.7385V23.4405L84.5229 31.0724C84.6183 31.1678 84.7137 31.1678 84.8091 31.1678H90.3422C90.533 31.1678 90.6761 31.0724 90.7715 30.8816C90.8192 30.6908 90.7715 30.5 90.6761 30.4046L78.5605 19.4815Z'
            fill='url(#paint3_linear_59_1632)'
          />
          <path
            id='Vector_9'
            d='M110.758 8.74912H106.942C106.703 8.74912 106.512 8.93991 106.512 9.17841V10.7525C105.892 10.2278 105.224 9.7508 104.557 9.41691C103.078 8.65372 101.361 8.22443 99.5482 8.22443C96.4001 8.22443 93.6335 9.36921 91.3916 11.6588C89.1498 13.9483 88.005 16.7149 88.005 19.863C88.005 23.1066 89.1498 25.9208 91.3439 28.1627C93.5858 30.4522 96.3047 31.597 99.4528 31.597C101.218 31.597 102.887 31.2154 104.366 30.4999C105.081 30.1184 105.797 29.6891 106.465 29.1167V30.643C106.465 30.8815 106.655 31.0723 106.894 31.0723H110.71C110.948 31.0723 111.139 30.8815 111.139 30.643V9.17841C111.187 8.93991 110.996 8.74912 110.758 8.74912ZM99.6913 27.0656C98.4988 27.0656 97.354 26.7317 96.257 26.1116C95.2076 25.4915 94.349 24.5852 93.7289 23.4882C93.1088 22.3434 92.7749 21.1509 92.7749 19.9584C92.7749 18.7182 93.1088 17.5258 93.7289 16.381C94.349 15.2839 95.2076 14.3776 96.257 13.8052C97.3063 13.1851 98.4988 12.8989 99.739 12.8989C101.695 12.8989 103.316 13.5667 104.652 14.95C105.988 16.3333 106.655 18.0028 106.655 20.0538C106.655 21.3894 106.369 22.6296 105.749 23.679C105.177 24.7283 104.318 25.5869 103.221 26.1593C102.124 26.7794 100.931 27.0656 99.6913 27.0656Z'
            fill='url(#paint4_linear_59_1632)'
          />
          <path
            id='Vector_10'
            d='M136.61 8.74915H132.747C132.508 8.74915 132.317 8.93994 132.317 9.17844V10.7048C131.602 10.0847 130.839 9.60773 130.076 9.22614C128.645 8.51065 127.023 8.17676 125.401 8.17676C123.398 8.17676 121.49 8.70145 119.677 9.75083C117.865 10.8002 116.434 12.2312 115.432 13.9961C114.43 15.7609 113.906 17.7166 113.906 19.7677C113.906 21.8187 114.383 23.7267 115.384 25.4439C116.386 27.161 117.769 28.5443 119.582 29.5937C121.394 30.5954 123.302 31.1201 125.353 31.1201C127.071 31.1201 128.74 30.7385 130.314 30.023C131.03 29.6891 131.697 29.2598 132.317 28.8305C132.222 30.0707 132.031 30.977 131.697 31.597C131.268 32.4079 130.553 33.0757 129.551 33.6481C128.502 34.2205 127.166 34.5067 125.592 34.5067C123.97 34.5067 122.635 34.2205 121.585 33.6481C120.536 33.1234 119.677 32.2648 119.057 31.0724L118.866 30.7385C118.771 30.5954 118.628 30.5 118.485 30.5H114.335C114.192 30.5 114.049 30.5954 113.953 30.6908C113.858 30.8339 113.858 30.977 113.906 31.1201L114.335 32.0263C115.098 33.7435 116.004 35.0791 116.958 35.9854C117.912 36.8917 119.2 37.6549 120.727 38.1795C122.205 38.7042 123.922 38.9904 125.783 38.9904C128.311 38.9904 130.553 38.418 132.413 37.321C134.321 36.1762 135.656 34.5544 136.42 32.5033C136.944 31.0724 137.231 28.8782 137.231 25.9209V9.17844C137.087 8.93994 136.897 8.74915 136.61 8.74915ZM132.461 19.7677C132.461 21.8187 131.84 23.4405 130.6 24.6807C129.36 25.9208 127.691 26.5409 125.592 26.5409C123.493 26.5409 121.871 25.9208 120.583 24.6807C119.296 23.4405 118.676 21.8664 118.676 19.9585C118.676 18.6706 119.009 17.4781 119.63 16.4287C120.25 15.3316 121.108 14.473 122.205 13.9007C123.302 13.2806 124.495 12.9944 125.783 12.9944C127.023 12.9944 128.168 13.2806 129.217 13.9007C130.266 14.473 131.077 15.3316 131.65 16.3333C132.174 17.2396 132.461 18.4321 132.461 19.7677Z'
            fill='url(#paint5_linear_59_1632)'
          />
          <path
            id='Vector_11'
            d='M139.949 31.1201H143.861C144.099 31.1201 144.29 30.9293 144.29 30.6908V23.2498C144.29 19.8631 144.433 17.6213 144.719 16.5719C145.101 15.284 145.578 14.33 146.246 13.7576C146.866 13.2329 147.438 12.9467 148.058 12.9467C148.154 12.9467 148.44 12.9944 149.012 13.2329L149.489 13.4237C149.68 13.5191 149.919 13.4237 150.062 13.2329L152.065 10.0371C152.113 9.94169 152.16 9.79859 152.113 9.70319C152.065 9.56009 152.017 9.46469 151.874 9.41699L151.302 9.0831C150.348 8.55841 149.394 8.27222 148.488 8.27222C147.343 8.27222 146.198 8.60611 145.149 9.3216C144.862 9.51239 144.576 9.70319 144.29 9.94169V9.2262C144.29 8.9877 144.099 8.79691 143.861 8.79691H139.949C139.711 8.79691 139.52 8.9877 139.52 9.2262V30.6908C139.52 30.9293 139.711 31.1201 139.949 31.1201Z'
            fill='url(#paint6_linear_59_1632)'
          />
          <g id='Group_4'>
            <path
              id='Vector_12'
              d='M156.358 6.69805C157.216 6.69805 157.98 6.36416 158.6 5.79177C159.22 5.17168 159.506 4.40849 159.506 3.5976C159.506 2.73902 159.172 1.97583 158.6 1.35574C157.98 0.735655 157.216 0.449463 156.358 0.449463C155.499 0.449463 154.736 0.783354 154.164 1.35574C153.544 1.97583 153.257 2.73902 153.257 3.5976C153.257 4.45619 153.591 5.21938 154.164 5.79177C154.784 6.36416 155.547 6.69805 156.358 6.69805Z'
              fill='url(#paint7_linear_59_1632)'
            />
            <path
              id='Vector_13'
              d='M157.693 8.84454H155.07C154.545 8.84454 154.116 9.27384 154.116 9.79853V30.3569C154.116 30.8816 154.545 31.3109 155.07 31.3109H157.693C158.218 31.3109 158.647 30.8816 158.647 30.3569V9.79853C158.647 9.27384 158.218 8.84454 157.693 8.84454Z'
              fill='url(#paint8_linear_59_1632)'
            />
          </g>
        </g>
      </g>
      <defs>
        <linearGradient
          id='paint0_linear_59_1632'
          x1='9.26056'
          y1='1.62934'
          x2='9.26056'
          y2='31.1115'
          gradientUnits='userSpaceOnUse'
        >
          <stop stop-color='#FFCC01' />
          <stop offset='0.1274' stop-color='#FFAC06' />
          <stop offset='0.3242' stop-color='#FF8109' />
          <stop offset='0.5149' stop-color='#FF5F0A' />
          <stop offset='0.6955' stop-color='#FF470A' />
          <stop offset='0.8617' stop-color='#FE390A' />
          <stop offset='1' stop-color='#FE330A' />
        </linearGradient>
        <linearGradient
          id='paint1_linear_59_1632'
          x1='32.6775'
          y1='8.21572'
          x2='32.6775'
          y2='31.6232'
          gradientUnits='userSpaceOnUse'
        >
          <stop stop-color='#FFCC01' />
          <stop offset='0.1274' stop-color='#FFAC06' />
          <stop offset='0.3242' stop-color='#FF8109' />
          <stop offset='0.5149' stop-color='#FF5F0A' />
          <stop offset='0.6955' stop-color='#FF470A' />
          <stop offset='0.8617' stop-color='#FE390A' />
          <stop offset='1' stop-color='#FE330A' />
        </linearGradient>
        <linearGradient
          id='paint2_linear_59_1632'
          x1='56.8934'
          y1='8.22524'
          x2='56.8934'
          y2='31.6328'
          gradientUnits='userSpaceOnUse'
        >
          <stop stop-color='#FFCC01' />
          <stop offset='0.1274' stop-color='#FFAC06' />
          <stop offset='0.3242' stop-color='#FF8109' />
          <stop offset='0.5149' stop-color='#FF5F0A' />
          <stop offset='0.6955' stop-color='#FF470A' />
          <stop offset='0.8617' stop-color='#FE390A' />
          <stop offset='1' stop-color='#FE330A' />
        </linearGradient>
        <linearGradient
          id='paint3_linear_59_1632'
          x1='80.9525'
          y1='0.941825'
          x2='80.9525'
          y2='31.1116'
          gradientUnits='userSpaceOnUse'
        >
          <stop stop-color='#FFCC01' />
          <stop offset='0.1274' stop-color='#FFAC06' />
          <stop offset='0.3242' stop-color='#FF8109' />
          <stop offset='0.5149' stop-color='#FF5F0A' />
          <stop offset='0.6955' stop-color='#FF470A' />
          <stop offset='0.8617' stop-color='#FE390A' />
          <stop offset='1' stop-color='#FE330A' />
        </linearGradient>
        <linearGradient
          id='paint4_linear_59_1632'
          x1='99.5967'
          y1='31.6328'
          x2='99.5967'
          y2='8.22524'
          gradientUnits='userSpaceOnUse'
        >
          <stop offset='0.0490798' stop-color='#459724' />
          <stop offset='0.6687' stop-color='#87C11C' />
        </linearGradient>
        <linearGradient
          id='paint5_linear_59_1632'
          x1='125.421'
          y1='39.0266'
          x2='125.421'
          y2='8.22527'
          gradientUnits='userSpaceOnUse'
        >
          <stop offset='0.0490798' stop-color='#459724' />
          <stop offset='0.6687' stop-color='#87C11C' />
        </linearGradient>
        <linearGradient
          id='paint6_linear_59_1632'
          x1='145.821'
          y1='31.1116'
          x2='145.821'
          y2='8.22533'
          gradientUnits='userSpaceOnUse'
        >
          <stop offset='0.0490798' stop-color='#459724' />
          <stop offset='0.6687' stop-color='#87C11C' />
        </linearGradient>
        <linearGradient
          id='paint7_linear_59_1632'
          x1='156.375'
          y1='6.68341'
          x2='156.375'
          y2='0.381873'
          gradientUnits='userSpaceOnUse'
        >
          <stop stop-color='#87C11C' />
          <stop offset='0.816' stop-color='#87C11C' />
        </linearGradient>
        <linearGradient
          id='paint8_linear_59_1632'
          x1='156.376'
          y1='31.3421'
          x2='156.376'
          y2='8.84712'
          gradientUnits='userSpaceOnUse'
        >
          <stop offset='0.0490798' stop-color='#459724' />
          <stop offset='0.6687' stop-color='#87C11C' />
        </linearGradient>
      </defs>
    </svg>
  );
}
