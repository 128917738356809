import React from "react";
import { Link } from "react-router-dom";
import useUser from "../../../utils/hooks/useUser";

const Error400 = () => {
  const { isAuthenticUser } = useUser();

  return (
    <div className='authincation'>
      <div className='container'>
        {" "}
        <div className='row justify-content-center h-100 align-items-center'>
          <div className='col-md-7'>
            <div className='form-input-content text-center error-page'>
              <h1 className='error-text fw-bold'>400</h1>
              <h4>
                <i className='fa fa-thumbs-down text-danger' /> Bad Request
              </h4>
              <p>Your Request resulted in an error</p>
              <div>
                <Link
                  className='btn btn-primary'
                  to={isAuthenticUser ? "/dashboard" : "/"}
                >
                  {isAuthenticUser ? "Back to Home" : "Back to login"}
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Error400;
