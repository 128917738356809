import React, { useState } from "react";
import Label from "./custom-inputs/Label";
import CustomInput from "./custom-inputs/CustomInput";
import ErrorFormik from "./ErrorFormik";
import DeletionCircle from "./svg/DeletionCircleSvg";
import EditSvg from "./svg/EditSvg";
import PlusIcon from "./svg/PlusIconSvg";
import GeneralSelect from "./custom-inputs/select-inputs/GeneralSelect";
import { toast } from "react-toastify";
import * as Yup from "yup";

const validationSchema = Yup.object().shape({
  personType: Yup.string().required("Person Type is required"),
  price: Yup.number().transform((value) => (isNaN(value) ? undefined : value)),
  minUnit: Yup.number()
    .transform((value) => (isNaN(value) ? undefined : value))
    .min(1, "Min Unit must be greater than 1")
    .required("Min Unit is required"),
  maxUnit: Yup.number()
    .transform((value) => (isNaN(value) ? undefined : value))
    .min(1, "Max Unit must be greater than 1")
    .test(
      "is-minUnit-greater-maxUnit",
      "Max Unit must be greater than Min Unit",
      function (value) {
        const { minUnit } = this.parent;
        if (!value) return true;
        return minUnit <= value;
      },
    )
    .required("Max Unit is required"),
  discount: Yup.number()
    .transform((value) => (value ? value : undefined))
    .test(
      "is-valid-discount",
      "Discount must be greater than or equal to price",
      function (value) {
        const { price } = this.parent;
        if (!value) return true; // Allow null or undefined
        return price >= value;
      },
    ),
});

function validateNoOverlap(newEntry, existingEntries) {
  const newMin = Number(newEntry.minUnit);
  const newMax = Number(newEntry.maxUnit);

  for (const entry of existingEntries) {
    const min = Number(entry.minUnit);
    const max = Number(entry.maxUnit);

    if (entry.personType === newEntry.personType) {
      if (newMin === newMax && newMin === min && newMax === max) {
        return true; // Overlap detected
      } else if (
        (newMin <= max && newMax >= min) ||
        (min <= newMax && max >= newMin)
      ) {
        return true; // Overlap detected
      }
    }
  }

  return false; // No overlap
}

export default function DynamicPricingFields({
  onChange,
  val,
  errors,
  touched,
  inputFields,
  fieldName,
  textButton,
  NewEntryShap,
  tableLabel
}) {

  const [newEntry, setNewEntry] = useState(NewEntryShap);
  const [isEditMode, setIsEditMode] = useState(false);
  const [editIndex, setEditIndex] = useState(undefined);

  const handleAddFields = async () => {
    try {
      await validationSchema.validate(newEntry, { abortEarly: false });
      // Yup validation passed, now check for overlaps max and min units
      if (validateNoOverlap(newEntry, val)) {
        toast.error("Overlapping units detected for the same person type.");
        return;
      }

      onChange([...val, newEntry]);
      setNewEntry(NewEntryShap);
    } catch (error) {
      if (error instanceof Yup.ValidationError) {
        const errorMessage = error.errors[0]; // .join(", ");
        toast.error(errorMessage);
      } else {
        // Handle other types of errors
        console.log(error)
        console.error("Error:", error);
        toast.error("An error occurred. Please try again later.");
      }
    }
  };

  const handleEditFields = async () => {
    try {
      await validationSchema.validate(newEntry, { abortEarly: false });
      // Yup validation passed, now check for overlaps max and min units
      const valuesWithoutEditIndex = val.filter(
        (_, index) => editIndex !== index,
      );
      if (validateNoOverlap(newEntry, valuesWithoutEditIndex)) {
        toast.error("Overlapping units detected for the same person type.");
        return;
      }

      setIsEditMode(false);
      setEditIndex(undefined);

      onChange([...valuesWithoutEditIndex, newEntry]);
      setNewEntry(NewEntryShap);
    } catch (error) {
      if (error instanceof Yup.ValidationError) {
        const errorMessage = error.errors[0]; // .join(", ");
        toast.error(errorMessage);
      } else {
        // Handle other types of errors
        console.error("Error:", error);
        toast.error("An error occurred. Please try again later.");
      }
    }
  };

  const handleRemoveFields = (index) => {
    const values = [...val];
    values.splice(index, 1);
    onChange(values);
  };

  const handleChangeNewEntry = (name, value) => {
    setNewEntry({ ...newEntry, [name]: value });
  };

  const handelEditField = async (index) => {
    setTimeout(() => {
      window.scrollTo(0, 300);
    }, 2);

    setEditIndex(index);
    setNewEntry(val[index]);
    setIsEditMode(true);
    
  };

  val.sort((a, b) => {
    if (a.personType < b.personType) {
      return -1;
    }
    if (a.personType > b.personType) {
      return 1;
    }
    return a.minUnit - b.minUnit;
  });
  return (
    <div>
      <div className='mt-3 d-flex justify-content-end'>
        <button
          type='button'
          className='btn btn-primary sw-btn-next d-flex align-content-center'
          onClick={isEditMode ? handleEditFields : handleAddFields}
        >
          <div className='w-6 h-6 select-none'>
            <PlusIcon width={24} height={24} />
          </div>
          <div className='ms-2 my-auto'>
            {isEditMode ? "Save" : textButton || "Add Slot"}
          </div>
        </button>
      </div>
      {/* Input fields */}
      <div className='w-100 row g-3 justify-content-center'>
        {inputFields?.map((input, inputIndex) => (
          <div className={`col-lg-6`} key={inputIndex}>
            <Label
              forId={input.id}
              text={input.label}
              isRequired={input.isRequired}
            />
            {["number", "text", "date", "time"].includes(
              input.type.toLowerCase(),
            ) && (
              <CustomInput
                placeholder={input.placeholder}
                id={input.id}
                name={input.name}
                type={input.type}
                value={newEntry?.[input.name] || ""}
                onChange={(event) =>
                  handleChangeNewEntry(input.name, event.target.value)
                }
                isDisable={input?.isDisable}
                className='form-control'
              />
            )}
            <ErrorFormik
              isError={
                errors?.[fieldName] && errors?.[fieldName]?.[0]?.[input.name]
              }
              isTouched={
                touched?.[fieldName] && touched?.[fieldName]?.[0]?.[input.name]
              }
              error={
                errors?.[fieldName] && errors?.[fieldName]?.[0]?.[input.name]
              }
            />
            {input.type.toLowerCase() === "select" && (
              <GeneralSelect
                defaultValue={newEntry?.[input.name] || ""}
                placeholder='Search'
                isClearable={false}
                isSearchable={true}
                isDisabled={false}
                isLoading={false}
                isRtl={false}
                options={input.options}
                handleChange={(val) =>
                  handleChangeNewEntry(input.name, val.value)
                }
                id={input.id}
                name={input.name}
              />
            )}
            <ErrorFormik
              isError={
                errors?.[fieldName] && errors?.[fieldName]?.[0]?.[input.name]
              }
              isTouched={
                touched?.[fieldName] && touched?.[fieldName]?.[0]?.[input.name]
              }
              error={
                errors?.[fieldName] && errors?.[fieldName]?.[0]?.[input.name]
              }
            />
          </div>
        ))}
      </div>
      {/* Table to display entered values */}
      <table className='table overflow-auto'>
        <thead>
          <tr>
            {tableLabel?.map((input) => (
              <th key={input?.label}>{input?.label}</th>
            ))}
          </tr>
        </thead>
        <tbody>
          {val?.map((field, index) => (
            <tr key={index}>
              {tableLabel?.slice(0, -1)?.map((input) => (
                <td key={`${index}-${input?.name}`}>{field[input?.name]}</td>
              ))}
              <td>
                <div className='d-flex gap-3'>
                  <button
                    style={{
                      width: "50px",
                      height: "50px",
                      padding: "0",
                      borderRadius: "50%",
                      backgroundColor: "red",
                    }}
                    type='button'
                    className='btn btn-danger d-flex align-items-center justify-content-center'
                    onClick={() => handleRemoveFields(index)}
                  >
                    <DeletionCircle width={24} height={24} style='' />
                  </button>
                  <button
                    disabled={isEditMode}
                    style={{
                      width: "50px",
                      height: "50px",
                      padding: "0",
                      borderRadius: "50%",
                      backgroundColor: "red",
                    }}
                    type='button'
                    className='btn btn-danger d-flex align-items-center justify-content-center'
                    onClick={() => handelEditField(index)}
                  >
                    <EditSvg width={24} height={24} style='' />
                  </button>
                </div>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}
