import React from 'react';

const Pagination = ({
  startIdx,
  endIdx,
  pageSize,
  handleChangePageSize,
  activePage,
  handleClick,
  data,
  itemsPerPage,
  bookings,
  totalCount
}) => {


  return (
    <div className='mt-3 mb-3 d-sm-flex justify-content-between align-items-center'>
      <div className="dataTables_info mb-2 mb-sm-0">
        Showing {startIdx + 1} to {endIdx} of 
        <div className="dataTables_length" style={{ display: 'inline-block', marginLeft: '10px' }}>
          <label htmlFor="pageSize" className="form-label">
            <select
              className="form-select"
              value={pageSize}
              onChange={handleChangePageSize}
              id="pageSize"
            >
              <option value="10">10</option>
              <option value="20">20</option>
              <option value="30">30</option>
            </select>
          </label>
        </div>
        <span style={{ marginLeft: '5px' }}>entries</span>
      </div>
      <div className="text-center">
        <div className='dataTables_paginate paging_simple_numbers mb-0' id='example2_paginate'>
          <button
            className={`btn btn-primary ${activePage === 0 ? "disabled" : ""}`}
            onClick={() => handleClick(activePage - 1)}
            disabled={bookings && bookings?.length === 0}
            style={{ marginRight: '10px' }}
          >
            <i className='fa fa-angle-double-left' aria-hidden='true'></i>
          </button>
          <span className='text-primary mx-3 h3'>{activePage + 1}</span>
          <button
            className={`btn btn-primary next ${activePage === Math.ceil(totalCount / itemsPerPage) - 1 ? "disabled" : ""}`}
            onClick={() => handleClick(activePage + 1)}
            disabled={bookings && bookings?.length === 0}
            style={{ marginLeft: '10px' }}
          >

            <i className='fa fa-angle-double-right' aria-hidden='true'></i>
          </button>
        </div>
      </div>
    </div>
  );
};

export default Pagination;
