import { useEffect, useState } from "react";
import ErrorFormik from "../../ui/ErrorFormik";
import { useFormik } from "formik";
import { useMutation, useQuery } from "@tanstack/react-query";
import { toast } from "react-toastify";
import { addSiteStep1Schema } from "../../../../utils/forms-schemas";
import {
  DIFFICULTY_OPTIONS,
  PLACE_TYPE_OPTIONS,
} from "../../../../utils/constants";
import GeneralSelect from "../../ui/custom-inputs/select-inputs/GeneralSelect";
import Label from "../../ui/custom-inputs/Label";
import CustomEditor from "../../ui/custom-inputs/CustomEditor";
import MultiSelect from "../../ui/custom-inputs/select-inputs/MultiSelect";
import Switch from "../../ui/custom-inputs/Switch";
import Hr from "../../ui/Hr";
import CustomInput from "../../ui/custom-inputs/CustomInput";
import DynamicInputFields from "../../ui/custom-inputs/DynamicInputFields";
import { usePublicContext } from "../../../../providers/ReactPublicContextProvider";
import {
  getAccessibilityFeaturesList,
  getAmenitiesList,
  getBookingOptionsList,
  getCitiesForCountries,
  getCountriesList,
  getExperienceTypeList,
  getLanguageList,
} from "../../../../queries/queryFunctions/publicQueryFns/getQueryFns";
import GoogleMaps from "../../ui/GoogleMaps";
import { postAddSiteStep1QueryFn } from "../../../../queries/queryFunctions/privetQueryFns/postQueryFns";
import { useSearchParams } from "react-router-dom";
import { patchAddSiteStep1QueryFn } from "../../../../queries/queryFunctions/privetQueryFns/patchQueryFns";
import CustomTextarea from "../../ui/custom-inputs/CustomTextarea";

const StepOne = ({ handleNext, ExperienceId, siteInfo }) => {
  const { setIsLoading } = usePublicContext();
  const [searchParams, setSearchParams] = useSearchParams();

  const [accessibilityList, setAccessibilityList] = useState([]);
  const [countriesList, setCountriesList] = useState([]);
  const [citiesList, setCitiesList] = useState([]);
  const [availableAmenitiesList, setAvailableAmenitiesList] = useState([]);
  const [experienceTypes, setExperienceTypes] = useState([]);
  const [spokenLanguagesList, setSpokenLanguagesList] = useState([]);
  const [bookOptionsList, setBookOptionsList] = useState([]);

  function setFieldsValues() {
    return {
      wheelChair: siteInfo?.site?.wheelChair || false,
      duration: siteInfo?.site?.duration || 0,
      thingsToKnow: siteInfo?.site?.thingsToKnow || "",
      placeType: siteInfo?.site?.type || "Experience",
      experienceTitle: siteInfo?.site?.name || "",
      overview: siteInfo?.site?.longDescription || "",
      whatToExpect: siteInfo?.site?.whatToExpect?.description || "",
      experienceTypes:
        siteInfo?.site?.experienceTypes?.map((item) => ({
          label: item?.nameEn,
          value: item?._id,
        })) || [],
      bookagriExperience: siteInfo?.site?.bookagriBadge || false,
      country: {
        label: siteInfo?.site?.country?.name || "",
        value: siteInfo?.site?.country?._id || "",
      },
      city: {
        label: siteInfo?.site?.city || "",
        value: siteInfo?.site?.city || "",
      },
      location: {
        type: "Point",
        coordinates: siteInfo?.site?.location?.coordinates || [],
        area: siteInfo?.site?.googleLocationLink || "",
      },
      extras:
        siteInfo?.site?.extras.length > 0
          ? siteInfo?.site?.extras?.map((item) => ({
              extraNameEn: item?.nameEn,
              extraNameAr: item?.nameAr,
              extraPrice: Number(item.price),
            }))
          : [{ extraNameEn: "",extraNameAr: "", extraPrice: 0 }],
      ageSuitability: siteInfo?.site?.ageSuitability || 0,
      levelOfDifficulty: siteInfo?.site?.levelOfDifficulty || "",
      guideIncluded: siteInfo?.site?.guideIsIncluded || false,
      guideOptionalOrMandatory: siteInfo?.site?.guideIsMandatory || false,
      guideDescription: siteInfo?.site?.guide?.description || "",
      guidePrice: siteInfo?.site?.guide?.price || 0,
      guideLearnMore: siteInfo?.site?.guide?.guideLearnMore || "",
      spokenLanguages:
        siteInfo?.site?.guide?.languages?.map((item) => ({
          label: item?.nameEn || "",
          value: item?._id,
        })) || [],
      specialInstructions: siteInfo?.site?.specialInstructions,
      accessibilityFeatures:
        siteInfo?.site?.accessibilityFeatures?.map((item) => ({
          label: item?.nameEn,
          value: item?._id,
        })) || [],
      bookOptions:
        siteInfo?.site?.bookOptions?.map((item) => ({
          label: item?.nameEn,
          value: item?._id,
        })) || [],
      availableAmenities:
        siteInfo?.site?.amenities?.map((item) => ({
          label: item?.nameEn,
          value: item?._id,
        })) || [],
      cancellationPolicy: siteInfo?.site?.cancellationPolicy || "",
      transportationIsIncluded:
        siteInfo?.site?.transportationIsIncluded || false,
      transportationIsMandatory:
        siteInfo?.site?.transportationIsMandatory || false,
      transportationDescription:
        siteInfo?.site?.transportation?.description || "",
      transportationPrice: siteInfo?.site?.transportation?.price || 0,
      transportationLearnMore: siteInfo?.site?.transportation?.learnMore || "",
    };
  }

  const {
    values,
    errors,
    touched,
    handleBlur,
    handleChange,
    handleSubmit,
    setFieldValue,
    setFieldTouched,
    isValid,
    resetForm,
    setValues,
  } = useFormik({
    initialValues: setFieldsValues(),
    enableReinitialize: true,
    validationSchema: addSiteStep1Schema,
    onSubmit: onSubmit,
  });

  const {
    data: bookingOptionsData,
    isPending: isLoadingBookingOptions,
    isError: isErrorBookingOptions,
  } = useQuery({
    queryKey: ["book-options-list", { isStayType: false }],
    queryFn: getBookingOptionsList,
  });

  const {
    data: accessibilityData,
    isPending: isLoadingAccessibilityData,
    isError: isErrorAccessibilityData,
  } = useQuery({
    queryKey: ["accessibility-features-list"],
    queryFn: getAccessibilityFeaturesList,
  });

  const {
    data: amenitiesData,
    isPending: isLoadingAmenitiesData,
    isError: isErrorAmenitiesData,
  } = useQuery({
    queryKey: ["amenities-list"],
    queryFn: getAmenitiesList,
  });

  const {
    data: experienceTypeData,
    isPending: isLoadingExperienceTypeData,
    isError: isErrorExperienceTypeData,
  } = useQuery({
    queryKey: ["experienceType-list", { isStayType: false }],
    queryFn: getExperienceTypeList,
  });

  const {
    data: languageData,
    isPending: isLoadingLanguageData,
    isError: isErrorLanguageData,
  } = useQuery({
    queryKey: ["language-list"],
    queryFn: getLanguageList,
  });

  const {
    data: countryData,
    isPending: isLoadingCountryData,
    isError: isErrorCountryData,
    fetchStatus: fetchStatusCountryData,
  } = useQuery({
    queryKey: ["country-list"],
    queryFn: getCountriesList,
  });

  const {
    data: citiesData,
    isPending: isLoadingCitiesData,
    isError: isErrorCitiesData,
    fetchStatus: fetchStatusCitiesData,
  } = useQuery({
    queryKey: ["cities-list", { countryId: values.country?.value }],
    queryFn: getCitiesForCountries,
    enabled: !!values.country?.value,
  });

  const {
    mutate,
    isPending: isLoadingMutate,
    isError: isErrorMutate,
  } = useMutation({
    mutationFn:
      ExperienceId && siteInfo
        ? patchAddSiteStep1QueryFn
        : postAddSiteStep1QueryFn,
    async onSuccess(data) {
      setSearchParams({ id: data?._id, step: 2 });
    },
    onError(error) {
      console.error("error Site Step 1: ", error);
      toast.error("Something went wrong, please try later!");
    },
  });

  useEffect(() => {
    setValues(setFieldsValues());
  }, [siteInfo]);

  useEffect(() => {
    setIsLoading(isLoadingMutate);
  }, [isLoadingMutate]);

  useEffect(() => {
    let res = (countryData || [])
      .filter((item) => item?.name) // Filter out countries
      .map((item) => ({
        label: item?.name || "",
        value: item?._id,
      }));
    setCountriesList(res);
  }, [countryData]);

  useEffect(() => {
    let res = (citiesData || [])
      .filter((item) => item?.name) // Filter out countries
      .map((item) => ({
        label: item?.name || "",
        value: item?.name,
      }));
    setCitiesList(res);
  }, [values?.country, citiesData]);

  useEffect(() => {
    let res = (accessibilityData || [])
      .filter((item) => item?.nameEn && item?._id)
      .map((item) => ({
        label: item?.nameEn,
        value: item?._id,
      }));
    setAccessibilityList(res);
  }, [accessibilityData]);

  useEffect(() => {
    let res = (amenitiesData || [])
      .filter((item) => item?.nameEn && item?._id)
      .map((item) => ({
        label: item?.nameEn || "",
        value: item?._id,
      }));
    setAvailableAmenitiesList(res);
  }, [amenitiesData]);

  useEffect(() => {
    let res = (experienceTypeData || [])
      .filter((item) => item?.nameEn && item?._id)
      .map((item) => ({
        label: item?.nameEn || "",
        value: item?._id,
      }));
    setExperienceTypes(res);
  }, [experienceTypeData]);

  useEffect(() => {
    let res = (languageData || [])
      .filter((item) => item?.nameEn && item?._id)
      .map((item) => ({
        label: item?.nameEn || "",
        value: item?._id || "",
      }));
    setSpokenLanguagesList(res);
  }, [languageData]);

  useEffect(() => {
    let res = (bookingOptionsData || [])
      .filter((item) => item?.nameEn) // Filter out countries
      .map((item) => ({
        label: item?.nameEn || "",
        value: item?._id,
      }));
    setBookOptionsList(res);
  }, [bookingOptionsData]);

  const handleExtrasChange = (values) => {
    setFieldValue("extras", values);
  };

  const handleLocationChange = (values) => {
    setFieldValue("location", values);
  };

  function onSubmit() {
    mutate({
      ...(ExperienceId && siteInfo && { id: ExperienceId }),
      wheelChair: values?.wheelChair,
      duration: values?.duration,
      thingsToKnow: values?.thingsToKnow,
      type: values?.placeType,
      name: values?.experienceTitle,
      longDescription: values?.overview,
      whatToExpect: {
        description: values?.whatToExpect,
      },
      experienceTypes: values?.experienceTypes?.map((item) => item?.value),
      bookagriBadge: values?.bookagriExperience,
      city: values?.city?.value,
      country: values?.country?.value,
      googleLocationLink: values?.location?.area,
      location: {
        type: values?.location?.type,
        coordinates: values?.location?.coordinates,
      },
      extras: values?.extras?.map((item) => ({
        nameEn: item.extraNameEn,
        nameAr: item.extraNameAr,
        price: Number(item.extraPrice),
      })),
      ageSuitability: values?.ageSuitability,
      levelOfDifficulty: values?.levelOfDifficulty,
      guideIsIncluded: values?.guideIncluded,
      guideIsMandatory: values?.guideOptionalOrMandatory,
      ...(values.guideIncluded && {
        guide: {
          description: values?.guideDescription,
          languages: values?.spokenLanguages?.map((item) => item?.value),
          price: values?.guidePrice,
          learnMore: values?.guideLearnMore,
        },
      }),
      specialInstructions: values?.specialInstructions,
      accessibilityFeatures: values?.accessibilityFeatures?.map(
        (item) => item?.value,
      ),
      amenities: values?.availableAmenities?.map((item) => item?.value),
      bookOptions: values?.bookOptions?.map((item) => item?.value),
      cancellationPolicy: values?.cancellationPolicy,

      transportationIsIncluded: values?.transportationIsIncluded,
      transportationIsMandatory: values?.transportationIsMandatory,
      transportation: {
        description: values?.transportationDescription,
        price: values?.transportationPrice,
        learnMore: values?.transportationLearnMore,
      },
    });
  };

  const handleErrors = async () => {
    const err = Object.values(errors);
    if (err.length > 0) {
      toast.error(err[0]);
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <h4 className=''>General Info</h4>
      <Hr />
      {/* Place Type & Experience Title(Name) */}
      <section className='row'>
        {/* Place Type */}
        <div className='col-lg-6'>
          <div className='form-group mb-3'>
            <Label forId='placeType' text='Place Type' isRequired={true} />
            <GeneralSelect
              defaultValue={values.placeType}
              placeholder='Search'
              isClearable={false}
              isSearchable={true}
              isDisabled={false}
              isLoading={false}
              isRtl={false}
              options={PLACE_TYPE_OPTIONS}
              handleChange={(val) => {
                setFieldValue("placeType", val.value);
              }}
              handleBlur={() => {
                handleBlur("placeType");
              }}
              id={"placeType"}
              name={"placeType"}
            />
            <ErrorFormik
              isError={errors?.placeType}
              isTouched={touched?.placeType}
              error={errors?.placeType}
            />
          </div>
        </div>
        {/* Experience Title(Name) */}
        <div className='col-lg-6'>
          <div className='form-group mb-3'>
            <Label
              forId='experienceTitle'
              text={`${values.placeType} Title(Name)`}
              isRequired={true}
            />
            <CustomInput
              withFocus={true}
              placeholder='Experience Title'
              id='experienceTitle'
              name='experienceTitle'
              type='text'
              value={values?.experienceTitle}
              onChange={handleChange}
              onBlur={handleBlur}
            />
            <ErrorFormik
              isError={errors?.experienceTitle}
              isTouched={touched?.experienceTitle}
              error={errors?.experienceTitle}
            />
          </div>
        </div>
      </section>
      {/* Experience Types & Accessibility Features */}
      <section className='row'>
        {/* Experience Types */}
        <div className='col-lg-6'>
          <div className='form-group mb-3'>
            <Label
              forId='experienceTypes'
              text={`${values.placeType} Types`}
              isRequired={true}
            />
            <MultiSelect
              defaultValue={values?.experienceTypes?.map((item) => item.label)}
              placeholder='Search'
              isClearable={false}
              isSearchable={true}
              isDisabled={false}
              isLoading={isLoadingExperienceTypeData}
              isRtl={false}
              options={experienceTypes}
              handleChange={(val) => {
                setFieldValue("experienceTypes", val);
              }}
              handleBlur={() => {
                handleBlur("experienceTypes");
              }}
              id={"experienceTypes"}
              name={"experienceTypes"}
            />
            <ErrorFormik
              isError={errors?.experienceTypes}
              isTouched={touched?.experienceTypes}
              error={errors?.experienceTypes}
            />
          </div>
        </div>
        {/* Accessibility Features */}
        <div className='col-lg-6'>
          <div className='form-group mb-3'>
            <Label
              forId='accessibilityFeatures'
              text='Accessibility Features'
              isRequired={false}
            />
            <MultiSelect
              defaultValue={values?.accessibilityFeatures?.map(
                (item) => item.label,
              )}
              placeholder='Search'
              isClearable={false}
              isSearchable={true}
              isDisabled={false}
              isLoading={isLoadingAccessibilityData}
              isRtl={false}
              options={accessibilityList}
              handleChange={(val) => {
                setFieldValue("accessibilityFeatures", val);
              }}
              handleBlur={() => {
                handleBlur("accessibilityFeatures");
              }}
              id={"accessibilityFeatures"}
              name={"accessibilityFeatures"}
            />
            <ErrorFormik
              isError={errors?.accessibilityFeatures}
              isTouched={touched?.accessibilityFeatures}
              error={errors?.accessibilityFeatures}
            />
          </div>
        </div>
      </section>
      {/* Level of Difficulty & Available Amenities */}
      <section className='row'>
        {/* Level of Difficulty */}
        <div className='col-lg-6'>
          <div className='form-group mb-3'>
            <Label
              forId='levelOfDifficulty'
              text='Level of Difficulty'
              isRequired={true}
            />
            <GeneralSelect
              defaultValue={values.levelOfDifficulty}
              placeholder='Search'
              isClearable={false}
              isSearchable={true}
              isDisabled={false}
              isLoading={false}
              isRtl={false}
              options={DIFFICULTY_OPTIONS}
              handleChange={(val) => {
                setFieldValue("levelOfDifficulty", val.value);
              }}
              handleBlur={() => {
                handleBlur("levelOfDifficulty");
              }}
              id={"levelOfDifficulty"}
              name={"levelOfDifficulty"}
            />
            <ErrorFormik
              isError={errors?.levelOfDifficulty}
              isTouched={touched?.levelOfDifficulty}
              error={errors?.levelOfDifficulty}
            />
          </div>
        </div>
        {/* Available Amenities */}
        <div className='col-lg-6'>
          <div className='form-group mb-3'>
            <Label
              forId='availableAmenities'
              text='Available Amenities'
              isRequired={false}
            />
            <MultiSelect
              defaultValue={values?.availableAmenities?.map(
                (item) => item.label,
              )}
              placeholder='Search'
              isClearable={false}
              isSearchable={true}
              isDisabled={false}
              isLoading={isLoadingAmenitiesData}
              isRtl={false}
              options={availableAmenitiesList}
              handleChange={(val) => {
                setFieldValue("availableAmenities", val);
              }}
              handleBlur={() => {
                handleBlur("availableAmenities");
              }}
              id={"availableAmenities"}
              name={"availableAmenities"}
            />
            <ErrorFormik
              isError={errors?.availableAmenities}
              isTouched={touched?.availableAmenities}
              error={errors?.availableAmenities}
            />
          </div>
        </div>
      </section>
      {/* Age Suitability Experience & Deration */}
      <section className='row'>
        {/* Age Suitability */}
        <div className='form-group col-lg-6 ' style={{ display: "inline-block" }}>
          <Label
            forId='ageSuitability'
            text='Age Suitability'
            isRequired={true}
          />
          <div style={{position: "relative"}}>
            <CustomInput
              withFocus={true}
              placeholder='Experience Title'
              id='ageSuitability'
              name='ageSuitability'
              type='number'
              value={values?.ageSuitability}
              onChange={handleChange}
              onBlur={handleBlur}
            />
            <span
              style={{
                position: "absolute",
                left: "5px",
                top: "50%",
                transform: "translateY(-50%)",
                fontSize: "16px",
                fontWeight: "bold"
              }}
            >
              +
            </span>
          </div>
          
          <ErrorFormik
            isError={errors?.ageSuitability}
            isTouched={touched?.ageSuitability}
            error={errors?.ageSuitability}
          />
        </div>
        {/* Duration */}
        <div className='col-lg-6'>
          <div className='form-group mb-3'>
            <Label forId='duration' text='Duration' isRequired={true} />
            <CustomInput
              withFocus={true}
              placeholder='Duration'
              id='duration'
              name='duration'
              type='number'
              value={values?.duration}
              onChange={handleChange}
              onBlur={handleBlur}
            />
            <ErrorFormik
              isError={errors?.duration}
              isTouched={touched?.duration}
              error={errors?.duration}
            />
          </div>
        </div>
      </section>
      {/* Booking Options & --- */}
      <section className='row'>
        {/* Booking Options */}
        <div className='col-lg-6'>
          <div className='form-group mb-3'>
            <Label forId='bookOptions' text='Book Options' isRequired={false} />
            <MultiSelect
              defaultValue={values?.bookOptions?.map((item) => item.label)}
              placeholder='Search'
              isClearable={false}
              isSearchable={true}
              isDisabled={false}
              isLoading={isLoadingBookingOptions}
              isRtl={false}
              options={bookOptionsList}
              handleChange={(val) => {
                setFieldValue("bookOptions", val);
              }}
              handleBlur={() => {
                handleBlur("bookOptions");
              }}
              id={"bookOptions"}
              name={"bookOptions"}
            />
            <ErrorFormik
              isError={errors?.bookOptions}
              isTouched={touched?.bookOptions}
              error={errors?.bookOptions}
            />
          </div>
        </div>
      </section>
      {/* Bookagri Experience & wheelChair */}
      <section className='row'>
        <div className='col-lg-6 mb-2'>
          <Label
            forId='levelOfDifficulty'
            text='Is Listing Certified By Bookagri'
            isRequired={false}
          />
          <Switch
            id='bookagriExperience'
            label={`Bookagri ${values.placeType}`}
            checked={values.bookagriExperience}
            onChange={(e) =>
              setFieldValue("bookagriExperience", e.target.checked)
            }
          />
        </div>
        <div className='col-lg-6 mb-2'>
          <Label
            forId='wheelChair'
            text='Wheelchair Accessible?'
            isRequired={false}
          />
          <Switch
            id='wheelChair'
            label='Wheelchair'
            checked={values.wheelChair}
            onChange={(e) => setFieldValue("wheelChair", e.target.checked)}
          />
        </div>
      </section>
      {/* Overview */}
      <section className='col-lg-12 mb-3'>
        <Label forId='overview' text='Overview' isRequired={true} />
        <CustomTextarea
          id={"overview"}
          name={"overview"}
          value={values?.overview}
          onChange={handleChange}
          onBlur={handleBlur}
        />
        <ErrorFormik
          isError={errors?.overview}
          isTouched={touched?.overview}
          error={errors?.overview}
        />
      </section>
      {/* What to Expect */}
      <section className='col-lg-12 my-3'>
        <Label forId='whatToExpect' text='What to Expect?' isRequired={false} />
        <CustomTextarea
          id={"whatToExpect"}
          name='whatToExpect'
          value={values?.whatToExpect}
          onChange={handleChange}
          onBlur={handleBlur}
        />
        <ErrorFormik
          isError={errors?.whatToExpect}
          isTouched={touched?.whatToExpect}
          error={errors?.whatToExpect}
        />
      </section>
      {/* Things to Know */}
      <section className='col-lg-12 my-3'>
        <Label forId='thingsToKnow ' text='Things to Know' isRequired={true} />
        <CustomTextarea
          id={"thingsToKnow"}
          name={"thingsToKnow"}
          value={values?.thingsToKnow}
          onChange={handleChange}
          onBlur={handleBlur}
        />
        <ErrorFormik
          isError={errors?.thingsToKnow}
          isTouched={touched?.thingsToKnow}
          error={errors?.thingsToKnow}
        />
      </section>
      {/* Guide */}
      <section>
        <h4 className=''>Guide Info</h4>
        <Hr />
        <div className='row'>
          {/* Guide Included */}
          <div className='col-lg-6'>
            <div className='mb-2'>
              <Label
                forId='guideIncluded'
                text='Is Guide Included ?'
                isRequired={false}
              />
              <Switch
                id='guideIncluded'
                checked={values.guideIncluded}
                onChange={(e) =>
                  setFieldValue("guideIncluded", e.target.checked)
                }
              />
            </div>
          </div>
          {/* Guide Optional or Mandatory */}
          {values.guideIncluded && (
            <div className='col-lg-6'>
              <div className='mb-2'>
                <Label
                  forId='guideOptionalOrMandatory'
                  text='Is Guide Mandatory ?'
                  isRequired={false}
                />
                <Switch
                  id='guideOptionalOrMandatory'
                  checked={values.guideOptionalOrMandatory}
                  onChange={(e) =>
                    setFieldValue("guideOptionalOrMandatory", e.target.checked)
                  }
                />
              </div>
            </div>
          )}
        </div>
        {values.guideIncluded && (
          <>
            <div className='row'>
              {/* Guide description */}
              <div className='col-lg-6'>
                <div className='form-group mb-3'>
                  <Label
                    forId='guideDescription'
                    text='Guide Description'
                    isRequired={true}
                  />
                  <CustomInput
                    withFocus={true}
                    placeholder='Guide Description'
                    id='guideDescription'
                    name='guideDescription'
                    type='text'
                    value={values?.guideDescription}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                  <ErrorFormik
                    isError={errors?.guideDescription}
                    isTouched={touched?.guideDescription}
                    error={errors?.guideDescription}
                  />
                </div>
              </div>
              {/* Guide Price */}
              <div className='col-lg-6'>
                <div className='form-group mb-3'>
                  <Label
                    forId='guidePrice'
                    text='Guide Price (JOD)'
                    isRequired={true}
                  />
                  <CustomInput
                    withFocus={true}
                    placeholder='Guide Price'
                    id='guidePrice'
                    name='guidePrice'
                    type='number'
                    value={values?.guidePrice}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                  <ErrorFormik
                    isError={errors?.guidePrice}
                    isTouched={touched?.guidePrice}
                    error={errors?.guidePrice}
                  />
                </div>
              </div>
            </div>
            <div className='row'>
              {/* Spoken Languages */}
              <div className='col-lg-6'>
                <div className='form-group mb-3'>
                  <Label
                    forId='spokenLanguages'
                    text='Spoken Languages'
                    isRequired={true}
                  />
                  <MultiSelect
                    defaultValue={values?.spokenLanguages?.map(
                      (item) => item.label,
                    )}
                    placeholder='Search'
                    isClearable={false}
                    isSearchable={true}
                    isDisabled={false}
                    isLoading={isLoadingLanguageData}
                    isRtl={false}
                    options={spokenLanguagesList}
                    handleChange={(val) => {
                      setFieldValue("spokenLanguages", val);
                    }}
                    handleBlur={() => {
                      handleBlur("spokenLanguages");
                    }}
                    id={"spokenLanguages"}
                    name={"spokenLanguages"}
                  />
                  <ErrorFormik
                    isError={errors?.spokenLanguages}
                    isTouched={touched?.spokenLanguages}
                    error={errors?.spokenLanguages}
                  />
                </div>
              </div>
            </div>
            {/* Guide Learn More */}
            <div className='col-lg-12 mb-3'>
              <Label
                forId='guideLearnMore'
                text='Guide Learn More'
                isRequired={false}
              />
              <CustomEditor
                id={"guideLearnMore"}
                value={values?.guideLearnMore}
                onChange={(value) => {
                  setFieldValue("guideLearnMore", value);
                }}
                onBlur={() => {
                  setFieldTouched("guideLearnMore", true);
                  handleBlur("guideLearnMore");
                }}
              />
              <ErrorFormik
                isError={errors?.guideLearnMore}
                isTouched={touched?.guideLearnMore}
                error={errors?.guideLearnMore}
              />
            </div>
          </>
        )}
      </section>
      {/* Transportation */}
      <section>
        <h4 className=''>Transportation Info</h4>
        <Hr />
        <div className='row'>
          {/* Transportation Included */}
          <div className='col-lg-6'>
            <div className='mb-2'>
              <Label
                forId='transportationIsIncluded'
                text='Is Transportation Included ?'
                isRequired={false}
              />
              <Switch
                id='transportationIsIncluded'
                checked={values.transportationIsIncluded}
                onChange={(e) =>
                  setFieldValue("transportationIsIncluded", e.target.checked)
                }
              />
            </div>
          </div>
          {/* Transportation Optional or Mandatory */}
          {values.transportationIsIncluded && (
            <div className='col-lg-6'>
              <div className='mb-2'>
                <Label
                  forId='transportationIsMandatory'
                  text='Is Transportation Mandatory ?'
                  isRequired={false}
                />
                <Switch
                  id='transportationIsMandatory'
                  checked={values.transportationIsMandatory}
                  onChange={(e) =>
                    setFieldValue("transportationIsMandatory", e.target.checked)
                  }
                />
              </div>
            </div>
          )}
        </div>
        {values.transportationIsIncluded && (
          <div className='row'>
            {/* Transportation description */}
            <div className='col-lg-6'>
              <div className='form-group mb-3'>
                <Label
                  forId='transportationDescription'
                  text='Transportation Description'
                  isRequired={true}
                />
                <CustomInput
                  withFocus={true}
                  placeholder='Transportation Description'
                  id='transportationDescription'
                  name='transportationDescription'
                  type='text'
                  value={values?.transportationDescription}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
                <ErrorFormik
                  isError={errors?.transportationDescription}
                  isTouched={touched?.transportationDescription}
                  error={errors?.transportationDescription}
                />
              </div>
            </div>
            {/* Transportation Price */}
            <div className='col-lg-6'>
              <div className='form-group mb-3'>
                <Label
                  forId='transportationPrice'
                  text='Transportation Price (JOD)'
                  isRequired={true}
                />
                <CustomInput
                  withFocus={true}
                  placeholder='Transportation Title'
                  id='transportationPrice'
                  name='transportationPrice'
                  type='number'
                  value={values?.transportationPrice}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
                <ErrorFormik
                  isError={errors?.transportationPrice}
                  isTouched={touched?.transportationPrice}
                  error={errors?.transportationPrice}
                />
              </div>
            </div>
            {/* Transportation Learn More  */}
            <div className='col-lg-12 mb-3'>
              <Label
                forId='transportationLearnMore'
                text='Transportation learn more'
                isRequired={false}
              />
              <CustomEditor
                id={"transportationLearnMore"}
                value={values?.transportationLearnMore}
                onChange={(value) => {
                  setFieldValue("transportationLearnMore", value);
                }}
                onBlur={() => {
                  setFieldTouched("transportationLearnMore", true);
                  handleBlur("transportationLearnMore");
                }}
              />
              <ErrorFormik
                isError={errors?.transportationLearnMore}
                isTouched={touched?.transportationLearnMore}
                error={errors?.transportationLearnMore}
              />
            </div>
          </div>
        )}
      </section>
      {/* location */}
      <section className='mb-3'>
        <h4 className=''>location</h4>
        <Hr />
        <section className='row'>
          {/* Country */}
          <div className='col-lg-6'>
            <div className='form-group mb-3'>
              <Label forId='country' text='Country' isRequired={true} />
              <GeneralSelect
                isLoading={isLoadingCountryData}
                defaultValue={values?.country?.label}
                placeholder='Search'
                isClearable={false}
                isSearchable={true}
                isDisabled={false}
                isRtl={false}
                options={countriesList}
                handleChange={(val) => setFieldValue("country", val)}
                handleBlur={() => {
                  handleBlur("country");
                }}
                id={"country"}
                name={"country"}
              />
              <ErrorFormik
                isError={errors?.country?.value}
                isTouched={touched?.country?.value}
                error={errors?.country?.value}
              />
            </div>
          </div>
          {/* Area */}
          <div className='col-lg-6'>
            <div className='form-group mb-3'>
              <Label forId='area' text='Area' isRequired={true} />
              <GeneralSelect
                defaultValue={values?.city?.label}
                placeholder='Search'
                isClearable={false}
                isSearchable={true}
                isDisabled={false}
                isLoading={isLoadingCitiesData}
                isRtl={false}
                options={citiesList}
                handleChange={(val) => setFieldValue("city", val)}
                handleBlur={() => {
                  handleBlur("city");
                }}
                id={"city"}
                name={"city"}
              />

              <ErrorFormik
                isError={errors?.city?.value}
                isTouched={touched?.city?.value}
                error={errors?.city?.value}
              />
            </div>
          </div>
        </section>
        <GoogleMaps
          onClick={handleLocationChange}
          defaultValue={{
            lat: values?.location?.coordinates[0],
            lng: values?.location?.coordinates[1],
          }}
        />
        <ErrorFormik
          isError={errors?.location?.coordinates}
          isTouched={touched?.location?.coordinates}
          error={errors?.location?.coordinates}
        />
      </section>
      {/* Extras */}
      <section>
        <h4 className=''>Extras</h4>
        <Hr />
        <div className='mb-3'>
          <DynamicInputFields
            inputFields={[
              {
                id: "extraNameEn",
                name: "extraNameEn",
                label: "Extra Name En",
                placeholder: "Extra Name En",
                type: "text",
                isRequired: false,
              },
              {
                id: "extraNameAr",
                name: "extraNameAr",
                label: "Extra Name Ar",
                placeholder: "Extra Name Ar",
                type: "text",
                isRequired: false,
              },
              {
                id: "extraPrice",
                name: "extraPrice",
                label: "Extra Price (JOD)",
                placeholder: "Extra Price",
                type: "number",
                isRequired: false,
              },
            ]}
            textButton='Add Extra Slot'
            fieldName={"extras"}
            onChange={handleExtrasChange}
            val={values?.extras}
            errors={errors}
            touched={touched}
          />
        </div>
      </section>
      {/* Instructions */}
      <section>
        <h4 className=''>Instructions</h4>
        <Hr />
        {/* Special Instructions */}
        <div className='col-lg-12 mb-3'>
          <Label
            forId='specialInstructions'
            text='Special Instructions'
            isRequired={true}
          />
          <CustomEditor
            id={"specialInstructions"}
            value={values?.specialInstructions}
            onChange={(value) => {
              setFieldValue("specialInstructions", value);
            }}
            onBlur={() => {
              setFieldTouched("specialInstructions", true);
              handleBlur("specialInstructions");
            }}
          />
          <ErrorFormik
            isError={errors?.specialInstructions}
            isTouched={touched?.specialInstructions}
            error={errors?.specialInstructions}
          />
        </div>
        {/* Cancellation Policy */}
        <div>
          <div className='col-lg-12 mb-3'>
            <Label
              forId='cancellationPolicy'
              text='Cancellation Policy'
              isRequired={true}
            />
            <CustomEditor
              id={"cancellationPolicy"}
              value={values?.cancellationPolicy}
              onChange={(value) => {
                setFieldValue("cancellationPolicy", value);
              }}
              onBlur={() => {
                setFieldTouched("cancellationPolicy", true);
                handleBlur("cancellationPolicy");
              }}
            />
            <ErrorFormik
              isError={errors?.cancellationPolicy}
              isTouched={touched?.cancellationPolicy}
              error={errors?.cancellationPolicy}
            />
          </div>
        </div>
      </section>
      <Hr />
      {/* Button */}
      <div className='text-end toolbar toolbar-bottom p-2'>
        <button
          // disabled={!isValid}
          onClick={handleErrors}
          type='submit'
          className='btn btn-primary sw-btn-next'
        >
          Next
        </button>
      </div>
    </form>
  );
};

export default StepOne;
