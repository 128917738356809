// AnnouncementsNotifications
import { usePublicContext } from "../../../../../providers/ReactPublicContextProvider";
import ErrorFormik from "../../../ui/ErrorFormik";
import CustomEditor from "../../../ui/custom-inputs/CustomEditor";
import Label from "../../../ui/custom-inputs/Label";
import { useFormik } from "formik";
import { toast } from "react-toastify";
import CustomInput from "../../../ui/custom-inputs/CustomInput";
import { useMutation, useQuery } from "@tanstack/react-query";
import { addAnnouncementsNotificationSchema } from "../../../../../utils/forms-schemas";
import { postAddAnnouncementsNotificationQueryFn } from "../../../../../queries/queryFunctions/privetQueryFns/postQueryFns";

const AnnouncementsNotificationsComponent = () => {
    const { setIsLoading } = usePublicContext();

    const {
        values,
        errors,
        touched,
        handleBlur,
        handleChange,
        handleSubmit,    
      } = useFormik({
        initialValues: {  
            titleEn: "",
            titleAr: "",
            bodyEn: "",
            bodyAr: ""
        },
        validationSchema: addAnnouncementsNotificationSchema,
        onSubmit: onSubmit,
      });

      function onSubmit() {
        addMutation({
            titleEn: values.titleEn,
            titleAr: values.titleAr,
            bodyEn: values.bodyEn,
            bodyAr: values.bodyAr,
          });
      }
    
    const {
        mutate : addMutation,
        isPending: isLoadingMutate,
        isError: isErrorMutate,
      } = useMutation({
        mutationFn: postAddAnnouncementsNotificationQueryFn,
        onSuccess(data) {
          toast.success("Notification sent successfully");
        },
        onError(error) {
          console.log(error)
          toast.error("Something went wrong, please try later!");
        },
      });

  
      

    return (
        <>
            <form onSubmit={handleSubmit}> 
              <section className='col-lg-12 my-3'>
                <div className="row">
                  <div className="col">
                    <Label forId='titleEn' text='Title (English)' isRequired={true} />
                    <CustomInput
                      withFocus={true}
                      placeholder='title in (English)'
                      id='titleEn'
                      name='titleEn'
                      type='text'
                      value={values?.titleEn}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                    <ErrorFormik
                      isError={errors?.titleEn}
                      isTouched={touched?.titleEn}
                      error={errors?.titleEn}
                    />
                  </div>
                  <div className="col">
                    <Label forId='titleAr' text='Title (Arabic)' isRequired={true} />
                    <CustomInput
                    withFocus={true}
                    placeholder='title in (Arabic)'
                    id='titleAr'
                    name='titleAr'
                    type='text'
                    value={values?.titleAr}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    />
                    <ErrorFormik
                      isError={errors?.titleAr}
                      isTouched={touched?.titleAr}
                      error={errors?.titleAr}
                    />
                  </div>
                </div>
                </section>
              <section className='col-lg-12 my-3'>
                <div className="row">
                    <div className="col">
                        <Label forId='bodyEn' text='Subject (English)' isRequired={true} />
                        <CustomInput
                        withFocus={true}
                        placeholder='Subject in (English)'
                        id='bodyEn'
                        name='bodyEn'
                        type='text'
                        value={values?.bodyEn}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        />
                        <ErrorFormik
                        isError={errors?.bodyEn}
                        isTouched={touched?.bodyEn}
                        error={errors?.bodyEn}
                        />
                    </div>
                    <div className="col">
                        <Label forId='bodyAr' text='Subject (Arabic)' isRequired={true} />
                        <CustomInput
                        withFocus={true}
                        placeholder='Subject in (Arabic)'
                        id='bodyAr'
                        name='bodyAr'
                        type='text'
                        value={values?.bodyAr}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        />
                        <ErrorFormik
                        isError={errors?.bodyAr}
                        isTouched={touched?.bodyAr}
                        error={errors?.bodyAr}
                        />
                    </div>
                 </div>
                </section>
              <button type='submit' className='btn btn-primary sw-btn-next ms-1' style={{ marginTop: '10px' }}>
                Submit
              </button>
            </form>
      </>
    )
}

export default AnnouncementsNotificationsComponent;
