import ReactPlayer from "react-player";

export default function VideoPreview({
  video,
  width = "100%",
  height = "100%",
}) {
  return (
    <div className={``}>
        <ReactPlayer
          style={{ objectFit: "cover", borderRadius: "8px" }}
          className={`rounded-lg`}
          url={video}
          width={width}
          height={height}
          playing={false}
          controls={true}
          // light={
          //   <Image
          //     src={ThumbnailVideo}
          //     className="w-full h-full rounded-xl"
          //     alt="ThumbnailVideo"
          //   />
          // } // if you want to show custom thumbnail video
          // // light={true} // if you want to show default thumbnail video
          // playIcon={<PlayVideoButton style={"absolute"} />}
        />
    </div>
  );
}
