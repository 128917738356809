import { useEffect } from "react";
import { usePublicContext } from "../../../providers/ReactPublicContextProvider";
import useUser from "../../../utils/hooks/useUser";
import { toast } from "react-toastify";
import { useMutation } from "@tanstack/react-query";
import { postVerificationCodeQueryFn } from "../../../queries/queryFunctions/publicQueryFns/postQueryFns";
import { useFormik } from "formik";
import { VerificationCodeSchema } from "../../../utils/forms-schemas";
import ErrorFormik from "../../components/ui/ErrorFormik";

function VerificationCode() {
  const { setIsLoading, isLoading } = usePublicContext();

  const { redirectTo, isAuthenticUser } = useUser();

  const { values, errors, touched, handleBlur, handleChange, handleSubmit } =
    useFormik({
      initialValues: {
        email: sessionStorage.getItem("email") || "",
        otp: "",
      },
      validationSchema: VerificationCodeSchema,
      onSubmit: onSubmit,
    });

  const {
    mutate,
    isPending: isLoadingMutate,
    isError: isErrorMutate,
  } = useMutation({
    mutationFn: postVerificationCodeQueryFn,
    onSuccess(data) {
      toast.success("Verification email success");
      setIsLoading(false);
      sessionStorage.removeItem("email");
      redirectTo("/");
    },
    onError(error) {
      toast.error("Wrong credentials!");
    },
  });

  useEffect(() => {
    if (isAuthenticUser) setIsLoading(true);

    if (isAuthenticUser) {
      redirectTo("/dashboard");
    }
    setIsLoading(false);
  }, [isLoading]);

  useEffect(() => {
    setIsLoading(isLoadingMutate);
  }, [isLoadingMutate]);

  async function onSubmit() {
    mutate({
      ...values,
    });
  }

  return (
    <div className='authincation d-flex flex-column flex-lg-row flex-column-fluid'>
      <div className='container flex-row-fluid d-flex flex-column justify-content-center position-relative overflow-hidden p-7 mx-auto'>
        <div className='d-flex justify-content-center h-100 align-items-center'>
          <div className='authincation-content style-2'>
            <div className='row no-gutters'>
              <div className='col-xl-12 tab-content'>
                <div id='sign-in' className='auth-form   form-validation'>
                  <form onSubmit={handleSubmit} className='form-validate'>
                    <h3 className='text-center mb-4 text-black'>
                      Verify Your Account
                    </h3>
                    <div className='form-group mb-3'>
                      <label className='mb-1' htmlFor='val-email'>
                        <strong>Email</strong>
                      </label>
                      <div>
                        <input
                          className='form-control'
                          type='email'
                          name='email'
                          value={values?.email}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          placeholder='Type Your Email Address'
                        />
                      </div>
                      <ErrorFormik
                        isError={errors?.email}
                        isTouched={touched?.email}
                        error={errors?.email}
                      />
                    </div>
                    <div className='form-group mb-3'>
                      <label className='mb-1'>
                        <strong>Verificaion Code</strong>
                      </label>
                      <input
                        className='form-control'
                        type='text'
                        name='otp'
                        value={values?.otp}
                        placeholder='Type Your Code'
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                      <ErrorFormik
                        isError={errors?.otp}
                        isTouched={touched?.otp}
                        error={errors?.otp}
                      />
                    </div>
                    <div className='text-center form-group mb-3'>
                      <button
                        type='submit'
                        className='btn btn-primary btn-block'
                      >
                        Submit
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default VerificationCode;
