import { RotatingLines } from "react-loader-spinner";

export default function Loader({ isFullScreen = true, style, width = "60" }) {
  return (
    <div
      className={`${style} d-flex justify-content-center align-items-center ${
        isFullScreen && "vh-100"
      } bg-opacity-10`}
    >
      <RotatingLines
        strokeColor={`#E23428`}
        strokeWidth='3'
        animationDuration='1.5'
        width={width}
        visible={true}
      />
    </div>
  );
}
