import React, { useState, useEffect } from "react";
import { useQuery } from "@tanstack/react-query";
import { getBookingsList } from "../../../../queries/queryFunctions/privetQueryFns/getQueryFns";
import { usePublicContext } from "../../../../providers/ReactPublicContextProvider";
import BookingListComponent from "../../ui/bookingList";

const CompletedBookingListComponent = ({ filter, searchData, activeTab, searchParams, activePage, handleSearch }) => {  
  const { setIsLoading } = usePublicContext();

  const [booking, setBooking] = useState();
  const [skip, setSkip] = useState(0);
  const [limit, setLimit] = useState(10);
  const [passingSearchData, setPassingSearchData] = useState(null)

  const { data, isPending: isLoading } = useQuery({
    queryKey: ["PendingBookingsList", { skip, limit , status: filter.status, ...searchParams}],
    queryFn: getBookingsList,

  });

  useEffect(() => {
    if (data) {
        setBooking(data);
    }
  }, [data]);

  useEffect(() => {
    setIsLoading(isLoading);
  }, [isLoading]);

  useEffect(() => {
    if (activeTab === 'Completed' && searchData) {
        setBooking(searchData);
    }
  }, [searchData]);

  useEffect(() => {
    if (searchData) {
      setPassingSearchData(searchData);
    }
  }, [searchData]);

  return (
    <BookingListComponent
      data={booking}
      filter={filter}
      setSkip={setSkip}
      setLimit={setLimit}
      itemsPerPage={limit}
      handleSearch={handleSearch}
      searchData={passingSearchData}
    />
  );
};

export default CompletedBookingListComponent;
