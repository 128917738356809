import { useSearchParams } from "react-router-dom";
import { usePublicContext } from "../../../../providers/ReactPublicContextProvider";
import useUser from "../../../../utils/hooks/useUser";
import { useEffect, useState } from "react";
import { getHelpCenterDetails } from "../../../../queries/queryFunctions/privetQueryFns/getQueryFns";
import { useQuery } from "@tanstack/react-query";
import ImagesGallery from "../../ui/ImagesGallery";
import { customTimeStampToDateWithUTC } from "../../../../utils/functions";

const DetailsPage = () => {
    const { setIsLoading } = usePublicContext();
    const { redirectTo } = useUser();
    const [searchParams, setSearchParams] = useSearchParams();
    const helpCenterId = searchParams.get("id");

    const [details, setDetails] = useState();

    const { data, isLoading, isError, error, refetch } = useQuery({
      queryKey: ["helpCenterDetails", { id: helpCenterId }],
      queryFn: getHelpCenterDetails,
    });

    useEffect(() => {
      if (data) {
        setDetails(data);
      }
    }, [data]);
  
  
    useEffect(() => {
      setIsLoading(isLoading);
    }, [isLoading, setIsLoading]);

  
    return (
        <div className="container">
            <div className="row justify-content-center">
                <div className='col-lg-6'>
                    <div className='card'>
                        <div className='card-body'>
                            <h2 className='font-w600'>Customer Information</h2>
                            <div>
                                <div className='col-auto'>
                                    {details?.userId?.profileImageUrl && (
                                        <ImagesGallery
                                            data={[
                                                {
                                                    large: details?.userId?.profileImageUrl,
                                                    thumb: details?.userId?.profileImageUrl,
                                                    alt: "Customer Image",
                                                },
                                            ]}
                                            className={"rounded img-fluid"}
                                            style={{
                                                width: "200px",
                                                height: "200px",
                                                objectFit: "cover",
                                            }}
                                        />
                                    )}
                                </div>
                                <div className='col'>
                                    <div className='mt-4'>
                                        <span className='font-w600'>Customer Name: </span>
                                        <span className='fs-16'>
                                            {details?.userId?.firstName +
                                                " " +
                                                details?.userId?.lastName}
                                        </span>
                                    </div>
                                    <div className='mt-4'>
                                        <span className='font-w600'>Email Address: </span>
                                        <span className='fs-16'>
                                            {details?.userId?.email}
                                        </span>
                                    </div>
                                    {details?.userId?.phoneNumber && (
                                    <div className='mt-4'>
                                        <span className='font-w600'>Mobile Number: </span>
                                        <span className='fs-16'>
                                          {(details?.userId?.countryCode || "") +
                                              (details?.userId?.phoneNumber || "")}
                                       </span>
                                    </div>
                                    )}
                                    {details?.createdAt && (
                                        <div className='mt-4'>
                                            <span className='font-w600'>Date: </span>
                                            <span className='fs-16'>
                                            {customTimeStampToDateWithUTC(details?.createdAt, "DD/MM/YYYY HH:mm")}

                                            </span>
                                        </div>
                                    )}
                                    {details?.subject && (
                                        <div className='mt-4'>
                                            <span className='font-w600'>Subject: </span>
                                            <span className='fs-16'>
                                                {details?.subject}
                                            </span>
                                        </div>
                                    )}
                                    {details?.message && (
                                        <div className='mt-4'>
                                            <span className='font-w600'>Message: </span>
                                            <span className='fs-16'>
                                                {details?.message}
                                            </span>
                                        </div>
                                    )}
                                </div>
                                <div className='col-auto' style={{ marginTop: "20px" }}>
                                    {details?.attachment && (
                                        <ImagesGallery
                                            data={[
                                                {
                                                    large: details?.attachment,
                                                    thumb: details?.attachment,
                                                    alt: "Attachment",
                                                },
                                            ]}
                                            className={"rounded img-fluid"}
                                            style={{ width: "200px", height: "200px", objectFit: "" }}
                                        />
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className=''>
          <button
            type='submit'
            className='btn btn-primary sw-btn-next'
            onClick={() => redirectTo(-1)}
          >
            Back
          </button>
        </div>
        </div>
    );
}

export default DetailsPage;
