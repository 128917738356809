import { useFormik } from "formik";
import { addSiteStep6Schema } from "../../../../utils/forms-schemas";
import { useMutation } from "@tanstack/react-query";
import { toast } from "react-toastify";
import Hr from "../../ui/Hr";
import ItineraryStops from "../../ui/ItineraryStops";
import { patchAddSiteStep6QueryFn } from "../../../../queries/queryFunctions/privetQueryFns/patchQueryFns";
import { useEffect } from "react";
import { usePublicContext } from "../../../../providers/ReactPublicContextProvider";
import { useSearchParams } from "react-router-dom";

const StepSix = ({ handleNext, handlePrev, ExperienceId, siteInfo }) => {
  const { setIsLoading } = usePublicContext();
  const [searchParams, setSearchParams] = useSearchParams();

  const {
    values,
    errors,
    touched,
    handleBlur,
    handleChange,
    handleSubmit,
    setFieldValue,
    setFieldTouched,
    setValues,
    isValid,
  } = useFormik({
    initialValues: {
      itineraryStops: [],
    },
    enableReinitialize: true,
    validationSchema: addSiteStep6Schema,
    onSubmit: onSubmit,
  });

  const {
    mutate,
    isPending: isLoadingMutate,
    isError: isErrorMutate,
  } = useMutation({
    mutationFn: patchAddSiteStep6QueryFn,
    onSuccess(data) {
      setSearchParams({ id: data?._id, step: 6 });
      handleNext();
    },
    onError(error) {
      console.error("error Site Step 6: ", error);
      toast.error("Something went wrong, please try later!");
    },
  });

  useEffect(() => {
    setValues({
      itineraryStops:
        siteInfo?.site?.itineraryStops.length > 0
          ? siteInfo?.site?.itineraryStops
          : [
              {
                locationURL: "",
                coordinates: [],
                title: "",
                details: "",
                duration: 0,
              },
            ],
    });
  }, [siteInfo]);

  useEffect(() => {
    setIsLoading(isLoadingMutate);
  }, [isLoadingMutate]);

  const handleitineraryStops = (values) => {
    setFieldValue("itineraryStops", values);
  };

  function onSubmit() {
    const itineraryStopsData = values.itineraryStops.map((stop) => ({
      title: stop.title,
      locationURL: stop.locationURL,
      coordinates: stop.coordinates,
      details: stop.details,
      duration: Number(stop.duration),
    }));

    mutate({
      id: ExperienceId,
      itineraryStops: itineraryStopsData,
    });
  }
  console.log();
  const handleErrors = async () => {
    if (errors.itineraryStops?.[0]) {
      const err = Object.values(errors.itineraryStops?.[0]);
      if (err.length > 0) {
        toast.error(err[0]);
      }
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <h4 className=''>Itinerary Stops</h4>
      <Hr />
      <div>
        <ItineraryStops
          Input1={{
            id: "title",
            name: "title",
            label: "Title",
            placeholder: "Title",
            type: "text",
            isRequired: true,
          }}
          Input2={{
            id: "details",
            name: "details",
            label: "Details",
            placeholder: "Details",
            type: "text",
            isRequired: true,
          }}
          Input3={{
            id: "duration",
            name: "duration",
            label: "Duration",
            placeholder: "Duration",
            type: "number",
            isRequired: true,
          }}
          textButton='Add Itinerary Stops'
          fieldName={"itineraryStops"}
          onChange={handleitineraryStops}
          val={values?.itineraryStops}
          errors={errors}
          touched={touched}
        />
      </div>
      <Hr />
      {/* Buttons */}
      <div className='text-end toolbar toolbar-bottom ps-2'>
        <button
          type='button'
          className='btn btn-secondary sw-btn-prev me-1'
          onClick={() => handlePrev()}
        >
          Prev
        </button>
        <button
          type='submit'
          onClick={handleErrors}
          className='btn btn-primary sw-btn-next ms-1'
        >
          Next
        </button>
      </div>
    </form>
  );
};

export default StepSix;
