import React, { useEffect, useState } from "react";
import { Alert, Button } from "react-bootstrap";
import { useMutation, useQuery } from "@tanstack/react-query";
import { getPaymentDetailsList } from "../../../../../queries/queryFunctions/publicQueryFns/getQueryFns";
import { usePublicContext } from "../../../../../providers/ReactPublicContextProvider";
import { useFormik } from "formik";
import { toast } from "react-toastify";
import SlideOver from "../../../../layouts/SlideOver";
import ErrorFormik from "../../../ui/ErrorFormik";
import CustomInput from "../../../ui/custom-inputs/CustomInput";
import Label from "../../../ui/custom-inputs/Label";
import DropdownBlog from "../../../ui/DropdownBlog";
import { patchPaymentTypeQueryFn, patchTogglePaymentTypeEnabledMutationQueryFn } from "../../../../../queries/queryFunctions/privetQueryFns/patchQueryFns";
import CustomEditor from "../../../ui/custom-inputs/CustomEditor";
import Pagination from "../../../ui/Pagination";

const PaymentTypesComponent = () => {
  const [activePage, setActivePage] = useState(0);
  const { setIsLoading } = usePublicContext();
  const [isSlideOverOpen, setIsSlideOverOpen] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);
  const [showAlert, setShowAlert] = useState(false);
  const [paymentTypeIdToDelete, setPaymentTYpeIdToDelete] = useState(null);
  const [pageSize, setPageSize] = useState(10);
  const [limit , setLimit] = useState(10);
  const [enabledData, setEnabledData] = useState()

  const {
    data: paymentTypesData,
    isPending: isLoadingPaymentTypesData,
    isError: isErrorPaymentTypesData,
    refetch,
  } = useQuery({
    queryKey: ["payment-types-list", limit],
    queryFn: getPaymentDetailsList,
  });

  useEffect( () => {
 if (paymentTypesData) {
  setEnabledData(paymentTypesData)
 }
  },[paymentTypesData])

  const {
    values,
    errors,
    touched,
    handleBlur,
    handleChange,
    handleSubmit,
    setFieldValue,
    setFieldTouched,
    isValid,
  } = useFormik({
    initialValues: {
      name: "",
      description: "",
    },
    onSubmit: onSubmit,
  });


  function onSubmit() {
    if (selectedItem) {
      updateMutation({
        id: selectedItem?._id, 
        name: values.name,
        description: values.description,
      });
    }
  }

  const {
    mutate: updateMutation, 
    isPending: isLoadingUpdateMutation,
    isError: isErrorUpdateMutation,
  } = useMutation({
    mutationFn: patchPaymentTypeQueryFn, 
    onSuccess(data) {
      toast.success("Type Updated Successfully");
      refetch();
      setIsSlideOverOpen(false)
    },
    onError(error) {
      if (error.response.data.statusCode === 413) {
        toast.error("The image in description is so large");
        return;
      }
      console.log(error.response.data.statusCode === 413);

      toast.error("Something went wrong, please try later!");
    },
  });


  useEffect(() => {
    setIsLoading(isLoadingPaymentTypesData || isLoadingUpdateMutation);
  }, [isLoadingPaymentTypesData, isLoadingUpdateMutation]);

  const openSlideOver = () => {
    setIsSlideOverOpen(true);
  };

  const closeSlideOver = () => {
    setIsSlideOverOpen(false);
  };

  const handleDelete = (id) => {
    setPaymentTYpeIdToDelete(id);
    setShowAlert(true);
  };

  const handleConfirmDelete = () => {
    // deletePaymentType(id);
    setShowAlert(false);
  };

  const handleEdit = (item) => {
    setSelectedItem(item);
    setFieldValue("name", item.name); 
    setFieldValue("description", item.description);
    setIsSlideOverOpen(true);
  };

  const handleClick = (pageIndex) => {
    setActivePage(pageIndex);
  };

  const startIndex = activePage * pageSize;
  const endIndex = Math.min(
    startIndex + pageSize,
    enabledData?.length,
  );

  const handleChangePageSize = (event) => {
    const newSize = parseInt(event.target.value, 10);
    setLimit(newSize);
    setPageSize(newSize);
  };


const {
  mutate: togglePaymentTypeEnabledMutation, 
  isPending: isLoadingEnabledDisabledMutation,
  isError: isErrorEnabledDisabledMutation,
} = useMutation({
  mutationFn: patchTogglePaymentTypeEnabledMutationQueryFn, 
  onSuccess(data) {
    toast.success(`Payment Type ${data?.isEnabled ? 'enabled' : 'disabled'} successfully`);
    refetch();
  },
  onError(error) {
    toast.error(error.response.data.message || "Something went wrong, please try later!");
  },
});

const handleTogglePaymentEnabled = (paymentTypeId) => {
  togglePaymentTypeEnabledMutation(paymentTypeId);
};

useEffect(() => {
  setIsLoading(isLoadingPaymentTypesData);
}, [isLoadingPaymentTypesData]);

useEffect(() => {
  setIsLoading(isLoadingEnabledDisabledMutation);
}, [isLoadingEnabledDisabledMutation]);

  return (
    <div className='table-responsive'>

      <table className='table card-table display mb-4 shadow-hover default-table .dataTablesCard dataTable no-footer'>
        <thead>
          <tr>
            <th className='sorting_asc'>Name</th>
            <th className='sorting'>Description</th>
            <th className='sorting bg-none'></th>
          </tr>
        </thead>
        <tbody>
          {enabledData &&
            enabledData
              .slice(startIndex, endIndex)
              .map((paymentType, index) => (
                
                <React.Fragment key={index}>
                  <tr className={index % 2 === 0 ? "even" : "odd"}>
                    <td>
                      <div>
                        <h5 className='text-nowrap'>{paymentType?.name}</h5>
                      </div>
                    </td>
                    <td>
                      <div>
                      <div>
                        <h5 className='text-nowrap' dangerouslySetInnerHTML={{ __html: paymentType?.description }} />
                      </div>
                      </div>
                    </td>
                    <td>
                      <div className="d-flex align-items-center">
                        <button
                          className={`btn btn-${paymentType?.isEnabled ? 'success' : 'danger'} btn-sm me-5`}
                          onClick={() => {
                              console.log("Payment type ID:", paymentType?.isEnabled);
                            handleTogglePaymentEnabled(paymentType?._id)}}
                          style={{ 
                            padding: '5px 10px',
                            fontSize: '0.8rem', 
                            borderRadius: '10px'
                          }}
                        >
                          {paymentType?.isEnabled ? 'Enabled' : 'Disabled'}
                        </button>
                          <DropdownBlog
                            showArchiveOption={false}
                            showDeleteOption={false}
                            showEditOption={true}
                            showLinkOption={false}
                            showPublishOption={false}
                            accessibilityFeatureId={paymentType?._id}
                            handleEdit={() => handleEdit(paymentType)}
                          />
                      </div>
                    </td>
                  </tr>      
                </React.Fragment>
              ))}
        </tbody>
      </table>

        <Pagination
          startIdx={startIndex}
          endIdx={endIndex}
          pageSize={pageSize}
          activePage={activePage}
          handleClick={handleClick}
          handleChangePageSize={handleChangePageSize}
          data={enabledData}
          itemsPerPage={pageSize}
          totalCount={enabledData?.length}
           />

      <SlideOver
        isOpen={isSlideOverOpen}
        onClose={closeSlideOver}
        onSubmit={handleSubmit}
        style={{ width: '100%', maxWidth: '100%', margin: 0, padding: 0 }}
      >
        <div style={{ height: '100%' }}>
          <h4>{selectedItem ? "Edit" : "Add"} Payment Type</h4>
          <section className='row'>
            <div className='col-lg-12'> 
              <div className='form-group mb-3'>
                <Label forId='placeType' text='Name' isRequired={true} />
                <CustomInput
                  withFocus={true}
                  placeholder='Name'
                  id='name'
                  name='name'
                  type='text'
                  value={selectedItem?.name}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
                <ErrorFormik
                  isError={errors?.name}
                  isTouched={touched?.name}
                  error={errors?.name}
                />
              </div>
            </div>
          </section>
          <section className='row'>
            <div className='col-lg-12'> 
              <div className='form-group mb-3'>
                <Label
                  forId='description'
                  text='Description'
                  isRequired={true}
                />
                <CustomEditor
                  id={"description"}
                  value={values?.description}
                  hasUploadImage
                  onChange={(value) => {
                    setFieldValue("description", value);
                  }}
                  onBlur={() => {
                    setFieldTouched("description", true);
                    handleBlur("description");
                  }}
                />
                <ErrorFormik
                  isError={errors?.description}
                  isTouched={touched?.description}
                  error={errors?.description}
                />
              </div>
            </div>
          </section>
        </div>
      </SlideOver>

      <Alert show={showAlert} variant='warning'>
        <Alert.Heading style={{ color: "red" }}>Warning!</Alert.Heading>
        <p style={{ color: "red" }}>
          Deleting this type will remove it from the associated records.
        </p>
        <hr />
        <div className='d-flex justify-content-end'>
          <div style={{ marginRight: "8px" }}>
            <Button
              onClick={() => setShowAlert(false)}
              variant='outline-warning'
            >
              Cancel
            </Button>
          </div>
          <Button onClick={handleConfirmDelete} variant='danger'>
            Confirm Delete
          </Button>
        </div>
      </Alert>
    </div>
  );
};
export default PaymentTypesComponent;
