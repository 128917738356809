import React, { useState, useEffect } from "react";
import { useMutation, useQuery } from "@tanstack/react-query";
import { usePublicContext } from "../../../../providers/ReactPublicContextProvider";
import { useSearchParams } from "react-router-dom";
import { getBookingsDetails } from "../../../../queries/queryFunctions/privetQueryFns/getQueryFns";
import BookingStatusBadge from "../../ui/svg/BookingStatusBadge";
import { toast } from "react-toastify";
import useUser from "./../../../../utils/hooks/useUser";
import {
  patchApprovBookingQueryFn,
  patchRefundBookingQueryFn,
} from "../../../../queries/queryFunctions/privetQueryFns/patchQueryFns";
import {
  convertTimeStampToDateWithTimezone,
  customTimeStampToDateWithUTC,
} from "../../../../utils/functions";
import ImagesGallery from "../../ui/ImagesGallery";
import { Alert, Button } from "react-bootstrap";

const BookingsDetails = () => {
  const { setIsLoading } = usePublicContext();
  const { redirectTo } = useUser();
  const [searchParams, setSearchParams] = useSearchParams();
  const bookingId = searchParams.get("id");
  const [showRefundAlert, setShowRefundAlert] = useState(false);
  const [showApproveAlert, setShowApproveAlert] = useState(false);

  const [booking, setBooking] = useState();

  const {
    data,
    isPending: isLoadingBookingsDetails,
    isError,
    error,
    refetch,
  } = useQuery({
    queryKey: ["BookingsDetails", { id: bookingId }],
    queryFn: getBookingsDetails,
  });

  const { mutate: RefundMutation, isPending: isPendingRefundBooking } =
    useMutation({
      mutationFn: patchRefundBookingQueryFn,
      onSuccess(data) {
        toast.success("Booking Refunded Successfully");
        redirectTo("/bookings-list");
      },
      onError(error) {
        toast.error("Something went wrong, please try later!");
      },
    });

  const { mutate: ApproveMutation, isPending: isPendingApprovBooking } =
    useMutation({
      mutationFn: patchApprovBookingQueryFn,
      onSuccess(data) {
        toast.success("Booking Approved Successfully");
        redirectTo("/bookings-list");
      },
      onError(error) {
        toast.error("Something went wrong, please try later!");
      },
    });

  const handleApprove = (id) => {
    ApproveMutation({ id });
  };

  const handleRefund = (id) => {
    RefundMutation({ id });
  };

  useEffect(() => {
    if (data) {
      setBooking(data);
    }
  }, [data]);

  const convertToYesNo = (value) => {
    return value ? "Yes" : "No";
  };

  const handleConfirmRefund = () => {
    handleRefund(booking?.booking?._id);
  };

  const handleConfirmApprove = () => {
    handleApprove(booking?.booking?._id);
  };

  useEffect(() => {
    setIsLoading(
      isLoadingBookingsDetails ||
        isPendingRefundBooking ||
        isPendingApprovBooking,
    );
  }, [
    isLoadingBookingsDetails,
    isPendingRefundBooking,
    isPendingApprovBooking,
  ]);

  console.log(booking?.booking.cancelReasonID?.reasonEn)
  return (
    <div className='container'>
      {booking && (
        <div className='row justify-content-center'>
          <div className='col-lg-6'>
            <div className='card'>
              <div className='card-body'>
                <h2 className='font-w600'>Customer Information</h2>
                <div>
                  <div className='col-auto'>
                    {booking?.booking?.userId?.profileImageUrl && (
                      <ImagesGallery
                        data={[
                          {
                            large: booking?.booking?.userId?.profileImageUrl,
                            thumb: booking?.booking?.userId?.profileImageUrl,
                            alt: "Customer Image",
                          },
                        ]}
                        className={"rounded img-fluid"}
                        style={{
                          width: "200px",
                          height: "200px",
                          objectFit: "cover",
                        }}
                      />
                    )}
                  </div>
                  <div className='col'>
                    <div className='mt-4'>
                      <span className='font-w600'>Customer Name: </span>
                      <span className='fs-16'>
                        {booking?.booking?.userId?.firstName +
                          " " +
                          booking?.booking?.userId?.lastName}
                      </span>
                    </div>
                    <div className='mt-4'>
                      <span className='font-w600'>Email Address: </span>
                      <span className='fs-16'>
                        {booking?.booking?.userId?.email}
                      </span>
                    </div>
                    {booking?.booking?.userId.phoneNumber && (
                    <div className='mt-4'>
                      <span className='font-w600'>Mobile Number: </span>
                      <span className='fs-16'>
                        {(booking?.booking?.userId?.countryCode ?? "") + booking?.booking?.userId?.phoneNumber}
                      </span>

                    </div>
                    )}
                    {booking?.booking?.userId?.birthDate && (
                      <div className='mt-4'>
                        <span className='font-w600'>Date Of Birth: </span>
                        <span className='fs-16'>
                          {convertTimeStampToDateWithTimezone(
                            booking?.booking?.userId?.birthDate,
                            "YYYY/MM/DD",
                          )}
                        </span>
                      </div>
                    )}
                    {booking?.booking?.userId?.gender && (
                      <div className='mt-4'>
                        <span className='font-w600'>Gender: </span>
                        <span className='fs-16'>
                          {booking?.booking?.userId?.gender}
                        </span>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className='col-lg-6'>
            <div className='card'>
              <div className='card-body'>
                <h2 className='font-w600'>Booking Information</h2>
                <div className='mt-4'>
                  <span className='font-w600'>
                    {data?.booking?.siteId?.type === "Stay"
                      ? "Stay"
                      : "Experience"}{" "}
                    Name:{" "}
                  </span>
                  <span className='fs-16'>{booking?.booking?.siteId?.name}</span>
                </div>
                <div className='mt-4'>
                  <span className='font-w600'>Amount: </span>
                  <span className='fs-16'>
                    {booking?.booking?.totalPrice} JOD
                  </span>
                </div>
                <div className='mt-4'>
                  <span className='font-w600'>Booking ID: </span>
                  <span className='fs-16'>{booking?.booking?.code}</span>
                </div>
                <div className='mt-4'>
                  <span className='font-w600'>Status: </span>
                  <BookingStatusBadge status={booking?.booking?.status} />
                </div>
                <div className='mt-4'>
                  <span className='font-w600'>Booking Date: </span>
                  <span className='fs-16'>
                    {convertTimeStampToDateWithTimezone(
                      booking.booking.createdAt,
                      "YYYY/MM/DD - HH:mm",
                    )}
                  </span>
                </div>
                <div className='mt-4'>
                  <span className='font-w600'>
                    {data?.booking?.siteId?.type === "Stay"
                      ? "Stay"
                      : "Experience"}{" "}
                    Start Date:{" "}
                  </span>
                  <span className='fs-16'>
                    {customTimeStampToDateWithUTC(
                      booking?.booking?.startDateTime,
                      "YYYY/MM/DD - HH:mm",
                    )}
                  </span>
                </div>
                {data?.booking?.siteId?.type === "Stay" && (
                  <div className='mt-4'>
                    <span className='font-w600'>
                      {data?.booking?.siteId?.type === "Stay"
                        ? "Stay"
                        : "Experience"}{" "}
                      End Date:{" "}
                    </span>
                    <span className='fs-16'>
                      {customTimeStampToDateWithUTC(
                        booking?.booking?.endDateTime,
                        "YYYY/MM/DD - HH:mm",
                      )}
                    </span>
                  </div>
                )}
                <div className='mt-4'>
                  <span className='font-w600'>Guide Included: </span>
                  <span className='fs-16'>
                    {convertToYesNo(booking?.booking?.hasGuide)}
                  </span>
                </div>
                <div className='mt-4'>
                  <span className='font-w600'>Transportation Included: </span>
                  <span className='fs-16'>
                    {convertToYesNo(booking?.booking?.hasTransportation)}
                  </span>
                </div>
                {booking?.booking.cancelReasonID?.reasonEn ||
                    booking?.booking.cancelReasonOther ? (
                      <div className='mt-4'>
                        <span className='font-w600'>Cancel Reason: </span>
                        <span className='fs-16'>
                          {booking?.booking.cancelReasonOther || booking?.booking.cancelReasonID?.reasonEn}
                        </span>
                      </div>
                    ) : null}


              </div>
            </div>
          </div>
          <div className='col-lg-6'>
            <div className='card'>
              <div className='card-body'>
                <h2 className='font-w600'>Guests Information</h2>
                <div className='mt-4'>
                  <span className='font-w600'>Number of Adults: </span>
                  <span className='fs-16'>
                    {booking?.booking?.guests?.adults}
                  </span>
                </div>
                <div className='mt-4'>
                  <span className='font-w600'>Number of Children: </span>
                  <span className='fs-16'>
                    {booking?.booking?.guests?.children}
                  </span>
                </div>
                <div className='mt-4'>
                  <span className='font-w600'>Number of Infants: </span>
                  <span className='fs-16'>
                    {booking?.booking?.guests?.infants}
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div className='col-lg-6'>
            <div className='card'>
              <div className='card-body'>
                <h2 className='font-w600'>Payment Information</h2>
                <div className='mt-4'>
                  <span className='font-w600'>Payment Method: </span>
                  <span className='fs-16'>
                    {booking?.booking?.paymentMethod?.name}
                  </span>
                </div>
                <div className='mt-4'>
                  <span className='font-w600'>Payment Reference: </span>
                  <span className='fs-16'>{booking?.booking?.paymentReference}</span>
                </div>
                <div className='col-auto' style={{ marginTop: "20px" }}>
                  {booking?.booking.attachment && (
                    <ImagesGallery
                      data={[
                        {
                          large: booking?.booking?.attachment,
                          thumb: booking?.booking?.attachment,
                          alt: "Attachment",
                        },
                      ]}
                      className={"rounded img-fluid"}
                      style={{ width: "200px", height: "200px", objectFit: "" }}
                    />
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      <div className='d-flex justify-content-between'>
        <div className=''>
          <button
            type='submit'
            className='btn btn-primary sw-btn-next'
            onClick={() => redirectTo(-1)}
          >
            Back
          </button>
        </div>
        <div className='col-md-6'>
          <div className='d-flex justify-content-end gap-4'>
            {booking?.booking?.status === 7 && (
              <div className=''>
                <button
                  type='submit'
                  className='btn btn-primary sw-btn-next'
                  onClick={() => setShowRefundAlert(true)}
                >
                  Refund
                </button>
              </div>
            )}
            {booking?.booking?.status === 1 && (
              <div className=''>
                <button
                  type='submit'
                  className='btn btn-primary sw-btn-next'
                  onClick={() => setShowApproveAlert(true)}
                >
                  Approve
                </button>
              </div>
            )}
          </div>
        </div>
      </div>
      {/* Alerts */}
      <div className='mt-3'>
        {/* Refund Alert */}
        <Alert show={showRefundAlert} variant='warning'>
          <Alert.Heading style={{ color: "red" }}>Warning!</Alert.Heading>
          <p style={{ color: "red" }}>Are you sure to Refund this booking.</p>
          <hr />
          <div className='d-flex justify-content-end'>
            <div style={{ marginRight: "8px" }}>
              <Button
                onClick={() => setShowRefundAlert(false)}
                variant='outline-warning'
              >
                Cancel
              </Button>
            </div>
            <Button onClick={handleConfirmRefund} variant='danger'>
              Confirm
            </Button>
          </div>
        </Alert>

        {/* Approve Alert */}
        <Alert show={showApproveAlert} variant='warning'>
          <Alert.Heading style={{ color: "red" }}>Warning!</Alert.Heading>
          <p style={{ color: "red" }}>Are you sure to Approve this booking.</p>
          <hr />
          <div className='d-flex justify-content-end'>
            <div style={{ marginRight: "8px" }}>
              <Button
                onClick={() => setShowApproveAlert(false)}
                variant='outline-warning'
              >
                Cancel
              </Button>
            </div>
            <Button onClick={handleConfirmApprove} variant='danger'>
              Confirm
            </Button>
          </div>
        </Alert>
      </div>
    </div>
  );
};

export default BookingsDetails;
