import SelectBadge from "./SelectBadge";

export default function SelectBadges({
  handelChange,
  values,
  options = [],
  containerStyle = "d-flex flex-wrap gap-3",
  badgeStyle = "h-8 px-4 py-2 rounded",
  textStyle,
  isMultiple,
  isDisable,
}) {
  return (
    <section className={containerStyle}>
      {options?.map((item, index) => {
        return (
          <SelectBadge
            isDisable={isDisable}
            key={item.value + index}
            label={item.label}
            value={item.value}
            id={item.id}
            handelChange={handelChange}
            values={values}
            boxStyle={badgeStyle}
            textStyle={textStyle}
            isMultiple={isMultiple}
          />
        );
      })}
    </section>
  );
}
