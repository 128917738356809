import { toast } from "react-toastify";
import { v4 as uuidv4 } from "uuid";
const dayjs = require("dayjs");

const utc = require("dayjs/plugin/utc");
const timezone = require("dayjs/plugin/timezone");

dayjs.extend(utc);
dayjs.extend(timezone);

export const generateQueryString = (properties) => {
  if (!properties) {
    return "";
  }

  const validProperties = Object.entries(properties)
    .filter(([key, value]) => value) // Filter out properties with falsy values
    .map(([key, value]) => `${key}=${value}`); // Create query string for each property

  return validProperties.join("&"); // Join query strings with '&'
};
export function capitalizeFirstLetter(str) {
  return str.charAt(0).toUpperCase() + str.slice(1);
}
export function getRandomNumber(min = 1, max = 1000) {
  return Math.random() * (max - min) + min;
}
export function isTimestamp(value) {
  const timestamp = Date.parse(value);
  return !isNaN(timestamp);
}
export const handleCopyText = (textToCopy, successMessage) => {
  const tempInput = document.createElement("input");
  tempInput.value = textToCopy;
  document.body.appendChild(tempInput);
  tempInput.select();
  document.execCommand("copy");
  document.body.removeChild(tempInput);
  toast.success(successMessage, {
    position: "top-right",
    autoClose: 1500,
  });
};
export const stopPropagation = (e) => {
  e.stopPropagation();
};
export const cleanObject = (obj) => {
  const cleanedObj = {};
  for (const [key, value] of Object.entries(obj)) {
    if (value !== "" && value !== null && value !== undefined && value !== 0) {
      cleanedObj[key] = value;
    }
  }
  return cleanedObj;
};
export const generateUniqueStringWithTimestamp = () => {
  const now = new Date();
  const year = now.getFullYear();
  const month = String(now.getMonth() + 1).padStart(2, "0");
  const day = String(now.getDate()).padStart(2, "0");
  const hour = String(now.getHours()).padStart(2, "0");
  const minute = String(now.getMinutes()).padStart(2, "0");
  const second = String(now.getSeconds()).padStart(2, "0");
  const miliseconds = String(now.getMilliseconds());

  return `${year}${month}${day}${hour}${minute}${second}${miliseconds}`;
};
export const getUniqueId = () => {
  return `${uuidv4()}-${generateUniqueStringWithTimestamp()}`;
};
export const calculateDuration = (startTime, endTime) => {
  try {
    const [startHour, startMinute] = startTime.split(":");
    const startTotalMinutes =
      parseInt(startHour, 10) * 60 + parseInt(startMinute, 10);

    let [endHour, endMinute] = endTime.split(":");
    let endTotalMinutes = parseInt(endHour, 10) * 60 + parseInt(endMinute, 10);

    // If end time is before start time, add 24 hours to the end time
    if (endTotalMinutes < startTotalMinutes) {
      endTotalMinutes += 24 * 60;
    }

    const durationInMinutes = endTotalMinutes - startTotalMinutes;
    const durationInHours = durationInMinutes / 60;

    return Number(durationInHours.toFixed(2)); // Return duration rounded to two decimal places
  } catch (error) {
    return 0;
  }
};

export const customTimeStampToDateWithUTC = (timestamp, format) => {
  try {
    if (!timestamp) return;

    return dayjs(timestamp).utc().format(format);
  } catch (error) { }
}

export const convertTimeStampToDateWithTimezone = (
  timestamp,
  format,
  timezone,
) => {
  try {
    if (!timestamp) return;

    let timezoneAfterConvert = dayjs(timestamp);

    const timezoneToUse = timezone || dayjs.tz.guess();
    timezoneAfterConvert = timezoneAfterConvert.tz(timezoneToUse);

    return timezoneAfterConvert.format(format);
  } catch (error) { }
};
export function addDurationToTime(time, duration) {
  // Split the time string into hours and minutes
  const [hours, minutes] = time.split(':').map(Number);

  // Convert the duration to minutes
  const durationMinutes = Math.floor(duration) * 60 + Math.round((duration % 1) * 60);

  // Calculate the total minutes
  const totalMinutes = hours * 60 + minutes;

  // Add the duration (in minutes)
  const newTotalMinutes = totalMinutes + durationMinutes;

  // Calculate the new hours and minutes
  const newHours = Math.floor(newTotalMinutes / 60);
  const newMinutes = newTotalMinutes % 60;

  // Format the new time
  const newTime = `${String(newHours).padStart(2, '0')}:${String(newMinutes).padStart(2, '0')}`;

  return newTime;
}
