import Select from "react-select";
import { colourStyles } from "./style";
import { useContext } from "react";
import { ThemeContext } from "../../../../../context/ThemeContext";

export default function GeneralSelect({
  isClearable = true,
  isSearchable = true,
  isDisabled = false,
  isLoading = true,
  isRtl = false,
  options,
  handleChange,
  handleBlur,
  style,
  id,
  name,
  defaultValue = "",
  placeholder = "Select...",
}) {
  let val = { label: "", value: "" };

  options?.forEach((item) => {
    if (item.value == defaultValue || item.label == defaultValue) {
      val = { label: item.label, value: item.value };

    }
  });


  const { background } = useContext(ThemeContext);

  return (
    <div className={`${style}`}>
      <Select
        value={val.value ? val : null}
        id={id}
        placeholder={placeholder}
        styles={colourStyles(background.value === "dark")}
        onChange={handleChange}
        onBlur={handleBlur}
        className='basic-single'
        classNamePrefix='select'
        isDisabled={isDisabled}
        isLoading={isLoading}
        isClearable={isClearable}
        isRtl={isRtl}
        isSearchable={isSearchable}
        name={name}
        options={options}
      />
    </div>
  );
}
