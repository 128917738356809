import LightGallery from "lightgallery/react";

// import styles
import "lightgallery/css/lightgallery.css";
import "lightgallery/css/lg-zoom.css";
import "lightgallery/css/lg-thumbnail.css";
import lgThumbnail from "lightgallery/plugins/thumbnail";
import lgZoom from "lightgallery/plugins/zoom";

export default function ImagesGallery({ data, className, style }) {
  return (
    <LightGallery
      speed={500}
      mode='lg-fade'
      plugins={[lgThumbnail, lgZoom]}
      elementClassNames='row'
    >
      {data.map((item, index) => (
        <div
          role='button'
          style={style}
          data-src={item.thumb}
          key={index + item.alt}
        >
          <img
            style={style}
            src={item.thumb}
            alt={item.alt}
            className={className}
          />
        </div>
      ))}
    </LightGallery>
  );
}
