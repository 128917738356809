import * as Yup from "yup";
import {
  hasLowerCase,
  hasUpperCase,
  hasNumbers,
  hasSymbols,
  hasSpaces,
  phoneNumber,
} from "./regex";

const passwordSchema = (requiredMessage) => {
  return Yup.string()
    .min(8, "Weak password. Must contain at least 8 characters.")
    .max(
      50,
      "Too Long password. The password must be between 8 and 50 characters long.",
    )
    .matches(hasSpaces, {
      message: "The password must not contain spaces.",
    })
    .matches(hasUpperCase, {
      message:
        "Not a very strong password. Must contain at least 1 uppercase letter.",
    })
    .matches(hasLowerCase, {
      message:
        "Not a very strong password. Must contain at least 1 lowercase letter.",
    })
    .matches(hasLowerCase, {
      message:
        "Not a very strong password. Must contain at least 1 lowercase letter.",
    })
    .matches(hasNumbers, {
      message: "Not a very strong password. Must contain at least 1 number.",
    })
    .matches(hasSymbols, {
      message:
        "Not a very strong password. Must contain at least 1 special character.",
    })
    .required(requiredMessage);
};

const isValidPasswordSchema = (requiredMessage) => {
  return (
    Yup.string()
      .min(8, "Invalid Password")
      // .max(50, "Invalid Password")
      // .matches(hasSpaces, {
      //   message: "Invalid Password",
      // })
      // .matches(hasUpperCase, {
      //   message: "Invalid Password",
      // })
      // .matches(hasLowerCase, {
      //   message: "Invalid Password",
      // })
      // .matches(hasLowerCase, {
      //   message: "Invalid Password",
      // })
      // .matches(hasNumbers, {
      //   message: "Invalid Password",
      // })
      // .matches(hasSymbols, {
      //   message: "Invalid Password",
      // })
      .required(requiredMessage)
  );
};

// SignUp form
export const SignUpSchema = Yup.object().shape({
  email: Yup.string().email("Invalid email").required("Email is required"),
  password: passwordSchema("Password is required"),
  firstName: Yup.string().required("First Name is required"),
  lastName: Yup.string().required("Last Name is required"),
  phoneNumber: Yup.string().matches(phoneNumber, {
    message: "The Phone Number must not contain spaces.",
  }),
});

// Complete Information
export const completeInformationSchema = Yup.object().shape({
  email: Yup.string().email("Invalid email").required("Email is required"),
  firstName: Yup.string().required("First Name is required"),
  lastName: Yup.string().required("Last Name is required"),
});

// SignIn
export const SignInSchema = Yup.object().shape({
  email: Yup.string().email("Invalid email").required("Email is required"),
  password: isValidPasswordSchema("Password is required"),
});

// Forget Password
export const ForgetPasswordEmailSchema = Yup.object().shape({
  email: Yup.string().email("Invalid email").required("Email is required"),
});

// Verification Code
export const VerificationCodeSchema = Yup.object().shape({
  email: Yup.string().email("Invalid email").required("Email is required"),
  otp: Yup.number().required("otp Code is required"),
});

// Reset Password Forget
export const resetPasswordForgotSchema = Yup.object().shape({
  newPassword: passwordSchema("New Password is required"),

  confirmNewPassword: Yup.string()
    .oneOf([Yup.ref("newPassword"), null], "Passwords must match")
    .required("Confirm Password is required"),
});

// Change Password
export const changePasswordSchema = Yup.object().shape({
  oldPassword: isValidPasswordSchema("Old Password is required").notOneOf(
    [Yup.ref("newPassword"), null],
    "Old Password and new Password can't be same.",
  ),

  newPassword: passwordSchema("New Password is required"),

  confirmPassword: Yup.string()
    .oneOf([Yup.ref("newPassword"), null], "Passwords must match")
    .required("Confirm Password is required"),
});

// Add / Edit Experience 
export const addSiteStep1Schema = Yup.object().shape({
  wheelChair: Yup.boolean(),
  duration: Yup.number()
    .min(1, "Duration must be greater than 1h")
    .required("Duration is required"),
  thingsToKnow: Yup.string().max(4000).required("Things To Know is required"),
  placeType: Yup.string().max(100).required("Place Type is required"),

  experienceTitle: Yup.string()
    .max(100)
    .required("Experience Title is required"),

  overview: Yup.string().required("Overview is required"),

  whatToExpect: Yup.string().max(4000),

  experienceTypes: Yup.array()
    .of(
      Yup.object().shape({
        label: Yup.string().required("label is required"),
        value: Yup.string().required("value is required"),
      }),
    )
    .min(1, "You must enter at least one option"),

  country: Yup.object().shape({
    label: Yup.string().required("country is required"),
    value: Yup.string().required("country is required"),
  }),

  city: Yup.object().shape({
    label: Yup.string().required("city is required"),
    value: Yup.string().required("city is required"),
  }),

  bookagriExperience: Yup.boolean(),

  location: Yup.object().shape({
    type: Yup.string().required("location is required"),
    coordinates: Yup.array().of(Yup.string()).min(2, "location is required"),
    area: Yup.string().required("location is required"),
  }),

  extras: Yup.array().of(
    Yup.object().shape({
      extraNameEn: Yup.string(),
      extraNameAr: Yup.string(),
      extraPrice: Yup.string(),
    }),
  ),

  ageSuitability: Yup.number()
    .min(1, "Age Suitability must a positive number")
    .required("Age Suitability is required"),

  levelOfDifficulty: Yup.string().required("Level of Difficulty is required"),

  guideIncluded: Yup.boolean(),

  guideOptionalOrMandatory: Yup.boolean(),

  guideDescription: Yup.string().when("guideIncluded", {
    is: false,
    then: (schema) => schema,
    otherwise: (schema) => schema.required("Guide description is required"),
  }),

  guidePrice: Yup.number().when("guideIncluded", {
    is: false,
    then: (schema) => schema,
    otherwise: (schema) => schema.required("Guide Price is required"),
  }),
  guideLearnMore: Yup.string().max(4000),

  spokenLanguages: Yup.array().when("guideIncluded", {
    is: false,
    then: (schema) => schema,
    otherwise: (schema) =>
      schema
        .of(
          Yup.object().shape({
            label: Yup.string().required("Label is required"),
            value: Yup.string().required("Value is required"),
          }),
        )
        .min(1, "You must enter at least one option"),
  }),

  specialInstructions: Yup.string()
    .required("Special Instructions is required"),

  accessibilityFeatures: Yup.array()
    .of(
      Yup.object().shape({
        label: Yup.string().required("label is required"),
        value: Yup.string().required("value is required"),
      }),
    ),

  availableAmenities: Yup.array()
    .of(
      Yup.object().shape({
        label: Yup.string().required("label is required"),
        value: Yup.string().required("value is required"),
      }),
    ),

  cancellationPolicy: Yup.string()
    .required("Cancellation Policy is required"),

  transportationIsIncluded: Yup.boolean(),

  transportationIsMandatory: Yup.boolean(),

  transportationDescription: Yup.string().when("transportationIsIncluded", {
    is: false,
    then: (schema) => schema,
    otherwise: (schema) =>
      schema.required("Transportation description is required"),
  }),

  transportationPrice: Yup.number().when("transportationIsIncluded", {
    is: false,
    then: (schema) => schema,
    otherwise: (schema) => schema.required("Transportation Price is required"),
  }),
  transportationLearnMore: Yup.string().max(4000),
});

export const addSiteStep2Schema = Yup.object().shape({
  firstName: Yup.string().max(40).required("firstName is required"),

  lastName: Yup.string().max(40).required("lastName is required"),

  localHostPhoto: Yup.string().required("local Host Photo is required"),

  moreInfo: Yup.string().max(4000).required("More Info is required"),

  spokenLanguages: Yup.array()
    .of(
      Yup.object().shape({
        label: Yup.string().required("Label is required"),
        value: Yup.string().required("Value is required"),
      }),
    )
    .min(1, "You must enter at least one option"),
});

export const addSiteStep3Schema = Yup.object().shape({
  experienceMainPhoto: Yup.string().required(
    "Experience Main Photo is required",
  ),

  experienceMainPhotos: Yup.array()
    .of(Yup.string())
    .min(1, "You must enter at least one experience photos"),

  experienceVideos: Yup.array().of(Yup.string()),

  whatToExpectPhotos: Yup.array()
    .of(Yup.string())
  ,
});

export const addSiteStep4Schema = Yup.object().shape({
  pricingInformation: Yup.array()
    .of(Yup.object()).min(1, "You must enter at least one price slot"),

  capacity: Yup.number().min(1, "capacity must be greater than 1").required("Capacity is required"),
  cancellationTime: Yup.number()
    .min(24, "Cancellation Time must be greater than 24H")
    .required("Cancellation Time is required"),
});

export const addSiteStep5Schema = Yup.object().shape({
  startDateTime: Yup.date().required("Start date is required"),
  endDateTime: Yup.date()
    .required("End date is required")
    .test(
      "end-date",
      "End date must be after the start date",
      function (endDateTime) {
        const startDate = this.parent.startDateTime;
        return endDateTime > startDate;
      },
    ),
  days: Yup.array()
    .of(
      Yup.object().shape({
        name: Yup.string().required("location is required"),
        slots: Yup.array()
          .of(
            Yup.object().shape({
              startTime: Yup.string().required("Start Time is required"),
              endTime: Yup.string().test(
                'is-valid-end-time',
                'End Time cannot exceed 12 o\'clock midnight',
                function (value) {
                  const [endHour, endMinute] = value.split(':').map(Number);
                  // Checking if time exceeds 24:00 or if it's exactly 24:00 but with minutes other than 00
                  const isInvalid = endHour > 24 || (endHour === 24 && endMinute > 0);
                  return !isInvalid;
                }
              ).required('End Time is required')
              // duration: Yup.number()
              //   .min(1, "Duration must be greater than 1h")
              //   .required("Duration is required"),
              // endTime: Yup.string().required('End Time is required')
              //   .test('is-valid-end-time', 'End Time must be greater than Start Time', function (value) {
              //     const { startTime } = this.parent; // Access the startTime from the parent object
              //     if (!startTime || !value) return true; // If either start or end time is not provided, let other validations handle it
              //     const startHour = parseInt(startTime.split(':')[0]);
              //     const startMinute = parseInt(startTime.split(':')[1]);
              //     const endHour = parseInt(value.split(':')[0]);
              //     const endMinute = parseInt(value.split(':')[1]);
              //     const startTimeInMinutes = startHour * 60 + startMinute;
              //     const endTimeInMinutes = endHour * 60 + endMinute;
              //     return endTimeInMinutes > startTimeInMinutes; // Return true if end time is greater than start time
              //   }),
            }),
          )
          .min(1, "You must enter at least one time slot"),
      }),
    )
    .min(1, "You must enter at least one day and one time slot"),
});

export const addSiteStep6Schema = Yup.object().shape({
  itineraryStops: Yup.array().of(
    Yup.object().shape({
      locationURL: Yup.string().required("Location is required"),
      title: Yup.string().max(200).required("Title is required"),
      details: Yup.string().max(4000).required("Details are required"),
      duration: Yup.number()
        .min(1, "Duration must be greater than 1h")
        .required("Duration is required"),
    }),
  ),
});

// Add / Edit Stay 
export const addStayStep1Schema = Yup.object().shape({
  checkIn: Yup.string().required("check In is required"),
  checkOut: Yup.string().required("check Out is required"),
  stayTitle: Yup.string()
    .max(100)
    .required("Experience Title is required"),

  overview: Yup.string().required("Overview is required"),

  // stayDetails: Yup.string().max(4000),
  stayDetails: Yup.object().shape({
    description: Yup.string().max(4000),
    numberOfBedrooms: Yup.number().integer().min(0).required("Number of Bedrooms is required"),
    numberOfBeds: Yup.number().integer().min(0).required("Number of Beds is required"),
    numberOfBathrooms: Yup.number().integer().min(0).required("Number of Bathrooms is required"),
  }),
  nearbyAndSurroundings: Yup.string().max(4000).required("Nearby And Surroundings is required"),
  houseRules: Yup.string().max(4000).required("House Rules is required"),
  stayTypes: Yup.array()
    .of(
      Yup.object().shape({
        label: Yup.string().required("label is required"),
        value: Yup.string().required("value is required"),
      }),
    )
    .min(1, "You must enter at least one option"),
  accessProvider: Yup.boolean(),
  bookagriExperience: Yup.boolean(),

  country: Yup.object().shape({
    label: Yup.string().required("country is required"),
    value: Yup.string().required("country is required"),
  }),

  city: Yup.object().shape({
    label: Yup.string().required("city is required"),
    value: Yup.string().required("city is required"),
  }),


  location: Yup.object().shape({
    type: Yup.string().required("location is required"),
    coordinates: Yup.array().of(Yup.string()).min(2, "location is required"),
    area: Yup.string().required("location is required"),
  }),


  extras: Yup.array().of(
    Yup.object().shape({
      extraNameEn: Yup.string()
        .matches(/^[a-zA-Z0-9\s\-_'’]+$/, 'Must contain only English letters, numbers, spaces, hyphens, underscores, and apostrophes')
        .required('English name is required'),
      extraNameAr:  Yup.string()
      .matches(/^[\u0600-\u06FF\s\-_'’]+$/, 'Must contain only Arabic letters, spaces, hyphens, underscores, and apostrophes')
      .notOneOf(['A', 'B', 'C', 'Z', 'a', 'b', 'c', 'z'], 'Arabic name should not contain English letters')
      .required('Arabic name is required'),
      extraPrice: Yup.string(),
    })
  ),

  airportTransportationIncluded: Yup.boolean(),

  airportTransOptionalOrMandatory: Yup.boolean(),

  airportTransDescription: Yup.string().when("airportTransportationIncluded", {
    is: false,
    then: (schema) => schema,
    otherwise: (schema) => schema.required("Airport description is required"),
  }),

  airportTransPrice: Yup.number().when("airportTransportationIncluded", {
    is: false,
    then: (schema) => schema,
    otherwise: (schema) => schema.required("Airport Price is required"),
  }),

  airportTransLearnMore: Yup.string().max(4000),

  accessibilityFeatures: Yup.array()
    .of(
      Yup.object().shape({
        label: Yup.string().required("label is required"),
        value: Yup.string().required("value is required"),
      }),
    ),

  availableAmenities: Yup.array()
    .of(
      Yup.object().shape({
        label: Yup.string().required("label is required"),
        value: Yup.string().required("value is required"),
      }),
    ),

  cancellationPolicy: Yup.string()
    .required("Cancellation Policy is required"),
});

export const addStayStep5Schema = Yup.object().shape({
  startDateTime: Yup.date().required("Start date is required"),
  endDateTime: Yup.date()
    .required("End date is required")
    .test(
      "end-date",
      "End date must be after the start date",
      function (endDateTime) {
        const startDate = this.parent.startDateTime;
        return endDateTime > startDate;
      },
    ),
  days: Yup.array()
    .of(
      Yup.object().shape({
        name: Yup.string().required("location is required"),
      }),
    )
    .min(1, "You must enter at least one day and one time slot"),
});

export const addAccessibilityFeaturesSchema = Yup.object().shape({
  nameEn: Yup.string()
    .max(40)
    .matches(/^[a-zA-Z0-9\s\-_'’]+$/, 'Must contain only English letters, numbers, spaces, hyphens, underscores, and apostrophes')
    .required('English name is required'),
  // description: Yup.string()
  //   .max(4000)
  //   .matches(/^[a-zA-Z\s]+$/, 'Only English letters are allowed')
  //   .required('Description is required'),
  nameAr: Yup.string()
    .max(40)
    .matches(/^[\u0600-\u06FF\s]+$/, "Only Arabic letters are allowed")
    .required("هذا الحقل مطلوب"),
});

export const addExperienceTypes = Yup.object().shape({
  nameEn: Yup.string()
    .max(40)
    .matches(/^[a-zA-Z\s]+$/, "Only English letters are allowed")
    .required("Name is required"),
  // description: Yup.string()
  //   .max(4000)
  //   .matches(/^[a-zA-Z\s]+$/, 'Only English letters are allowed')
  //   .required('Description is required'),
  nameAr: Yup.string()
    .max(40)
    .matches(/^[\u0600-\u06FF\s]+$/, "Only Arabic letters are allowed")
    .required("هذا الحقل مطلوب"),
  isStayType: Yup.boolean(),
});

export const addLanguageSchema = Yup.object().shape({
  nameEn: Yup.string()
    .max(40)
    .matches(/^[a-zA-Z\s]+$/, "Only English letters are allowed")
    .required("Name is required"),
  nameAr: Yup.string()
    .max(40)
    .matches(/^[\u0600-\u06FF\s]+$/, "Only Arabic letters are allowed")
    .required("هذا الحقل مطلوب"),
});

export const addPaymentTypesSchema = Yup.object().shape({
  cliQAccount: Yup.string().nullable(),
  cliQAccountDesc: Yup.string().when(["cliQAccount"], (cliQAccount, schema) => {
    return cliQAccount &&
      typeof cliQAccount === "string" &&
      cliQAccount.trim() !== ""
      ? schema.required("CliQ Account Description is required")
      : schema;
  }),
  eWalletAccount: Yup.string().nullable(),
  eWalletAccountDesc: Yup.string().when(
    ["eWalletAccount"],
    (eWalletAccount, schema) => {
      return eWalletAccount &&
        typeof eWalletAccount === "string" &&
        eWalletAccount.trim() !== ""
        ? schema.required("eWallet Account Description is required")
        : schema;
    },
  ),
  bankDetails: Yup.object().shape({
    name: Yup.string().when(
      "$bankDetailsFields",
      (bankDetailsFields, schema) => {
        return bankDetailsFields
          ? schema.required("Name is required for bank details")
          : schema;
      },
    ),
    IBAN: Yup.string()
      .matches(
        /^[A-Z]{2}\d{2}[A-Z0-9]{4}\d{7}([A-Z0-9]?){0,16}$/,
        "Invalid IBAN format",
      )
      .when("$bankDetailsFields", (bankDetailsFields, schema) => {
        return bankDetailsFields
          ? schema.required("IBAN is required for bank details")
          : schema;
      }),
    bankName: Yup.string().when(
      "$bankDetailsFields",
      (bankDetailsFields, schema) => {
        return bankDetailsFields
          ? schema.required("Bank name is required for bank details")
          : schema;
      },
    ),
    swiftNumber: Yup.string()
      .matches(
        /^[A-Z]{6}[A-Z\d]{2}([A-Z\d]{3})?$/,
        "Invalid SWIFT number format",
      )
      .when("$bankDetailsFields", (bankDetailsFields, schema) => {
        return bankDetailsFields
          ? schema.required("SWIFT number is required for bank details")
          : schema;
      }),
    currency: Yup.string().when(
      "$bankDetailsFields",
      (bankDetailsFields, schema) => {
        return bankDetailsFields
          ? schema.required("Currency is required for bank details")
          : schema;
      },
    ),
  }),
  onSiteCardPayment: Yup.string().nullable(),
  onSiteCardDesc: Yup.string().when(
    ["onSiteCardPayment"],
    (onSiteCardPayment, schema) => {
      return onSiteCardPayment &&
        typeof onSiteCardPayment === "string" &&
        onSiteCardPayment.trim() !== ""
        ? schema.required("On Site Card Payment Description is required")
        : schema;
    },
  ),
  onSiteCashPayment: Yup.string().nullable(),
  onSiteCashDesc: Yup.string().when(
    ["onSiteCashPayment"],
    (onSiteCashPayment, schema) => {
      return onSiteCashPayment &&
        typeof onSiteCashPayment === "string" &&
        onSiteCashPayment.trim() !== ""
        ? schema.required("On Site Cash Payment Description is required")
        : schema;
    },
  ),
  exchangeOffices: Yup.string().nullable(),
  exchangeOfficesDesc: Yup.string().when(
    ["exchangeOffices"],
    (exchangeOffices, schema) => {
      return exchangeOffices &&
        typeof exchangeOffices === "string" &&
        exchangeOffices.trim() !== ""
        ? schema.required("Exchange Offices Description is required")
        : schema;
    },
  ),
});

export const addAmenitiySchema = Yup.object().shape({
  nameEn: Yup.string()
    .max(40)
    .matches(/^[a-zA-Z\s-_./]+$/, "Only English letters, '-', '_', and '.' are allowed")
    .required("Name is required"),
  nameAr: Yup.string()
    .max(40)
    .matches(/^[\u0600-\u06FF\s]+$/, "Only Arabic letters are allowed")
    .required("هذا الحقل مطلوب"),
  category: Yup.object().shape({
    value: Yup.string().required("Category is required"),
  }),
  iconPath: Yup.string().required("Icon is required"),
});


const baseSchema = Yup.object().shape({
  firstName: Yup.string(),
  lastName: Yup.string(),
  profileImageUrl: Yup.string(),
  phoneNumber: Yup.string().matches(phoneNumber, {
    message: "The phone number must not contain spaces.",
  }),
  spokenLanguages: Yup.array().of(
    Yup.object().shape({
      label: Yup.string(),
      value: Yup.string(),
    })
  ).min(1, "You must enter at least one option"),
  description: Yup.string(),
});

const withCurrentPassword = baseSchema.concat(
  Yup.object().shape({
    oldPassword: Yup.string().required("Current Password is required"),
  })
);

const withNewPassword = baseSchema.concat(
  Yup.object().shape({
    newPassword: passwordSchema("New Password is required"),
    confirmPassword: Yup.string()
      .required("Confirm Password is required")
      .oneOf([Yup.ref("newPassword"), null], "Passwords must match"),
  })
);

const fullSchema = baseSchema.concat(
  Yup.object().shape({
    oldPassword: Yup.string(),
    newPassword: Yup.string(),
    confirmPassword: Yup.string(),
  })
);

export const EditUserInfoSchema = (values = {}) => {
  if (values.oldPassword && values.newPassword) {
    return withCurrentPassword.concat(withNewPassword);
  } else if (values.newPassword) {
    return withNewPassword;
  } else {
    return fullSchema;
  }
};

export const addBookingOptionsSchema = Yup.object().shape({
  nameEn: Yup.string()
    .max(40)
    .matches(/^[a-zA-Z\s-_./]+$/, "Only English letters, '-', '_', and '.' are allowed")
    .required('Name (English) is required'),
  nameAr: Yup.string()
    .max(40)
    .matches(/^[\u0600-\u06FF\s]+$/, "Only Arabic letters are allowed")
    .required('Name (Arabic) is required'),
  descriptionEn: Yup.string()
    .max(80)
    .matches(/^[a-zA-Z\s-_./]+$/, "Only English letters, '-', '_', and '.' are allowed"),
  descriptionAr: Yup.string()
    .max(80)
    .matches(/^[\u0600-\u06FF\s]+$/, "Only Arabic letters are allowed"),
});

export const addFAQSchema = Yup.object().shape({
  questionEn: Yup.string()
    .max(400)
    .required("Question is required"),
    answerEn: Yup.string()
    .max(4000)
    .required('Answer is required'),
  questionAr: Yup.string()
    .max(400)
    .matches(/^[\u0600-\u06FF\s]+$/, "Only Arabic letters are allowed")
    .required("هذا الحقل مطلوب"),
  answerAr: Yup.string()
    .max(4000)
    .required("هذا الحقل مطلوب"),
});

export const addAnnouncementsNotificationSchema = Yup.object().shape({
  titleEn: Yup.string()
    .max(400)
    .matches(/^[a-zA-Z\s]+$/, "Only English letters are allowed")
    .required("Question is required"),
    bodyEn: Yup.string()
    .max(400)
    .required('Answer is required'),
    titleAr: Yup.string()
    .max(400)
    .matches(/^[\u0600-\u06FF\s]+$/, "Only Arabic letters are allowed")
    .required("هذا الحقل مطلوب"),
    bodyAr: Yup.string()
    .max(400)
    .required("هذا الحقل مطلوب"),
});