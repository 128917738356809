import { useSearchParams } from "react-router-dom";
import { getUsersDetails } from "../../../queries/queryFunctions/privetQueryFns/getQueryFns";
import { useMutation, useQuery } from "@tanstack/react-query";
import { useFormik } from "formik";
import { EditUserInfoSchema, changePasswordSchema } from "../../../utils/forms-schemas";
import { patchEditUserInfoQueryFn } from "../../../queries/queryFunctions/privetQueryFns/patchQueryFns";
import { toast } from "react-toastify";
import ErrorFormik from "../../components/ui/ErrorFormik";
import CustomInput from "../../components/ui/custom-inputs/CustomInput";
import Label from "../../components/ui/custom-inputs/Label";
import Hr from "../../components/ui/Hr";

export default function ChangePassword() {
    const [searchParams, setSearchParams] = useSearchParams();
    const userId = searchParams.get("id");

    const { data, isLoading, isError, error, refetch } = useQuery({
        queryKey: ["usersDetails", { id: userId }],
        queryFn: getUsersDetails,
      });

      const {
        values,
        errors,
        touched,
        handleBlur,
        handleChange,
        handleSubmit,
        setFieldValue,
        setValues,
        isValid,
      } = useFormik({
        initialValues: {
          oldPassword : "",
          newPassword: "",
          confirmPassword: "",

        },
        enableReinitialize: true,
        validationSchema: changePasswordSchema,
        onSubmit: onSubmit,
      });

      const {
        mutate,
        isPending: isLoadingMutate,
        isError: isErrorMutate,
      } = useMutation({
        mutationFn: patchEditUserInfoQueryFn,
        onSuccess(data) {
          toast.success('Your Password has been updated successfully')
        },
        onError(error) {
          toast.error("Something went wrong, please try later!");
        },
      });

      function onSubmit() {
        mutate({
          id: userId,
          oldPassword : values.oldPassword,
          newPassword: values.newPassword,
          confirmPassword: values.confirmPassword,

        });
      }
  
    return (
        <>
        <form onSubmit={handleSubmit}>
          <section className='row'>
          <h4 className=''>Change Password</h4>
           <Hr />
              {/* Current password  */}
              <div className='col-lg-4'>
                <div className='form-group mb-3'>
                  <Label forId='oldPassword' text='Current Password'  />
                  <CustomInput
                    withFocus={true}
                    placeholder='Current Password'
                    id='oldPassword'
                    name='oldPassword'
                    type='text'
                    value={values?.oldPassword}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                  <ErrorFormik
                    isError={errors?.oldPassword}
                    isTouched={touched?.oldPassword}
                    error={errors?.oldPassword}
                  />
                </div>
              </div>
              {/* New Password */}
              <div className='col-lg-4'>
                <div className='form-group mb-3'>
                  <Label forId='newPassword' text='New Password' />
                  <CustomInput
                    withFocus={true}
                    placeholder='New Password'
                    id='newPassword'
                    name='newPassword'
                    type='text'
                    value={values?.newPassword}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                  <ErrorFormik
                    isError={errors?.newPassword}
                    isTouched={touched?.newPassword}
                    error={errors?.newPassword}
                  />
                </div>
              </div>
              {/* Confirm New Password */}
              <div className='col-lg-4'>
                <div className='form-group mb-3'>
                  <Label forId='confirmPassword' text='Confirm New Password' />
                  <CustomInput
                    withFocus={true}
                    placeholder='Confirm New Password'
                    id='confirmPassword'
                    name='confirmPassword'
                    type='text'
                    value={values?.confirmPassword}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                  <ErrorFormik
                    isError={errors?.confirmPassword}
                    isTouched={touched?.confirmPassword}
                    error={errors?.confirmPassword}
                  />
                </div>
              </div>
            </section>
            <div className='text-end toolbar toolbar-bottom p-2'>
        <button
          type='submit'
          className='btn btn-primary sw-btn-next'
          disabled={!isValid}
        >
          Submit
        </button>
      </div>
      </form>
        </>
    )
}