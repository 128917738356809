import { useMutation, useQuery } from "@tanstack/react-query";
import { getCurrencyRateList } from "../../../../../queries/queryFunctions/privetQueryFns/getQueryFns";
import { useEffect, useState } from "react";
import { usePublicContext } from "../../../../../providers/ReactPublicContextProvider";
import { useFormik } from "formik";
import { toast } from "react-toastify";
import { patchCurrencyRateQueryFn } from "../../../../../queries/queryFunctions/privetQueryFns/patchQueryFns";

const CurrencyRateComponent = () => {
    const { setIsLoading } = usePublicContext();
    const [selectedItem, setSelectedItem] = useState(null);

    const {
        data: currencyRateData,
        isPending: isLoadingCurrencyRateData,
        isError: isErrorCurrencyRateData,
        refetch,
    } = useQuery({
        queryKey: ["currency-rate"],
        queryFn: getCurrencyRateList,
    });

    const {
        handleSubmit,
        setValues,
        handleChange,
        values,
        setFieldValue,
    } = useFormik({
        initialValues: {}, 
        onSubmit: onSubmit,
    });

    function onSubmit() {
        if (selectedItem) {
            updateMutation({
                id: selectedItem?._id,
                rate: parseFloat(values?.[selectedItem?._id]),
            });
        }
    }

    const {
        mutate: updateMutation,
        isPending: isPendingCurrencyRateData,
        isError: isErrorUpdateMutation,
    } = useMutation({
        mutationFn: patchCurrencyRateQueryFn,
        onSuccess(data) {
            toast.success("Currency rate Updated Successfully");
        },
        onError(error) {
            toast.error(error.response.data.message);
        },
    });
    

    useEffect(() => {
        setIsLoading(isLoadingCurrencyRateData);
    }, [isLoadingCurrencyRateData]);

    useEffect(() => {
        if (currencyRateData) {
            const initialValues = {};
            currencyRateData.forEach((currency) => {
                initialValues[currency?._id] = currency?.rate;
            });
            setValues(initialValues); 
        }
    }, [currencyRateData]);

    return (
        <>
            <div className="container">
                <div className="row">
                    <div className="col-lg-6 mx-auto">
                        <h4 className="font-w600 mt-4">Please set the exchange rates for other currencies based on the Jordanian Dinar (JOD) as the reference currency. The rates you input will be used for currency conversion throughout the system</h4>
                        <div className="card">
                            <div className="card-body">
                                <form className="currency-form" onSubmit={handleSubmit}>
                                    {currencyRateData && (
                                        <div className="row bg-primary-custom text-white-custom p-2" style={{ borderRadius: '10px', marginBottom: '10px' }}>
                                            <div className="col-2">
                                                <label htmlFor="jod" className="font-w600 text-right" style={{ marginTop: '8px' }}>JOD</label>
                                            </div>
                                            <div className="col-3">
                                                <input type="text" readOnly className="form-control-plaintext text-center text-white-custom custom-margin" id="jod" value="1" />
                                            </div>
                                            <div className="col-7"></div>
                                        </div>
                                    )}
                                    {currencyRateData &&
                                        currencyRateData.map((currency, index) => (
                                            <div key={index} className="form-group row align-items-center p-2">
                                                <label htmlFor={currency.to} className="col-2 col-form-label font-w600 text-right">
                                                    {currency.to}
                                                </label>
                                                <div className="col-3">
                                                <input
                                                    type="number"
                                                    className="form-control currency-rate-input text-center custom-margin"
                                                    id={currency._id}
                                                    name={currency.to}
                                                    value={values[currency._id] || ""}
                                                    onChange={(e) => {
                                                        const { id, value } = e.target;
                                                        setFieldValue(id, value); 
                                                        setSelectedItem(currency);
                                                    }}
                                                />

                                                </div>
                                                <div className="col-7 d-flex justify-content-end">
                                                    <button type="submit"  className="btn btn-primary">
                                                        Edit
                                                    </button>
                                                </div>
                                            </div>
                                        ))}
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default CurrencyRateComponent;
