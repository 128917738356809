import React, { Suspense, useContext } from "react";

/// React router dom
import { Routes, Route, Outlet } from "react-router-dom";

/// Css
import "./index.css";
import "./chart.css";
import "./step.css";

/// Layout
import Nav from "./layouts/nav";
import Footer from "./layouts/Footer";
import ScrollToTop from "./layouts/ScrollToTop";

/// Dashboard
import Home from "./components/Dashboard/Home";

/////Demo
import Theme1 from "./components/Dashboard/Demo/Theme1";
import Theme2 from "./components/Dashboard/Demo/Theme2";
import Theme3 from "./components/Dashboard/Demo/Theme3";
import Theme4 from "./components/Dashboard/Demo/Theme4";
import Theme5 from "./components/Dashboard/Demo/Theme5";
import Theme6 from "./components/Dashboard/Demo/Theme6";

/// App
import AppProfile from "./components/AppsMenu/AppProfile/AppProfile";

/// Form
import AddExperience from "./pages/partner/add-experience";

/// Pages
import LockScreen from "./pages/web/LockScreen";
import Error400 from "./pages/web/Error400";
import Error403 from "./pages/web/Error403";
import Error404 from "./pages/web/Error404";
import Error500 from "./pages/web/Error500";
import Error503 from "./pages/web/Error503";
// import Setting from "./layouts/Setting";
import { ThemeContext } from "../context/ThemeContext";
import PublicTerms from "./pages/web/PublicTerms";


/// Auth
import Registration from "./pages/web/Registration";
import ForgotPassword from "./pages/web/ForgotPassword";
import VerificationCode from "./pages/web/VerificationCode";
import ResetPassword from "./pages/web/ResetPassword";
import Login from "./pages/web/Login";

/// UI
import Loader from "./components/ui/Loader";

/// Hooks
import useUser from "../utils/hooks/useUser";
import AccessibilityFeatures from "./pages/partner/Settings/accessibility-features";
import PaymentTypes from "./pages/partner/Settings/payment-types";
import ExperienceTypes from "./pages/partner/Settings/experience-types";
import SpokenLanguages from "./pages/partner/Settings/spoken-languages";
import Amenities from "./pages/partner/Settings/Amenities";
import StepSix from "./components/partner/add-experience/StepSix";
import Profile from "./components/partner/Profile";
import BookingsListPage from "./pages/partner/bookings-list";
import BookingsDetails from "./components/partner/bookings-list/bookings-details";
import AddStay from "./pages/partner/add-stay";
import PrivacyPolicy from "./pages/partner/Settings/privacy-policy";
import TermsAndConditions from "./pages/partner/Settings/terms-and-conditions";
import CompleteInformation from "./pages/web/CompleteInformation";
import Privacy from "./pages/web/Privacy";
import BookingOptions from "./pages/partner/bookings-list/booking-options";
import FAQManagementComponent from "./components/partner/Settings/FAQ-management/FAQ-management";
import ChangePassword from "./layouts/nav/ChangePassword";
import AnnouncementsNotificationsComponent from "./components/partner/Settings/announcements-notifications/announcements-notifications";
import YourExperiencePage from "./pages/partner/your-experiences";
import HelpCenter from "./pages/partner/help-center";
import DetailsPage from "./components/partner/help-center/details-page";
import CurrencyRate from "./pages/partner/Settings/currency-rate";
import StayTypes from "./pages/partner/Settings/stay-types";


const Index = () => {
  const { isAuthenticUser } = useUser();
  const PARTNER_ROUTES = [
    /// Dashboard Partner
    { url: "dashboard", component: <Home /> },
    { url: "add-experience", component: <AddExperience /> },
    { url: "edit-experience", component: <AddExperience /> },
    { url: "add-stay", component: <AddStay /> },
    { url: "edit-stay", component: <AddStay /> },
    {
      url: "your-Experiences",
      component: <YourExperiencePage />,
    },
    { url: "accessibility-features", component: <AccessibilityFeatures /> },
    { url: "payment-type", component: <PaymentTypes /> },
    { url: "experience-types", component: <ExperienceTypes /> },
    { url: "spoken-languages", component: <SpokenLanguages /> },
    { url: "amenities", component: <Amenities /> },
    { url: "stay-types", component: <StayTypes/> },
    { url: "bookings-list", component: <BookingsListPage /> },
    { url: "bookings-details", component: <BookingsDetails /> },
    { url: "privacy-policy", component: <PrivacyPolicy /> },
    { url: "terms-and-conditions", component: <TermsAndConditions /> },
    { url: "booking-options", component: <BookingOptions /> },
    // { url: "amenities-category", component: <CategoriesComponent /> },
    { url: "FAQ-management", component: <FAQManagementComponent /> },
    { url: "announcements-notifications", component: <AnnouncementsNotificationsComponent /> },
    { url: "help-center", component: <HelpCenter /> },
    {url: "details-page", component: <DetailsPage />},
    { url: "currency-rate", component: <CurrencyRate />},

    
    ///Demo
    { url: "primary-sidebar", component: <Theme1 /> },
    { url: "mini-primary-sidebar", component: <Theme2 /> },
    { url: "compact-primary-header", component: <Theme3 /> },
    { url: "horizontal-primary-sidebar", component: <Theme4 /> },
    { url: "horizontal-modern-sidebar", component: <Theme5 /> },
    { url: "modern-sidebar", component: <Theme6 /> },

    /// Apps
    { url: "profile", component: <Profile /> },
    { url: "change-password", component: <ChangePassword /> },
  ];

  return (
    <div className='vh-100'>
      <Suspense fallback={<Loader />}>
        <Routes>
          <Route index path='/' element={<Login />} />
          <Route path='*' element={<Error404 />} />
          <Route path='/sign-up' element={<Registration />} />
          <Route path='/complete-information' element={<CompleteInformation />} />
          <Route path='/forgot-password' element={<ForgotPassword />} />
          <Route path='/verification-code' element={<VerificationCode />} />
          <Route path='/reset-password' element={<ResetPassword />} />
          <Route path='/lock-screen' element={<LockScreen />} />
          <Route path='/error-400' element={<Error400 />} />
          <Route path='/error-403' element={<Error403 />} />
          <Route path='/error-500' element={<Error500 />} />
          <Route path='/error-503' element={<Error503 />} />
          <Route path='/public-terms' element={<PublicTerms />} />
          <Route path='/public-privacy' element={<Privacy />} />
          <Route element={<MainLayout />}>
            {PARTNER_ROUTES.map((data, i) => (
              <Route
                key={i}
                exact
                path={`${data.url}`}
                element={data.component}
              />
            ))}
          </Route>
        </Routes>
        {/* {isAuthenticUser && <Setting />} */}
        <ScrollToTop />
      </Suspense>
    </div>
  );
};

function MainLayout() {
  const { menuToggle, sidebariconHover } = useContext(ThemeContext);
  return (
    <div
      id='main-wrapper'
      className={`show ${sidebariconHover ? "iconhover-toggle" : ""} ${menuToggle ? "menu-toggle" : ""}`}
    >
      <Nav />
      <div
        className='content-body'
        style={{ minHeight: window.screen.height - 45 }}
      >
        <div className='container-fluid'>
          <Outlet />
        </div>
      </div>
      <Footer />
    </div>
  );
}
export default Index;
