import { useEffect, useState } from "react";

export default function SelectBadge({
  value,
  label,
  handelChange,
  values,
  boxStyle,
  textStyle,
  isDisable = false,
  isMultiple,
}) {
  const [isChecked, setIsChecked] = useState(false);

  useEffect(() => {
    setIsChecked(values?.some((item) => item.value == value));
  }, [values, value]);

  const handleRadioChange = () => {
    if (isMultiple) {
      if (isChecked) {
        handelChange(values.filter((item) => item.value !== value));
        setIsChecked(false); // Uncheck the current option
      } else {
        handelChange([...values, { label: label, value: value }]);
        setIsChecked(true); // Check the current option
      }
    } else {
      handelChange([{ label: label, value: value }]);
    }
  };

  return (
    <>
      <label
        className={`justify-content-center align-items-center d-inline-flex ${
          !isDisable && "cursor-pointer"
        }`}
      >
        <input
          type={isMultiple ? "checkbox" : "radio"} // checkbox for multiple selection, radio for single selection
          value={value}
          checked={isChecked}
          onChange={handleRadioChange}
          className="form-check-input visually-hidden"
          disabled={isDisable}
        />
        <span
          className={`${
            isChecked
              ? `border border-2 ${
                  isDisable ? "border-secondary bg-light" : "border-primary bg-primary-light"
                }`
              : "bg-primary-3"
          } ${boxStyle}  border border-2 select-none justify-content-center align-items-center d-inline-flex`}
        >
          <span
            className={`${textStyle} ${
              isChecked
                ? `${isDisable ? "text-secondary" : "text-primary"}`
                : "text-muted"
            } text-small font-weight-medium`}
          >
            {label}
          </span>
        </span>
      </label>
    </>
  );
}
