import React from 'react';
import Label from './Label';
import CustomInput from './CustomInput';
import ErrorFormik from '../ErrorFormik';
import { Button } from "react-bootstrap";

const CategoryForm = ({ values, errors, touched, handleChange, handleBlur, handleCategorySubmit, categoryOptionData, onClose }) => {
 
  const closeButtonStyle = {
    position: "absolute",
    top: "10px", // Adjust top position to prevent overlap with input fields
    right: "10px",
    fontSize: "12px", // Adjust font size to make the button smaller
  };

  const formWrapperStyle = {
    margin: '20px',
    border: '1px solid #ccc',
    borderRadius: '10px', // Make the border rounded
    padding: '20px',
    position: 'relative', // Position relative for the absolute positioning of the close button
  };

  return (
    <div style={formWrapperStyle}>
      <Button variant='light' onClick={onClose} style={closeButtonStyle}>
        x
      </Button>
      <section className='row'>
        <div className='col-lg-6'>
          <div className='form-group mb-3'>
            <Label forId='placeType' text='Category Name' isRequired={true} />
            <CustomInput
              withFocus={true}
              placeholder='Name'
              id='categoryNameEn'
              name='categoryNameEn'
              type='text'
              value={values?.categoryNameEn || categoryOptionData?.label}
              onChange={handleChange}
              onBlur={handleBlur}
            />
            <ErrorFormik
              isError={errors?.categoryNameEn}
              isTouched={touched?.categoryNameEn}
              error={errors?.categoryNameEn}
            />
          </div>
        </div>
        <div className='col-lg-6'>
          <div className='form-group mb-3'>
            <Label forId='categoryNameAr' text='الاسم ' isRequired={true} />
            <CustomInput
              withFocus={true}
              placeholder=' category الاسم'
              id='categoryNameAr'
              name='categoryNameAr'
              type='text'
              value={values?.categoryNameAr || categoryOptionData?.nameAr }
              onChange={handleChange}
              onBlur={handleBlur}
            />
            <ErrorFormik
              isError={errors?.categoryNameAr}
              isTouched={touched?.categoryNameAr}
              error={errors?.categoryNameAr}
            />
          </div>
        </div>
        <div className='col-lg-12 mt-3'>
          <Button className='btn btn-primary' onClick={handleCategorySubmit}>
            Save
          </Button>
        </div>
      </section>
    </div>
  );
};

export default CategoryForm;
