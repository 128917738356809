export default function DeletionCircle({ width, height, style }) {
  return (
    <svg
      className={`${style}`}
      width={width}
      height={height}
      viewBox='0 0 20 20'
      stroke='#FFF'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M14 10L6 10'
        strokeWidth='2'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <circle cx='10' cy='10' r='9' strokeWidth='2' strokeLinecap='round' />
    </svg>
  );
}
