import React, { useEffect, useState } from "react";
import ReactQuill, { Quill } from "react-quill";
import "react-quill/dist/quill.snow.css";
import { IndentStyle } from "./indent.js";
import "./style.css"

const formats = [
  "bold",
  "italic",
  "underline",
  "strike",
  "link",
  "image",
  "list",
  "bullet",
  "check",
  "script",
  "indent",
  "direction",
  "size",
  "color",
  "background",
  "align",
  "break",
];

const fontSizes = [
  false,
  "9px",
  "10px",
  "11px",
  "12px",
  "14px",
  "16px",
  "18px",
  "20px",
  "22px",
  "24px",
  "26px",
  "30px",
  "32px",
  "34px",
  "36px",
  "38px",
  "40px",
];

const CustomEditor = ({
  onChange,
  onBlur,
  value,
  config,
  id,
  hasUploadImage,
}) => {
  const [modules, setModules] = useState(null);

  useEffect(() => {
    const Size = Quill.import("attributors/style/size");
    const Align = Quill.import("attributors/style/align");
    const Direction = Quill.import("attributors/style/direction");
    const Delta = Quill.import("delta");
    const Break = Quill.import("blots/break");
    const Embed = Quill.import("blots/embed");
  
    class SmartBreak extends Break {
      length() {
        return 1;
      }
      value() {
        return "\n";
      }
      insertInto(parent, ref) {
        Embed.prototype.insertInto.call(this, parent, ref);
      }
    }
  
    SmartBreak.blotName = "break";
    SmartBreak.tagName = "BR";
    Quill.register(SmartBreak, true);
  
    Size.whitelist = fontSizes;
    Size.addRemove = (formatValue, nodeValue) => {
      if (formatValue === "normal") {
        return ""; // Removes any inline size style when 'normal' is selected
      }
      return formatValue; // Otherwise, just use the provided value
    };
  
    Quill.register(Size, true);
    Quill.register(Align, true);
    Quill.register(IndentStyle, true);
    Quill.register(Direction, true);
  
    const lineBreakMatcher = () => {
      let newDelta = new Delta();
      newDelta.insert({ break: "" });
      return newDelta;
    };
  
    const clipboardModule = {
      matchers: [["BR", lineBreakMatcher]],
    };
  
    setModules({
      toolbar: {
        container: [
          ["bold", "italic", "underline", "strike"],
          ["link", hasUploadImage && "image"],
          [{ list: "ordered" }, { list: "bullet" }, { list: "check" }],
          [{ script: "sub" }, { script: "super" }],
          [{ indent: "-1" }, { indent: "+1" }],
          [{ direction: "rtl" }],
          [
            {
              size: fontSizes,
            },
          ],
          [{ color: [] }, { background: [] }],
          [{ align: [] }],
          ["clean"],
          [{ 'break': 'break-button'}], // Add a custom class name for the break button
        ],
        handlers: {
          break: function () {
            const range = this.quill.getSelection();
            if (range) {
              const currentLeaf = this.quill.getLeaf(range.index)[0];
              const nextLeaf = this.quill.getLeaf(range.index + 1)[0];
              this.quill.insertEmbed(range.index, "break", true, "user");
  
              if (nextLeaf === null || currentLeaf.parent !== nextLeaf.parent) {
                this.quill.insertEmbed(range.index, "break", true, "user");
              }
  
              this.quill.setSelection(range.index + 1, Quill.sources.SILENT);
            }
          },
        },
      },
      clipboard: clipboardModule,
      keyboard: {
        bindings: {
          linebreak: {
            key: 13,
            shiftKey: true,
            handler: function (range) {
              const currentLeaf = this.quill.getLeaf(range.index)[0];
              const nextLeaf = this.quill.getLeaf(range.index + 1)[0];
              this.quill.insertEmbed(range.index, "break", true, "user");
  
              if (nextLeaf === null || currentLeaf.parent !== nextLeaf.parent) {
                this.quill.insertEmbed(range.index, "break", true, "user");
              }
  
              this.quill.setSelection(range.index + 1, Quill.sources.SILENT);
            },
          },
          newLine: {
            key: 13,
            handler: function (range) {
              const currentLeaf = this.quill.getLeaf(range.index)[0];
              this.quill.insertText(range.index, "\n", Quill.sources.USER);
              this.quill.setSelection(range.index + 1, Quill.sources.SILENT);
              this.quill.focus();
            },
          },
        },
      },
    });
  }, []);
  const handleChange = (content, delta, source, editor) => {
    try {
      onChange(content);
    } catch (error) {
      console.error("Text Editor error: ", error);
    }
  };

  if (!modules) return <div>Loading...</div>; // Display a loading message or spinner

  return (
    <div style={{ color: "black" }}>
      <ReactQuill
        theme='snow'
        value={value}
        onChange={handleChange}
        onBlur={onBlur}
        modules={modules}
        formats={formats}
        bounds={"#root"}
      />
    </div>
  );
};

export default CustomEditor;
