import React from "react";

const StatusBadge = ({ status }) => {
  const getStatusString = (status) => {
    switch (status) {
      case 1:
        return "DRAFT";
      case 2:
        return "ACTIVE";
      case 3:
        return "ARCHIVED";
      default:
        return "";
    }
  };

  const statusText = getStatusString(status);

  let badgeStyle = {
    padding: "2px 6px",
    borderRadius: "9999px",
    fontWeight: "bold",
  };

  switch (status) {
    case 1:
      badgeStyle.backgroundColor = "#FDE68A"; // Light yellow
      badgeStyle.color = "#AB691E"; // Darker yellow
      break;
    case 2:
      badgeStyle.backgroundColor = "#A7F3D0"; // Light green
      badgeStyle.color = "#147C3F"; // Darker green
      break;
    case 3:
      badgeStyle.backgroundColor = "#FECACA"; // Light red
      badgeStyle.color = "#9B2C2C"; // Darker red
      break;
    default:
      badgeStyle.backgroundColor = "#D1D5DB"; // Light gray
      badgeStyle.color = "#6B7280"; // Darker gray
      break;
  }

  return <span style={badgeStyle}>{statusText}</span>;
};

export default StatusBadge;
