import React, { useEffect, useState } from "react";
import { Button } from "react-bootstrap";
import { useMutation, useQuery } from "@tanstack/react-query";
import { getLanguageList } from "../../../../../queries/queryFunctions/publicQueryFns/getQueryFns";
import { useFormik } from "formik";
import { toast } from "react-toastify";
import SlideOver from "../../../../layouts/SlideOver";
import ErrorFormik from "../../../ui/ErrorFormik";
import CustomInput from "../../../ui/custom-inputs/CustomInput";
import Label from "../../../ui/custom-inputs/Label";
import { postSpokenLanguagesQueryFn } from "../../../../../queries/queryFunctions/privetQueryFns/postQueryFns";
import DropdownBlog from "../../../ui/DropdownBlog";
import { addLanguageSchema } from "../../../../../utils/forms-schemas";
import { patchSpokenLanguageQueryFn } from "../../../../../queries/queryFunctions/privetQueryFns/patchQueryFns";
import { deleteSpokenLanguageQueryFn } from "../../../../../queries/queryFunctions/privetQueryFns/deleteQueryFns";
import { usePublicContext } from "../../../../../providers/ReactPublicContextProvider";
import Pagination from "../../../ui/Pagination";
import CustomModal from "../../../ui/Modal";

const SpokenLanguagesComponent = () => {
  const [activePage, setActivePage] = useState(0);
  const [isSlideOverOpen, setIsSlideOverOpen] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);
  const [showAlert, setShowAlert] = useState(false);
  const [languageIdToDelete, setLanguageIdToDelete] = useState(null);
  const { setIsLoading } = usePublicContext();
  const [pageSize, setPageSize] = useState(10);
  const [limit, setLimit] = useState(10);

  const {
    values,
    errors,
    touched,
    handleBlur,
    handleChange,
    handleSubmit,
    setFieldValue,
    setFieldTouched,
    isValid,
    resetForm,
  } = useFormik({
    initialValues: {
      nameEn: "",
      nameAr: "",
    },
    validationSchema: addLanguageSchema,
    onSubmit: onSubmit,
  });

  const {
    data: spokenLanguagesData,
    isPending: isLoadingSpokenLanguagesData,
    isError: isErrorSpokenLanguagesData,
    refetch,
  } = useQuery({
    queryKey: ["spoken-languages", limit],
    queryFn: getLanguageList,
  });

  const {
    mutate: addMutation,
    isPending: isLoadingAddMutation,
    isError: isErrorAddMutation,
  } = useMutation({
    mutationFn: postSpokenLanguagesQueryFn,
    onSuccess(data) {
      toast.success("Language Added Successfully");
      refetch();
    },
    onError(error) {
      toast.error("Something went wrong, please try later!");
    },
  });

  const {
    mutate: updateMutation,
    isPending: isLoadingUpdateMutation,
    isError: isErrorUpdateMutation,
  } = useMutation({
    mutationFn: patchSpokenLanguageQueryFn,
    onSuccess(data) {
      toast.success("Language Updated Successfully");
      refetch();
      // setIsSlideOverOpen(false);
    },
    onError(error) {
      toast.error("Something went wrong, please try later!");
    },
  });

  const {
    mutate: deleteMutation,
    isPending: isLoadingDeleteMutation,
    isError: isErrorDeleteMutation,
  } = useMutation({
    mutationFn: deleteSpokenLanguageQueryFn,
    onSuccess(data) {
      toast.success("Language Deleted Successfully");
      refetch();
      setShowAlert(false);
    },
    onError(error) {
      toast.error("Something went wrong, please try later!");
    },
  });

  useEffect(() => {
    setIsLoading(isLoadingSpokenLanguagesData);
  }, [isLoadingSpokenLanguagesData]);

  function onSubmit() {
    if (selectedItem) {
      updateMutation({
        id: selectedItem?._id,
        nameEn: values.nameEn,
        nameAr: values.nameAr,
      });
    } else {
      addMutation({
        nameEn: values.nameEn,
        nameAr: values.nameAr,
      });
    }
    resetForm();
    setIsSlideOverOpen(false);
  }

  const openSlideOver = () => {
    setSelectedItem(null);
    setIsSlideOverOpen(true);
  };

  const closeSlideOver = () => {
    setFieldValue("nameEn", "");
    setFieldValue("nameAr", "");
    setSelectedItem(null);
    setIsSlideOverOpen(false);
  };

  const handleDelete = (id) => {
    setLanguageIdToDelete(id);
    setShowAlert(true);
  };

  const handleConfirmDelete = () => {
    deleteMutation(languageIdToDelete);
    setShowAlert(false);
  };

  const handleEdit = (item) => {
    setSelectedItem(item);
    setFieldValue("nameEn", item.nameEn);
    setFieldValue("nameAr", item.nameAr);
    setIsSlideOverOpen(true);
  };

  const handleClick = (pageIndex) => {
    setActivePage(pageIndex);
  };

  // const itemsPerPage = 10;
  const startIndex = activePage * pageSize;
  const endIndex = Math.min(startIndex + pageSize, spokenLanguagesData?.length);

  const handleChangePageSize = (event) => {
    const newSize = parseInt(event.target.value, 10);
    setLimit(newSize);
    setPageSize(newSize);
  };

  return (
    <div className='table-responsive'>
      <CustomModal
        title='Warning!'
        description='Deleting this type will remove it from the associated records.'
        isOpen={showAlert}
        setIsOpen={setShowAlert}
        buttonConfirmAction={handleConfirmDelete}
        buttonConfirmText={"Confirm Delete"}
        buttonCloseText='Cancel'
      />
      <div
        className='guest-calendar'
        style={{ display: "flex", justifyContent: "flex-end" }}
      >
        <Button className='me-2' variant='secondary' onClick={openSlideOver}>
          Add
        </Button>
      </div>

      <table className='table card-table display mb-4 shadow-hover default-table .dataTablesCard dataTable no-footer'>
        <thead>
          <tr>
            <th className='sorting_asc'>Name</th>
            <th className='sorting'>اﻻسم</th>
            <th className='sorting bg-none'></th>
          </tr>
        </thead>
        <tbody>
          {Array.isArray(spokenLanguagesData) &&
            spokenLanguagesData
              .slice(startIndex, endIndex)
              .map((spokenLanguage, index) => (
                <tr key={index} className={index % 2 === 0 ? "even" : "odd"}>
                  <td>
                    <div>
                      <h5 className='text-nowrap'>{spokenLanguage.nameEn}</h5>
                    </div>
                  </td>
                  <td>
                    <div>
                      <h5 className='text-nowrap'>{spokenLanguage.nameAr}</h5>
                    </div>
                  </td>
                  <DropdownBlog
                    showArchiveOption={false}
                    showDeleteOption={true}
                    showPublishOption={false}
                    experienceTypeId={spokenLanguage?._id}
                    handleDelete={() => handleDelete(spokenLanguage?._id)}
                    handleEdit={() => handleEdit(spokenLanguage)}
                  />
                </tr>
              ))}
        </tbody>
      </table>

      <Pagination
        startIdx={startIndex}
        endIdx={endIndex}
        pageSize={pageSize}
        activePage={activePage}
        handleClick={handleClick}
        handleChangePageSize={handleChangePageSize}
        data={spokenLanguagesData}
        itemsPerPage={pageSize}
        totalCount={spokenLanguagesData?.length}
      />

      <SlideOver
        isOpen={isSlideOverOpen}
        onClose={closeSlideOver}
        onSubmit={handleSubmit}
      >
        <div>
          <h4>{selectedItem ? "Edit" : "Add"} Spoken Languages</h4>
          <section className='row'>
            <div className='col-lg-6'>
              <div className='form-group mb-3'>
                <Label forId='placeType' text='Name' isRequired={true} />
                <CustomInput
                  withFocus={true}
                  placeholder='Name'
                  id='nameEn'
                  name='nameEn'
                  type='text'
                  value={values?.nameEn}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
                <ErrorFormik
                  isError={errors?.nameEn}
                  isTouched={touched?.nameEn}
                  error={errors?.nameEn}
                />
              </div>
            </div>
            <div className='col-lg-6'>
              <div className='form-group mb-3'>
                <Label forId='nameAr' text='الاسم ' isRequired={true} />
                <CustomInput
                  withFocus={true}
                  placeholder='الاسم'
                  id='nameAr'
                  name='nameAr'
                  type='text'
                  value={values?.nameAr}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
                <ErrorFormik
                  isError={errors?.nameAr}
                  isTouched={touched?.nameAr}
                  error={errors?.nameAr}
                />
              </div>
            </div>
          </section>
        </div>
      </SlideOver>
    </div>
  );
};

export default SpokenLanguagesComponent;
