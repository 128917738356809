import "react-phone-number-input/style.css";
import { getPublicTerms } from "../../../queries/queryFunctions/publicQueryFns/getQueryFns";
import { useQuery } from "@tanstack/react-query";
import { useEffect } from "react";
import { usePublicContext } from "../../../providers/ReactPublicContextProvider";


export default function PublicTerms() {
    const { setIsLoading } = usePublicContext();


    const {
        data: publicTermsData,
        isPending: isLoadingPublicTermsData,
        isError: isErrorPublicTermsData,
        refetch,
      } = useQuery({
        queryKey: ["public-terms"],
        queryFn: getPublicTerms,
      });

      useEffect(() => {
        setIsLoading(isLoadingPublicTermsData);
      }, [isLoadingPublicTermsData]);

  return (
    <div className='authincation justify-content-center py-5'>
        {publicTermsData && (
        <div dangerouslySetInnerHTML={{ __html: publicTermsData.termsConditionsEn }} />
        )}
  </div>

  );
}
