import React from 'react';
import ExcelJS from 'exceljs/dist/exceljs.min.js';

const ExcelExportButton = ({ data, filename }) => {
  const exportToExcel = async () => {
    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet('Bookings');
  
    // Define headers
    const headers = [
      'Booking ID',
      'Customer Name',
      'Experience Name',
      'Experience Date',
      'Booking Date',
      'Status',
      'Number Of Persons',
      'Amount (JOD)',
      'Payment Method'
    ];
  
    // Add headers to worksheet
    worksheet.addRow(headers);

    worksheet?.columns?.forEach(column => {
      column.width = 20; // Adjust the width as needed
    });
  
    // Add data rows
    data?.All?.bookings.forEach(booking => {
      const rowData = [
        booking?._id,
        `${booking.userId.firstName} ${booking?.userId?.lastName}`,
        booking?.siteId?.name,
        new Date(booking?.startDateTime).toLocaleDateString(), 
        new Date(booking?.createdAt).toLocaleDateString(), 
        getBookingStatus(booking.status),
        `${booking?.guests?.adults + booking?.guests?.children}`, 
        booking?.totalPrice,
        booking?.paymentMethod?.name
      ];
      worksheet.addRow(rowData);
    });
  
    // Generate blob and initiate download
    const buffer = await workbook.xlsx.writeBuffer();
    const blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
    const url = URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = filename || 'bookings.xlsx';
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
    URL.revokeObjectURL(url);
  };
  
  const getBookingStatus = (status) => {
    switch (status) {
      case 1:
        return "PENDING";
      case 2:
        return "APPROVED";
      case 3:
        return "DECLINED";
      case 4:
        return "CANCELLED";
      case 5:
        return "REFUNDED";
      case 6:
        return "COMPLETED";
      default:
        return "";
    }
  };
  
  

  return (
    <button className='btn btn-primary sw-btn-next' onClick={exportToExcel}>Export</button>
  );
};

export default ExcelExportButton;
