import React from "react";

const BookingStatusBadge = ({ status }) => {
  const getStatusString = (status) => {
    switch (status) {
      case 1:
        return "PENDING";
      case 2:
        return "APPROVED";
      case 3:
        return "DECLINED";
      case 4:
        return "CANCELLED";
      case 5:
        return "REFUNDED";
      case 6:
        return "COMPLETED";
        case 7:
          return "REQUESTED";
      default:
        return "";
    }
  };

  const statusText = getStatusString(status);

  let badgeStyle = {
    padding: "2px 6px",
    borderRadius: "9999px",
    fontWeight: "bold",
    color: "#FFFFFF", // Default text color
  };

  switch (status) {
    case 1:
      badgeStyle.backgroundColor = "#FDE68A"; // Light yellow
      badgeStyle.color = "#AB691E"; // Darker yellow
      break;
    case 2:
      badgeStyle.backgroundColor = "#A7F3D0"; // Light green
      badgeStyle.color = "#147C3F"; // Darker green
      break;
    case 3:
      badgeStyle.backgroundColor = "#FECACA"; // Light red
      badgeStyle.color = "#9B2C2C"; // Darker red
      break;
    case 4:
      badgeStyle.backgroundColor = "#B794F4"; // Light purple
      badgeStyle.color = "#6D28D9"; // Darker purple
      break;
    case 5:
      badgeStyle.backgroundColor = "#93C5FD"; // Light blue
      badgeStyle.color = "#1E3A8A"; // Darker blue
      break;
    case 6:
      badgeStyle.backgroundColor = "#9CA3AF"; // Light gray
      badgeStyle.color = "#1F2937"; // Darker gray
      break;
      case 7:
        badgeStyle.backgroundColor = "#FFA500"; // Orange
        badgeStyle.color = "#8B4513"; // Darker orange
        break;
    default:
      badgeStyle.backgroundColor = "#D1D5DB"; // Light gray
      badgeStyle.color = "#6B7280"; // Darker gray
      break;
  }
  
  return <span style={badgeStyle}>{statusText}</span>;
};

export default BookingStatusBadge;
