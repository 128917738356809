import { useCallback, useMemo } from "react";
import { useDropzone } from "react-dropzone";
import { toast } from "react-toastify";
import DownloadSvg from "./../../../../images/svg/download.svg";

const baseStyle = {
  flex: 1,
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  padding: "23px",
  borderWidth: 1,
  borderRadius: 6,
  borderColor: "#D3D2DA",
  borderStyle: "dashed",
  backgroundColor: "#F2F3F7",
  color: "#75748B",
  outline: "none",
  transition: "border .24s ease-in-out",
};

const focusedStyle = {
  borderColor: "#0F0",
};

const acceptStyle = {
  borderColor: "#00e676",
};

const rejectStyle = {
  borderColor: "#ff1744",
};

export default function FileDropzone({
  // You must enter an object that contains the file types you want to accept.
  // Check: https://developer.mozilla.org/en-US/docs/Web/API/window/showOpenFilePicker
  // https://developer.mozilla.org/en-US/docs/Web/HTTP/Basics_of_HTTP/MIME_types/Common_types
  // Example : {"image/*": [".png", ".gif", ".jpeg", ".jpg"]}
  filesAccept = { "application/pdf": [] },

  children,
  setState,
  value,
  multiple = false,
  maxSize,
  name,
}) {
  const onDrop = useCallback((acceptedFiles, rejectedFiles) => {
    if (rejectedFiles.length > 0) {
      // toast.error(rejectedFiles[0].errors[0].code.replaceAll("-", " "));
      toast.error(rejectedFiles[0].errors[0].message);
    } else {
      setState(acceptedFiles);
    }
  }, []);

  const {
    getRootProps,
    getInputProps,
    isFocused,
    fileRejections,
    isDragAccept,
    isDragReject,
    isDragActive,
    acceptedFiles,
  } = useDropzone({
    onDrop,
    accept: filesAccept,
    noClick: false,
    multiple: multiple,
    maxSize: maxSize,
  });

  const style = useMemo(
    () => ({
      ...baseStyle,
      // ...(isFocused ? focusedStyle : {}),
      ...(isDragAccept ? acceptStyle : {}),
      ...(isDragReject ? rejectStyle : {}),
    }),
    [isFocused, isDragAccept, isDragReject],
  );

  return (
    <div className='w-full h-72' style={style} {...getRootProps()}>
      <input name={name} {...getInputProps()} />
      <div className='d-flex gap-2' role='button'>
        <div className='w-6 h-6'>
          <img alt='Download icon' src={DownloadSvg} width={24} height={24} />
        </div>
        {isDragActive ? (
          <div>
            <span className=''>Drop file here ...</span>
          </div>
        ) : (
          <div className='whitespace-nowrap unselectable'>
            <span className=''>Drop file here to upload or </span>
            <label htmlFor='uploadCertificate' className='text-primary'>
              browse
            </label>
          </div>
        )}
      </div>
    </div>
  );
}
