import React, { useEffect, useState } from "react";
import CustomInput from "../../ui/custom-inputs/CustomInput";
import { Link } from "react-router-dom";
import { Button } from "react-bootstrap";
import { useFormik } from "formik";
import GeneralSelect from "../../ui/custom-inputs/select-inputs/GeneralSelect";
import { EXPERIENCE_TYPE } from "../../../../utils/constants";
import { useQuery } from "@tanstack/react-query";
import {
  getCitiesForCountries,
  getCountriesList,
} from "../../../../queries/queryFunctions/publicQueryFns/getQueryFns";
import Label from "../../ui/custom-inputs/Label";

export default function Filter({
  setFilter,
  handelApply,
  isClearFilter,
  setIsClearFilter,
}) {
  const [countriesList, setCountriesList] = useState([]);

  const [citiesList, setCitiesList] = useState([]);

  const {
    values,
    errors,
    touched,
    handleBlur,
    handleChange,
    handleSubmit,
    setFieldValue,
    setFieldTouched,
    setValues,
    isValid,
  } = useFormik({
    initialValues: {
      filter: "",
      type: "",
      country: "",
      city: "",
    },
    enableReinitialize: true,
  });

  const {
    data: countryData,
    isPending: isLoadingCountryData,
    isError: isErrorCountryData,
    fetchStatus: fetchStatusCountryData,
  } = useQuery({
    queryKey: ["country-list"],
    queryFn: getCountriesList,
  });

  const {
    data: citiesData,
    isLoading: isLoadingCitiesData,
    isError: isErrorCitiesData,
    fetchStatus: fetchStatusCitiesData,
  } = useQuery({
    queryKey: ["cities-list", { countryId: values?.country }],
    queryFn: getCitiesForCountries,
    enabled: !!values?.country,
  });

  useEffect(() => {
    let res = (countryData || [])
      .filter((item) => item.name) // Filter out countries
      .map((item) => ({
        label: item.name || "",
        value: item?._id,
      }));
    setCountriesList(res);
  }, [countryData]);

  useEffect(() => {
    let res = (citiesData || [])
      .filter((item) => item.name) // Filter out countries
      .map((item) => ({
        label: item.name || "",
        value: item.name,
      }));
    setCitiesList(res);
  }, [values?.country, citiesData]);

  useEffect(() => {
    setFilter(values);
  }, [values]);

  useEffect(() => {
    if (isClearFilter) {
      setValues({
        filter: "",
        type: "",
        country: "",
        city: "",
      });
      setIsClearFilter(false);
    }
  }, [isClearFilter]);

  return (
    <div className='d-flex justify-content-between align-items-center flex-wrap'>
      <div className='card-action coin-tabs mb-2 d-flex align-items-center flex-wrap'>
        <div className='me-2' style={{width: "188px"}} >
          <Label forId='type' text='Experience Name' isRequired={false} />
          <CustomInput
            className='form-control me-2'
            value={values.filter}
            placeholder='Search'
            isRequired={false}
            name='filter'
            id='filter'
            type='text'
            onChange={handleChange}
          />
        </div>
        <div className='me-2' style={{width: "188px"}}>
          <Label forId='type' text='Type' isRequired={false} />
          <GeneralSelect
            isLoading={false}
            defaultValue={values?.type}
            placeholder='Search to type'
            isClearable={true}
            isSearchable={true}
            isDisabled={false}
            isRtl={false}
            options={EXPERIENCE_TYPE}
            handleChange={(val) => setFieldValue("type", val?.value)}
            id={"type"}
            name={"type"}
          />
        </div>
        <div className='me-2' style={{width: "188px"}}>
          <Label forId='country' text='Country' isRequired={false} />
          <GeneralSelect
            isLoading={isLoadingCountryData}
            defaultValue={values?.country}
            placeholder='Search for country'
            isClearable={true}
            isSearchable={true}
            isDisabled={false}
            isRtl={false}
            options={countriesList}
            handleChange={(val) => setFieldValue("country", val?.value)}
            id={"country"}
            name={"country"}
          />
        </div>
        <div className='me-2' style={{width: "188px"}}>
          <Label forId='city' text='City' isRequired={false} />
          <GeneralSelect
            isLoading={isLoadingCitiesData}
            defaultValue={values?.city}
            placeholder='Search for city'
            isClearable={true}
            isSearchable={true}
            isDisabled={false}
            isRtl={false}
            options={citiesList}
            handleChange={(val) => setFieldValue("city", val?.value)}
            id={"city"}
            name={"city"}
          />
        </div>
      </div>

      <div className='d-flex gap-3 mt-3'>
        <button
          type='button'
          className='btn btn-primary sw-btn-next d-flex align-content-center'
          onClick={handelApply}
        >
          Search
        </button>
        <button
          type='button'
          className='btn btn-primary sw-btn-next d-flex align-content-center'
          onClick={() => {
            setIsClearFilter(true);
          }}
        >
          Clear
        </button>
        <Link to='/add-experience'>
          <Button className='me-2' variant='secondary'>
            Add
          </Button>
        </Link>
      </div>
    </div>
  );
}
