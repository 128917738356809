import React, { useEffect, useState } from "react";
import { Tab, Nav, Dropdown, Button } from "react-bootstrap";
import CustomInput from "../../../components/ui/custom-inputs/CustomInput";
import { useFormik } from "formik";
import PendingBookingListComponent from "../../../components/partner/bookings-list/pending-bookings";
import CompletedBookingListComponent from "../../../components/partner/bookings-list/completed-bookings";
import ExcelExportButton from "../../../components/ui/ExcelExportButton";
import AllBookingListComponent from "../../../components/partner/bookings-list/all-bookings";
import RefundedBookingListComponent from "../../../components/partner/bookings-list/refunded-bookings";
import CanceledBookingListComponent from "../../../components/partner/bookings-list/canceled-bookings";
import RefundedRequestedBookingListComponent from "../../../components/partner/bookings-list/refunded-requested-bookings";
import { getBookingsList } from "../../../../queries/queryFunctions/privetQueryFns/getQueryFns";
import ErrorFormik from "../../../components/ui/ErrorFormik";
import Label from "../../../components/ui/custom-inputs/Label";
import GeneralSelect from "../../../components/ui/custom-inputs/select-inputs/GeneralSelect";
import ApprovedBookingListComponent from "../../../components/partner/bookings-list/approved-bookings";
import { useQuery } from "@tanstack/react-query";
import { getPaymentList } from "../../../../queries/queryFunctions/publicQueryFns/getQueryFns";

const BookingsListPage = () => {
  const [searchData, setSearchData] = useState({});
  const [bookingData, setBookingData] = useState(null);
  const [selectBtn, setSelectBtn] = useState("Newest");
  const [paymentList, setPaymentList] = useState([]);
  const [skip, setSkip] = useState(0);
  const [limit, setLimit] = useState(10);
  const [activeTab, setActiveTab] = useState('All');
  const [searchParams, setSearchParams] = useState({});

  const { values, errors, handleBlur, setFieldValue, touched } = useFormik({
    initialValues: {
      searchInput: "",
      searchSelect: "",
      bookingDateTime: "",
      experienceDateTime: "",
    },
  });


  const handleSearch = async (paginationSkip = skip, paginationLimit = limit) => {
    try {
      let searchParams = {
        search: values.searchInput || "",
        startDateTime: values.experienceDateTime ? new Date(values.experienceDateTime).getTime() : null,
        bookingDate: values.bookingDateTime ? new Date(values.bookingDateTime).getTime() : null,
        paymentType: values.searchSelect ? values.searchSelect.value : null,
      };
  
      let sortByDate = selectBtn === "Newest" ? -1 : (selectBtn === "Oldest" ? 1 : null);
  
      let statusFilter = null;
      switch (activeTab) {
        case 'Pending': statusFilter = 1; break;
        case 'Booked': statusFilter = 2; break;
        case 'Completed': statusFilter = 6; break;
        case 'Canceled': statusFilter = 4; break;
        case 'RefundedRequested': statusFilter = 7; break;
        case 'Refunded': statusFilter = 5; break;
        default: break;
      }
  
      const res = await getBookingsList({
        queryKey: ["search", searchParams],
        sortByDate: sortByDate,
        status: statusFilter,
        skip: paginationSkip,
        limit: paginationLimit,
      });
  
      setSearchData(prevSearchData => ({
        ...prevSearchData,
        [activeTab]: res 
      }));
    } catch (error) {
      console.error("Error fetching search data:", error);
    }
  };
  
  
  const handleTabChange = (tab) => {
    setActiveTab(tab);
    handleSearch(); 
  };

  useEffect(() => {
    handleSearch();
  }, [values, selectBtn]);


  const handleInputChange = (fieldName, value) => {
      setFieldValue(fieldName, value);
  };
  
  const handleClearInputs = () => {
    setFieldValue("bookingDateTime", "");
    setFieldValue("experienceDateTime", "");
    setFieldValue("searchInput", "");
    setFieldValue("searchSelect", "");


    setSearchData({});
  };

  const handleSelectChange = (value) => {
    setSelectBtn(value);
  };

  const {
    data: paymentData,
    isPending: isLoadingPaymentData,
    isError: isErrorPaymentData,
  } = useQuery({
    queryKey: ["getPaymentList"],
    queryFn: getPaymentList,
  });

  useEffect(() => {
    let res = (paymentData || [])
      .filter((item) => item?.name)
      .map((item) => ({
        label: item?.name || "",
        value: item?._id,
      }));
    setPaymentList(res);
  }, [paymentData]);

  useEffect(() => {
    if (searchData) {
      setBookingData(searchData);
    }
  }, [searchData]);
 

  return (
    <Tab.Container defaultActiveKey='All'>
      <div className='d-flex justify-content-between align-items-center flex-wrap'>
        <div className='card-action coin-tabs mb-2 d-flex align-items-center'>
          <div
            className='d-flex align-items-center me-2'
            style={{ marginTop: "6px" }}
          >
            <CustomInput
              className='form-control me-2'
              value={values.searchInput}
              placeholder='Search'
              isRequired={false}
              name='searchInput'
              id='searchInput'
              type='text'
              onChange={(e) => setFieldValue("searchInput", e.target.value)}
              onBlur={handleBlur}
            />
          </div>
        </div>
        <div className='col-sm-2 mb-2' style={{ marginTop: "-15px" }}>
          <div className='d-flex flex-column mb-2'>
            <Label forId='searchSelect' text='Payment Type' />
            <GeneralSelect
              className='form-select me-2'
              defaultValue={values?.searchSelect?.label}
              placeholder='Search'
              isClearable={true}
              isSearchable={true}
              isDisabled={false}
              isLoading={false}
              isRtl={false}
              options={paymentList}
              handleChange={(val) => {
                setFieldValue("searchSelect", val);
              }}
              handleBlur={() => {
                handleBlur("searchSelect");
              }}
              id='searchSelect'
              name='searchSelect'
            />
          </div>
        </div>
        <div className='col-sm-2 mb-2' style={{ marginTop: "-15px" }}>
          <div className='d-flex flex-column mb-2'>
            <Label forId='bookingDateTime' text='Booking Date' />
            <CustomInput
              withFocus={true}
              id='bookingDateTime'
              name='bookingDateTime'
              type='date'
              value={values?.bookingDateTime}
              onChange={(e) =>
                handleInputChange("bookingDateTime", e.target.value)
              }
              onBlur={handleBlur}
              className='form-control form-control-sm'
            />
            <ErrorFormik
              isError={errors?.bookingDateTime}
              isTouched={touched?.bookingDateTime}
              error={errors?.bookingDateTime}
            />
          </div>
        </div>
        <div className='col-sm-2 mb-2' style={{ marginTop: "-15px" }}>
          <div className='d-flex flex-column mb-2'>
            <Label
              forId='experienceDateTime'
              text='Experience Date'
              isRequired={false}
            />
            <CustomInput
              withFocus={true}
              id='experienceDateTime'
              name='experienceDateTime'
              type='date'
              value={values?.experienceDateTime}
              onChange={(e) =>
                handleInputChange("experienceDateTime", e.target.value)
              }
              onBlur={handleBlur}
              className='form-control form-control-sm'
            />
            <ErrorFormik
              isError={errors?.experienceDateTime}
              isTouched={touched?.experienceDateTime}
              error={errors?.experienceDateTime}
            />
          </div>
        </div>

        <div className='d-flex align-items-center'>
          <ExcelExportButton data={bookingData} filename='bookings.xlsx' />
          <Button
            type='button'
            className='btn btn-danger'
            style={{marginLeft: "5px"}}
            onClick={handleClearInputs}
            >
            x</Button>
          <div className='newest ms-3'>
            <Dropdown>
              <Dropdown.Toggle
                as='div'
                className=' btn-select-drop default-select btn i-false'
              >
                {selectBtn} <i className='fas fa-angle-down ms-2 '></i>
              </Dropdown.Toggle>
              <Dropdown.Menu>
                <Dropdown.Item
                  onClick={() => handleSelectChange("Oldest")}
                  eventKey='All'
                >
                  Oldest
                </Dropdown.Item>
                <Dropdown.Item
                  onClick={() => handleSelectChange("Newest")}
                  eventKey='All'
                >
                  Newest
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </div>
        </div>
      </div>
      <div className='card-action coin-tabs mb-2'>
        <Nav as='ul' className='nav nav-tabs'>
          <Nav.Item as='li' className='nav-item'>
            <Nav.Link className='nav-link' eventKey='All' onClick={() => handleTabChange('All')} 
>
              All
            </Nav.Link>
          </Nav.Item>
          <Nav.Item as='li' className='nav-item'>
            <Nav.Link className='nav-link' eventKey='Pending' onClick={() => handleTabChange('Pending')} >
              Pending
            </Nav.Link>
          </Nav.Item>
          <Nav.Item as='li' className='nav-item'>
            <Nav.Link className='nav-link' eventKey='Booked' onClick={() => handleTabChange('Booked')}>
              Upcoming
            </Nav.Link>
          </Nav.Item>
          <Nav.Item as='li' className='nav-item'>
            <Nav.Link className='nav-link' eventKey='Completed' onClick={() => handleTabChange('Completed')}>
              Completed
            </Nav.Link>
          </Nav.Item>
          <Nav.Item as='li' className='nav-item'>
            <Nav.Link className='nav-link' eventKey='Canceled' onClick={() => handleTabChange('Canceled')}>
              Canceled
            </Nav.Link>
          </Nav.Item>
          <Nav.Item as='li' className='nav-item'>
            <Nav.Link className='nav-link' eventKey='RefundedRequested' onClick={() => handleTabChange('RefundedRequested')}>
              Refunded Requested
            </Nav.Link>
          </Nav.Item>
          <Nav.Item as='li' className='nav-item'>
            <Nav.Link className='nav-link' eventKey='Refunded' onClick={() => handleTabChange('Refunded')}>
              Refunded
            </Nav.Link>
          </Nav.Item>
        </Nav>
      </div>
      <Tab.Content>
        <Tab.Pane eventKey='All'>
          {/* Content for "All Bookings" tab */}
          <AllBookingListComponent
            searchData={searchData['All']}
            skip={skip}
            limit={limit}
            setSkip={setSkip}
            setLimit={setLimit}
            handleSearch={handleSearch}
            searchParams={searchParams}
            activeTab={activeTab}
            
          />
        </Tab.Pane>
        <Tab.Pane eventKey='Pending'>
          {/* Content for "Pending Bookings" tab */}
          <PendingBookingListComponent
            searchData={searchData["Pending"]}
            filter={{ status: 1 }}
            skip={skip} 
            limit={limit} 
            activeTab={activeTab}
            handleSearch={handleSearch}
            searchParams={searchParams}
          />
        </Tab.Pane>
        <Tab.Pane eventKey='Booked'>
          {/* Content for "Booked Bookings" tab */}
          <ApprovedBookingListComponent
            currentTab='booked'
            searchData={searchData["Booked"]}
            filter={{ status: 2 }}
            skip={skip} 
            limit={limit} 
            activeTab={activeTab}
            handleSearch={handleSearch}
            searchParams={searchParams}
          />
        </Tab.Pane>
        <Tab.Pane eventKey='Completed'>
          {/* Content for "Completed Bookings" tab */}
          <CompletedBookingListComponent
            currentTab='completed'
            searchData={searchData["Completed"]}
            filter={{ status: 6 }}
            skip={skip} 
            limit={limit} 
            activeTab={activeTab}
            handleSearch={handleSearch}
            searchParams={searchParams}
          />
        </Tab.Pane>
        <Tab.Pane eventKey='Canceled'>
          {/* Content for "Canceled Bookings" tab */}
          <CanceledBookingListComponent
            currentTab='Canceled'
            searchData={searchData["Canceled"]}
            filter={{ status: 4 }}
            skip={skip} 
            limit={limit} 
            activeTab={activeTab}
            handleSearch={handleSearch}
            searchParams={searchParams}
          />
        </Tab.Pane>
        <Tab.Pane eventKey='RefundedRequested'>
          {/* Content for "Refunded Requested Bookings" tab */}
          <RefundedRequestedBookingListComponent
            currentTab='RefundedRequested'
            searchData={searchData["RefundedRequested"]}
            filter={{ status: 7 }}
            skip={skip} 
            limit={limit} 
            activeTab={activeTab}
            handleSearch={handleSearch}
            searchParams={searchParams}
          />
        </Tab.Pane>
        <Tab.Pane eventKey='Refunded'>
          {/* Content for "Refunded Bookings" tab */}
          <RefundedBookingListComponent
            currentTab='Refunded'
            searchData={searchData["Refunded"]}
            filter={{ status: 5 }}
            skip={skip} 
            limit={limit} 
            activeTab={activeTab}
            handleSearch={handleSearch}
            searchParams={searchParams}
          />
        </Tab.Pane>
      </Tab.Content>
    </Tab.Container>
  );
};

export default BookingsListPage;
