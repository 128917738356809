import { useEffect } from "react";
import "react-phone-number-input/style.css";
import { usePublicContext } from "../../../providers/ReactPublicContextProvider";
import useUser from "../../../utils/hooks/useUser";
import { useFormik } from "formik";
import { useMutation } from "@tanstack/react-query";
import { completeInformationSchema } from "../../../utils/forms-schemas";
import { toast } from "react-toastify";
import ErrorFormik from "../../components/ui/ErrorFormik";
import BookagriName from "../../components/ui/svg/BookagriName";
import Bookagri_Icon from "../../components/ui/svg/Bookagri_Icon";
import { Link } from "react-router-dom";
import { patchCompleteProfileQueryFn } from "../../../queries/queryFunctions/publicQueryFns/patchQueryFns";

export default function CompleteInformation() {
  const { setIsLoading, isLoading } = usePublicContext();
  const { redirectTo, isAuthenticUser, setUserData } = useUser();
  const socialData = JSON?.parse(sessionStorage?.getItem("socialData"));

  const {
    values,
    errors,
    touched,
    handleBlur,
    handleChange,
    handleSubmit,
    setFieldValue,
    setFieldTouched,
    isValid,
  } = useFormik({
    initialValues: {
      firstName: socialData?.firstName || "",
      lastName: socialData?.lastName || "",
      email: socialData?.email || "",
    },
    validationSchema: completeInformationSchema,
    onSubmit: onSubmit,
  });

  const { mutate: socialMutate, isPending: isLoadingSocialMutate } =
    useMutation({
      mutationFn: patchCompleteProfileQueryFn,
      onSuccess(data) {
        if (!data?.token || !data?.user?.isProfileCompleted) {
          sessionStorage.setItem("email", values.email);
          sessionStorage.removeItem("socialData");
          toast.success("Please Verify your email");
          redirectTo("/verification-code");
          setIsLoading(false);
          return;
        }

        const userData = data?.user;
        const token = data?.token;
        setUserData({ ...userData, token });
        sessionStorage.removeItem("socialData");
        redirectTo("/dashboard");
        setIsLoading(false);
      },
      onError(error) {
        console.error("error social Mutate: ", error);
        toast.error("Something went wrong, please try again later!");
      },
    });

  useEffect(() => {
    if (isAuthenticUser) setIsLoading(true);

    if (isAuthenticUser) {
      redirectTo("/dashboard");
    }
    setIsLoading(false);
  }, [isLoading]);

  useEffect(() => {
    setIsLoading(isLoadingSocialMutate);
  }, [isLoadingSocialMutate]);

  async function onSubmit() {
    if (
      !socialData?.socialMediaId ||
      !socialData?.socialLoginType ||
      !socialData?.tokens
    ) {
      toast.error("Something went wrong, please try again later!");
      return;
    }

    socialMutate({
      id: socialData?.tokens,
      ...(!socialData?.email && { email: values.email }),
      firstName: values?.firstName,
      lastName: values?.lastName,
    });
  }

  useEffect(() => {
    setFieldTouched("", "");

    return () => {
      sessionStorage.removeItem("socialData");
    };
  }, []);

  return (
    <div className='authincation justify-content-center py-5'>
      <div className='container row justify-content-center align-items-center'>
        <div className='row justify-content-center align-items-center'>
          <div className='d-flex justify-content-center mb-3'>
            <div className={``}>
              <BookagriName />
            </div>
            <div className='ms-2'>
              <Bookagri_Icon />
            </div>
          </div>
          <div className='col-md-6'>
            <div className='authincation-content'>
              <div className='row no-gutters'>
                <div className='col-xl-12'>
                  <div className='auth-form'>
                    <h4 className='text-center mb-4 '>
                      Complete your information
                    </h4>
                    <form onSubmit={handleSubmit}>
                      {socialData && (
                        <>
                          <div className='form-group mb-3'>
                            <label className='mb-1 '>
                              <strong>First Name</strong>
                            </label>
                            <input
                              className='form-control'
                              name='firstName'
                              defaultValue={values?.firstName}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              placeholder='first Name'
                            />
                          </div>
                          <ErrorFormik
                            isError={errors?.firstName}
                            isTouched={touched?.firstName}
                            error={errors?.firstName}
                          />
                          <div className='form-group my-3'>
                            <label className='mb-1 '>
                              <strong>Last Name</strong>
                            </label>
                            <input
                              className='form-control'
                              name='lastName'
                              defaultValue={values?.lastName}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              placeholder='Last Name'
                            />
                          </div>
                          <ErrorFormik
                            isError={errors?.lastName}
                            isTouched={touched?.lastName}
                            error={errors?.lastName}
                          />
                          <div
                            className='form-group mb-3'
                            style={{ position: "relative" }}
                          ></div>
                          <ErrorFormik
                            isError={errors?.phoneNumber}
                            isTouched={touched?.phoneNumber}
                            error={errors?.phoneNumber}
                          />
                          {!socialData?.email && (
                            <div className='form-group mb-3'>
                              <label className='mb-1 '>
                                <strong>Email</strong>
                              </label>
                              <input
                                className='form-control'
                                name='email'
                                value={values?.email}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                placeholder='email'
                              />
                            </div>
                          )}
                          <ErrorFormik
                            isError={errors?.email}
                            isTouched={touched?.email}
                            error={errors?.email}
                          />
                        </>
                      )}
                      {socialData ? (
                        <div className='text-center mt-4'>
                          <button
                            type='submit'
                            className='btn btn-primary btn-block'
                            disabled={!isValid}
                          >
                            Submit
                          </button>
                        </div>
                      ) : (
                        <>
                          <p>
                            There is an error. Please try again later or try
                            another registration method
                          </p>
                          <Link className='text-' to={"sign-up"}>
                            <button
                              type='button'
                              className='btn btn-primary btn-block'
                              disabled={!isValid}
                            >
                              back
                            </button>
                          </Link>
                        </>
                      )}
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
