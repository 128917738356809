import React, { useEffect, useReducer, useState } from "react";
import { getExperienceList } from "../../../../queries/queryFunctions/privetQueryFns/getQueryFns";
import SelectBadges from "../../../components/ui/SelectBadges";
import Filter from "../../../components/partner/your-experiences/Filter";
import { useQuery } from "@tanstack/react-query";
import { usePublicContext } from "../../../../providers/ReactPublicContextProvider";
import Table from "../../../components/partner/your-experiences/Table";

const options = [
  { label: "All", value: "all experiences" },
  { label: "Archive", value: "archived" },
];

const YourExperiencePage = () => {
  const { setIsLoading } = usePublicContext();

  const [skip, setSkip] = useState(0);
  const [limit, setLimit] = useState(10);
  const [activePage, setActivePage] = useState(0);

  const [filter, setFilter] = useState({});
  const [filterApply, setFilterApply] = useState({});
  const [isClearFilter, setIsClearFilter] = useState(false);

  const [reducer, forceUpdate] = useReducer((x) => x + 1, 0);
  const [contentTap, setContentTap] = useState([
    { label: "All", value: "all experiences" },
  ]);

  const {
    data,
    isPending: isLoading,
    isError,
    isFetching,
    isPreviousData,
    refetch,
  } = useQuery({
    queryKey: [
      "your-experience-list",
      { ...filterApply, skip, limit, status: contentTap?.[0]?.value },
    ],
    queryFn: getExperienceList,
    options: { keepPreviousData: true },
  });

  useEffect(() => {
    setFilterApply(() => filter);
  }, [reducer, isClearFilter]);

  useEffect(() => {
    setIsLoading(isLoading);
  }, [isLoading]);

  useEffect(() => {
    setSkip(0);
    setActivePage(0);
  }, [contentTap]);

  function handelApply() {
    forceUpdate();
  }

  return (
    <>
      <div className='mb-4'>
        <SelectBadges
          options={options}
          handelChange={(val) => setContentTap(val)}
          values={contentTap}
          isMultiple={false}
          textStyle={"text-nowrap text-black"}
        />
      </div>

      <Filter
        handelApply={handelApply}
        setFilter={setFilter}
        isClearFilter={isClearFilter}
        setIsClearFilter={setIsClearFilter}
      />

      <Table
        data={data}
        setSkip={setSkip}
        setLimit={setLimit}
        itemsPerPage={limit}
        refetch={refetch}
        activePage={activePage}
        setActivePage={setActivePage}
      />
    </>
  );
};

export default YourExperiencePage;