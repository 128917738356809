import React, { useState, useEffect } from "react";
import { useQuery } from "@tanstack/react-query";
import { getBookingsList } from "../../../../queries/queryFunctions/privetQueryFns/getQueryFns";
import { usePublicContext } from "../../../../providers/ReactPublicContextProvider";
import BookingListComponent from "../../ui/bookingList";

const AllBookingListComponent = ({ filter, searchData,searchParams, activePage, skip, limit, setSkip, setLimit, handleSearch}) => {
  const { setIsLoading } = usePublicContext();
  const [booking, setBooking] = useState();
  const [passingSearchData, setPassingSearchData] = useState(null)

  const { data, isPending, isError, error, refetch } = useQuery({
    queryKey: ["BookingsList", { skip, limit, filter, ...searchParams }],
    queryFn: getBookingsList,
  });

  useEffect(() => {
    if (searchParams) {
      handleSearch();
    }
  }, [searchParams]);


  useEffect(() => {
    if (data) {
      setBooking(data);
    }
  }, [data]);

  useEffect(() => {
    setIsLoading(isPending);
  }, [isPending]);

  useEffect(() => {
    if (searchData) {
      setPassingSearchData(searchData);
    }
  }, [searchData]);

  return (
    <BookingListComponent
      data={booking}
      searchData={passingSearchData}
      filter={filter}
      setSkip={setSkip}
      setLimit={setLimit}
      itemsPerPage={limit}
      allListActivePage={activePage}
      handleSearch={handleSearch} 
    />
  );
};

export default AllBookingListComponent;


