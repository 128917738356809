import React, { useState } from "react";
import Select from "react-select";
import { colourStyles } from "./style";
import { faEdit, faTrashAlt } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const CustomOption = ({ innerRef, innerProps, data, onEdit, handleDelete }) => {
  const [isHoveredEdit, setIsHoveredEdit] = useState(false);
  const [isHoveredDelete, setIsHoveredDelete] = useState(false);

  const handleEdit = (event) => {
    event.stopPropagation();
    onEdit(data);
  };

  return (
    <div
      ref={innerRef}
      {...innerProps}
      style={{ position: 'relative' }}
    >
      <span>{data.label}</span>
      <span style={{ position: 'absolute', right: 0 }}>
        <span
          onMouseEnter={() => setIsHoveredEdit(true)}
          onMouseLeave={() => setIsHoveredEdit(false)}
          onClick={handleEdit}
          style={{ marginRight: '5px' }}
        >
          <FontAwesomeIcon
            icon={faEdit}
            style={{ color: isHoveredEdit ? 'red' : 'black' }}
          />
        </span>
        <span
          onMouseEnter={() => setIsHoveredDelete(true)}
          onMouseLeave={() => setIsHoveredDelete(false)}
          onClick={() => handleDelete(data.value)}
          style={{ marginRight: '5px' }}
        >
          <FontAwesomeIcon
            icon={faTrashAlt}
            style={{ color: isHoveredDelete ? 'red' : 'black' }}
          />
        </span>
      </span>
    </div>
  );
};

export default function EditableSelect({
  isClearable = true,
  isSearchable = true,
  isDisabled = false,
  isLoading = true,
  isRtl = false,
  options,
  handleChange,
  handleBlur,
  style,
  id,
  name,
  defaultValue = "",
  placeholder = "Select...",
  onEdit,
  handleDelete
}) {
  let val = { label: "", value: "" };
  options?.forEach((item) => {
    if (item.value === defaultValue || item.label === defaultValue) {
      val = { label: item.label, value: item.value };
    }
  });

  return (
    <div className={`${style}`}>
      <Select
        value={val.value ? val : null}
        id={id}
        placeholder={placeholder}
        styles={colourStyles(isDisabled)}
        onChange={handleChange}
        onBlur={handleBlur}
        className='basic-single'
        classNamePrefix='select'
        isDisabled={isDisabled}
        isLoading={isLoading}
        isClearable={isClearable}
        isRtl={isRtl}
        isSearchable={isSearchable}
        name={name}
        options={options}
        components={{ Option: (props) => <CustomOption {...props} onEdit={onEdit} handleDelete={handleDelete} /> }}
      />
    </div>
  );
}
