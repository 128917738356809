import { useEffect, useState } from "react";
import { usePublicContext } from "../../../../providers/ReactPublicContextProvider";
import { useQuery } from "@tanstack/react-query";
import { getHelpCenter } from "../../../../queries/queryFunctions/privetQueryFns/getQueryFns";
import useUser from "../../../../utils/hooks/useUser";
import { customTimeStampToDateWithUTC } from "../../../../utils/functions";
import Pagination from "../../ui/Pagination";

const HelpCenterComponent = () => {

    const { redirectTo } = useUser();
    const [activePage, setActivePage] = useState(0);
    const [pageSize, setPageSize] = useState(10); 
    const [limit , setLimit] = useState(10);

    const {
        data: helpCenterData,
        isPending: isLoadingHelpCenterData,
        isError: isErrorHelpCenterData,
        refetch,
      } = useQuery({
        queryKey: ["help-center", limit], 
            queryFn: getHelpCenter,
      });


  const handleClick = (pageIndex) => {
    setActivePage(pageIndex);
  };

  const startIndex = activePage * pageSize;
  const endIndex = Math.min(
    startIndex + pageSize,
    helpCenterData?.length,
  );

  const handleChangePageSize = (event) => {
    const newSize = parseInt(event.target.value, 10);
    setPageSize(newSize);
    setLimit(newSize);
};

    return(
     <>
          <div className='table-responsive'>
            <table className='table card-table display mb-4 shadow-hover default-table .dataTablesCard dataTable no-footer'>
                <thead>
                <tr >
                    <th className='sorting_asc'>Customer Image</th>
                    <th className='sorting'>Customer Name </th>
                    <th className='sorting'>Subject </th>
                    <th className='sorting bg-none'>Date</th>
                </tr>
                </thead>
                <tbody>
                {helpCenterData &&
                    helpCenterData
                    .slice(startIndex, endIndex)
                    .map((helpCenter, index) => (
                        <tr role="button" onClick={() => redirectTo(`/details-page?id=${helpCenter?._id}`)} key={index} className={index % 2 === 0 ? "even" : "odd"}>
                        <td>
                            <div className='concierge-bx d-flex align-items-center'>
                                <img
                                style={{objectFit: "cover"}}
                                className='me-3 rounded'
                                src={
                                    helpCenter?.userId?.profileImageUrl ||
                                    "https://placehold.co/40"
                                }
                                alt=''
                                />
                            </div>
                            </td>
                        <td>
                            <div>
                            <h5 className='text-nowrap'>
                                {helpCenter?.userId?.firstName + " " + helpCenter?.userId?.lastName }
                            </h5>
                            </div>
                        </td>
                        <td>
                            <div>
                            <h5 className='text-nowrap'>
                                {helpCenter?.subject }
                            </h5>
                            </div>
                        </td>
                        <td>
                            <div>
                            <h5 className='text-nowrap'>
                            {customTimeStampToDateWithUTC(helpCenter?.createdAt, "DD/MM/YYYY HH:mm")}
                                </h5>
                            </div>
                        </td>
                    
                        </tr>
                    ))}
                </tbody>
            </table>

        <Pagination
          startIdx={startIndex}
          endIdx={endIndex}
          pageSize={pageSize}
          activePage={activePage}
          handleClick={handleClick}
          handleChangePageSize={handleChangePageSize}
          data={helpCenterData}
          itemsPerPage={pageSize}
          totalCount={helpCenterData?.length}
           />
    </div>
</>
    )
}

export default HelpCenterComponent;