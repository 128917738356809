import React, { useEffect } from "react";
import { usePublicContext } from "../../../providers/ReactPublicContextProvider";
import useUser from "../../../utils/hooks/useUser";
import { toast } from "react-toastify";
import { postForgetPassword } from "../../../queries/queryFunctions/publicQueryFns/postQueryFns";
import { useMutation } from "@tanstack/react-query";
import { useFormik } from "formik";
import { ForgetPasswordEmailSchema } from "../../../utils/forms-schemas";
import ErrorFormik from "../../components/ui/ErrorFormik";

const ForgotPassword = () => {
  const { setIsLoading, isLoading } = usePublicContext();

  const { redirectTo, isAuthenticUser } = useUser();

  const { values, errors, touched, handleBlur, handleChange, handleSubmit } =
    useFormik({
      initialValues: {
        email: "",
      },
      validationSchema: ForgetPasswordEmailSchema,
      onSubmit: onSubmit,
    });

  const {
    mutate,
    isPending: isLoadingMutate,
    isError: isErrorMutate,
  } = useMutation({
    mutationFn: postForgetPassword,
    onSuccess(data) {
      sessionStorage.setItem("email", values.email);

      toast.success(
        "A verification link has been sent to your email, please check your email.",
      );
      setIsLoading(false);
      redirectTo("/");
    },
    onError(error) {
      toast.error("This email address is not registered with us!");
    },
  });

  useEffect(() => {
    if (isAuthenticUser) setIsLoading(true);

    if (isAuthenticUser) {
      redirectTo("/dashboard");
    }
    setIsLoading(false);
  }, [isLoading]);

  useEffect(() => {
    setIsLoading(isLoadingMutate);
  }, [isLoadingMutate]);

  async function onSubmit() {
    mutate({
      ...values,
    });
  }

  return (
    <div className='authincation h-100 p-meddle'>
      <div className='container h-100'>
        {" "}
        <div className='row justify-content-center h-100 align-items-center'>
          <div className='col-md-6'>
            <div className='authincation-content'>
              <div className='row no-gutters'>
                <div className='col-xl-12'>
                  <div className='auth-form'>
                    <h4 className='text-center mb-4 '>Forgot Your Password?</h4>
                    <form onSubmit={handleSubmit}>
                      <div className='form-group mb-3'>
                        <label className='mb-1'>
                          <strong>Email</strong>
                        </label>
                        <input
                          className='form-control'
                          placeholder='Enter your email'
                          type='email'
                          name='email'
                          value={values?.email}
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />
                      </div>
                      <ErrorFormik
                        isError={errors?.email}
                        isTouched={touched?.email}
                        error={errors?.email}
                      />
                      <div className='text-center'>
                        <button
                          type='submit'
                          className='btn btn-primary btn-block'
                        >
                          Submit
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ForgotPassword;
