import React, { useEffect } from "react";
import Hr from "../../ui/Hr";
import { useMutation } from "@tanstack/react-query";
import { toast } from "react-toastify";
import { useFormik } from "formik";
import { addSiteStep5Schema } from "../../../../utils/forms-schemas";
import { useSearchParams } from "react-router-dom";
import { usePublicContext } from "../../../../providers/ReactPublicContextProvider";
import { patchAddSiteStep5QueryFn, patchPublishSiteQueryFn } from "../../../../queries/queryFunctions/privetQueryFns/patchQueryFns";
import CustomInput from "../../ui/custom-inputs/CustomInput";
import Label from "../../ui/custom-inputs/Label";
import ErrorFormik from "../../ui/ErrorFormik";
import DynamicInputFields from "../../ui/custom-inputs/DynamicInputFields";
import { DAYS_OF_WEEK } from "../../../../utils/constants";
import { addDurationToTime, customTimeStampToDateWithUTC } from "../../../../utils/functions";
import useUser from "../../../../utils/hooks/useUser";

const StepFive = ({ handleNext, handlePrev, ExperienceId, siteInfo }) => {
  const { setIsLoading } = usePublicContext();

  const { redirectTo } = useUser();
  const {
    values,
    errors,
    touched,
    handleBlur,
    handleChange,
    handleSubmit,
    setFieldValue,
    setFieldTouched,
    setValues,
    isValid,
  } = useFormik({
    initialValues: {
      startDateTime: "",
      endDateTime: "",
      days: [],
    },
    enableReinitialize: true,
    validationSchema: addSiteStep5Schema,
    onSubmit: onSubmit,
  });

  const {
    mutate,
    isPending: isLoadingMutate,
    isError: isErrorMutate,
  } = useMutation({
    mutationFn: patchAddSiteStep5QueryFn,
    onSuccess(data) {
      toast.success("The experience was added successfully");
      redirectTo("/your-experiences");
    },
    onError(error) {
      console.error("error Site Step 5: ", error);
      toast.error("Something went wrong, please try later!");
    },
  });

  useEffect(() => {
    const days = siteInfo?.site?.schedule?.days?.map((item1) => {
      return {
        ...item1,
        slots: item1.slots.map((item2) => ({
          startTime: customTimeStampToDateWithUTC(Number(item2.startTime), "HH:mm"),
          endTime: addDurationToTime(customTimeStampToDateWithUTC(Number(item2.startTime), "HH:mm"), siteInfo?.site?.duration),
        })),
      };
    });

    setValues({
      startDateTime: siteInfo?.site?.schedule?.startDateTime
        ? customTimeStampToDateWithUTC(
            siteInfo?.site?.schedule?.startDateTime,
            "YYYY-MM-DD",
          )
        : "",
      endDateTime: siteInfo?.site?.schedule?.endDateTime
        ? customTimeStampToDateWithUTC(
            siteInfo?.site?.schedule?.endDateTime,
            "YYYY-MM-DD",
          )
        : "",
      days: days?.length > 0 ? days : [],
    });
  }, [siteInfo]);

  useEffect(() => {
    setIsLoading(isLoadingMutate);
  }, [isLoadingMutate]);

  const handleSundayChange = (dayName, value) => {
    const newSlots = values?.days?.map((item) => {
      if (item.name === dayName) {
        return {
          ...item,
          slots: value.map((item) => ({
            ...item,
            endTime: addDurationToTime(item.startTime, siteInfo?.site?.duration),
          })),
        };
      } else {
        return item;
      }
    });
    setFieldValue("days", newSlots);
  };

  const handleCheckbox = (dayName, value) => {
    if (value) {
      setFieldValue("days", [
        ...values.days,
        {
          name: dayName,
          slots: [
            {
              startTime: "08:00",
              endTime: addDurationToTime("08:00", siteInfo?.site?.duration),
            },
          ],
        },
      ]);
    } else {
      setFieldValue(
        "days",
        values.days.filter((item) => item.name !== dayName),
      );
    }
  };

  function onSubmit() {
    const days = values?.days?.map((item) => {
      return {
        ...item,
        slots: item.slots.map((item) => ({
          startTime: item.startTime,
          duration: Number(item.duration),
        })),
      };
    });

    mutate({
      id: ExperienceId,

      startDateTime: new Date(values?.startDateTime).valueOf(),
      endDateTime: new Date(values?.endDateTime).valueOf(),
      days: days,
    });
  }
  
  const handleErrors = async () => {
    const err = Object.values(errors);
    if (err.length > 0) {
      toast.error(err[0]);
    }
  }; 

  return (
    <form onSubmit={handleSubmit}>
      <div className='row justify-content-center'>
        <div className='col-sm-4 mb-2'>
          <Label forId='startDateTime' text='Start Date' isRequired={true} />
          <CustomInput
            withFocus={true}
            id='startDateTime'
            name='startDateTime'
            type='date'
            value={values?.startDateTime}
            onChange={handleChange}
            onBlur={handleBlur}
          />
          <ErrorFormik
            isError={errors?.startDateTime}
            isTouched={touched?.startDateTime}
            error={errors?.startDateTime}
          />
        </div>
        <div className='col-sm-4 mb-2'>
          <Label forId='endDateTime' text='End Date' isRequired={true} />
          <CustomInput
            withFocus={true}
            id='endDateTime'
            name='endDateTime'
            type='date'
            value={values?.endDateTime}
            onChange={handleChange}
            onBlur={handleBlur}
          />
          <ErrorFormik
            isError={errors?.endDateTime}
            isTouched={touched?.endDateTime}
            error={errors?.endDateTime}
          />
        </div>
      </div>
      {DAYS_OF_WEEK?.map((day, index) => {
        return (
          <div key={day}>
            <SlotDay
              handleCheckbox={handleCheckbox}
              values={values}
              handleSundayChange={handleSundayChange}
              errors={errors}
              touched={touched}
              dayName={day}
              siteInfo={siteInfo}
            />
          </div>
        );
      })}
      <ErrorFormik
        isError={errors?.days && typeof errors?.days === "string"}
        isTouched={touched?.days}
        error={errors?.days}
      />
      <Hr />
      {/* Buttons */}
      <div className='text-end toolbar toolbar-bottom ps-2'>
        <button
          type='button'
          className='btn btn-secondary sw-btn-prev me-1'
          onClick={() => handlePrev()}
        >
          Prev
        </button>
        <button type='submit' onClick={handleErrors} className='btn btn-primary sw-btn-next ms-1'>
          Next
        </button>
      </div>
    </form>
  );
};

export default StepFive;

function SlotDay({
  handleCheckbox,
  values,
  handleSundayChange,
  errors,
  touched,
  dayName,
  siteInfo
}) {
  const isChecked = values?.days?.some((item) => item.name === dayName);
  const dayIndex = values?.days?.findIndex((day) => day.name === dayName);
  const val = values?.days?.[dayIndex]?.slots || [];
  
  return (
    <>
      <Hr />
      <div className='row'>
        <div className='col-sm-4 mb-2'>
          <div className='form-check custom-checkbox check-lg'>
            <input
              checked={isChecked}
              type='checkbox'
              className='form-check-input me-1 mt-2'
              id={dayName}
              onChange={(e) => handleCheckbox(dayName, e.target.checked)}
            />
            <label
              className='form-check-label unselectable font-w600 h3'
              htmlFor={dayName}
            >
              {dayName}
            </label>
          </div>
        </div>
        <div>
          {isChecked && (
            <DynamicInputFields
              inputFields={[
                {
                  id: "startTime",
                  name: "startTime",
                  label: "Start Time",
                  type: "time",
                  isRequired: true,
                  defaultValue: "08:00"
                },
                {
                  id: "endTime",
                  name: "endTime",
                  label: "End Time",
                  type: "time",
                  isRequired: true,
                  isDisable: true,
                  defaultValue: addDurationToTime("08:00", siteInfo?.site?.duration),
                },
              ]}
              textButton='Add Time Slot'
              fieldName='slots'
              onChange={(value) => handleSundayChange(dayName, value)}
              val={val}
              errors={errors?.days?.[dayIndex]}
              touched={touched?.days?.[dayIndex]}
            />
          )}
        </div>
      </div>
    </>
  );
}
