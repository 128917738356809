import { Dropdown } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";

const DropdownBlog = ({
  experienceId,
  handleArchive,
  handleDelete,
  handleEdit,
  handleDraft,
  handlePublish,
  showArchiveOption,
  showDraftOption,
  showPublishOption,
  editLink,
  showDeleteOption,

  editText = "Edit",
  archiveText = "Archive",
  deleteText = "Delete",
  draftText = "Draft",
  publishText = "Publish",
}) => {
  const navigate = useNavigate();
  const handleEditClick = () => {
    if (editLink) {
      // If editLink is provided, navigate to the specified link
      navigate(editLink);
    } else {
      // Otherwise, invoke the handleEdit function provided by the parent component
      handleEdit();
    }
  };

  return (
    <>
      <Dropdown className='dropdown'>
        <Dropdown.Toggle
          as='div'
          className='btn-link i-false'
          data-bs-toggle='dropdown'
          aria-expanded='false'
        >
          <svg
            width='24'
            height='24'
            viewBox='0 0 24 24'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
          >
            <path
              d='M11 12C11 12.5523 11.4477 13 12 13C12.5523 13 13 12.5523 13 12C13 11.4477 12.5523 11 12 11C11.4477 11 11 11.4477 11 12Z'
              stroke='#262626'
              strokeWidth='2'
              strokeLinecap='round'
              strokeLinejoin='round'
            />
            <path
              d='M18 12C18 12.5523 18.4477 13 19 13C19.5523 13 20 12.5523 20 12C20 11.4477 19.5523 11 19 11C18.4477 11 18 11.4477 18 12Z'
              stroke='#262626'
              strokeWidth='2'
              strokeLinecap='round'
              strokeLinejoin='round'
            />
            <path
              d='M4 12C4 12.5523 4.44772 13 5 13C5.55228 13 6 12.5523 6 12C6 11.4477 5.55228 11 5 11C4.44772 11 4 11.4477 4 12Z'
              stroke='#262626'
              strokeWidth='2'
              strokeLinecap='round'
              strokeLinejoin='round'
            />
          </svg>
        </Dropdown.Toggle>
        <Dropdown.Menu className='dropdown-menu'>
          {editLink ? ( // If editLink is provided, render the Link option
            <Dropdown.Item className='dropdown-item' onClick={handleEditClick}>
              {editText}
            </Dropdown.Item>
          ) : (
            <Dropdown.Item className='dropdown-item' onClick={handleEditClick}>
              {editText}
            </Dropdown.Item>
          )}
          {showArchiveOption && (
            <Dropdown.Item
              className='dropdown-item'
              onClick={() => handleArchive(experienceId)}
            >
              {archiveText}
            </Dropdown.Item>
          )}
          {showDeleteOption && (
            <Dropdown.Item
              className='dropdown-item'
              onClick={() => handleDelete(experienceId)}
            >
              {deleteText}
            </Dropdown.Item>
          )}
          {showDraftOption && (
            <Dropdown.Item
              className='dropdown-item'
              onClick={() => handleDraft(experienceId)}
            >
              {draftText}
            </Dropdown.Item>
          )}
          {showPublishOption && (
            <Dropdown.Item
              className='dropdown-item'
              onClick={() => handlePublish(experienceId)}
            >
              {publishText}
            </Dropdown.Item>
          )}
        </Dropdown.Menu>
      </Dropdown>
    </>
  );
};

export default DropdownBlog;
