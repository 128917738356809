/* global google */


import {
  GoogleMap,
  Marker,
  StandaloneSearchBox,
  useLoadScript,
} from "@react-google-maps/api";
import { useEffect, useRef, useState } from "react";

const libraries = ["places", "geometry"];

export default function GoogleMaps({
  onClick,
  defaultValue,
  mapContainerStyle = {
    width: "100%",
    height: "400px",
  },
}) {
  const mapRef = useRef(null);
  const searchBoxRef = useRef(null);

  const [marker, setMarker] = useState(null);
  const [googleLocationLink, setGoogleLocationLink] = useState("");
  const [center, setCenter] = useState({
    lat: 31.963158,
    lng: 35.930359,
  });

  const { isLoaded } = useLoadScript({
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY || "",
    libraries,
  });

  useEffect(() => {
    if (defaultValue && defaultValue.lat && defaultValue.lng) {
      setMarker(defaultValue);
      setCenter(defaultValue);
    } else {
      setCenter({
        lat: 31.963158,
        lng: 35.930359,
      });
    }
  }, [defaultValue]);
  

  const handleMapClick = (event) => {
    const latLng = {
      lat: event.latLng.lat(),
      lng: event.latLng.lng(),
    };
    setMarker(latLng);
    onClick({
      type: "Point",
      coordinates: [latLng.lat, latLng.lng],
      area: `https://www.google.com/maps/search/?api=1&query=${latLng.lat},${latLng.lng}&radius=1000`,
    });
  };

  const handleSearchBoxLoad = (searchBox) => {
    searchBoxRef.current = searchBox;
  };

  const handleMapLoad = (map) => {
    mapRef.current = map;
  };

  const onPlacesChanged = () => {
    const searchBox = searchBoxRef.current

    if (!searchBox) return

    const places = searchBox.getPlaces()
    if (!places || places.length === 0) return

    const lat = places[0].geometry?.location?.lat()
    const lng = places[0].geometry?.location?.lng()

    // Set the coordinates state
    if (lat && lng) {
      setCenter({ lat, lng })
    }
  }

  return (
    <section>
      <div className='form-group mb-3'>
        <label className='mb-1' htmlFor='val-country'>
          <strong>Search for places</strong>
        </label>
        <StandaloneSearchBox
          onLoad={handleSearchBoxLoad}
          onPlacesChanged={onPlacesChanged}
        >
          <input
            type='text'
            placeholder='Search for places'
            style={{
              boxSizing: `border-box`,
              border: `1px solid transparent`,
              width: `240px`,
              height: `32px`,
              padding: `0 12px`,
              borderRadius: `3px`,
              boxShadow: `0 2px 6px rgba(0, 0, 0, 0.3)`,
              fontSize: `14px`,
              outline: `none`,
              textOverflow: `ellipsis`,
              marginRight: "10px",
              fontFamily: "inherit",
              fontWeight: "inherit",
              color: "#333",
              backgroundColor: "#fff",
              border: "1px solid #ddd",
              borderRadius: "4px",
              padding: "6px 12px",
              width: "100%",
              boxSizing: "border-box",
              marginTop: "5px",
            }}
          />
        </StandaloneSearchBox>
      </div>
      {isLoaded && (
        <div>
          <GoogleMap
            mapContainerStyle={mapContainerStyle}
            center={center}
            zoom={8}
            onClick={handleMapClick}
            onLoad={handleMapLoad}
          >
            {marker && <Marker position={marker} />}
          </GoogleMap>
        </div>
      )}
    </section>
  );
}
