import styles from "./styles.module.scss";


const ReactThemeToggleButton = ({
  isDark,
  onChange,
}) => (
  // eslint-disable-next-line jsx-a11y/label-has-associated-control
  <label
    className={`${styles.container} ${isDark ?styles.IsDark : styles.IsLight }`}
    title={isDark ? "Activate light mode" : "Activate dark mode"}
    aria-label={isDark ?  "Activate light mode" : "Activate dark mode"}
  >
    <input
      type="checkbox"
      onChange={onChange}
    />
    <div />
  </label>
);

export default ReactThemeToggleButton;
