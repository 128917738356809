import React, { Fragment, useContext, useEffect, useState } from "react";
import { Stepper, Step } from "react-form-stepper";
import StepOne from "./StepOne";
import StepTwo from "./StepTwo";
import StepThree from "./StepThree";
import StepFour from "./StepFour";
import StepFive from "./StepFive";
import StepSix from "./StepSix";
import { useSearchParams } from "react-router-dom";
import { useQuery } from "@tanstack/react-query";
import { getSite } from "../../../../queries/queryFunctions/privetQueryFns/getQueryFns";
import Loader from "../../ui/Loader";
import { toast } from "react-toastify";
import { PLACE_TYPE_OPTIONS } from "../../../../utils/constants";
import { ThemeContext } from "../../../../context/ThemeContext";

const AddExperienceComponent = () => {
  const { background } = useContext(ThemeContext);

  const [searchParams, setSearchParams] = useSearchParams();
  const experienceId = searchParams.get("id");
  const step = parseInt(searchParams.get("step")) || 1;

  const [goSteps, setGoSteps] = useState(1);

  const {
    data: siteInfo,
    isPending: isLoadingSiteInfo,
    isError: isErrorSiteInfo,
    fetchStatus: fetchStatusSiteInfo,
    refetch: refetchSiteInfo,
  } = useQuery({
    queryKey: [
      "site-info",
      { id: experienceId, path: window.location.pathname, goSteps },
    ],
    queryFn: getSite,
    enabled: !!experienceId,
    retry: 0,
    staleTime: 0,
  });

  useEffect(() => {
    if (!experienceId) {
      refetchSiteInfo({ enabled: false });
    }
  }, [experienceId, refetchSiteInfo]);

  useEffect(() => {
    if (siteInfo && step > siteInfo.site.site_setup_status) {
      setSearchParams({
        id: experienceId,
        step: siteInfo.site.site_setup_status,
      });
    } else if (step !== goSteps) {
      setGoSteps(step);
    }
  }, [step, siteInfo, searchParams, setSearchParams, goSteps]);

  const handleNext = () => {
    if (goSteps < 6) {
      setGoSteps((prevStep) => prevStep + 1);
      setSearchParams({ id: experienceId, step: goSteps + 1 });
    }
  };

  const handlePrev = () => {
    if (goSteps > 1) {
      setGoSteps((prevStep) => prevStep - 1);
      setSearchParams({ id: experienceId, step: goSteps - 1 });
    }
  };

  function chooseStep(number) {
    if (siteInfo && number <= siteInfo.site.site_setup_status) {
      setSearchParams({
        id: experienceId,
        step: number,
      });
    }

  }
  if (isLoadingSiteInfo) {
    return <Loader />;
  } else if (isErrorSiteInfo && experienceId) {
    toast.error("Some thing went wrong, please try later!");
    return;
  }

  return (
    <Fragment>
      <div className='row'>
        <div className='col-xl-12 col-xxl-12'>
          <div className='card'>
            <div className='card-header'>
              <h4 className='card-title'>
                {window.location.pathname === "/edit-experience"
                  ? `Edit Experience - ${siteInfo?.site?.name}`
                  : `Add Experience ${siteInfo?.site?.name ? `- ${siteInfo?.site?.name}` : ""}`}
              </h4>
            </div>
            <div className='card-body'>
              <div className='form-wizard '>
                <Stepper
                  className='nav-wizard' activeStep={goSteps - 1} nonLinear={window.location.pathname === "/edit-experience" &&
                    ((siteInfo?.site?.type === PLACE_TYPE_OPTIONS?.[2].value && siteInfo?.site?.site_setup_status > 5) ||
                      (siteInfo?.site?.type === PLACE_TYPE_OPTIONS?.[2].value && siteInfo?.site?.site_setup_status > 6))
                  }
                >
                  <Step
                    label='Basic Information'
                    className={`nav-link ${background.value === "dark" ? "dark" : ""}`}
                    onClick={() => chooseStep(1)}
                    completed={siteInfo?.site?.site_setup_status > 1 && goSteps !== 1}
                    
                  />
                  <Step
                    label='Co-host Information'
                    className={`nav-link ${background.value === "dark" ? "dark" : ""}`}
                    onClick={() => chooseStep(2)}
                    completed={siteInfo?.site?.site_setup_status > 2 && goSteps !== 2}

                  />
                  <Step
                    label='Media'
                    className={`nav-link ${background.value === "dark" ? "dark" : ""}`}
                    onClick={() => chooseStep(3)}
                    completed={siteInfo?.site?.site_setup_status > 3 && goSteps !== 3}
                  />
                  <Step
                    label='Booking Info'
                    className={`nav-link ${background.value === "dark" ? "dark" : ""}`}
                    onClick={() => chooseStep(4)}
                    completed={siteInfo?.site?.site_setup_status > 4 && goSteps !== 4}
                  />
                  {siteInfo?.site?.type === PLACE_TYPE_OPTIONS?.[2].value && (
                    <Step
                      label='Itinerary Details (Packages and offers)'
                      className={`nav-link ${background.value === "dark" ? "dark" : ""}`}
                      onClick={() => chooseStep(5)}
                      completed={siteInfo?.site?.site_setup_status > 5 && goSteps !== 5}
                    />
                  )}
                  <Step
                    label='Availability'
                    className={`nav-link ${background.value === "dark" ? "dark" : ""}`}
                    onClick={() => chooseStep(siteInfo?.site?.type === PLACE_TYPE_OPTIONS?.[2].value ? 6 : 5)}
                    completed={siteInfo?.site?.type === PLACE_TYPE_OPTIONS?.[2].value ?
                      siteInfo?.site?.site_setup_status > 6 && goSteps !== 6
                      :
                      siteInfo?.site?.site_setup_status > 5 && goSteps !== 5}
                  />
                </Stepper>
                {goSteps === 1 && (
                  <StepOne
                    handleNext={handleNext}
                    ExperienceId={experienceId}
                    siteInfo={siteInfo}
                  />
                )}
                {goSteps === 2 && (
                  <>
                    <StepTwo
                      handleNext={handleNext}
                      handlePrev={handlePrev}
                      ExperienceId={experienceId}
                      siteInfo={siteInfo}
                    />
                  </>
                )}
                {goSteps === 3 && (
                  <StepThree
                    handleNext={handleNext}
                    handlePrev={handlePrev}
                    ExperienceId={experienceId}
                    siteInfo={siteInfo}
                  />
                )}
                {goSteps === 4 && (
                  <>
                    <StepFour
                      handleNext={handleNext}
                      handlePrev={handlePrev}
                      ExperienceId={experienceId}
                      siteInfo={siteInfo}
                    />
                  </>
                )}
                {(goSteps === 5) && siteInfo?.site?.type === PLACE_TYPE_OPTIONS?.[2].value ? (
                  <>
                    <StepSix
                      handleNext={handleNext}
                      handlePrev={handlePrev}
                      ExperienceId={experienceId}
                      siteInfo={siteInfo}
                    />
                  </>
                ) : (goSteps === 5 || goSteps === 6) && (
                  <>
                    <StepFive
                      handleNext={handleNext}
                      handlePrev={handlePrev}
                      ExperienceId={experienceId}
                      siteInfo={siteInfo}
                    />
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default AddExperienceComponent;
