import { AppleLoginButton } from "react-social-login-buttons";
import { toast } from "react-toastify";
import { SOCIAL_MEDIA_TYPE } from "../../../../utils/constants";
import { signInWithApple } from "../../../../Firebase";

export default function AppleLogin({ handleClick, text }) {
  const onClick = async () => {
    try {
      const res = await signInWithApple();

      const dataMutate = {
        firstName: res?._tokenResponse?.firstName || "",
        lastName: res?._tokenResponse?.lastName || "",
        // if the Apple Email is private don't put it in the payload 
        ...(res?._tokenResponse?.email &&
          !(
            res?._tokenResponse?.email.includes("@privaterelay.appleid.com") ||
            res?._tokenResponse?.email.includes("@icloud.com")
          ) 
          && { email: res?._tokenResponse?.email }),
        profileImageUrl: res?._tokenResponse?.photoUrl || "",
        socialMediaId: res?._tokenResponse?.localId || "",
        socialLoginType: {
          [SOCIAL_MEDIA_TYPE.Apple]: SOCIAL_MEDIA_TYPE.Apple,
        },
        tokens: res?._tokenResponse?.localId || "",
      };

      handleClick(dataMutate);
    } catch (error) {
      console.error("error signInWithApple : ", error);
      toast.error("Something went wrong, please try again later!");
    }
  };

  return (
    <div>
      <AppleLoginButton
        text={text}
        onClick={onClick}
        style={{
          borderRadius: "0.8rem",
          margin: 0,
          width: "100%",
        }}
      />
    </div>
  );
}
