import React, { useEffect, useState } from "react";
import { Button } from "react-bootstrap";
import { useMutation, useQuery } from "@tanstack/react-query";
import { usePublicContext } from "../../../../../providers/ReactPublicContextProvider";
import { useFormik } from "formik";
import { toast } from "react-toastify";
import SlideOver from "../../../../layouts/SlideOver";
import ErrorFormik from "../../../ui/ErrorFormik";
import CustomInput from "../../../ui/custom-inputs/CustomInput";
import Label from "../../../ui/custom-inputs/Label";
import { postFAQsQueryFn } from "../../../../../queries/queryFunctions/privetQueryFns/postQueryFns";
import { addFAQSchema } from "../../../../../utils/forms-schemas";
import DropdownBlog from "../../../ui/DropdownBlog";
import { patchFaqQueryFn } from "../../../../../queries/queryFunctions/privetQueryFns/patchQueryFns";
import { deleteQuestionQueryFn } from "../../../../../queries/queryFunctions/privetQueryFns/deleteQueryFns";
import { getFAQs } from "../../../../../queries/queryFunctions/privetQueryFns/getQueryFns";
import CustomEditor from "../../../ui/custom-inputs/CustomEditor";
import Pagination from "../../../ui/Pagination";
import CustomModal from "../../../ui/Modal";

const FAQManagementComponent = () => {
  const { setIsLoading } = usePublicContext();
  const [activePage, setActivePage] = useState(0);
  const [isSlideOverOpen, setIsSlideOverOpen] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);
  const [showAlert, setShowAlert] = useState(false);
  const [questionIdToDelete, setQuestionIdToDelete] = useState(null);
  const [pageSize, setPageSize] = useState(10);
  const [limit, setLimit] = useState(10);

  const {
    values,
    errors,
    touched,
    handleBlur,
    handleChange,
    handleSubmit,
    setFieldValue,
    setFieldTouched,
    isValid,
    resetForm,
  } = useFormik({
    initialValues: {
      questionEn: "",
      questionAr: "",
      answerEn: "",
      answerAr: "",
    },
    validationSchema: addFAQSchema,
    onSubmit: onSubmit,
  });

  const {
    data: FAQData,
    isPending: isLoadingFAQData,
    isError: isErrorFaqData,
    refetch,
  } = useQuery({
    queryKey: ["faq", limit],
    queryFn: getFAQs,
  });

  const {
    mutate: addMutation,
    isPending: isLoadingMutate,
    isError: isErrorMutate,
  } = useMutation({
    mutationFn: postFAQsQueryFn,
    onSuccess(data) {
      toast.success("Question Added Successfully");
      refetch();
    },
    onError(error) {
      toast.error("Something went wrong, please try later!");
    },
  });

  useEffect(() => {
    setIsLoading(isLoadingFAQData);
  }, [isLoadingFAQData]);

  const {
    mutate: updateMutation,
    isPending: isLoadingUpdateMutation,
    isError: isErrorUpdateMutation,
  } = useMutation({
    mutationFn: patchFaqQueryFn,
    onSuccess(data) {
      toast.success("Question Updated Successfully");
      refetch();
    },
    onError(error) {
      toast.error("Something went wrong, please try later!");
    },
  });

  const {
    mutate: deleteMutation,
    isPending: isLoadingDeleteMutation,
    isError: isErrorDeleteMutation,
  } = useMutation({
    mutationFn: deleteQuestionQueryFn,
    onSuccess(data) {
      toast.success("Question Deleted Successfully");
      refetch();
      setShowAlert(false);
    },
    onError(error) {
      toast.error("Something went wrong, please try later!");
    },
  });

  function onSubmit() {
    if (selectedItem) {
      updateMutation({
        id: selectedItem?._id,
        questionEn: values.questionEn,
        questionAr: values.questionAr,
        answerEn: values.answerEn,
        answerAr: values.answerAr,
      });
    } else {
      addMutation({
        questionEn: values.questionEn,
        questionAr: values.questionAr,
        answerEn: values.answerEn,
        answerAr: values.answerAr,
      });
    
    }
    resetForm();
    setIsSlideOverOpen(false);
  }

  const openSlideOver = () => {
    setIsSlideOverOpen(true);
  };

  const closeSlideOver = () => {
    setSelectedItem(null);
    setFieldValue("questionEn", "");
    setFieldValue("questionAr", "");
    setFieldValue("answerEn", "");
    setFieldValue("answerAr", "");
    setIsSlideOverOpen(false);
  };

  const handleDelete = (id) => {
    setQuestionIdToDelete(id);
    setShowAlert(true);
  };

  const handleConfirmDelete = () => {
    deleteMutation(questionIdToDelete);
    setShowAlert(false);
  };

  const handleEdit = (item) => {
    setSelectedItem(item);
    setFieldValue("questionEn", item.questionEn);
    setFieldValue("questionAr", item.questionAr);
    setFieldValue("answerEn", item.answerEn);
    setFieldValue("answerAr", item.answerAr);
    setIsSlideOverOpen(true);
  };

  const handleClick = (pageIndex) => {
    setActivePage(pageIndex);
  };

  const startIndex = activePage * pageSize;
  const endIndex = Math.min(startIndex + pageSize, FAQData?.length);

  const handleChangePageSize = (event) => {
    const newSize = parseInt(event.target.value, 10);
    setLimit(newSize);
    setPageSize(newSize);
  };

  const truncateText = (text, maxLength = 50) => {
    if (text.length <= maxLength) return text;
    return text.substring(0, maxLength) + "...";
  };

  function stripHtmlTags(html) {
    return html.replace(/<[^>]*>?/gm, "");
  }

  return (
    <div
      className='table-scroll-container'
      style={{
        position: "relative",
        overflowX: window.innerWidth > 768 ? "auto" : "hidden",
        overflowY: "hidden",
      }}
    >
      <CustomModal
        title='Warning!'
        description='Deleting this type will remove it from the associated records.'
        isOpen={showAlert}
        setIsOpen={setShowAlert}
        buttonConfirmAction={handleConfirmDelete}
        buttonConfirmText={"Confirm Delete"}
        buttonCloseText='Cancel'
      />
      <div
        className='table-container'
        style={{ marginBottom: "80px", position: "relative" }}
      >
        <div className='table-responsive'>
          <div
            className='guest-calendar'
            style={{ display: "flex", justifyContent: "flex-end" }}
          >
            <Button
              className='me-2'
              variant='secondary'
              onClick={openSlideOver}
            >
              Add
            </Button>
          </div>

          <table className='table card-table display mb-4 shadow-hover default-table .dataTablesCard dataTable no-footer'>
            <thead>
              <tr>
                <th style={{ width: "200px" }} className='sorting_asc'>
                  Question (in English )
                </th>
                <th className='sorting'>Answer (in English )</th>
                <th style={{ width: "200px" }} className='sorting_asc'>
                  Question (in Arabic )
                </th>
                <th className='sorting'>Answer (in Arabic )</th>
                <th className='sorting bg-none'></th>
              </tr>
            </thead>
            <tbody>
              {FAQData &&
                FAQData.map((faq, index) => (
                  <tr key={index} className={index % 2 === 0 ? "even" : "odd"}>
                    <td>
                      <div
                        style={{
                          display: "inline-block",
                          width: "100%",
                          height: "100%",
                          overflow: "hidden",
                          marginTop:"8px",
                          textOverflow: "ellipsis",
                          whiteSpace: "nowrap",
                          color: "black",
                        }}
                        title={faq.questionEn || ""}
                      >
                        {faq.questionEn || ""}
                      </div>
                    </td>
                    <td>
                      <div
                        style={{
                          display: "inline-block",
                          width: "100%",
                          height: "100%",
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                          whiteSpace: "nowrap",
                          color: "black",
                        }}
                        title={stripHtmlTags(faq.answerEn)}
                      >
                        <div
                          dangerouslySetInnerHTML={{
                            __html: truncateText(faq.answerEn),
                          }}
                        />
                      </div>
                    </td>
                    <td>
                      <div
                        style={{
                          display: "inline-block",
                          width: "100%",
                          height: "100%",
                          overflow: "hidden",
                          marginTop:"8px",
                          textOverflow: "ellipsis",
                          whiteSpace: "nowrap",
                          color: "black",
                        }}
                        title={faq.questionAr || ""}
                      >
                        {faq.questionAr || ""}
                      </div>
                    </td>
                    <td>
                      <div
                        style={{
                          display: "inline-block",
                          width: "100%",
                          height: "100%",
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                          whiteSpace: "nowrap",
                          color: "black",
                        }}
                        title={stripHtmlTags(faq.answerAr)}
                        dangerouslySetInnerHTML={{
                          __html: truncateText(faq.answerAr),
                        }}
                      />
                    </td>

                    <td>
                      <div className="d-flex">
                        <DropdownBlog
                          showArchiveOption={false}
                          showDeleteOption={true}
                          showEditOption={true}
                          showLinkOption={false}
                          showPublishOption={false}
                          faqId={faq?._id}
                          handleDelete={() => handleDelete(faq?._id)}
                          handleEdit={() => handleEdit(faq)}
                        />
                      </div>
                    </td>
                  </tr>
                ))}
            </tbody>

          </table>
        </div>
        <SlideOver
          isOpen={isSlideOverOpen}
          onClose={closeSlideOver}
          onSubmit={handleSubmit}
        >
          <div>
            <h4>{selectedItem ? "Edit" : "Add"} Question</h4>
            <section className='row'>
              <div className='col-lg-6'>
                <div className='form-group mb-3'>
                  <Label
                    forId='placeType'
                    text='Question in (English)'
                    isRequired={true}
                  />
                  <CustomInput
                    withFocus={true}
                    placeholder='Question in English'
                    id='questionEn'
                    name='questionEn'
                    type='text'
                    value={values?.questionEn}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                  <ErrorFormik
                    isError={errors?.questionEn}
                    isTouched={touched?.questionEn}
                    error={errors?.questionEn}
                  />
                </div>
              </div>
              <div className='col-lg-6'>
                <div className='form-group mb-3'>
                  <Label
                    forId='questionAr'
                    text='Question in (Arabic)'
                    isRequired={true}
                  />
                  <CustomInput
                    withFocus={true}
                    placeholder='Question in Arabic'
                    id='questionAr'
                    name='questionAr'
                    type='text'
                    value={values?.questionAr}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                  <ErrorFormik
                    isError={errors?.questionAr}
                    isTouched={touched?.questionAr}
                    error={errors?.questionAr}
                  />
                </div>
              </div>
            </section>
            <section className='col-lg-12 my-3'>
              <form onSubmit={handleSubmit}>
                <div className='row'>
                  <div className='col'>
                    <Label
                      forId='answerEn'
                      text='Answer in (English)'
                      isRequired={true}
                    />
                    <CustomEditor
                      id={"answerEn"}
                      value={values?.answerEn}
                      onChange={(value) => {
                        setFieldValue("answerEn", value);
                      }}
                      onBlur={() => {
                        setFieldTouched("answerEn", true);
                        handleBlur("answerEn");
                      }}
                    />
                    <ErrorFormik
                      isError={errors?.answerEn}
                      isTouched={touched?.answerEn}
                      error={errors?.answerEn}
                    />
                  </div>
                  <div className='col'>
                    <Label
                      forId='answerAr'
                      text='Answer in (Arabic)'
                      isRequired={true}
                    />
                    <CustomEditor
                      id={"answerAr"}
                      value={values?.answerAr}
                      onChange={(value) => {
                        setFieldValue("answerAr", value);
                      }}
                      onBlur={() => {
                        setFieldTouched("answerAr", true);
                        handleBlur("answerAr");
                      }}
                    />
                    <ErrorFormik
                      isError={errors?.answerAr}
                      isTouched={touched?.answerAr}
                      error={errors?.answerAr}
                    />
                  </div>
                </div>
              </form>
            </section>
          </div>
        </SlideOver>

        <Pagination
          startIdx={startIndex}
          endIdx={endIndex}
          pageSize={pageSize}
          activePage={activePage}
          handleClick={handleClick}
          handleChangePageSize={handleChangePageSize}
          data={FAQData}
          itemsPerPage={pageSize}
          totalCount={FAQData?.length}
        />
      </div>
    </div>
  );
};

export default FAQManagementComponent;
