import { post } from "../../../configs/api";

export async function postSignInQueryFn(data) {
  const postData = {
    ...data,
    returnSecureToken: true,
  };
  return await post(`/auth/adminLogin`, postData);
}

export async function postSignUpQueryFn(data) {
  const postData = {
    ...data,
    // returnSecureToken: true,
  };
  return await post(`/users/partnerSignUp`, postData);
}

export async function postSignInWithSocialQueryFn(data) {
  return await post(`/auth/partner/socialMediaLogin`, data);
}

export async function postVerificationCodeQueryFn(data) {
  return await post(`/users/verifyCode`, data);
}

export async function postForgetPassword(data) {
  const postData = {
    ...data,
    // returnSecureToken: true,
  };
  return await post(`/users/adminForgetPassword`, postData);
}

export async function postResetPassword(data) {
  const postData = {
    ...data,
    // returnSecureToken: true,
  };
  return await post(`/users/resetPasswordLink`, postData);
}

export async function postLocationQueryFn(locationData) {
  const postData = {
    ...locationData,
  };

  return await post(`/location-endpoint`, postData);
}
