import { usePublicContext } from "../../../../../providers/ReactPublicContextProvider";
import ErrorFormik from "../../../ui/ErrorFormik";
import CustomEditor from "../../../ui/custom-inputs/CustomEditor";
import Label from "../../../ui/custom-inputs/Label";
import { useFormik } from "formik";
import { toast } from "react-toastify";
import { useMutation, useQuery } from "@tanstack/react-query";
import { getPublicTerms } from "../../../../../queries/queryFunctions/publicQueryFns/getQueryFns";
import { patchTermsAndPrivacyQueryFn } from "../../../../../queries/queryFunctions/privetQueryFns/patchQueryFns";
import { useEffect } from "react";

const PrivacyPolicyComponent = () => {
    const { setIsLoading } = usePublicContext();

    const {
        data: privacyPolicyData,
        isPending: isLoadingPrivacyPolicyData,
        isError: isErrorPrivacyPolicyData,
        refetch,
      } = useQuery({
        queryKey: ["privacy-policy"],
        queryFn: getPublicTerms,
      });

    const {
        values,
        errors,
        touched,
        handleBlur,
        setFieldValue,
        setFieldTouched,
        setValues,
        handleSubmit,
      } = useFormik({
        initialValues: {  
          privacyPolicyEn: "",
          privacyPolicyAr: ""
        },
        enableReinitialize: true,
        onSubmit: onSubmit,
      });

      function onSubmit() {
          updateMutation({
            id: privacyPolicyData?._id,
            privacyPolicyEn: values.privacyPolicyEn,
            privacyPolicyAr: values.privacyPolicyA
          });
      }
    
    
      const {
        mutate: updateMutation, 
        isPending: isLoadingUpdateMutation,
        isError: isErrorUpdateMutation,
      } = useMutation({
        mutationFn: patchTermsAndPrivacyQueryFn, 
        onSuccess(data) {
          toast.success("Privacy Policy Updated Successfully");
        },
        onError(error) {
          toast.error("Something went wrong, please try later!");
        },
      });

      useEffect(() => {
        if (privacyPolicyData) {
          setValues({
            privacyPolicyEn: privacyPolicyData.privacyPolicyEn || "",
            privacyPolicyAr: privacyPolicyData.privacyPolicyAr || ""
          });
        }
      }, [privacyPolicyData]);

    return (
        <>
         <section className='col-lg-12 my-3'>
          <form onSubmit={handleSubmit}> 
            <div className="row">
              <div className="col">
                <Label
                  forId='privacyPolicyEn'
                  text='Privacy Policy (English)'
                  isRequired={true}
                />
                <CustomEditor
                  id={"privacyPolicyEn"}
                  value={values?.privacyPolicyEn}
                  onChange={(value) => {
                    setFieldValue("privacyPolicyEn", value);
                  }}
                  onBlur={() => {
                    setFieldTouched("privacyPolicyEn", true);
                    handleBlur("privacyPolicyEn");
                  }}
                />
                <ErrorFormik
                  isError={errors?.privacyPolicyEn}
                  isTouched={touched?.privacyPolicyEn}
                  error={errors?.privacyPolicyEn}
                />
              </div>
              <div className="col">
                <Label
                  forId='privacyPolicyAr'
                  text='Privacy Policy (Arabic)'
                  isRequired={true}
                />
                <CustomEditor
                  id={"privacyPolicyAr"}
                  value={values?.privacyPolicyAr}
                  onChange={(value) => {
                    setFieldValue("privacyPolicyAr", value);
                  }}
                  onBlur={() => {
                    setFieldTouched("privacyPolicyAr", true);
                    handleBlur("privacyPolicyAr");
                  }}
                />
                <ErrorFormik
                  isError={errors?.privacyPolicyAr}
                  isTouched={touched?.privacyPolicyAr}
                  error={errors?.privacyPolicyAr}
                />
              </div>
            </div>
            <button type='submit' className='btn btn-primary sw-btn-next ms-1' style={{ marginTop: '10px' }}>
              Submit
            </button>
          </form>
        </section>
      </>
    )
}

export default PrivacyPolicyComponent;
