import React, { useEffect, useState } from "react";
import { useMutation, useQuery} from "@tanstack/react-query";
import { useFormik } from "formik";
import { toast } from "react-toastify";
import { EditUserInfoSchema } from "../../../../utils/forms-schemas";
import { postUploadImageQueryFn } from "../../../../queries/queryFunctions/privetQueryFns/postQueryFns";
import { ACCEPT_IMAGES_TYPES_OBJECT, MAX_SIZE_IMAGE, S3_FILES_NAME, USER_DETAILS } from "../../../../utils/constants";
import FileDropzone from "../../ui/FileDropzone";
import Label from "../../ui/custom-inputs/Label";
import ErrorFormik from "../../ui/ErrorFormik";
import { usePublicContext } from "../../../../providers/ReactPublicContextProvider";
import CustomInput from "../../ui/custom-inputs/CustomInput";
import MultiSelect from "../../ui/custom-inputs/select-inputs/MultiSelect";
import { getLanguageList } from "../../../../queries/queryFunctions/publicQueryFns/getQueryFns";
import Hr from "../../ui/Hr";
import { patchEditUserInfoQueryFn } from "../../../../queries/queryFunctions/privetQueryFns/patchQueryFns";
import PhoneInput from "react-phone-number-input";
import { useSearchParams } from "react-router-dom";
import { getUsersDetails } from "../../../../queries/queryFunctions/privetQueryFns/getQueryFns";
import useUser from "../../../../utils/hooks/useUser";


export default function Profile() {
    const { setIsLoading, setUserDataUpdated } = usePublicContext();
    const [searchParams, setSearchParams] = useSearchParams();
    const userId = searchParams.get("id");
    const [spokenLanguagesList, setSpokenLanguagesList] = useState([]);
    const [profileImageUrl, setProfileImageUrl] = useState("https://placehold.co/600x400?text=Host Photo",);
    const { setUserData } = useUser();

    const {
      data: languageData,
      isPending: isLoadingLanguageData,
      isError: isErrorLanguageData,
    } = useQuery({
      queryKey: ["getLanguageList"],
      queryFn: getLanguageList,
    });

    const { data, isLoading, isError, error, refetch } = useQuery({
      queryKey: ["usersDetails", { id: userId }],
      queryFn: getUsersDetails,
    });

  
    const {
      values,
      errors,
      touched,
      handleBlur,
      handleChange,
      handleSubmit,
      setFieldValue,
      setValues,
      isValid,
    } = useFormik({
      initialValues: {
        firstName: "",
        lastName: "",
        profileImageUrl: "",
        phoneNumber: "",
        spokenLanguages: [],
        description: "",
      },
      enableReinitialize: true,
      validationSchema: EditUserInfoSchema,
      onSubmit: onSubmit,
    });
  
    const {
      mutate,
      isPending: isLoadingMutate,
      isError: isErrorMutate,
    } = useMutation({
      mutationFn: patchEditUserInfoQueryFn,
      onSuccess(data) {
        const storedUserData = localStorage.getItem(USER_DETAILS);
        const userData = storedUserData ? JSON.parse(storedUserData) : {}; 
        
        const updatedUserData = {
          ...userData, 
          firstName: data?.firstName,
          lastName: data?.lastName,
          profileImageUrl: data?.profileImageUrl,
          phoneNumber: data?.phoneNumber,
          description: data?.description,
          languages: data?.languages
        };
        
        setUserData(updatedUserData);
        setUserDataUpdated(updatedUserData)
        toast.success('Your Info has been updated successfully');
      },
      onError(error) {
        toast.error(error.response.data.message);
      },
    });
  
    const {
      mutate: mutateUploadImage,
      isPending: isLoadingUploadImage,
      isError: IsErrorUploadImage,
      data: dataUploadImage,
      error: errorUploadImage,
    } = useMutation({
      mutationFn: postUploadImageQueryFn,
      onSuccess(data) {
        setProfileImageUrl(data);
        setFieldValue("profileImageUrl", data);
        toast.success("Upload Profile Image successfully");
      },
      onError(error) {
        toast.error(error?.response?.data?.message);
      },
    });
  
 
  useEffect(() => {
    if (data) {
      setValues({
        firstName: data?.firstName || "",
        lastName: data?.lastName || "",
        profileImageUrl: data?.profileImageUrl || "",
        phoneNumber: data?.phoneNumber || "",
        description: data?.description || "",
        spokenLanguages: (data.languages || []).map((language) => ({
          label: language?.nameEn || language,
          value:  language?._id,
        })),
      });
    }
  }, [data]);
  
    useEffect(() => {
      setIsLoading(isLoadingMutate || isLoadingUploadImage);
    }, [isLoadingMutate, isLoadingUploadImage]);
  
    useEffect(() => {
      let res = (languageData || [])
        .filter((item) => item?.nameEn && item?._id)
        .map((item) => ({
          label: item?.nameEn || "",
          value: item?._id,
        }));
      setSpokenLanguagesList(res);
    }, [languageData]);
  
    async function onImageUpload() {
      if (profileImageUrl?.length && typeof profileImageUrl === "object") {
        mutateUploadImage({
          file: profileImageUrl[0],
          type: S3_FILES_NAME.PROFILE,
        });
      }
    }
  
    useEffect(() => {
      onImageUpload();
    }, [profileImageUrl]);
  
    function onSubmit() {
      mutate({
        id: userId,
        firstName: values?.firstName,
        lastName: values?.lastName,
        profileImageUrl: values?.profileImageUrl,
        phoneNumber: values?.phoneNumber,
        description: values?.description,
        languages: values?.spokenLanguages?.map((item) => item?.value),
      });
    }

    return (
      <form onSubmit={handleSubmit}>
        <h4 className=''>Profile Photo Upload</h4>
           <Hr />
           {/* profile Image */}
           <section>
          <Label
            forId='profileImageUrl'
            text='Profile Image'
          />
          <div className='w-100 d-flex flex-column gap-3'>
            <img
              style={{ width: "250px", height: "250px", objectFit: "cover" }}
              className='img-fluid img-thumbnail rounded'
              src={values?.profileImageUrl || "https://placehold.co/250x250"}
              alt='profileImageUrl'
            />
            <FileDropzone
              name={"profileImageUrl"}
              setState={setProfileImageUrl}
              value={false}
              filesAccept={ACCEPT_IMAGES_TYPES_OBJECT}
              maxSize={MAX_SIZE_IMAGE}
            />
            <ErrorFormik
              isError={errors?.profileImageUrl}
              isTouched={touched?.profileImageUrl}
              error={errors?.profileImageUrl}
            />
          </div>
        </section>
        {/* First Name & Last Name */}
        <section className='row'  style={{ marginTop: '20px' }}>
        <h4 className="">Edit First and Last Name</h4>
          <Hr/>
          {/* First Name  */}
          <div className='col-lg-6'>
            <div className='form-group mb-3'>
              <Label forId='firstName' text='First Name'  />
              <CustomInput
                withFocus={true}
                placeholder='First Name'
                id='firstName'
                name='firstName'
                type='text'
                value={values?.firstName}
                onChange={handleChange}
                onBlur={handleBlur}
              />
              <ErrorFormik
                isError={errors?.firstName}
                isTouched={touched?.firstName}
                error={errors?.firstName}
              />
            </div>
          </div>
          {/* Last Name */}
          <div className='col-lg-6'>
            <div className='form-group mb-3'>
              <Label forId='lastName' text='Last Name'  />
              <CustomInput
                withFocus={true}
                placeholder='Last Name'
                id='lastName'
                name='lastName'
                type='text'
                value={values?.lastName}
                onChange={handleChange}
                onBlur={handleBlur}
              />
              <ErrorFormik
                isError={errors?.lastName}
                isTouched={touched?.lastName}
                error={errors?.lastName}
              />
            </div>
          </div>
        </section>
        {/* Spoken Languages */}
        <section className='row'>
        <h4 className=''>Edit Mobile Number and Languages</h4>
           <Hr />
          <div className='col-lg-6'>
            <div className='form-group mb-3'>
              <Label forId='phoneNumber' text='Phone Number'  />
              <PhoneInput
                  className='form-control'
                  international
                  defaultCountry='JO'
                  placeholder='Enter phone number'
                  name='phoneNumber'
                  value={values?.phoneNumber}
                  onChange={(val) => setFieldValue("phoneNumber", val)}
                  style={{
                    display: "inline-flex",
                    alignItems: "center",
                    width: "calc(100% - 40px)", 
                    border: "1px solid #ced4da",
                    borderRadius: "0.25rem",
                    padding: "0.375rem 0.75rem",
                    fontSize: "1rem",
                  }}
                />
              <ErrorFormik
                isError={errors?.phoneNumber}
                isTouched={touched?.phoneNumber}
                error={errors?.phoneNumber}
              />
            </div>
          </div>
            <div className='col-lg-6'>
              <div className='form-group mb-3'>
                <Label forId='spokenLanguages' text='Spoken Languages'  />
                <MultiSelect
                    defaultValue={values?.spokenLanguages?.map(
                      (item) => item.label,
                    )}
                    placeholder='Search'
                    isClearable={false}
                    isSearchable={true}
                    isDisabled={false}
                    isLoading={isLoadingLanguageData}
                    isRtl={false}
                    options={spokenLanguagesList}
                    handleChange={(val) => {
                      setFieldValue("spokenLanguages", val);
                    }}
                    handleBlur={() => {
                      handleBlur("spokenLanguages");
                    }}
                    id={"spokenLanguages"}
                    name={"spokenLanguages"}
                  />
                  <ErrorFormik
                    isError={errors?.spokenLanguages}
                    isTouched={touched?.spokenLanguages}
                    error={errors?.spokenLanguages}
                  />
              </div>
            </div>
          </section>
        
        <Hr />
        <section className='row'  style={{ marginTop: '20px' }}>
        <h4 className="">Edit Description</h4>
          <Hr/>
          {/* Description  */}
          <div className='col-lg-6'>
            <div className='form-group mb-3'>
              <Label forId='description' text='Description'  />
              <CustomInput
                withFocus={true}
                placeholder='Description'
                id='description'
                name='description'
                type='text'
                value={values?.description}
                onChange={handleChange}
                onBlur={handleBlur}
              />
              <ErrorFormik
                isError={errors?.description}
                isTouched={touched?.description}
                error={errors?.description}
              />
            </div>
          </div>
        </section>
        <div className='text-end toolbar toolbar-bottom p-2'>
        <button
          type='submit'
          className='btn btn-primary sw-btn-next'
        >
          Submit
        </button>
      </div>
      </form>
      
    );
  
}