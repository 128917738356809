import React, { useEffect, useState } from "react";
import { usePublicContext } from "../../../providers/ReactPublicContextProvider";
import useUser from "../../../utils/hooks/useUser";
import { useFormik } from "formik";
import { useMutation } from "@tanstack/react-query";
import { resetPasswordForgotSchema } from "../../../utils/forms-schemas";
import { toast } from "react-toastify";
import ErrorFormik from "../../components/ui/ErrorFormik";
import {
  postForgetPassword,
  postResetPassword,
} from "../../../queries/queryFunctions/publicQueryFns/postQueryFns";
import { useLocation } from "react-router-dom";

function ResetPassword() {
  const { setIsLoading, isLoading } = usePublicContext();
  // const email = sessionStorage.getItem("email");
  const { search } = useLocation();
  const queryParams = new URLSearchParams(search);
  const token = queryParams.get("token");
  const email = queryParams.get("email");

  const { redirectTo, isAuthenticUser } = useUser();

  const [disabled, setDisabled] = useState(false);
  const [timer, setTimer] = useState(30);

  const { values, errors, touched, handleBlur, handleChange, handleSubmit } =
    useFormik({
      initialValues: {
        newPassword: "",
        confirmNewPassword: "",
        token: token,
      },
      validationSchema: resetPasswordForgotSchema,
      onSubmit: onSubmit,
    });

  const {
    mutate,
    isPending: isLoadingMutate,
    isError: isErrorMutate,
  } = useMutation({
    mutationFn: postResetPassword,
    onSuccess(data) {
      // sessionStorage.removeItem("email", values.email);

      toast.success("Your password has been reset successfully");
      redirectTo("/");
    },
    onError(error) {
      toast.error("Wrong credentials!");
    },
  });

  const {
    mutate: resendCodeMutate,
    isPending: isLoadingResendMutate,
    isError: isErrorResendMutate,
  } = useMutation({
    mutationFn: postForgetPassword,
    onSuccess(data) {
      toast.success("The link has been re-sent successfully");
    },
    onError(error) {
      toast.error("This email address is not registered with us!");
    },
  });

  useEffect(() => {
    if (isAuthenticUser) setIsLoading(true);

    if (isAuthenticUser) {
      redirectTo("/dashboard");
    }
    setIsLoading(false);
  }, [isLoading]);

  useEffect(() => {
    setIsLoading(isLoadingMutate || isLoadingResendMutate);
  }, [isLoadingMutate, isLoadingResendMutate]);

  useEffect(() => {
    let intervalId;
    if (disabled) {
      intervalId = setInterval(() => {
        setTimer((prevTimer) => prevTimer - 1);
      }, 1000);
    }

    return () => {
      clearInterval(intervalId);
    };
  }, [disabled]);

  useEffect(() => {
    if (timer === 0) {
      setDisabled(false);
      setTimer(30);
    }
  }, [timer]);

  const resendCode = () => {
    if (!email) {
      toast.error("Something went wrong, please try again later.");
      return;
    }
    setDisabled(true);
    resendCodeMutate({ email });
  };

  async function onSubmit() {
    mutate({ resetPasswordToken: token, password: values.newPassword });
  }
  return (
    <div className='authincation d-flex flex-column flex-lg-row flex-column-fluid'>
      <div className='container flex-row-fluid d-flex flex-column justify-content-center position-relative overflow-hidden p-7 mx-auto'>
        <div className='d-flex justify-content-center h-100 align-items-center'>
          <div className='authincation-content style-2'>
            <div className='row no-gutters'>
              <div className='col-xl-12 tab-content'>
                <div id='sign-in' className='auth-form   form-validation'>
                  <form onSubmit={handleSubmit} className='form-validate'>
                    <h3 className='text-center mb-4 text-black'>
                      Reset your password
                    </h3>
                    <div className='form-group mb-3'>
                      <label className='mb-1'>
                        <strong>New Password</strong>
                      </label>
                      <div>
                        <input
                          type='password'
                          className='form-control'
                          placeholder='Enter your new password'
                          name='newPassword'
                          value={values?.newPassword}
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />
                      </div>
                      <ErrorFormik
                        isError={errors?.newPassword}
                        isTouched={touched?.newPassword}
                        error={errors?.newPassword}
                      />
                    </div>
                    <div className='form-group mb-3'>
                      <label className='mb-1'>
                        <strong>Confirm Password</strong>
                      </label>
                      <input
                        type='password'
                        className='form-control'
                        placeholder='Confirm your new password'
                        name='confirmNewPassword'
                        value={values?.confirmNewPassword}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                      <ErrorFormik
                        isError={errors?.confirmNewPassword}
                        isTouched={touched?.confirmNewPassword}
                        error={errors?.confirmNewPassword}
                      />
                    </div>

                    <button
                      type='button'
                      onClick={resendCode}
                      className='text-center btn text-primary btn-unstyled p-0 h-0'
                      disabled={disabled}
                    >
                      {disabled ? `Resend link (${timer}s)` : "Resend link"}
                    </button>
                    <div className='text-center form-group mb-3'>
                      <button
                        type='submit'
                        className='btn btn-primary btn-block'
                      >
                        Submit
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ResetPassword;
