import React, { useEffect, useState } from "react";
import BookingStatusBadge from "./svg/BookingStatusBadge";
import useUser from "../../../utils/hooks/useUser";
import { convertTimeStampToDateWithTimezone, customTimeStampToDateWithUTC } from "../../../utils/functions";
import Pagination from "./Pagination";

const BookingListComponent = ({
  data,
  filter,
  setSkip,
  setLimit,
  itemsPerPage,
  searchData,
  handleSearch
}) => {
  const [activePage, setActivePage] = useState(0);
  const [bookings, setBookings] = useState([]);
  const [pageSize, setPageSize] = useState(itemsPerPage);
  const { redirectTo } = useUser();


  useEffect(() => {
    if (data && Array.isArray(data.bookings)) {
      setBookings(data.bookings);
    }
  }, [data, filter]);

  useEffect(() => {
    if (searchData && Array.isArray(searchData.bookings)) {
      setBookings(searchData.bookings);
    }
  }, [searchData]);


  const handleClick = (pageIndex) => {
    setActivePage(pageIndex);
    const newSkip = pageIndex * itemsPerPage;
    const newLimit = itemsPerPage;
    setSkip(newSkip);
    setLimit(newLimit);
    handleSearch(newSkip, newLimit);
  };


  const handleChangePageSize = (event) => {
    const newSize = parseInt(event.target.value, 10);
    const newSkip = activePage * 10;
    setPageSize(newSize);
    setLimit(newSize);
    handleSearch(newSkip, newSize);
  };

  const startIdx = activePage * itemsPerPage;
  const endIdx = Math.min(startIdx + itemsPerPage, bookings.length);

 let totalCount = data?.totalCount || searchData?.totalCount
  
  return (
    <div className='table-container'>
      <div className='table-responsive'>
        <table className='table card-table display mb-4 shadow-hover default-table .dataTablesCard dataTable no-footer'>
          <thead>
            <tr>
              <th className='sorting'>BookingID</th>
              <th className='sorting'>Customer image</th>
              <th className='sorting'>Customer name</th>
              <th className='sorting'>Experience Name</th>
              <th className='sorting'>
                {data && data.type === "stay" ? "Check-in" : "Experience Date"}
              </th>
              <th
                className='sorting'
                colSpan={data && data.type === "stay" ? 2 : 1}
              >
                Booking Date
              </th>
              {data && data.type === "stay" && (
                <th className='sorting'>Check-out</th>
              )}
              <th className='sorting'>Status</th>
              <th className='sorting'>Number of Persons</th>
              <th className='sorting'>Amount</th>
              <th className='sorting'>Payment Method</th>
              <th className='sorting bg-none'></th>
            </tr>
          </thead>
          <tbody>
            {bookings &&
              bookings.map((booking, index) => (
                <tr role="button" onClick={() => redirectTo(`/bookings-details?id=${booking?._id}`)} key={index} className={index % 2 === 0 ? "even" : "odd"}>
                    <td>
                      <div>
                        <h5 className='text-nowrap'>{booking?.code}</h5>
                      </div>
                    </td>
                    <td>
                      <div className='concierge-bx d-flex align-items-center'>
                        <img
                        style={{objectFit: "cover"}}
                          className='me-3 rounded'
                          src={
                            booking?.userId?.profileImageUrl ||
                            "https://placehold.co/40"
                          }
                          alt=''
                        />
                      </div>
                    </td>
                    <td>
                      <div>
                        <h5 className='text-nowrap'>
                          {booking?.userId?.firstName +
                            " " +
                            booking?.userId?.lastName}
                        </h5>
                      </div>
                    </td>
                    <td>
                      <div
                        style={{
                          width: "200px",
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                        }}
                      >
                        <h5 className='fs-16 mb-0 text-nowrap'>
                          <span
                            className='experience-name'
                            style={{
                              display: "inline-block",
                              width: "100%",
                              height: "100%",
                              overflow: "hidden",
                              textOverflow: "ellipsis",
                              whiteSpace: "nowrap",
                            }}
                            title={booking?.siteId?.name || ""}
                          >
                            {booking?.siteId?.name || ""}
                          </span>
                        </h5>
                      </div>
                    </td>
                    <td>
                      <div>
                        <h5 className='text-nowrap'>
                          {customTimeStampToDateWithUTC(booking?.startDateTime, "DD/MM/YYYY HH:mm")}
                        </h5>
                      </div>
                    </td>
                    {data && data.type === "stay" && (
                      <td>
                        <div>
                          <h5 className='text-nowrap'>
                            {customTimeStampToDateWithUTC(booking?.endDateTime, "DD/MM/YYYY HH:mm")}

                          </h5>
                        </div>
                      </td>
                    )}
                    <td>
                      <div>
                        <h5 className='text-nowrap'>
                          {convertTimeStampToDateWithTimezone(booking?.createdAt, "DD/MM/YYYY HH:mm")}
                        </h5>
                      </div>
                    </td>
                    <td>
                      <BookingStatusBadge status={booking?.status} />
                    </td>
                    <td>
                      <div>
                        <h5 className='text-nowrap'>
                          {booking?.guests?.adults +
                            booking?.guests?.children +
                            booking?.guests?.infants}
                        </h5>
                      </div>
                    </td>
                    <td>
                      <div>
                        <h5>{booking?.totalPrice} JOD</h5>
                      </div>
                    </td>
                    <td>
                      <div>
                        <h5 className='text-nowrap'>
                          {booking?.paymentMethod?.name}
                        </h5>
                      </div>
                    </td>
                </tr>
              ))}
          </tbody>
        </table>
      </div>

  <Pagination
      startIdx={startIdx}
      endIdx={endIdx}
      pageSize={pageSize}
      activePage={activePage}
      handleClick={handleClick}
      handleChangePageSize={handleChangePageSize}
      data={data}
      itemsPerPage={itemsPerPage}
      bookings={bookings}
      totalCount={totalCount}
   />
  

  </div>
  );
};

export default BookingListComponent;
