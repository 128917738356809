export const MenuList = [
  {
    title: (
      <>
        Site Content <span style={{ marginLeft: '30px' }}>Management</span>
      </>
    ),    
    classsChange: "mm-collapse",
    iconStyle: <i className='fas fa-clone'></i>,
    
    // content: [
      // {
      //   title: "Your Experiences/Stays",
      //   classsChange: "mm-collapse",
      //   iconStyle: <i className='fas fa-clone'></i>,
        hasMenu:true,
        isNested: true,
        content: [
          {
            title: "Add Experience/Event/Package",
            to: "add-experience",
          },
          {
            title: "Add Stay",
            to: "add-stay",
          },
          {
            title: "Explore",
            to: "your-experiences",
          },
        ],
      },
    
    // ],
  // },

  {
    title: (
      <>
        Broadcast <span style={{ marginLeft: '30px' }}>Notification</span>
      </>
    ),
    isAdminAccess: true,
    classsChange: "mm-collapse",
    iconStyle: <i class="fa fa-bell"></i>,
    content: [
        {
          title: "Send Notifications",
          to: "announcements-notifications",
      },
    
    ],
  },
  {
    title: "Site Settings",
    isAdminAccess: true,
    classsChange: "mm-collapse",
    iconStyle: <i className='fa fa-cog'></i>,
    content: [
      {
        title: "Experience Types",
        to: "experience-types",
      },
      {
        title: "Stay Types",
        to: "stay-types",
      },
      {
        title: "-----------------",
        classsChange: "separator",
      },
      {
        title: "Amenity Management",
        to: "amenities",
      },
      {
        title: "Accessibility Features",
        to: "accessibility-features",
      },
      {
        title: "Booking options",
        to: "booking-options",
      },
      {
        title: "Payment Types",
        to: "payment-type",
      },
      {
        title: "Currency Rates",
        to: "currency-rate",
      },
      // {
      //   title: "Amenities Category",
      //   to: "amenities-category",
      // },   
      {
        title: "Spoken Languages",
        to: "spoken-languages",
      },
      {
        title: "-----------------",
        classsChange: "separator",
      },
      {
        title: "FAQ management",
        to: "FAQ-management",
      },
      {
        title: "Terms and Conditions",
        to: "terms-and-conditions",
      },
      {
        title: "Privacy Policy",
        to: "privacy-policy",
      },  
    ],
  },
  {
    title: "Bookings list",
    classsChange: "mm-collapse",
    iconStyle: <i class="fas fa-calendar-check"></i>,
    content: [
      {
        title: "bookings list",
        to: "bookings-list",
      },
    
    ],
  },
  {
    title: "Help Center",
    isAdminAccess: true,
    classsChange: "mm-collapse",
    iconStyle: <i class='fas fa-headset'></i>,
    content: [
      {
        title: "help center",
        to: "help-center",
      },
    
    ],
  },
];

{
  /* <i className="fas fa-home"/>
<i className="fas fa-info-circle"></i>
<i className="fas fa-chart-line"></i>
<i className="fab fa-bootstrap"></i>	
<i className="fas fa-heart"></i>
<i className="fas fa-user-check"></i>
<i className="fas fa-file-alt"></i>
<i className="fas fa-table"></i>
<i className="fas fa-clone"></i> */
}
