export default function CustomTextarea({
  onChange,
  onBlur,
  name,
  id,
  value,
  placeholder,
  height = "157px",
  style,
}) {
  return (
    <textarea
      placeholder={placeholder}
      defaultValue={value}
      onBlur={onBlur}
      onChange={onChange}
      id={id}
      name={name}
      style={{ borderColor: "#d3d2da", height: height, color: "#000", width: "100%", borderRadius: "1rem", padding: "0.5rem"  }}
      className={`form-control ${style}`}
    />
  );
}
