import { get } from "../../../configs/api";

export function getAccessibilityFeaturesList() {
  return get(`/accessibility-features`);
}

export function getAmenitiesList() {
  return get(`/amenities`);
}

export function getExperienceTypeList({ queryKey }) {
  const [_key, _limit, isStayType] = queryKey;
  return get(`/experience-type?isStayType=${isStayType}`);
}

export async function getBookingOptionsList({ queryKey }) {
  const [_key, { isStayType }] = queryKey;
  const res = await get(`/book-options?isStayType=${isStayType}`);
  return res;
}

export async function getPaymentDetailsList() {
  return await get(`/payment-methods`);
}

export function getLanguageList() {
  return get(`/language`);
}

export function getCountriesList() {
  return get("/countries");
}

export function getCitiesForCountries({ queryKey }) {
  const [_key, { countryId }] = queryKey;
  return get(`/countries/cities?countryIds=${countryId}`);
}

export async function getAmenityCategoriesList() {
  return await get(`/amenity-category`);
}

export function getPaymentList() {
  return get(`/payment-methods`);
}

export function getPublicTerms() {
  return get(`/policies`);
}

