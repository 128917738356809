import { createContext, useContext, useState } from "react";

const PublicContext = createContext();

export const usePublicContext = () => {
  return useContext(PublicContext);
};

export default function ReactPublicContextProvider({ children }) {
  const [isLoading, setIsLoading] = useState();
  const [userDataUpdated, setUserDataUpdated] = useState({
    value: true,
    profileImageUrl: "",
  });


  return (
    <PublicContext.Provider
      value={{
        isLoading,
        setIsLoading,
        userDataUpdated,
        setUserDataUpdated
      }}
    >
      {children}
    </PublicContext.Provider>
  );
}
