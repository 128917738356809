import React, { useEffect, useState } from "react";
import Label from "./Label";
import CustomInput from "./CustomInput";
import ErrorFormik from "../ErrorFormik";
import DeletionCircle from "../svg/DeletionCircleSvg";
import PlusIcon from "../svg/PlusIconSvg";
import Hr from "../Hr";
import GeneralSelect from "./select-inputs/GeneralSelect";
import { toast } from "react-toastify";

export default function DynamicInputFields({
  onChange,
  val,
  errors,
  touched,
  inputFields,
  fieldName,
  textButton,
}) {
  const [fields, setFields] = useState(val);

  const handleChange = (index, field, value) => {
    const values = [...fields];
    values[index][field] = value;
    setFields(values);
    onChange(values);
  };

  const handleAddFields = () => {
    if (!Array.isArray(fields)) {
      toast.error("Something went wrong, please try later!");
      return;
    }
    setFields([
      ...fields,
      Object.fromEntries(inputFields?.map((input) => [input.name, input.defaultValue || ""])),
    ]);
  };

  const handleRemoveFields = (index) => {
    const values = [...fields];
    values.splice(index, 1);
    setFields(values);
    onChange(values);
  };

  useEffect(() => {
    setFields(val);
  }, [val]);

  return (
    <div>
      <div className='mt-3 d-flex justify-content-end'>
        <button
          type='button'
          className='btn btn-primary sw-btn-next d-flex align-content-center'
          onClick={handleAddFields}
        >
          <div className='w-6 h-6 select-none'>
            <PlusIcon width={24} height={24} />
          </div>
          <div className='ms-2 my-auto'>{textButton || "Add Slot"}</div>
        </button>
      </div>
      {fields?.map((field, index) => (
        <div key={index}>
          <div className='row g-3 justify-content-center'>
            {inputFields?.map((input, inputIndex) => (
              <div
                className={`${fields.length > 1 ? "col-lg-5" : "col-lg-6"}`}
                key={inputIndex}
              >
                <Label
                  forId={input.id}
                  text={input.label}
                  isRequired={input.isRequired}
                />
                {["number", "text", "date", "time"].includes(
                  input.type.toLowerCase(),
                ) && (
                  <CustomInput
                    placeholder={input.placeholder}
                    id={input.id}
                    name={input.name}
                    type={input.type}
                    value={field[input.name]}
                    onChange={(event) =>
                      handleChange(index, input.name, event.target.value)
                    }
                    isDisable={input?.isDisable}
                    className='form-control'
                  />
                )}
                {input.type.toLowerCase() === "select" && (
                  <GeneralSelect
                    defaultValue={field[input.name].value || field[input.name]}
                    placeholder='Search'
                    isClearable={false}
                    isSearchable={true}
                    isDisabled={false}
                    isLoading={false}
                    isRtl={false}
                    options={input.options}
                    handleChange={(val) => {
                      handleChange(index, input.name, val.value);
                    }}
                    id={input.id}
                    name={input.name}
                  />
                )}
                <ErrorFormik
                  isError={
                    errors?.[fieldName] &&
                    errors?.[fieldName]?.[index]?.[input.name]
                  }
                  isTouched={
                    touched?.[fieldName] &&
                    touched?.[fieldName]?.[index]?.[input.name]
                  }
                  error={
                    errors?.[fieldName] &&
                    errors?.[fieldName]?.[index]?.[input.name]
                  }
                />
              </div>
            ))}
            {fields.length > 0 && (
              <div className='col-lg-2 d-flex align-content-end align-self-end justify-content-end'>
                <button
                  style={{
                    width: "50px",
                    height: "50px",
                    padding: "0",
                    borderRadius: "50%",
                    backgroundColor: "red",
                  }}
                  type='button'
                  className='btn btn-danger d-flex align-items-center justify-content-center'
                  onClick={() => handleRemoveFields(index)}
                >
                  <DeletionCircle width={24} height={24} style='' />
                </button>
              </div>
            )}
          </div>
          {index !== fields.length - 1 && <Hr />}
        </div>
      ))}
    </div>
  );
}
