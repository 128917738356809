import { get } from "../../../configs/api";
import { generateQueryString } from "../../../utils/functions";

export async function getExperienceList({ queryKey }) {
  const [_key, props] = queryKey;


  let queries = generateQueryString(props);

  return await get(`/sites/admin?${queries}`);
}


export async function getArchivedExperienceList({ queryKey }) {
  const [_key, props] = queryKey;
  let queries = generateQueryString(props);
  queries = queries.replace(/&?filter=[^&]*/, '');
  if (props.filter) {
    queries += `&filter=${encodeURIComponent(props.filter)}`;
  }
  const res = await get(`/sites/admin?${queries}&status=archived`);
  return res;
}

export async function getSite({ queryKey }) {
  const [_key, { id }] = queryKey;
  return await get(`/sites/${id}`);
}


export async function getBookingsList({ 
  queryKey, 
  sortByDate, 
  bookingDate, 
  startDateTime,
  paymentType,
  skip,
  limit,
  status }) {
    
  const [_key, props] = queryKey;
  let queries = generateQueryString(props);

  if (typeof sortByDate === 'number') {
    queries += `&sortByDate=${sortByDate}`;
  }

  if (bookingDate) {
    queries += `&bookingDate=${bookingDate}`;
  }

  if (startDateTime) {
    queries += `&startDateTime=${startDateTime}`;
  }

  if (paymentType) {
    queries += `&paymentType=${paymentType}`;
  }

  if (status) { 
    queries += `&status=${status}`;
  }

  if (skip || limit) {
    queries += `&skip=${skip}&limit=${limit}`;
  }

  const res = await get(`/booking/admin?${queries}`);
  return res;
}

export async function getBookingsDetails({ queryKey }) {
  const [_key, { id }] = queryKey;
  const res = await get(`/booking/details/${id}`);
  return res;
}

export async function getUsersDetails({ queryKey }) {
  const [_key, { id }] = queryKey;
  const res = await get(`/users/${id}/details`);
  return res;
}

export async function getBookingOptions({ queryKey }) { 
  const [_key, props] = queryKey;
  let queries = generateQueryString(props);
  const res = await get(`/book-options?${queries}`);
  return res;
}

export function getFAQs() {
  return get(`/faq`);
}


export async function getHelpCenter() {
  return await get(`/help-center/admin/all`);
}

export async function getHelpCenterDetails({ queryKey }) {
  const [_key, { id }] = queryKey;
  const res = await get(`/help-center/admin/details/${id}`);
  return res;
}

export function getCurrencyRateList() {
  return get(`/currency`);

}