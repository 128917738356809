import { del, patch } from "../../../configs/api";

export async function patchAddSiteStep1QueryFn(data) {
  const { id, ...rest } = data;
  return await patch(`/sites/${id}/basic-information`, rest);
}
export async function patchAddSiteStep2QueryFn(data) {
  const { id, ...rest } = data;
  return await patch(`/sites/${id}/cohost-information`, rest);
}

export async function patchAddSiteStep3QueryFn(data) {
  const { id, ...rest } = data;
  return await patch(`/sites/${id}/media`, rest);
}
export async function patchAddSiteStep4QueryFn(data) {
  const { id, ...rest } = data;
  return await patch(`/sites/${id}/booking-info`, rest);
}
export async function patchAddSiteStep5QueryFn(data) {
  const { id, ...rest } = data;
  return await patch(`/sites/${id}/availability`, rest);
}
export async function patchAddSiteStep6QueryFn(data) {
  const { id, ...rest } = data;
  return await patch(`/sites/${id}/itinerary-details`, rest);
}

export async function patchPublishSiteQueryFn(data) {
  const { id, ...rest } = data;
  return await patch(`/sites/${id}/publish-site`, rest);
}

export async function patchUnPublishSiteQueryFn(data) {
  const { id, ...rest } = data;
  return await patch(`/sites/${id}/unpublish-site`, rest);
}

export async function patchExperienceStatusToArchiveQueryFn(id) {
  return await patch(`/sites/${id}/archive-site`);
}

export async function patchExperienceStatusToUnArchiveQueryFn(id) {
  return await patch(`/sites/${id}/unarchive-site`);
}

export async function delDeleteSiteQueryFn(id) {
  return await del(`/sites/${id}/delete-site`);
}

export async function patchSpokenLanguageQueryFn(data) {
  const { id, ...rest } = data;
  return await patch(`/language/${id}`, rest);
}

export async function patchAccessibilityFeatureQueryFn(data) {
  const { id, ...rest } = data;
  return await patch(`/accessibility-features/${id}`, rest);
}

export async function patchAmenityQueryFn(data) {
  const { id, ...rest } = data;
  return await patch(`/amenities/${id}`, rest);
}


export async function patchCategotyQueryFn(data) {
  const { id, ...rest } = data;
  return await patch(`/amenity-category/${id}`, rest);
}

export async function patchExperienceTypeQueryFn(data) {
  const { id, ...rest } = data;
  return await patch(`/experience-type/${id}`, rest);
}

export async function patchPaymentTypeQueryFn(data) {
  const { id, ...rest } = data;
  return await patch(`/payment-methods/${id}`, rest);
}

export async function patchEditUserInfoQueryFn(data) {
  const { id, ...rest } = data;
  return await patch(`/users/${id}/edit-admin`, rest);
}

export async function patchRefundBookingQueryFn(data) {
  const { id } = data;
  return await patch(`/booking/${id}/refund-booking`);
}

export async function patchApprovBookingQueryFn(data) {
  const { id } = data;
  return await patch(`/booking/${id}/approve-booking`);
}

export async function patchTermsAndPrivacyQueryFn(data) {
  const { id, ...rest } = data;
  return await patch(`/policies/${id}`, rest);
}

export async function patchExperienceStatusToDraftQueryFn(id) {
  return await patch(`/sites/${id}/draft-site`);
}

export async function patchBookingOptionQueryFn(data) {
  const { id, ...rest } = data;
  return await patch(`/book-options/${id}`, rest);
}

export async function patchFaqQueryFn(data) {
  const { id, ...rest } = data;
  return await patch(`/faq/${id}`, rest);
}

export async function patchCurrencyRateQueryFn(data) {
  const { id, ...rest } = data;
  console.log(id)
  return await patch(`/currency/${id}/change-rate`, rest);
}

export async function patchTogglePaymentTypeEnabledMutationQueryFn(id) {
  const res = await patch(`/payment-methods/${id}/enable-disable`, );
  // console.log(res)
  return res
}