const Switch = ({ id, label, checked, onChange }) => {
  return (
    <div className='d-flex gap-2 form-check form-switch'>
      <input
        className='form-check-input'
        type='checkbox'
        id={id}
        checked={checked}
        onChange={onChange}
        style={{ width: "40px", height: "24px" }} // Custom styles for larger size
      />
      <label className='form-check-label mt-2 unselectable' htmlFor={id}>
        {label}
      </label>
    </div>
  );
};

export default Switch;
