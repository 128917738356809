import React, { useEffect, useState } from "react";

import GoogleMaps from "./GoogleMaps";
import Label from "./custom-inputs/Label";
import CustomInput from "./custom-inputs/CustomInput";
import ErrorFormik from "./ErrorFormik";
import DeletionCircle from "./svg/DeletionCircleSvg";
import PlusIcon from "./svg/PlusIconSvg";
import Hr from "./Hr";

export default function ItineraryStops({
  onChange,
  val,
  errors,
  touched,
  Input1,
  Input2,
  Input3,
  fieldName,
  textButton,
}) {
  const [inputFields, setInputFields] = useState(val);

  const handleChange = (index, field, event) => {
    const values = [...inputFields];
    values[index][field] = event.target.value;
    setInputFields(values);
    onChange(values);
  };

  const handleLocationChange = (location, index) => {
    const updatedInputFields = inputFields.map((field, i) => {
      if (i === index) {
        return {
          ...field,
          locationURL: location?.area || "",
          coordinates: location.coordinates || [],
        };
      }
      return field;
    });

    setInputFields(updatedInputFields);
    onChange(updatedInputFields);
  };

  const handleAddFields = () => {
    setInputFields([
      ...inputFields,
      {
        [Input1.name]: "",
        [Input2.name]: "",
        [Input3.name]: "",
        locationURL: "",
        coordinates: [],
      },
    ]);
  };

  const handleRemoveFields = (index) => {
    const values = [...inputFields];
    values.splice(index, 1);
    setInputFields(values);
    onChange(values);
  };

  useEffect(() => {
    setInputFields(val);
  }, [val]);

  return (
    <div>
      {inputFields?.map((inputField, index) => (
        <div key={index}>
          <div className='w-100 d-flex justify-content-between align-items-center'>
            <div className='w-100'>
              <GoogleMaps
                onClick={(values) =>
                  handleLocationChange(values, index)
                }
                defaultValue={{
                  lat: inputField?.coordinates?.[0],
                  lng: inputField?.coordinates?.[1],
                }}
                mapContainerStyle={{
                  width: "75%",
                  height: "300px",
                }}
              />
              <ErrorFormik
                isError={
                  errors?.[fieldName] &&
                  errors?.[fieldName]?.[index]?.locationURL
                }
                isTouched={
                  touched?.[fieldName] &&
                  touched?.[fieldName]?.[index]?.locationURL
                }
                error={
                  errors?.[fieldName] &&
                  errors?.[fieldName]?.[index]?.locationURL
                }
              />
            </div>
            <div className=''>
              {inputFields.length > 1 && (
                <button
                  type='button'
                  className='btn btn-danger'
                  onClick={() => handleRemoveFields(index)}
                >
                  <DeletionCircle width={24} height={24} style={""} />
                </button>
              )}
            </div>
          </div>
          <div className='row g-3'>
            <div className='col-lg-6'>
              <Label
                forId={Input1.id}
                text={Input1.label}
                isRequired={Input1.isRequired}
              />
              <CustomInput
                placeholder={Input1.placeholder}
                id={Input1.id}
                name={Input1.name}
                type={Input1.type}
                value={inputField[Input1.name]}
                onChange={(event) => handleChange(index, Input1.name, event)}
                className='form-control'
              />
              <ErrorFormik
                isError={
                  errors?.[fieldName] &&
                  errors?.[fieldName]?.[index]?.[Input1.name]
                }
                isTouched={
                  touched?.[fieldName] &&
                  touched?.[fieldName]?.[index]?.[Input1.name]
                }
                error={
                  errors?.[fieldName] &&
                  errors?.[fieldName]?.[index]?.[Input1.name]
                }
              />
            </div>
            <div className='col-lg-6'>
              <Label
                forId={Input2.id}
                text={Input2.label}
                isRequired={Input2.isRequired}
              />
              <div className='input-group'>
                <CustomInput
                  placeholder={Input2.placeholder}
                  id={Input2.id}
                  name={Input2.name}
                  type={Input2.type}
                  value={inputField[Input2.name]}
                  onChange={(event) => handleChange(index, Input2.name, event)}
                  className='form-control'
                />
              </div>
              <ErrorFormik
                isError={
                  errors?.[fieldName] &&
                  errors?.[fieldName]?.[index]?.[Input2.name]
                }
                isTouched={
                  touched?.[fieldName] &&
                  touched?.[fieldName]?.[index]?.[Input3.name]
                }
                error={
                  errors?.[fieldName] &&
                  errors?.[fieldName]?.[index]?.[Input2.name]
                }
              />
            </div>
            <div className='col-lg-6'>
              <Label
                forId={Input3.id}
                text={Input3.label}
                isRequired={Input3.isRequired}
              />
              <CustomInput
                placeholder={Input3.placeholder}
                id={Input3.id}
                name={Input3.name}
                type={Input3.type}
                value={inputField[Input3.name]}
                onChange={(event) => handleChange(index, Input3.name, event)}
                className='form-control'
              />
              <ErrorFormik
                isError={
                  errors?.[fieldName] &&
                  errors?.[fieldName]?.[index]?.[Input3.name]
                }
                isTouched={
                  touched?.[fieldName] &&
                  touched?.[fieldName]?.[index]?.[Input3.name]
                }
                error={
                  errors?.[fieldName] &&
                  errors?.[fieldName]?.[index]?.[Input3.name]
                }
              />
            </div>
          </div>
          {index !== inputFields.length - 1 && <Hr />}
        </div>
      ))}
      <div className='mt-3 d-flex justify-content-end'>
        <button
          type='button'
          className='btn btn-primary sw-btn-next d-flex align-content-center'
          onClick={handleAddFields}
        >
          <div className='w-6 h-6 select-none'>
            <PlusIcon width={24} height={24} />
          </div>
          <div className='ms-2 my-auto'>{textButton || "Add Slot"}</div>
        </button>
      </div>
    </div>
  );
}
