import React, { Fragment, useContext, useEffect, useState } from "react";
import { Stepper, Step } from "react-form-stepper";
import StepOne from "./StepOne";
import StepTwo from "./StepTwo";
import StepThree from "./StepThree";
import StepFour from "./StepFour";
import StepFive from "./StepFive";
import { useSearchParams } from "react-router-dom";
import { useQuery } from "@tanstack/react-query";
import { getSite } from "../../../../queries/queryFunctions/privetQueryFns/getQueryFns";
import Loader from "../../ui/Loader";
import { toast } from "react-toastify";
import { ThemeContext } from "../../../../context/ThemeContext";

const AddStayComponent = () => {
  const { background } = useContext(ThemeContext);

  const [searchParams, setSearchParams] = useSearchParams();
  const stayId = searchParams.get("id");
  const step = parseInt(searchParams.get("step")) || 1;

  const [goSteps, setGoSteps] = useState(1);

  const {
    data: siteInfo,
    isPending: isLoadingSiteInfo,
    isError: isErrorSiteInfo,
    fetchStatus: fetchStatusSiteInfo,
    refetch: refetchSiteInfo,
  } = useQuery({
    queryKey: [
      "site-info",
      { id: stayId, path: window.location.pathname, goSteps },
    ],
    queryFn: getSite,
    enabled: !!stayId,
    retry: 0,
    staleTime: 0,
  });

  useEffect(() => {
    if (!stayId) {
      refetchSiteInfo({ enabled: false });
    }
  }, [stayId, refetchSiteInfo]);

  useEffect(() => {
    if (siteInfo && step > siteInfo.site.site_setup_status) {
      setSearchParams({
        id: stayId,
        step: siteInfo.site.site_setup_status,
      });
    } else if (step !== goSteps) {
      setGoSteps(step);
    }
  }, [step, siteInfo, searchParams, setSearchParams, goSteps]);

  const handleNext = () => {
    if (goSteps < 5) {
      setGoSteps((prevStep) => prevStep + 1);
      setSearchParams({ id: stayId, step: goSteps + 1 });
    }
  };

  const handlePrev = () => {
    if (goSteps > 1) {
      setGoSteps((prevStep) => prevStep - 1);
      setSearchParams({ id: stayId, step: goSteps - 1 });
    }
  };

  function chooseStep(number) {
    if (siteInfo && number <= siteInfo.site.site_setup_status) {
      setSearchParams({
        id: stayId,
        step: number,
      });
    }

  }

  if (isLoadingSiteInfo) {
    return <Loader />;
  } else if (isErrorSiteInfo && stayId) {
    toast.error("Some thing went wrong, please try later!");
    return;
  }

  return (
    <Fragment>
      <div className='row'>
        <div className='col-xl-12 col-xxl-12'>
          <div className='card'>
            <div className='card-header'>
              <h4 className='card-title'>
                {window.location.pathname === "/edit-stay"
                  ? `Edit Stay - ${siteInfo?.site?.name}`
                  : `Add Stay ${siteInfo?.site?.name ? `- ${siteInfo?.site?.name}` : ""}`}
              </h4>
            </div>
            <div className='card-body'>
              <div className='form-wizard '>
                <Stepper
                  className='nav-wizard' nonLinear={
                    window.location.pathname === "/edit-stay" &&
                    siteInfo?.site?.site_setup_status > 5
                  }
                  activeStep={goSteps - 1}
                >
                  <Step
                    label='Basic Information'
                    className={`nav-link ${background.value === "dark" ? "dark" : ""}`}
                    onClick={() => chooseStep(1)}
                    completed={siteInfo?.site?.site_setup_status > 1 && goSteps !== 1}

                  />
                  <Step
                    label='Co-host Information'
                    className={`nav-link ${background.value === "dark" ? "dark" : ""}`}
                    onClick={() => chooseStep(2)}
                    completed={siteInfo?.site?.site_setup_status > 2 && goSteps !== 2}
                  />
                  <Step
                    label='Media'
                    className={`nav-link ${background.value === "dark" ? "dark" : ""}`}
                    onClick={() => chooseStep(3)}
                    completed={siteInfo?.site?.site_setup_status > 3 && goSteps !== 3}
                  />
                  <Step
                    label='Booking Info'
                    className={`nav-link ${background.value === "dark" ? "dark" : ""}`}
                    onClick={() => chooseStep(4)}
                    completed={siteInfo?.site?.site_setup_status > 4 && goSteps !== 4}
                  />
                  <Step
                    label='Availability'
                    className={`nav-link ${background.value === "dark" ? "dark" : ""}`}
                    onClick={() => chooseStep(5)}
                    completed={siteInfo?.site?.site_setup_status > 5 && goSteps !== 5}
                  />
                </Stepper>
                {goSteps === 1 && (
                  <StepOne
                    handleNext={handleNext}
                    ExperienceId={stayId}
                    siteInfo={siteInfo}
                  />
                )}
                {goSteps === 2 && (
                  <>
                    <StepTwo
                      handleNext={handleNext}
                      handlePrev={handlePrev}
                      ExperienceId={stayId}
                      siteInfo={siteInfo}
                    />
                  </>
                )}
                {goSteps === 3 && (
                  <StepThree
                    handleNext={handleNext}
                    handlePrev={handlePrev}
                    ExperienceId={stayId}
                    siteInfo={siteInfo}
                  />
                )}
                {goSteps === 4 && (
                  <>
                    <StepFour
                      handleNext={handleNext}
                      handlePrev={handlePrev}
                      ExperienceId={stayId}
                      siteInfo={siteInfo}
                    />
                  </>
                )}
                {goSteps === 5 && (
                  <>
                    <StepFive
                      handleNext={handleNext}
                      handlePrev={handlePrev}
                      ExperienceId={stayId}
                      siteInfo={siteInfo}
                    />
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default AddStayComponent;
