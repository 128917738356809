import React, { useEffect, useState } from "react";
import { useMutation, useQuery } from "@tanstack/react-query";
import { getBookingOptions } from "../../../../queries/queryFunctions/privetQueryFns/getQueryFns";
import DropdownBlog from "../../ui/DropdownBlog";
import { Button } from "react-bootstrap";
import GeneralSelect from "../../ui/custom-inputs/select-inputs/GeneralSelect";
import { useFormik } from "formik";
import { postBookingOptionsQueryFn } from "../../../../queries/queryFunctions/privetQueryFns/postQueryFns";
import { usePublicContext } from "../../../../providers/ReactPublicContextProvider";
import { toast } from "react-toastify";
import { BOOKING_OPTIONS_TYPE } from "../../../../utils/constants";
import ErrorFormik from "../../ui/ErrorFormik";
import CustomInput from "../../ui/custom-inputs/CustomInput";
import Label from "../../ui/custom-inputs/Label";
import SlideOver from "../../../layouts/SlideOver";
import { patchBookingOptionQueryFn } from "../../../../queries/queryFunctions/privetQueryFns/patchQueryFns";
import { deleteBookingOptionsQueryFn } from "../../../../queries/queryFunctions/privetQueryFns/deleteQueryFns";
import { addBookingOptionsSchema } from "../../../../utils/forms-schemas";
import CustomModal from "../../ui/Modal";

const BookingOptionsComponent = ({ showArchiveOption, showDraftOption }) => {
  const { setIsLoading } = usePublicContext();
  const [activePage, setActivePage] = useState(0);
  const [bookings, setBookings] = useState([]);
  const [pageSize, setPageSize] = useState(10);
  const [skip, setSkip] = useState(0);
  const [limit, setLimit] = useState(10);
  const [isStayType, setIsStayType] = useState(false);
  const [isSlideOverOpen, setIsSlideOverOpen] = useState(false);
  const [selectedItem, setSelectedItem] = useState(undefined);
  const [showAlert, setShowAlert] = useState(false);
  const [bookingOptionIdToDelete, setBookingOptionIdToDelete] = useState(null);
  const [localIsStayType, setLocalIsStayType] = useState(false);
  const [selectedType, setSelectedType] = useState(null);

  const {
    values,
    errors,
    touched,
    handleBlur,
    handleChange,
    handleSubmit,
    setFieldValue,
    isValid,
    resetForm,
  } = useFormik({
    initialValues: {
      nameEn: "",
      nameAr: "",
      descriptionEn: "",
      descriptionAr: "",
    },
    validationSchema: addBookingOptionsSchema,
    onSubmit: onSubmit,
  });

  const { data, isLoading, isError, error, refetch } = useQuery({
    queryKey: [
      "BookingOptions",
      { skip, limit, ...(isStayType !== undefined && { isStayType }) },
    ],
    queryFn: getBookingOptions,
  });

  const handleIsStayTypeChange = (newValue) => {
    setIsStayType(newValue);
    setLocalIsStayType(newValue);
  };

  const handleLocalIsStayTypeChange = (newValue) => {
    setLocalIsStayType(newValue);
    // setSelectedType(newValue);
  };

  const {
    mutate: addMutation,
    isPending: isLoadingMutate,
    isError: isErrorMutate,
  } = useMutation({
    mutationFn: postBookingOptionsQueryFn,
    onSuccess(data) {
      toast.success("Booking Options Added Successfully");
      refetch();
    },
    onError(error) {
      toast.error("Something went wrong, please try later!");
    },
  });

  function onSubmit() {
    if (selectedItem) {

      updateMutation({
        id: selectedItem?._id,
        nameEn: values.nameEn,
        nameAr: values.nameAr,
        descriptionEn: values.descriptionEn,
        descriptionAr: values.descriptionAr,
        isStayType: localIsStayType === "true", // Convert string to boolean
      });
    } else {
      addMutation({
        nameEn: values.nameEn,
        nameAr: values.nameAr,
        descriptionEn: values.descriptionEn,
        descriptionAr: values.descriptionAr,
        isStayType: localIsStayType === "true", // Convert string to boolean
      });
    }
    resetForm();
    setIsSlideOverOpen(false);
  }

  const {
    mutate: updateMutation,
    isPending: isLoadingUpdateMutation,
    isError: isErrorUpdateMutation,
  } = useMutation({
    mutationFn: patchBookingOptionQueryFn,
    onSuccess(data) {
      toast.success("Booking Option Updated Successfully");
      refetch();
    },
    onError(error) {
      toast.error("Something went wrong, please try later!");
    },
  });

  const {
    mutate: deleteMutation,
    isPending: isLoadingDeleteMutation,
    isError: isErrorDeleteMutation,
  } = useMutation({
    mutationFn: deleteBookingOptionsQueryFn,
    onSuccess(data) {
      toast.success("Booking Options Deleted Successfully");
      refetch();
      setShowAlert(false);
    },
    onError(error) {
      toast.error("Something went wrong, please try later!");
    },
  });

  const openSlideOver = () => {
    setIsSlideOverOpen(true);
    setSelectedType(isStayType);
  };

  const closeSlideOver = () => {
    setSelectedItem(null);
    resetForm();
    // setFieldValue("nameEn", '');
    // setFieldValue("nameAr", '');
    // setFieldValue("descriptionEn", '');
    // setFieldValue("descriptionAr", '');
    // setLocalIsStayType(false)
    setIsSlideOverOpen(false);
  };

  const handleDelete = (id) => {
    setBookingOptionIdToDelete(id);
    setShowAlert(true);
  };

  const handleConfirmDelete = () => {
    deleteMutation(bookingOptionIdToDelete);
    setShowAlert(false);
  };

  const handleEdit = (item) => {
    setSelectedItem(item);
    setSelectedType(item.isStayType);
    setFieldValue("nameEn", item.nameEn);
    setFieldValue("nameAr", item.nameAr);
    setFieldValue("descriptionEn", item.descriptionEn);
    setFieldValue("descriptionAr", item.descriptionAr);
    setLocalIsStayType(item.isStayType);
    setIsSlideOverOpen(true);
  };

  useEffect(() => {
    if (data) {
      setBookings(data);
    }
  }, [data]);

  const handleClick = (pageIndex) => {
    setActivePage(pageIndex);
    const newSkip = pageIndex * pageSize;
    const newLimit = pageSize;
    setSkip(newSkip);
    setLimit(newLimit);
  };

  const handleChangePageSize = (event) => {
    const newSize = parseInt(event.target.value, 10);
    setPageSize(newSize);
    setLimit(newSize);
  };

  const startIdx = activePage * pageSize;
  const endIdx = Math.min(startIdx + pageSize, bookings.length);

  return (
    <div className='table-container' style={{ position: "relative" }}>
      <CustomModal
        title='Warning!'
        description='Deleting this type will remove it from the associated records.'
        isOpen={showAlert}
        setIsOpen={setShowAlert}
        buttonConfirmAction={handleConfirmDelete}
        buttonConfirmText={"Confirm Delete"}
        buttonCloseText='Cancel'
      />
      <div className='table-responsive'>
        <div className='d-flex justify-content-between align-items-center mb-3 '>
          <div
            className='d-flex align-items-center me-2'
            style={{ marginLeft: "30px" }}
          >
            <GeneralSelect
              defaultValue={isStayType}
              placeholder='Select Type'
              isClearable={true}
              isSearchable={false}
              isDisabled={false}
              isLoading={false}
              isRtl={false}
              options={BOOKING_OPTIONS_TYPE}
              handleChange={(val) => handleIsStayTypeChange(val?.value)}
              id='bookingTypeSelect'
              name='bookingTypeSelect'
            />
          </div>
          <div className='guest-calendar'>
            <Button
              className='me-2'
              variant='secondary'
              onClick={openSlideOver}
            >
              Add
            </Button>
          </div>
        </div>
        <table className='table card-table display mb-4 shadow-hover default-table .dataTablesCard dataTable no-footer'>
          <thead>
            <tr>
              <th className='sorting_asc'>Name in English</th>
              <th className='sorting'>Description in English</th>
              <th className='sorting'>Name in Arabic</th>
              <th className='sorting'>Description in Arabic</th>
              <th className='sorting'>Type</th>
              <th className='sorting bg-none'></th>
            </tr>
          </thead>
          <tbody>
            {bookings?.map((booking, index) => (
              <tr key={index} className={index % 2 === 0 ? "even" : "odd"}>
                <td>
                  <div className='concierge-bx d-flex align-items-center'>
                    <div
                      style={{
                        width: "200px",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                      }}
                    >
                      <h5 className='text-nowrap'>{booking?.nameEn}</h5>
                    </div>
                  </div>
                </td>
                <td>
                  <div>
                    <h5 className='text-nowrap'>{booking?.descriptionEn}</h5>
                  </div>
                </td>
                <td>
                  <div>
                    <h5 className='text-nowrap'>{booking?.nameAr}</h5>
                  </div>
                </td>
                <td>
                  <div>
                    <h5 className='text-nowrap'>{booking?.descriptionAr}</h5>
                  </div>
                </td>
                <td>
                  <div>
                    <h5 className='text-nowrap'>
                      {booking?.isStayType ? "Stay" : "Experience"}
                    </h5>
                  </div>
                </td>
                <td>
                  <DropdownBlog
                    experienceId={booking?._id}
                    showDeleteOption={true}
                    showPublishOption={false}
                    showArchiveOption={showArchiveOption}
                    showDraftOption={showDraftOption}
                    handleDelete={() => handleDelete(booking?._id)}
                    handleEdit={() => handleEdit(booking)}
                  />
                </td>
              </tr>
            ))}
          </tbody>
        </table>

        <div className='d-sm-flex text-center justify-content-between align-items-center mt-3 mb-3'>
          <div className='dataTables_info'>
            Showing {startIdx + 1} to {endIdx} of
            <div
              className='dataTables_length'
              style={{ display: "inline-block", marginLeft: "10px" }}
            >
              <label htmlFor='pageSize' className='form-label'>
                <select
                  className='form-select'
                  value={pageSize}
                  onChange={handleChangePageSize}
                  id='pageSize'
                >
                  <option value='10'>10</option>
                  <option value='20'>20</option>
                  <option value='30'>30</option>
                </select>
              </label>
            </div>
            <span style={{ marginLeft: "5px" }}>entries</span>
          </div>
          <div style={{ position: "absolute", bottom: "20px", right: "10px" }}>
            <div
              className='dataTables_paginate paging_simple_numbers mb-0'
              id='example2_paginate'
            >
              <button
                className={`btn btn-primary ${activePage === 0 ? "disabled" : ""}`}
                onClick={() => handleClick(activePage - 1)}
              >
                <i className='fa fa-angle-double-left' aria-hidden='true'></i>
              </button>
              <span className='text-primary mx-3 h3'>{activePage + 1}</span>
              <button
                className={`btn btn-primary next ${activePage === Math.ceil(data?.length / pageSize) - 1 || !data || data?.length === 0 ? "disabled" : ""}`}
                onClick={() => handleClick(activePage + 1)}
                disabled={
                  activePage === Math.ceil(data?.length / pageSize) - 1 ||
                  !data ||
                  data.length === 0
                }
              >
                <i className='fa fa-angle-double-right' aria-hidden='true'></i>
              </button>
            </div>
          </div>
        </div>
      </div>
      <SlideOver
        isOpen={isSlideOverOpen}
        onClose={closeSlideOver}
        onSubmit={handleSubmit}
      >
        <div>
          <h4>{selectedItem ? "Edit" : "Add"} Booking Options</h4>
          <section className='row'>
            <div className='col-lg-6'>
              <div className='form-group mb-3'>
                <Label forId='type' text='type' isRequired={false} />
                <div className='w-100 d-flex align-items-center'>
                  <div style={{ flex: 1 }}>
                    <GeneralSelect
                      defaultValue={localIsStayType ? "true" : "false"}
                      placeholder='Search'
                      isClearable={false}
                      isSearchable={true}
                      isDisabled={false}
                      isLoading={false}
                      isRtl={false}
                      options={BOOKING_OPTIONS_TYPE}
                      handleChange={(val) =>
                        handleLocalIsStayTypeChange(val.value)
                      }
                      id='bookingTypeSelect'
                      name='bookingTypeSelect'
                    />
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section className='row'>
            <div className='col-lg-6'>
              <div className='form-group mb-3'>
                <Label
                  forId='nameEn'
                  text='Name in English'
                  isRequired={true}
                />
                <CustomInput
                  withFocus={true}
                  placeholder='Name'
                  id='nameEn'
                  name='nameEn'
                  type='text'
                  value={values?.nameEn}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
                <ErrorFormik
                  isError={errors?.nameEn}
                  isTouched={touched?.nameEn}
                  error={errors?.nameEn}
                />
              </div>
            </div>
            <div className='col-lg-6'>
              <div className='form-group mb-3'>
                <Label forId='nameAr' text='Name in Arabic' isRequired={true} />
                <CustomInput
                  withFocus={true}
                  placeholder='الاسم'
                  id='nameAr'
                  name='nameAr'
                  type='text'
                  value={values?.nameAr}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
                <ErrorFormik
                  isError={errors?.nameAr}
                  isTouched={touched?.nameAr}
                  error={errors?.nameAr}
                />
              </div>
            </div>
          </section>
          <section className='row'>
            <div className='col-lg-6'>
              <div className='form-group mb-3'>
                <Label
                  forId='descriptionEn'
                  text='Description in English'
                  isRequired={false}
                />
                <CustomInput
                  withFocus={true}
                  placeholder='Description in English'
                  id='descriptionEn'
                  name='descriptionEn'
                  type='text'
                  value={values?.descriptionEn}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
                <ErrorFormik
                  isError={errors?.descriptionEn}
                  isTouched={touched?.descriptionEn}
                  error={errors?.descriptionEn}
                />
              </div>
            </div>
            <div className='col-lg-6'>
              <div className='form-group mb-3'>
                <Label
                  forId='descriptionAr'
                  text='Description in Arabic'
                  isRequired={false}
                />
                <CustomInput
                  withFocus={true}
                  placeholder='Description in Arabic'
                  id='descriptionAr'
                  name='descriptionAr'
                  type='text'
                  value={values?.descriptionAr}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
                <ErrorFormik
                  isError={errors?.descriptionAr}
                  isTouched={touched?.descriptionAr}
                  error={errors?.descriptionAr}
                />
              </div>
            </div>
          </section>
        </div>
      </SlideOver>
    </div>
  );
};

export default BookingOptionsComponent;
