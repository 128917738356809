import { post } from "../../../configs/api";

export const postUploadImageQueryFn = async (data) => {
  const formData = new FormData();
  formData.append("file", data?.file);

  return await post(`/file/uploadImage/${data?.type}`, formData, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
};

export const postUploadImagesQueryFn = async (data) => {
  const formData = new FormData();
  // Append each file individually
  data.file.forEach((file, index) => {
    formData.append(`files`, file);
  });

  return await post(`/file/uploadImages/${data?.type}`, formData, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
};

export const postUploadVideoQueryFn = async (data) => {
  const formData = new FormData();
  formData.append("file", data?.file);

  return await post(`/file/uploadVideo/${data?.type}`, formData, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
};

// Create Experience Sites
export function postAddSiteStep1QueryFn(data) {
  return post(`/sites/basic-information`, data);
}

export function postAddAccessibilityFeaturesQueryFn(data) {
  return post(`/accessibility-features`, data);
}

// export function postAddPaymentQueryFn(data) {
//   return post(`/payment-details`, data);
// }

export function postAddExperienceTypesQueryFn(data) {
  return post(`/experience-type`, data);
}

export function postSpokenLanguagesQueryFn(data) {
  return post(`/language`, data);
}

export function postAmenityQueryFn(data) {
  return post(`/amenities`, data);
}

export function postAddCategotyQueryFn(data) {
  return post(`/amenity-category`, data);
}


export function postBookingOptionsQueryFn(data) {
  return post(`/book-options`, data);
}

export function postFAQsQueryFn(data) {
  return post(`/faq`, data);
}

export function postAddAnnouncementsNotificationQueryFn(data) {
  return post(`/notification/new-announcement`, data);
}