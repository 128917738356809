import React, { useContext } from "react";
import Button from "react-bootstrap/Button";
import { ThemeContext } from "../../context/ThemeContext";

const SlideOver = ({ isOpen, onClose, onSubmit, title, children }) => {
  const { background } = useContext(ThemeContext);


  const slideOverStyle = {
    position: "fixed",
    top: 0,
    right: isOpen ? "0" : "-100%",
    bottom: 0,
    width: "80%", // Adjust as needed
    maxWidth: "600px", // Maximum width of slide-over
    backgroundColor: background.value === "dark" ? "#202020" : "white",
    transition: "right 0.3s ease",
    zIndex: 1000,
    overflowY: "auto", // Enable scrolling if content exceeds slide-over height
    padding: "20px",
    display: "flex",
    flexDirection: "column", // Align children in a column
  };

  const closeButtonStyle = {
    position: "absolute",
    top: "10px",
    right: "10px",
  };

  const footerStyle = {
    marginTop: "auto", 
    textAlign: "center",
    padding: "20px",
    borderTop: "1px solid #ddd", 
  };

  return (
    <div style={slideOverStyle}>
      <Button variant='light' onClick={onClose} style={closeButtonStyle}>
        x
      </Button>
      <div style={{ marginTop: "40px", marginBottom: "auto" }}>
        {title && <h4>{title}</h4>}
        <div>{children}</div>
      </div>
      <div style={footerStyle}>
        <Button variant='primary' onClick={onSubmit}>
          Submit
        </Button>
      </div>
    </div>
  );
};

export default SlideOver;
