import React, { useEffect, useState } from "react";
import { Button } from "react-bootstrap";
import { useMutation, useQuery } from "@tanstack/react-query";
import { getExperienceTypeList } from "../../../../../queries/queryFunctions/publicQueryFns/getQueryFns";
import { usePublicContext } from "../../../../../providers/ReactPublicContextProvider";
import { useFormik } from "formik";
import { toast } from "react-toastify";
import SlideOver from "../../../../layouts/SlideOver";
import ErrorFormik from "../../../ui/ErrorFormik";
import CustomInput from "../../../ui/custom-inputs/CustomInput";
import Label from "../../../ui/custom-inputs/Label";
import { postAddExperienceTypesQueryFn } from "../../../../../queries/queryFunctions/privetQueryFns/postQueryFns";
import DropdownBlog from "../../../ui/DropdownBlog";
import { addExperienceTypes } from "../../../../../utils/forms-schemas";
import { useLocation } from "react-router-dom";
import { deleteExperienceTypeQueryFn } from "../../../../../queries/queryFunctions/privetQueryFns/deleteQueryFns";
import { patchExperienceTypeQueryFn } from "../../../../../queries/queryFunctions/privetQueryFns/patchQueryFns";
import Pagination from "../../../ui/Pagination";
import CustomModal from "../../../ui/Modal";

const StayTypesComponent = () => {
  const [activePage, setActivePage] = useState(0);
  const { setIsLoading } = usePublicContext();
  const [isSlideOverOpen, setIsSlideOverOpen] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);
  const [showAlert, setShowAlert] = useState(false);
  const [experienceIdToDelete, setExperienceIdToDelete] = useState(null);
  const [pageSize, setPageSize] = useState(10);
  const location = useLocation();
  const [limit, setLimit] = useState(10);

  //   const isStayType = location.pathname.includes("stay-types");

  const {
    values,
    errors,
    touched,
    handleBlur,
    handleChange,
    handleSubmit,
    setFieldValue,
    setFieldTouched,
    isValid,
    resetForm,
  } = useFormik({
    initialValues: {
      nameEn: "",
      // description: "",
      nameAr: "",
      isStayType: true,
    },
    validationSchema: addExperienceTypes,
    onSubmit: onSubmit,
  });

  const {
    data: experienceTypesData,
    isPending: isLoadingExperienceTypesData,
    isError: isErrorExperienceTypesData,
    refetch,
  } = useQuery({
    queryKey: ["stay-types", limit, values.isStayType],
    queryFn: getExperienceTypeList,
  });

  const {
    mutate: addMutation,
    isPending: isLoadingMutate,
    isError: isErrorMutate,
  } = useMutation({
    mutationFn: postAddExperienceTypesQueryFn,
    onSuccess(data) {
      toast.success("Experience Type Added Successfully");
      refetch();
    },
    onError(error) {
      toast.error("Something went wrong, please try later!");
    },
  });

  const {
    mutate: updateMutation,
    isPending: isLoadingUpdateMutation,
    isError: isErrorUpdateMutation,
  } = useMutation({
    mutationFn: patchExperienceTypeQueryFn,
    onSuccess(data) {
      toast.success("Type Updated Successfully");
      refetch();
      // setIsSlideOverOpen(false);
    },
    onError(error) {
      toast.error("Something went wrong, please try later!");
    },
  });

  const {
    mutate: deleteMutation,
    isPending: isLoadingDeleteMutation,
    isError: isErrorDeleteMutation,
  } = useMutation({
    mutationFn: deleteExperienceTypeQueryFn,
    onSuccess(data) {
      toast.success("Type Deleted Successfully");
      refetch();
      setShowAlert(false);
    },
    onError(error) {
      toast.error("Something went wrong, please try later!");
    },
  });

  function onSubmit() {
    if (selectedItem) {
      updateMutation({
        id: selectedItem?._id,
        nameEn: values.nameEn,
        nameAr: values.nameAr,
        isStayType: values.isStayType,
      });
    } else {
      addMutation({
        nameEn: values.nameEn,
        nameAr: values.nameAr,
        isStayType: values.isStayType,
      });
      resetForm();
      setIsSlideOverOpen(false);
    }
  }

  useEffect(() => {
    refetch();
  }, [values.isStayType]);

  useEffect(() => {
    setIsLoading(isLoadingExperienceTypesData);
  }, [isLoadingExperienceTypesData]);

  const openSlideOver = () => {
    setIsSlideOverOpen(true);
  };

  const closeSlideOver = () => {
    setSelectedItem(null);
    setFieldValue("nameEn", "");
    setFieldValue("nameAr", "");
    setIsSlideOverOpen(false);
  };

  const handleDelete = (id) => {
    setExperienceIdToDelete(id);
    setShowAlert(true);
  };

  const handleConfirmDelete = () => {
    deleteMutation(experienceIdToDelete);
    setShowAlert(false);
  };

  const handleEdit = (item) => {
    setSelectedItem(item);
    setFieldValue("nameEn", item.nameEn);
    setFieldValue("nameAr", item.nameAr);
    setIsSlideOverOpen(true);
  };

  const handleClick = (pageIndex) => {
    setActivePage(pageIndex);
  };

  const startIndex = activePage * pageSize;
  const endIndex = Math.min(startIndex + pageSize, experienceTypesData?.length);

  const handleChangePageSize = (event) => {
    const newSize = parseInt(event.target.value, 10);
    setPageSize(newSize);
    setLimit(newSize);
  };

  return (
    <div className='table-responsive'>
      <CustomModal
        title='Warning!'
        description='Deleting this type will remove it from the associated records.'
        isOpen={showAlert}
        setIsOpen={setShowAlert}
        buttonConfirmAction={handleConfirmDelete}
        buttonConfirmText={"Confirm Delete"}
        buttonCloseText='Cancel'
      />
      <div
        className='guest-calendar'
        style={{ display: "flex", justifyContent: "flex-end" }}
      >
        <Button className='me-2' variant='secondary' onClick={openSlideOver}>
          Add
        </Button>
      </div>

      <table className='table card-table display mb-4 shadow-hover default-table .dataTablesCard dataTable no-footer'>
        <thead>
          <tr>
            <th className='sorting_asc'>Name</th>
            <th className='sorting'>اﻻسم</th>
            <th className='sorting bg-none'></th>
          </tr>
        </thead>
        <tbody>
          {Array.isArray(experienceTypesData) &&
            experienceTypesData
              .slice(startIndex, endIndex)
              .map((experienceType, index) => (
                <tr key={index} className={index % 2 === 0 ? "even" : "odd"}>
                  <td>
                    <div>
                      <h5 className='text-nowrap'>{experienceType.nameEn}</h5>
                    </div>
                  </td>
                  <td>
                    <div>
                      <h5 className='text-nowrap'>{experienceType.nameAr}</h5>
                    </div>
                  </td>
                  <DropdownBlog
                    showArchiveOption={false}
                    showDeleteOption={true}
                    showPublishOption={false}
                    experienceTypeId={experienceType?._id}
                    handleDelete={() => handleDelete(experienceType?._id)}
                    handleEdit={() => handleEdit(experienceType)}
                  />
                </tr>
              ))}
        </tbody>
      </table>

        <Pagination
          startIdx={startIndex}
          endIdx={endIndex}
          pageSize={pageSize}
          activePage={activePage}
          handleClick={handleClick}
          handleChangePageSize={handleChangePageSize}
          data={experienceTypesData}
          itemsPerPage={pageSize}
          totalCount={experienceTypesData?.length}
           />

      <SlideOver
        isOpen={isSlideOverOpen}
        onClose={closeSlideOver}
        onSubmit={handleSubmit}
      >
        <div>
          <h4>{selectedItem ? "Edit" : "Add"} Experience Type</h4>
          <section className='row'>
            <div className='col-lg-6'>
              <div className='form-group mb-3'>
                <Label forId='placeType' text='Name' isRequired={true} />
                <CustomInput
                  withFocus={true}
                  placeholder='Name'
                  id='nameEn'
                  name='nameEn'
                  type='text'
                  value={values?.nameEn}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
                <ErrorFormik
                  isError={errors?.nameEn}
                  isTouched={touched?.nameEn}
                  error={errors?.nameEn}
                />
              </div>
            </div>
            <div className='col-lg-6'>
              <div className='form-group mb-3'>
                <Label forId='nameAr' text='الاسم ' isRequired={true} />
                <CustomInput
                  withFocus={true}
                  placeholder='الاسم'
                  id='nameAr'
                  name='nameAr'
                  type='text'
                  value={values?.nameAr}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
                <ErrorFormik
                  isError={errors?.nameAr}
                  isTouched={touched?.nameAr}
                  error={errors?.nameAr}
                />
              </div>
            </div>
          </section>
        </div>
      </SlideOver>
    </div>
  );
};

export default StayTypesComponent;
