import { Button, Modal } from "react-bootstrap";

export default function CustomModal({
  title,
  description,
  isOpen,
  setIsOpen,
  buttonConfirmAction,
  buttonConfirmText = "Confirm",
  buttonCloseText = "Close"
}) {
  return (
    <div>
      <Modal className='fade' show={isOpen}>
        <Modal.Header>
          <Modal.Title>{title}</Modal.Title>
          <Button
            onClick={() => setIsOpen(false)}
            variant=''
            className='btn-close'
          ></Button>
        </Modal.Header>
        <Modal.Body>
          <p>{description}</p>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={() => setIsOpen(false)} variant='danger light'>
            {buttonCloseText}
          </Button>
          <Button onClick={() => buttonConfirmAction()} variant='primary'>{buttonConfirmText}</Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}
