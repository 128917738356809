import { usePublicContext } from "../../../../../providers/ReactPublicContextProvider";
import ErrorFormik from "../../../ui/ErrorFormik";
import CustomEditor from "../../../ui/custom-inputs/CustomEditor";
import Label from "../../../ui/custom-inputs/Label";
import { useFormik } from "formik";
import { toast } from "react-toastify";
import { useMutation, useQuery } from "@tanstack/react-query";
import { getPublicTerms } from "../../../../../queries/queryFunctions/publicQueryFns/getQueryFns";
import { patchTermsAndPrivacyQueryFn } from "../../../../../queries/queryFunctions/privetQueryFns/patchQueryFns";
import { useEffect } from "react";

const TermsAndConditionsComponent = () => {
    const { setIsLoading } = usePublicContext();

    const {
        data: termsAndConditionsData,
        isPending: isLoadingTermsAndConditionsData,
        isError: isErrorTermsAndConditionsData,
        refetch,
      } = useQuery({
        queryKey: ["terms-and-conditions"],
        queryFn: getPublicTerms,
      });

    const {
        values,
        errors,
        touched,
        handleBlur,
        setFieldValue,
        setFieldTouched,
        setValues, 
        handleSubmit,    
      } = useFormik({
        initialValues: {  
          termsConditionsEn: "",
          termsConditionsAr: ""
        },
        enableReinitialize: true,
        onSubmit: onSubmit,
      });

      function onSubmit() {
          updateMutation({
            id: termsAndConditionsData?.id,
            termsConditionsEn: values.termsConditionsEn,
            termsConditionsAr: values.termsConditionsAr
          });
      }
    
    
      const {
        mutate: updateMutation, 
        isPending: isLoadingUpdateMutation,
        isError: isErrorUpdateMutation,
      } = useMutation({
        mutationFn: patchTermsAndPrivacyQueryFn, 
        onSuccess(data) {
          toast.success("Terms Updated Successfully");
        },
        onError(error) {
          toast.error("Something went wrong, please try later!");
        },
      });

      useEffect(() => {
        if (termsAndConditionsData) {
          setValues({
            termsConditionsEn: termsAndConditionsData.termsConditionsEn || "",
            termsConditionsAr: termsAndConditionsData.termsConditionsAr|| ""
          });
        }
      }, [termsAndConditionsData]);
      

    return (
        <>
          <section className='col-lg-12 my-3'>
            <form onSubmit={handleSubmit}> 
              <div className="row">
                <div className="col">
                  <Label forId='termsConditionsEn' text='Terms And Conditions (English)' isRequired={true} />
                  <CustomEditor
                    id={"termsConditionsEn"}
                    value={values?.termsConditionsEn}
                    onChange={(value) => {
                      setFieldValue("termsConditionsEn", value);
                    }}
                    onBlur={() => {
                      setFieldTouched("termsConditionsEn", true);
                      handleBlur("termsConditionsEn");
                    }}
                  />
                  <ErrorFormik
                    isError={errors?.termsConditionsEn}
                    isTouched={touched?.termsConditionsEn}
                    error={errors?.termsConditionsEn}
                  />
                </div>
                <div className="col">
                  <Label forId='termsConditionsAr' text='Terms And Conditions (Arabic)' isRequired={true} />
                  <CustomEditor
                    id={"termsConditionsAr"}
                    value={values?.termsConditionsAr}
                    onChange={(value) => {
                      setFieldValue("termsConditionsAr", value);
                    }}
                    onBlur={() => {
                      setFieldTouched("termsConditionsAr", true);
                      handleBlur("termsConditionsAr");
                    }}
                  />
                  <ErrorFormik
                    isError={errors?.termsConditionsAr}
                    isTouched={touched?.termsConditionsAr}
                    error={errors?.termsConditionsAr}
                  />
                </div>
              </div>
              <button type='submit' className='btn btn-primary sw-btn-next ms-1' style={{ marginTop: '10px' }}>
                Submit
              </button>
            </form>
          </section>
      </>
    )
}

export default TermsAndConditionsComponent;
