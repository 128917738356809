import { useMutation, useQuery } from "@tanstack/react-query";
import ErrorFormik from "../../ui/ErrorFormik";
import CustomInput from "../../ui/custom-inputs/CustomInput";
import Label from "../../ui/custom-inputs/Label";
import MultiSelect from "../../ui/custom-inputs/select-inputs/MultiSelect";
import { useFormik } from "formik";
import { getLanguageList } from "../../../../queries/queryFunctions/publicQueryFns/getQueryFns";
import { useEffect, useState } from "react";
import { usePublicContext } from "../../../../providers/ReactPublicContextProvider";
import { addSiteStep2Schema } from "../../../../utils/forms-schemas";
import { toast } from "react-toastify";
import { patchAddSiteStep2QueryFn } from "../../../../queries/queryFunctions/privetQueryFns/patchQueryFns";
import FileDropzone from "../../ui/FileDropzone";
import {
  ACCEPT_IMAGES_TYPES_OBJECT,
  MAX_SIZE_IMAGE,
  S3_FILES_NAME,
} from "../../../../utils/constants";
import { postUploadImageQueryFn } from "../../../../queries/queryFunctions/privetQueryFns/postQueryFns";
import { useSearchParams } from "react-router-dom";
import Hr from "../../ui/Hr";
import CustomTextarea from "../../ui/custom-inputs/CustomTextarea";

const StepTwo = ({ handleNext, handlePrev, ExperienceId, siteInfo }) => {
  const { setIsLoading } = usePublicContext();
  const [searchParams, setSearchParams] = useSearchParams();

  const [spokenLanguagesList, setSpokenLanguagesList] = useState([]);
  const [localHostPhoto, setLocalHostPhoto] = useState(
    siteInfo?.site?.coHost?.image ||
      "https://placehold.co/600x400?text=Local Host Photo",
  );

  const {
    data: languageData,
    isPending: isLoadingLanguageData,
    isError: isErrorLanguageData,
  } = useQuery({
    queryKey: ["getLanguageList"],
    queryFn: getLanguageList,
  });

  const {
    values,
    errors,
    touched,
    handleBlur,
    handleChange,
    handleSubmit,
    setFieldValue,
    setFieldTouched,
    setValues,
    isValid,
  } = useFormik({
    initialValues: {
      firstName: "",
      lastName: "",
      localHostPhoto: "",
      moreInfo: "",
      spokenLanguages: [],
    },
    enableReinitialize: true,
    validationSchema: addSiteStep2Schema,
    onSubmit: onSubmit,
  });

  const {
    mutate,
    isPending: isLoadingMutate,
    isError: isErrorMutate,
  } = useMutation({
    mutationFn: patchAddSiteStep2QueryFn,
    onSuccess(data) {
      setSearchParams({ id: data?._id, step: 3 });
      handleNext();
    },
    onError(error) {
      console.error("error Site Step 2: ", error);
      toast.error("Something went wrong, please try later!");
    },
  });

  const {
    mutate: mutateUploadImage,
    isPending: isLoadingUploadImage,
    isError: IsErrorUploadImage,
    data: dataUploadImage,
    error: errorUploadImage,
  } = useMutation({
    mutationFn: postUploadImageQueryFn,
    onSuccess(data) {
      setLocalHostPhoto(data);
      setFieldValue("localHostPhoto", data);
      toast.success("Upload Local Host Photo successfully");
    },
    onError(error) {
      toast.error(error?.response?.data?.message);
    },
  });

  useEffect(() => {
    setValues({
      firstName: siteInfo?.site?.coHost?.firstName || "",
      lastName: siteInfo?.site?.coHost?.lastName || "",
      localHostPhoto: siteInfo?.site?.coHost?.image || "",
      moreInfo: siteInfo?.site?.coHost?.description || "",
      spokenLanguages:
        siteInfo?.site?.coHost?.languages?.map((item) => ({
          label: item?._id || item?.nameEn || item,
          value: item?._id || item?.nameEn || item,
        })) || [],
    });
  }, [siteInfo]);

  useEffect(() => {
    setIsLoading(isLoadingMutate || isLoadingUploadImage);
  }, [isLoadingMutate, isLoadingUploadImage]);

  useEffect(() => {
    let res = (languageData || [])
      .filter((item) => item?.nameEn && item?._id)
      .map((item) => ({
        label: item?.nameEn || "",
        value: item?._id,
      }));
    setSpokenLanguagesList(res);
  }, [languageData]);

  async function onImageUpload() {
    if (localHostPhoto?.length && typeof localHostPhoto === "object") {
      mutateUploadImage({
        file: localHostPhoto[0],
        type: S3_FILES_NAME.PLACES_MEDIA,
      });
    }
  }

  useEffect(() => {
    onImageUpload();
  }, [localHostPhoto]);

  function onSubmit() {
    mutate({
      id: ExperienceId,
      firstName: values.firstName,
      lastName: values.lastName,
      image: values.localHostPhoto,
      description: values.moreInfo,
      languages: values.spokenLanguages.map((item) => item.value),
    });
  }

    const handleErrors = async () => {
    const err = Object.values(errors);
    if (err.length > 0) {
      toast.error(err[0]);
    }
  }; 

  return (
    <form onSubmit={handleSubmit}>
      {/* First Name & Last Name */}
      <section className='row'>
        {/* First Name  */}
        <div className='col-lg-6'>
          <div className='form-group mb-3'>
            <Label forId='firstName' text='First Name' isRequired={true} />
            <CustomInput
              withFocus={true}
              placeholder='First Name'
              id='firstName'
              name='firstName'
              type='text'
              value={values?.firstName}
              onChange={handleChange}
              onBlur={handleBlur}
            />
            <ErrorFormik
              isError={errors?.firstName}
              isTouched={touched?.firstName}
              error={errors?.firstName}
            />
          </div>
        </div>
        {/* Last Name */}
        <div className='col-lg-6'>
          <div className='form-group mb-3'>
            <Label forId='lastName' text='Last Name' isRequired={true} />
            <CustomInput
              withFocus={true}
              placeholder='Last Name'
              id='lastName'
              name='lastName'
              type='text'
              value={values?.lastName}
              onChange={handleChange}
              onBlur={handleBlur}
            />
            <ErrorFormik
              isError={errors?.lastName}
              isTouched={touched?.lastName}
              error={errors?.lastName}
            />
          </div>
        </div>
      </section>
      {/* Spoken Languages */}
      <section className='row'>
        {/* Spoken Languages  */}
        <div className='col-lg-6'>
          <div className='form-group mb-3'>
            <Label
              forId='spokenLanguages'
              text='Spoken Languages'
              isRequired={true}
            />
            <MultiSelect
              defaultValue={values?.spokenLanguages?.map((item) => item.label)}
              placeholder='Search Languages'
              isClearable={false}
              isSearchable={true}
              isDisabled={false}
              isLoading={isLoadingLanguageData}
              isRtl={false}
              options={spokenLanguagesList}
              handleChange={(val) => {
                setFieldValue("spokenLanguages", val);
              }}
              handleBlur={() => {
                handleBlur("spokenLanguages");
              }}
              id={"spokenLanguages"}
              name={"spokenLanguages"}
            />
            <ErrorFormik
              isError={errors?.spokenLanguages}
              isTouched={touched?.spokenLanguages}
              error={errors?.spokenLanguages}
            />
          </div>
        </div>
      </section>
      {/* More Info */}
      <section className='col-lg-12 mb-3'>
        <Label forId='moreInfo' text='More Info' isRequired={true} />
        <CustomTextarea
          id={"moreInfo"}
          name='moreInfo'
          value={values?.moreInfo}
          onChange={handleChange}
          onBlur={handleBlur}
        />
        <ErrorFormik
          isError={errors?.moreInfo}
          isTouched={touched?.moreInfo}
          error={errors?.moreInfo}
        />
      </section>
      {/* Local Host Photo */}
      <section>
        <Label
          forId='localHostPhoto'
          text='Local Host Photo'
          isRequired={true}
        />
        <div className='w-100 d-flex flex-column gap-3'>
          <img
            style={{ width: "250px", height: "250px", objectFit: "cover" }}
            className='img-fluid img-thumbnail rounded'
            src={values.localHostPhoto || localHostPhoto}
            alt='localHostPhoto'
          />
          <FileDropzone
            name={"localHostPhoto"}
            setState={setLocalHostPhoto}
            value={false}
            filesAccept={ACCEPT_IMAGES_TYPES_OBJECT}
            maxSize={MAX_SIZE_IMAGE}
          />
          <ErrorFormik
            isError={errors?.localHostPhoto}
            isTouched={touched?.localHostPhoto}
            error={errors?.localHostPhoto}
          />
        </div>
      </section>
      <Hr />
      {/* Buttons */}
      <div className='text-end toolbar toolbar-bottom ps-2'>
        <button
          type='button'
          className='btn btn-secondary sw-btn-prev me-1'
          onClick={() => handlePrev()}
        >
          Prev
        </button>
        <button type='submit' onClick={handleErrors} className='btn btn-primary sw-btn-next ms-1'>
          Next
        </button>
      </div>
    </form>
  );
};

export default StepTwo;
