// ⋆༺𓆩☠︎︎𓆪༻⋆ Do not change the order of the arrays. I repeat, do not change the order of the arrays ⋆༺𓆩☠︎︎𓆪༻⋆

export const MAX_SIZE_IMAGE = 10 * 1_048_576;
export const MAX_SIZE_VIDEO = 360 * 1_048_576;
export const MAX_SIZE_PDF = 5 * 1_048_576;

export const ROLES = {
  SUPER_ADMIN: 1,
  PARTNER: 3,
};
export const USER_DETAILS = "userDetails";

export const APPROVAL_STATUS = {
  INACTIVE: 0,
  ACTIVE: 1,
  DECLINE: 2,
  PENDING: 3,
};

export const PLACE_TYPE_OPTIONS = [
  { value: "Experience", label: "Experience" },
  { value: "Event", label: "Event" },
  { value: "Offers & Packages", label: "Offers & Packages" },
];

export const DIFFICULTY_OPTIONS = [
  { value: "easy", label: "Easy" },
  { value: "moderate", label: "Moderate" },
  { value: "difficult", label: "Hard" },
];

export const AGE_SUITABILITY_OPTIONS = [
  { value: "adults", label: "Adults" },
  { value: "children", label: "Children" },
  { value: "infants", label: "Infants" },
];

export const S3_FILES_NAME = {
  EXTRA_PLACES: "extra_places",
  PROFILE: "profile",
  PAYMENT_INFO: "paymentInfo",
  PLACES_MEDIA: "places_media",
  CONTINENTS: "continents"
};

export const ACCEPT_IMAGES_TYPES_OBJECT = {
  "image/jpeg": [],
  "image/png": [],
  "image/webp": [],
  "image/jpg": [],
  "image/heic": [], 
};

export const ACCEPT_VIDEO_TYPES_OBJECT = {
  "video/mp4": [],
  "video/webm": [],
  "video/ogg": [],
};
export const ACCEPT_PDF_TYPES_OBJECT = {
  "application/pdf": [],
};

export const SEARCH_SELECT = [
  { value: "name", label: "Name" },
  { value: "country", label: "Country" },
  { value: "city", label: "City" },
];

export const PAYMENT_SELECT = [
  { value: "Cliq", label: "Cliq" },
  { value: "eWallets", label: "eWallets" },
  { value: "Bank transfer", label: "Bank transfer" },
  { value: "On-site Card Payment", label: "On-site Card Payment" },
  { value: "On-site Cash Payment", label: "On-site Cash Payment" },
  { value: "Exchange Offices", label: "Exchange Offices" },
];

export const SITE_STATUS = {
  DRAFT: 1,
  ACTIVE: 2,
  ARCHIVED: 3,
};

export const SITE_SETUP_STATUS = {
  basicInformation: 1, // redirect to step 1
  coHostInformation: 2, // redirect to step 2
  Media: 3, // redirect to step 3
  bookingInfo: 4, // redirect to step 4
  Availability: 5, // redirect to step 5
  itineraryDetails: 6, // redirect to itinerary details step
  Published: 7, // redirect to step 6
};

export const DAYS_OF_WEEK = [
  "Sunday",
  "Monday",
  "Tuesday",
  "Wednesday",
  "Thursday",
  "Friday",
  "Saturday",
];

export const BOOKING_STATUS = {
  PENDING : 1,
  APPROVED : 2,
  DECLINED : 3,
  CANCELLED : 4,
  REFUNDED : 5,
  COMPLETED : 6
}
export const SOCIAL_MEDIA_TYPE = {
  Apple: "Apple",
  Google: "Google",
  Facebook: "Facebook",
}

export const BOOKING_OPTIONS_TYPE = [
  { value: "true", label: "Stay" },
  { value: "false", label: "Experience" }
];

export const EXPERIENCE_TYPE = [
  { value: "Experience", label: "Experience" },
  { value: "Event", label: "Event" },
  { value: "Offers %26 Packages", label: "Offers & Packages" },
  { value: "Stay", label: "Stay" },
];
// ⋆༺𓆩☠︎︎𓆪༻⋆ Do not change the order of the arrays. I repeat, do not change the order of the arrays ⋆༺𓆩☠︎︎𓆪༻⋆
