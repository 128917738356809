import { GoogleLoginButton } from "react-social-login-buttons";
import { toast } from "react-toastify";
import { SOCIAL_MEDIA_TYPE } from "../../../../utils/constants";
import { signInWithGoogle } from "../../../../Firebase";

export default function GoogleLogin({ handleClick, text }) {
  const onClick = async () => {
    try {
      const res = await signInWithGoogle();

      const dataMutate = {
        firstName: res?._tokenResponse?.firstName || "",
        lastName: res?._tokenResponse?.lastName || "",
        // if they're not email don't put it in the payload 
        ...(res?._tokenResponse?.email && { email: res?._tokenResponse?.email }),
        profileImageUrl: res?._tokenResponse?.photoUrl || "",
        socialMediaId: res?._tokenResponse?.localId || "",
        socialLoginType: {
          [SOCIAL_MEDIA_TYPE.Google]: SOCIAL_MEDIA_TYPE.Google,
        },
        tokens: res?._tokenResponse?.localId || "",
      };
      
      handleClick(dataMutate)
    } catch (error) {
      console.error("error signInWithGoogle : ", error);
      toast.error("Something went wrong, please try again later!");
    }
  };

  return (
    <div>
      <GoogleLoginButton
        text={text}
        onClick={onClick}
        style={{
          borderRadius: "0.8rem",
          margin: 0,
          width: "100%",
        }}
      />
    </div>
  );
}
