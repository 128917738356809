import Loader from "./../jsx/components/ui/Loader";
import { ROLES } from "../utils/constants";
import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import useUser from "../utils/hooks/useUser";

// Define public paths that everyone can access
const publicPaths = [
  "/",
  "/sign-up",
  "/forgot-password",
  "/verification-code",
  "/reset-password",
  "/error-403",
  "/error-400",
  "/page-error-404",
  "/error-500",
  "/error-503",
  "/lock-screen",
  "/complete-information",
  "/public-terms",
  "/public-privacy"
];

// Define allowed paths for each role
const allowedPathsForRole = {
  [ROLES.SUPER_ADMIN]: [
    "/dashboard",
    "/add-experience",
    "/edit-experience",
    "/add-stay",
    "/edit-stay",
    "/your-experiences",
    "/accessibility-features",
    "/payment-type",
    "/experience-types",
    "/spoken-languages",
    "/amenities",
    "/stay-types",
    "/profile",
    "/bookings-list",
    "/bookings-details",
    "/privacy-policy",
    "/terms-and-conditions",
    "/booking-options",
    "/FAQ-management",
    "/change-password",
    "/announcements-notifications",
    "/help-center",
    "/details-page",
    "/currency-rate"
  ],
  [ROLES.PARTNER]: [
    "/dashboard",
    "/dashboard-dark",
    "/add-experience",
    "/edit-experience",
    "/add-stay",
    "/edit-stay",
    "/your-experiences",
    "/profile",
    "/bookings-list",
    "/bookings-details",
    "/change-password",
  ],
  // Add more roles and their allowed paths as needed
};

const CustomAuthProvider = ({ children }) => {
  const location = useLocation();
  const [loading, setLoading] = useState(true);
  const { userData, redirectTo, isAuthenticUser } = useUser();

  useEffect(() => {
    const checkAccess = async () => {
      if (!isAuthenticUser) {
        if (!publicPaths.includes(location.pathname)) {
          redirectTo("/error-403");
        }
      } else {
        const allowedPaths = allowedPathsForRole[userData?.role?.id] || [];
        const isAllowed = allowedPaths.some((path) => {
          if (typeof path === "string") {
            return location.pathname === path;
          } else if (path instanceof RegExp) {
            return path.test(location.pathname);
          }
          return false;
        });

        if (!isAllowed && !publicPaths.includes(location.pathname)) {
          redirectTo("/error-403");
        }
      }

      setLoading(false);
    };

    checkAccess();
  }, [location.pathname]);

  return <>{loading ? <Loader /> : children}</>;
};

export default CustomAuthProvider;
